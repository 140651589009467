<script setup lang="ts">
    /**
     * Implements software requirement: H1SR-68
     *
     * @link https://formuslabs.youtrack.cloud/issue/H1SR-68/User-can-authenticate-with-their-email-as-a-username-and-their-chosen-password
     */
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { ROUTES } from '@/router';
    import AppTextInput from '@/components/inputs/AppTextInput.vue';
    import { LOGOUT_REASON, useAuth } from '@/stores/auth';

    const auth = useAuth();
    const router = useRouter();

    const isOpen = true;
    const isValid = ref(false);
    const isLoading = ref(false);
    const error = ref('');
    const email = ref('');
    const password = ref('');

    const isEmail = (value: string) =>
        !!value.match(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm);
    const emailRules = [(value: string) => isEmail(value) || 'Login email address is not valid'];
    const passwordRules = [
        (value: string) => (value ? true : 'Authentication password is not valid'),
    ];

    const submit = async () => {
        error.value = '';
        isLoading.value = true;

        const credentials = { email: email.value, password: password.value };
        const [loginSuccessful, message] = await auth.login(credentials);

        if (loginSuccessful) {
            router.push({ name: ROUTES.HOME });
        } else {
            error.value = message;
        }

        isLoading.value = false;
    };
</script>

<template>
    <v-form v-model="isValid">
        <v-row justify="center">
            <v-dialog :model-value="isOpen" :persistent="true" width="600">
                <v-card>
                    <app-modal-title>Log In</app-modal-title>

                    <app-modal-content>
                        <v-alert
                            v-if="auth.logoutReason === LOGOUT_REASON.IDLE_TIMEOUT"
                            class="mb-8"
                        >
                            <b>You were logged out.</b>
                            In order to ensure you and your patients&rsquo; security, you are
                            automatically logged out after 60 minutes of inactivity. Please log in
                            again to continue.
                        </v-alert>
                        <v-alert
                            v-if="error"
                            type="error"
                            :text="error"
                            variant="tonal"
                            class="mb-8"
                        />
                        <app-form-group label="Email" size="fluid">
                            <app-text-input
                                v-model="email"
                                :rules="emailRules"
                                type="email"
                                aria-label="email"
                                clearable
                            />
                        </app-form-group>
                        <app-form-group label="Password" size="fluid">
                            <app-text-input
                                v-model="password"
                                :rules="passwordRules"
                                type="password"
                                aria-label="password"
                                clearable
                            />
                        </app-form-group>
                    </app-modal-content>

                    <app-modal-actions>
                        <router-link :to="{ name: ROUTES.FORGOT_PASSWORD }">
                            <app-link>Forgot Password?</app-link>
                        </router-link>
                        <app-button
                            @click="submit"
                            :disabled="!isValid"
                            :loading="isLoading"
                            colour="green"
                        >
                            Log in
                        </app-button>
                    </app-modal-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-form>
</template>
