import { planeNode, type PlaneNode } from '@/planner/3d/plane';
import {
    BufferGeometry,
    Color,
    DoubleSide,
    LineBasicMaterial,
    LineSegments,
    Mesh,
    MeshBasicMaterial,
    type MeshBasicMaterialParameters,
    MeshStandardMaterial,
    Plane,
    ShaderMaterial,
    Vector3,
} from 'three';
import type { PlannerStore } from '@/planner/plannerStore';
import { watchImmediate } from '@vueuse/core';
import { stop, stopAll, type StopHandle } from '@/util';
import { type PlannerMaterialId, plannerMaterialIds } from '@/planner/scene/plannerMaterial';
import { useDeveloperSettings } from '@/planner/developerSettings';
import type { PlannerSceneContext } from '@/planner/scene/plannerSceneContext';
import { updateCrossSectionOutline } from '@/planner/3d/crossSectionOutline';
import { isMaterialId } from '@/planner/3d/materials/materialId';
import { isMeshNode, type MeshNode } from '@/planner/3d/mesh';
import { computed, reactive } from 'vue';
import type { SceneContext } from '@/planner/3d/SceneContext';
import { isDoubleSidedMaterial } from '@/planner/3d/materials/meshMaterial';

export const crossSectionIds = [
    'stem-neck-cross-section',
    'stem-coronal-cross-section',
    'cup-coronal-cross-section',
] as const;

/** Identifies cross-sections */
export type CrossSectionId = (typeof crossSectionIds)[number];

export function isCrossSectionId(value: string): value is CrossSectionId {
    return crossSectionIds.includes(value as CrossSectionId);
}

export type CrossSectionNode = PlaneNode & {
    id: CrossSectionId;

    /** Show the plane iteself when it is toggled-on. Defaults to false. */
    showPlane: boolean;
};

export function crossSectionNode(
    id: CrossSectionId,
    properties?: Partial<CrossSectionNode>,
): CrossSectionNode {
    if (!_crossSectionMaterial) {
        _crossSectionMaterial = new MeshBasicMaterial(_CROSS_SECTION_MATERIAL_PARAMETERS);
    }
    return {
        ...planeNode(id, {
            ..._CROSS_SECTION_INITIAL_PROPERTIES,
            material: _crossSectionMaterial,
            ...properties,
        }),
        id,
        showPlane: properties?.showPlane ?? false,
    };
}

/** Update the cross-section plane */
export function updateCrossSection(store: PlannerStore, node: CrossSectionNode) {
    return watchImmediate(
        () => store.visibility[node.id],
        (visibility) => {
            if (visibility === 'on') {
                node.visible = node.showPlane || (useDeveloperSettings().show3dFeatures ?? false);
            } else {
                node.visible = false;
            }
        },
    );
}

/** Update the cross-section clipping and outlines */
export function updateCrossSectionClipping(
    store: PlannerStore,
    context: PlannerSceneContext,
): StopHandle {
    let update: StopHandle | null = null;

    return stopAll(
        watchImmediate(
            () => {
                const ids = crossSectionIds.filter((id) => store.visibility[id] === 'on');
                return ids.length === 0 ? null : ids[0];
            },
            (crossSection: CrossSectionId | null) => {
                stop(update);
                update = null;

                if (crossSection !== null) {
                    update = _updateClippingPlane(context, crossSection);
                }
            },
        ),
        () => stop(update),
    );
}

function _updateClippingPlane(
    context: PlannerSceneContext,
    crossSection: CrossSectionId,
): StopHandle {
    const clippingPlane = reactive<Plane>(new Plane());
    const materialsToClip = _materialsToClip(crossSection);

    const result = stopAll(
        watchImmediate(
            () => context.worldTransform(crossSection),
            (worldTransform) => {
                const normal = new Vector3(0, 0, -1).transformDirection(worldTransform);
                const position = new Vector3().setFromMatrixPosition(worldTransform);
                return clippingPlane.setFromNormalAndCoplanarPoint(normal, position);
            },
            { deep: true }
        ),
        ...Array.from(context.nodes.values()).flatMap((node) => {
            if (!isMeshNode(node) || !_shouldClipMesh(materialsToClip, node)) {
                return [];
            }
            return _updateOutline(context, clippingPlane, node);
        }),
        () => {
            plannerMaterialIds.forEach((id) => (context.getMaterial(id).clippingPlanes = []));
        },
    );

    plannerMaterialIds.forEach((id) => {
        context.getMaterial(id).clippingPlanes = materialsToClip.has(id) ? [clippingPlane] : [];
    });

    return result;
}

/**
 * Add and update cross-section-outline to a mesh
 */
export function _updateOutline(
    context: SceneContext,
    clippingPlane: Plane,
    meshNode: MeshNode,
): StopHandle {
    const mesh = context.getObject(meshNode.id) as Mesh;
    if (!mesh.isMesh) {
        throw new Error('Object to outline is not a Mesh');
    }

    const outlineMaterial = new LineBasicMaterial({ color: _getMeshColor(mesh) });
    const outlineSegments = new LineSegments(new BufferGeometry(), outlineMaterial);
    mesh.add(outlineSegments);

    return stopAll(
        updateCrossSectionOutline(
            outlineSegments,
            clippingPlane,
            computed(() => meshNode.geometry),
            computed(() => context.worldTransform(meshNode.id)),
        ),
        () => {
            mesh.remove(outlineSegments);
            outlineMaterial.dispose();
            outlineSegments.geometry.dispose();
        },
    );
}

function _shouldClipMesh(materialsToClip: Set<PlannerMaterialId>, node: MeshNode): boolean {
    if (node.geometry === null) {
        return false;
    } else if (isMaterialId(node.material)) {
        return materialsToClip.has(node.material as PlannerMaterialId);
    } else if (isDoubleSidedMaterial(node.material)) {
        return materialsToClip.has(node.material.front as PlannerMaterialId);
    } else {
        return false;
    }
}

/**
 * Get the mesh object material diffuse color or return random is no diffuse color available
 */
function _getMeshColor(mesh: Mesh): Color {
    if (useDeveloperSettings().highlightOutlines) {
        return new Color('#b7ff00')
    } else if (mesh.material instanceof ShaderMaterial) {
        return mesh.material.uniforms.diffuse.value;
    } else if (mesh.material instanceof MeshStandardMaterial) {
        return mesh.material.color;
    } else {
        return new Color().setHex(Math.random() * 0xffffff);
    }
}

function _materialsToClip(id: CrossSectionId): Set<PlannerMaterialId> {
    switch (id) {
        case 'stem-neck-cross-section':
            return new Set([
                'operative-femur-front',
                'operative-femur-back',
                'operative-femur-xray',
                'operative-femur-ics-front',
                'operative-femur-ics-back',
                'operative-femur-ics-xray',
            ]);
        case 'stem-coronal-cross-section':
            return new Set([
                'bone-front',
                'bone-back',
                'bone-xray',
                'operative-femur-front',
                'operative-femur-back',
                'operative-femur-xray',
                'operative-femur-ics-front',
                'operative-femur-ics-back',
                'operative-femur-ics-xray',
                'implant-white-front',
                'implant-white-back',
                'implant-white-xray',
                'implant-blue-front',
                'implant-blue-back',
                'implant-blue-xray',
            ]);
        case 'cup-coronal-cross-section':
            return new Set([
                'bone-front',
                'bone-back',
                'bone-xray',
                'operative-femur-front',
                'operative-femur-back',
                'operative-femur-xray',
                'operative-femur-ics-front',
                'operative-femur-ics-back',
                'operative-femur-ics-xray',
                'implant-white-front',
                'implant-white-back',
                'implant-white-xray',
                'implant-blue-front',
                'implant-blue-back',
                'implant-blue-xray',
            ]);
        default:
            throw Error(`Unexpected cross-section id '${id}'`);
    }
}

const _CROSS_SECTION_INITIAL_PROPERTIES: Partial<PlaneNode> = {
    width: 100,
    height: 100,
    /* TODO: I have no idea why this would need to have 50 segments, but that's what it was in the
        vue-2 app. When things are working change it to 1 and I think it should work the same. */
    widthSegments: 50,
    heightSegments: 1,
    renderOrder: 1,
    visible: false,
} as const;

const _CROSS_SECTION_MATERIAL_PARAMETERS: MeshBasicMaterialParameters = {
    opacity: 0.4,
    color: '#4343f9',
    transparent: true,
    side: DoubleSide,
} as const;

let _crossSectionMaterial: MeshBasicMaterial | null = null;
