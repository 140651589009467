/**
 * Implements software requirements: H1SR-95
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-95/Information-about-the-patient-can-be-entered-and-edited-in-the-patient-information-section-of-case-creation
 */
import { AxiosError, type AxiosRequestConfig, type AxiosResponse, HttpStatusCode } from 'axios';
import { isEmpty } from 'lodash';
import { client } from '@/api/http';
import type {
    CaseDetailsApiObjectBase,
    CaseSettingsApiObject,
    OptionsApiResponse,
} from '@/api/case/types';
import { getIdFromUrl } from '@/lib/getIdFromUrl';
import type { StudyCopyCreateDataRepresentation } from '@/stores/admin/store';
import { AppError } from '@/stores/appErrorStore';
import { errorDetail } from '@/planner/api/errorDetail';

function isValidApiResponse({ options }: OptionsApiResponse): boolean {
    return !isEmpty(options?.stems) && !isEmpty(options?.surgeons);
}

export async function getCaseSettingsOptions(): Promise<OptionsApiResponse> {
    return client
        .get('/cases/options')
        .then(({ status, data }: AxiosResponse<OptionsApiResponse>) => {
            if (status === HttpStatusCode.Ok && isValidApiResponse(data)) {
                return data;
            } else {
                throw Error('Failed to load case settings options');
            }
        });
}

export async function getCaseSettings(
    id: number,
    config?: AxiosRequestConfig,
): Promise<CaseSettingsApiObject> {
    return client.get(`/cases/${id}/settings`, config).then(({ status, data }: AxiosResponse) => {
        if (status === HttpStatusCode.Ok) {
            return data;
        } else if (status === HttpStatusCode.NotFound || status === HttpStatusCode.Forbidden) {
            throw new AppError(
                'Case not found' + errorDetail(data),
                'This case does not exist or you do not have permission to view it.',
            );
        }

        throw Error(`Failed to load case settings: ${data?.detail}`);
    });
}

export async function getCaseDetails(
    id: number,
    config?: AxiosRequestConfig,
): Promise<CaseDetailsApiObjectBase> {
    return client.get(`/cases/${id}`, config).then(({ status, data }: AxiosResponse) => {
        if (status === HttpStatusCode.Ok) {
            return data;
        } else if (status === HttpStatusCode.NotFound || status === HttpStatusCode.Forbidden) {
            throw new AppError(
                'Case not found' + errorDetail(data),
                'This case does not exist or you do not have permission to view it.',
            );
        }

        throw Error(`Failed to load case settings: ${data?.detail}`);
    });
}

export async function createCaseSettings(body: any): Promise<number> {
    return client.post('/cases/', body).then(({ status, data }: AxiosResponse) => {
        if (status === HttpStatusCode.Created) {
            return data?.id;
        }

        throw new AxiosError(`Failed to save case settings: ${data?.detail}`);
    });
}

export async function updateCaseSettings(caseId: number, body: any): Promise<void> {
    return client
        .put(`/cases/${caseId}/settings`, body, { timeout: 10 * 1000 })
        .then(({ status, data }: AxiosResponse) => {
            if (status === HttpStatusCode.Accepted) {
                return;
            }

            throw new AxiosError(`Failed to update case settings: ${data?.detail}`);
        });
}

export async function createStudy(caseId: number, body: any): Promise<string> {
    const { status, data, headers }: AxiosResponse = await client.post(
        `/cases/${caseId}/studies`,
        body,
    );
    if (status === HttpStatusCode.Created) {
        return data?.id ?? getIdFromUrl(headers.location);
    }

    throw new AxiosError(`Failed to save case settings: ${data?.detail}`);
}

export async function createStudyFromFiles(
    caseId: number,
    body: StudyCopyCreateDataRepresentation,
): Promise<string> {
    const { status, data, headers }: AxiosResponse = await client.post(
        `/project/${caseId}/study`,
        body,
    );

    if (status === HttpStatusCode.Created) {
        return data?.id ?? getIdFromUrl(headers.location);
    }

    throw new AxiosError(`Failed to save case settings: ${data?.detail}`);
}

export async function startStudyProcess(
    caseId: number,
    studyId: number | string,
    webComponentVersion: string,
) {
    const { status, data }: AxiosResponse = await client.post(
        `/cases/${caseId}/studies/${studyId}/process`,
        { web_component_version: webComponentVersion },
        /**
         * This request might take a while to complete in the server side, so we need to increase the timeout
         * The backend publishes a job to the queue and waits until the message was acknowledged by the queue, but
         * during period of high load this takes a while.
         */
        { timeout: 60 * 1000 },
    );
    if (status === HttpStatusCode.Accepted) {
        return;
    }

    throw new AxiosError(`Failed to save case settings: ${data?.detail}`);
}

export async function unassignSurgeon(caseId: number): Promise<void> {
    const { status, data }: AxiosResponse = await client.delete(`/cases/${caseId}/surgeon`);

    if (status === HttpStatusCode.NoContent) {
        return data;
    }

    throw new AxiosError(`Failed to delete user surgeons: ${data?.detail}`);
}
