import anylogger, { type BaseLogger, type Logger as _Logger } from 'anylogger';

export const logLevels = ['error', 'warn', 'warning', 'info', 'log', 'debug', 'trace'] as const;
export type LogLevel = typeof logLevels[number]

/** A function that formats and logs a message */
export type LogFunction = (message?: unknown, ...args: unknown[]) => void;

/**
 * An object with various methods to support logging at various levels
 * */
export type Logger = _Logger & {
    warning(message?: any, ...args: any[]): void;
};

const _defaultLogger: Logger = _adaptLogger(anylogger('formus-planner'));

export type LoggerOptions = {
    /** The name of the logger to create */
    name?: string
    /**
     * The logging-level. If this is given the result will be a log *function* not a
     * {@link Logger} object
     */
    level?: LogLevel
}

export function logger(name?: string): Logger
export function logger(options: { name?: string }): Logger
export function logger(options: { name?: string, level: LogLevel }): LogFunction

/**
 * Create either:
 * - a logger object with debug/info/error member functions, if no level is given in the options
 * - a log function bound to a particular level if one is given in the options
 */
export function logger(options?: string | LoggerOptions): Logger | LogFunction {
    if (options === undefined || typeof options === 'string') {
        return _makeLogger(options)
    }
    const { name, level } = options;

    const log = _makeLogger(name)

    switch (level) {
        case undefined:
            return log
        case 'error':
            return log.error.bind(log);
        case 'warn':
        case 'warning':
            return log.warn.bind(log);
        case 'info':
            return log.info.bind(log);
        case 'log':
            return log.log.bind(log);
        case 'debug':
            return log.debug.bind(log);
        case 'trace':
            return log.trace.bind(log);
        default:
            throw Error(`Invalid log level: ${level}`);
    }
}

/** Type-guard that checks a value is a valid log-levels */
export function isLogLevel(value: unknown): value is LogLevel {
    return logLevels.includes(value as LogLevel);
}

function _makeLogger(name?: string) {
    return name ? _adaptLogger(anylogger(name)) : _defaultLogger;
}

function _adaptLogger(logger: BaseLogger): Logger {
    (logger as unknown as Record<string, unknown>).warning = logger.warn;
    return logger as unknown as Logger;
}
