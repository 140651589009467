import { drop } from 'ramda';

import { type DicomMessage, DicomMessageLevel, type DicomSeries } from '@/lib/dicom/DicomSeries';
import { IMAGE_TYPE_LOCALISER, IMAGE_TYPE_VRT } from '@/lib/dicom/DicomTags';
import { isFiniteNumber, logger } from '@/util';
import type { DicomInfo } from '@/lib/dicom/DicomInfo';
import ContentType from '@/lib/mimetype';
import DicomUtils from '@/lib/dicom/DicomUtil';
import { DicomInfoUtil } from '@/lib/dicom/DicomInfoUtil';
import { DateUtil } from '@/lib/DateUtil';
import { dicomFileName as filename } from '@/lib/dicom/dicomFileName';

const log = logger('DicomSeriesUtils');

/**
 * Arbitrary ratio tolerance distance between slices
 * Note: 0.05 is a very strict one
 */
const RATIO_TOLERANCE_DISTANCE_BETWEEN_SLICES = 0.05;

const NO_IDENTITY_GROUP_KEY = 'no-identity-group';

/**
 * A unique and global counter to apply to new messages.
 */
let uniqueMessageId = 0;

/**
 *  The well known name for the DICOMDIR file that is usually located at the
 *  root of the DICOM export filesystem.
 *
 *  This is considered to be a case-sensitive filename.
 */
export const DicomdirFilename = 'DICOMDIR';

type DicomInfoCheck = (dicomInfo: DicomInfo) => void;

/**
 * Arbitrary size chosed (100 kbytes)
 */
export const MIN_PIXEL_DATA_LENGTH = 100 * 1024;

export enum BooleanFieldValues {
    Yes = 'YES',
    No = 'NO',
}

export enum DicomPatientSexValues {
    Other = 'O',
    Female = 'F',
    Male = 'M',
}

export type DicomPatientSexValuesType =
    | DicomPatientSexValues.Male
    | DicomPatientSexValues.Female
    | DicomPatientSexValues.Other;

/**
 * Support for various checks on a DICOM series as defined by {@link DicomSeries}.
 *
 * A series is an in memory summary of the DICOM files as a list of {@link DicomInfo}
 * data structures.
 *
 * @see {@link DicomSeries}, {@link DicomInfo}
 */
export class DicomSeriesUtil {
    public static appendMessage(
        messages: DicomMessage[],
        level: DicomMessageLevel,
        message: string,
    ): void {
        messages.push(this.makeMessage(level, message));
    }

    public static makeMessage(level: DicomMessageLevel, message: string): DicomMessage {
        return {
            id: ++uniqueMessageId,
            level,
            message,
        };
    }

    /**
     * Check that the patient information has been removed. If patient identity has been removed, then exclude the file.
     *
     * The reason is that if the patient information has been removed, then we won't be able to check for consistency
     * across all DICOM files, or for consistency with the metadata entered by the surgeon.
     * e.g. patient name, sex and DOB.
     */
    public static excludeIfPatientIdentityRemoved(info: DicomInfo): void {
        if (info?.patientIdentityRemoved === BooleanFieldValues.Yes) {
            DicomSeriesUtil.excludeWithErrorMessage(
                info,
                `The patient information has been removed from file '${info.file.name}'`,
            );
        }
    }

    /**
     * Check the patient name/sex/date of birth (DOB) are the same on all records. Use a previousDicomInfo
     * style pattern to store information between successive calls.
     *
     * @param dicomInfo the record to check
     * @param previousDicomInfo the previousDicomInfo for the operation (this is simplified to just being the first record
     */
    public static excludeIfPatientNamesAreNotTheSame(
        dicomInfo: DicomInfo,
        previousDicomInfo?: DicomInfo,
    ): DicomInfo | undefined {
        if (dicomInfo && !dicomInfo.isExcluded) {
            if (!previousDicomInfo) {
                return dicomInfo; // first record is always logically equivalent
            }
            const patientName = dicomInfo.patientName;
            if (patientName?.familyName && patientName.givenName) {
                if (patientName.familyName === previousDicomInfo.patientName?.familyName) {
                    if (patientName.givenName === previousDicomInfo.patientName?.givenName) {
                        // middle name has to be the same in each scan, but not necessarily present
                        if (patientName.middleName === previousDicomInfo.patientName?.middleName) {
                            // ok
                        } else {
                            DicomSeriesUtil.excludeWithErrorMessage(
                                dicomInfo,
                                `The patient name ('${DicomUtils.formatPatientName(patientName)}') does not match in file '${filename(dicomInfo)}' `,
                            );
                        }
                    } else {
                        DicomSeriesUtil.excludeWithErrorMessage(
                            dicomInfo,
                            `The patient name ('${DicomUtils.formatPatientName(patientName)}') does not match in file '${filename(dicomInfo)}' `,
                        );
                    }
                } else {
                    DicomSeriesUtil.excludeWithErrorMessage(
                        dicomInfo,
                        `The patient name ('${DicomUtils.formatPatientName(patientName)}') does not match in file '${filename(dicomInfo)}' `,
                    );
                }
            } else {
                DicomSeriesUtil.excludeWithErrorMessage(
                    dicomInfo,
                    `The patient given and/or family names are not specified in file '${filename(dicomInfo)}'`,
                );
            }
        }
        return previousDicomInfo;
    }

    /**
     * Check the patient name/sex/date of birth (DOB) are the same on all records. Use an previousDicomInfo
     * style pattern to store information between successive calls.
     *
     * @param dicomInfo the record to check
     * @param previousDicomInfo the previousDicomInfo for the operation (this is simplified to just being the first record
     */
    public static excludeIfPatientSexIsNotTheSame(
        dicomInfo: DicomInfo,
        previousDicomInfo?: DicomInfo,
    ): DicomInfo | undefined {
        if (dicomInfo && !dicomInfo.isExcluded) {
            if (!previousDicomInfo) {
                return dicomInfo; // first record is always logically equivalent
            }

            // Check that we have a value for 'sex'
            if (dicomInfo.patientSex) {
                // Check the sex is one of three expected values
                if (
                    Object.values(DicomPatientSexValues).some(
                        (s: string): boolean => s === dicomInfo.patientSex,
                    )
                ) {
                    if (dicomInfo.patientSex === previousDicomInfo.patientSex) {
                        // success
                    } else {
                        DicomSeriesUtil.excludeWithErrorMessage(
                            dicomInfo,
                            `The patient sex doesn't match in file '${filename(dicomInfo)}'`,
                        );
                    }
                } else {
                    DicomSeriesUtil.excludeWithErrorMessage(
                        dicomInfo,
                        `The patient sex '${dicomInfo.patientSex}' is unexpected in '${filename(dicomInfo)}'`,
                    );
                }
                // name is good
            } else {
                DicomSeriesUtil.excludeWithErrorMessage(
                    dicomInfo,
                    `The patient sex is not specified in file '${filename(dicomInfo)}'`,
                );
            }
        }
        return previousDicomInfo;
    }

    /**
     * Check the patient name/sex/date of birth (DOB) are the same on all records. Use an previousDicomInfo
     * style pattern to store information between successive calls.
     *
     * @param dicomInfo the record to check
     */
    public static patientIdentityKey(dicomInfo: DicomInfo): string {
        if (dicomInfo?.patientIdentityRemoved === BooleanFieldValues.Yes) {
            return NO_IDENTITY_GROUP_KEY;
        } else {
            return JSON.stringify({
                patient: {
                    name: dicomInfo.patientName ?? 'no-patient-name',
                    sex: dicomInfo.patientSex ?? 'no-sex',
                    birth: dicomInfo.patientBirthDate?.getTime() ?? 'no-date-of-birth',
                },
            });
        }
    }

    /**
     * Check the patient name/sex/date of birth (DOB) are the same on all records. Use an previousDicomInfo
     * style pattern to store information between successive calls.
     *
     * @param dicomInfo the record to check
     * @param previousDicomInfo the previousDicomInfo for the operation (this is simplified to just being the first record
     */
    public static excludeIfPatientDateOfBirthIsNotTheSame(
        dicomInfo: DicomInfo,
        previousDicomInfo?: DicomInfo,
    ): DicomInfo | undefined {
        if (dicomInfo && !dicomInfo.isExcluded) {
            if (!previousDicomInfo) {
                return dicomInfo; // first record is always logically equivalent
            }
            // Check that we have a value for 'sex'
            if (dicomInfo.patientBirthDate) {
                // Check the sex is one of three expected values
                if (
                    previousDicomInfo.patientBirthDate &&
                    DateUtil.areEqual(
                        dicomInfo.patientBirthDate,
                        previousDicomInfo.patientBirthDate,
                    )
                ) {
                    // success
                } else {
                    DicomSeriesUtil.excludeWithErrorMessage(
                        dicomInfo,
                        `The patient date of birth (DOB) doesn't match in file '${filename(dicomInfo)}'`,
                    );
                }
            } else {
                DicomSeriesUtil.excludeWithErrorMessage(
                    dicomInfo,
                    `The patient date of birth (DOB) is not specified in file '${filename(dicomInfo)}'`,
                );
            }
        }
        return previousDicomInfo;
    }

    /**
     * Ignore DICOM files with the name 'DICOMDIR'.
     */
    public static checkExcludeDicomdir(dicomInfo: DicomInfo): void {
        if (filename(dicomInfo) === DicomdirFilename) {
            if (dicomInfo.fileType && dicomInfo.fileType.mime === ContentType.Dicom) {
                log.info('Found %s', DicomdirFilename);
                DicomSeriesUtil.excludeWithInfoMessage(
                    dicomInfo,
                    `${DicomdirFilename} file ignored`,
                );
            }
        }
    }

    /**
     * An iterator over non excluded dicom info
     * @param group
     * @param fn
     */
    public static eachNonExcludedDicomInfo(group: DicomSeries, fn: DicomInfoCheck): void {
        if (!group.isExcluded) {
            this.filterExcluded(group).forEach(fn);
        }
    }

    /**
     * Check that the file has a modality of 'CT'.
     *
     * This will exclude files that have PDF or DOC files in them
     * as well as other types of scans.
     */
    public static checkModalityIsCt(dicomInfo: DicomInfo): void {
        if (dicomInfo.modality) {
            if (dicomInfo.modality === 'CT') {
                // Ok
            } else {
                DicomSeriesUtil.excludeWithInfoMessage(
                    dicomInfo,
                    `DICOM file '${filename(dicomInfo)}' with modality of '${dicomInfo.modality}' ignored`,
                );
            }
        } else {
            DicomSeriesUtil.appendMessage(
                dicomInfo.messages,
                DicomMessageLevel.Warning,
                `DICOM file '${filename(dicomInfo)}' has no modality (assuming CT)`,
            );
        }
    }

    /**
     * The photometric interpretation is a mandatory field.
     *
     * WARNING: historically the anonymisation code has stripped this field making reimporting
     * the DICOM series unavailable.
     *
     * (00028,0004) Photometric
     */
    public static checkHasPhotometricInterpretation(dicomInfo: DicomInfo): void {
        if (dicomInfo.photometricInterpretation) {
            // Monochrome1 is a negative, monochrome2 is a positive image
            if (
                ['MONOCHROME2'].some(
                    (s: string): boolean => s === dicomInfo.photometricInterpretation,
                )
            ) {
                // Ok
            } else {
                DicomSeriesUtil.excludeWithInfoMessage(
                    dicomInfo,
                    `DICOM file '${filename(dicomInfo)}' with photometric interpretation of '${dicomInfo.photometricInterpretation}' ignored`,
                );
            }
        } else {
            DicomSeriesUtil.excludeWithWarningMessage(
                dicomInfo,
                `DICOM file '${filename(dicomInfo)}' has no photometric interpretation`,
            );
        }
    }

    public static checkForMultipleImages(group: DicomSeries): void {
        if (!group.isExcluded) {
            // TODO: implement
        }
    }

    /**
     * Enumerate all images in the series and exclude any 'scout images'. These are an
     * images that is highly annotated with text and lines and describe the rest of the
     * series.
     *
     * If a human were to be looking at the series then these may be useful. For machine
     * based reading of the images, these are not required and just unhelpful.
     */
    public static checkForScoutImages(dicomInfo: DicomInfo): void {
        if (dicomInfo.imageType.includes(IMAGE_TYPE_LOCALISER)) {
            DicomSeriesUtil.excludeWithInfoMessage(
                dicomInfo,
                `Scout image in file ${filename(dicomInfo)} ignored`,
            );
        }
    }

    /**
     * Check that the files are not 3d models.
     */
    public static checkForVolumeRenderingTechnique(dicomInfo: DicomInfo): void {
        if (dicomInfo.imageType.includes(IMAGE_TYPE_VRT)) {
            DicomSeriesUtil.excludeWithInfoMessage(
                dicomInfo,
                `Volume image in file ${filename(dicomInfo)} ignored`,
            );
        }
    }

    /**
     * Check that the image inside the DICOM file is present and is larger than
     * some arbitrary size (e.g. 100kbytes).
     *
     * The actual size isn't important, except we want to exclude obviously invalid images
     * that are not CT images.
     *
     * @see {@link https://dicom.innolitics.com/ciods/us-image/image-pixel/7fe00010}
     */
    public static checkImageSizes(group: DicomSeries): void {
        if (!group.isExcluded) {
            this.filterExcluded(group).forEach((dicomInfo) => {
                if (!dicomInfo.pixelDataLength) {
                    DicomSeriesUtil.excludeWithErrorMessage(
                        group,
                        `No pixel data in some DICOM files.`,
                    );
                    DicomSeriesUtil.excludeWithErrorMessage(
                        dicomInfo,
                        `No pixel data in ${filename(dicomInfo)}`,
                    );
                } else if (dicomInfo.pixelDataLength < MIN_PIXEL_DATA_LENGTH) {
                    DicomSeriesUtil.excludeWithInfoMessage(
                        dicomInfo,
                        `Small image (pixel data) ${filename(dicomInfo)} is excluded`,
                    );
                } // else it is assumed the pixel data is valid
            });
        }
    }

    /**
     * Check if the 'BurnedInAnnotation' flag is set. For ACID images this files should be excluded
     * for the following reasons:
     *
     *  - the annotation can't be anonymised. The client size processing is intended to
     *  only upload anonymised data to the ACID server.
     *
     *  - the annotation will mean that the machine reading of the images will have less
     *  than ideal outcomes as the training does not include annotations.
     */
    public static checkForBurnedInAnnotation(dicomInfo: DicomInfo): void {
        if (dicomInfo.burnedInAnnotation === BooleanFieldValues.Yes) {
            DicomSeriesUtil.excludeWithInfoMessage(
                dicomInfo,
                `Image in file ${filename(dicomInfo)} contains annotations`,
            );
        }
    }

    /**
     * The images must be 512 x 512 pixel images.
     *
     * Check that all files in the series (that haven't already been excluded) for
     * other reasons are 512x512 pixels.
     *
     * The current segmentation can handle slightly different images, but the
     * new segmentation will have run training on 512x512 images only.
     */
    public static checkFor512x512Image(group: DicomSeries): void {
        function is512x512(i: DicomInfo): boolean {
            const ok = i.columns && i.rows && i.columns === 512 && i.rows === 512;
            if (!ok) {
                log.info('File %s has %d x %d pixels', i.file.name, i.rows, i.columns);
            }
            return !!ok;
        }

        if (!group.isExcluded) {
            if (!DicomSeriesUtil.filterExcluded(group).every((i) => is512x512(i))) {
                DicomSeriesUtil.excludeWithInfoMessage(
                    group,
                    `All images must be 512 by 512 pixels`,
                );
            }
        }
    }

    /**
     * Enumerate all file that haven't been excluded and check that
     *   - the file has a pixel spacing
     *   - the x & y pixel spacing are equivalent
     *   - the pixel spacing is less than 3.0mm
     * If it fails then mark the series as excluded. If any file has a pixel spacing greater than
     * 1.5mm then add a warning to that file.
     */
    public static checkPixelSpacing(group: DicomSeries): void {
        if (
            !DicomSeriesUtil.filterExcluded(group).every((i) => {
                if (i.pixelSpacing && i.pixelSpacing.length === 2) {
                    const [x, y] = i.pixelSpacing;
                    if (x !== undefined && y !== undefined) {
                        if (areEqualWithinTolerance(x, y, 0.01)) {
                            if (x <= 3.0 /* mm */ && y < 3.0 /* mm */) {
                                if (x > 1.5 /* mm */ || y > 1.5) {
                                    DicomSeriesUtil.appendMessage(
                                        i.messages,
                                        DicomMessageLevel.Warning,
                                        `The pixel spacing is greater than 1.5mm`,
                                    );
                                }
                                return true;
                            }
                        }
                    }
                }
                return false;
            })
        ) {
            DicomSeriesUtil.excludeWithInfoMessage(
                group,
                `Pixel spacing must be isotropic and less than 3mm`,
            );
        }
    }

    /**
     * Check that every image in the series has the correct patient orientation. The
     * orientation is expressed as a vector of 6 numbers (3 positions, 3 cosines).
     *
     * @see {@link https://dicom.innolitics.com/ciods/ct-image/image-plane/00200032}
     */
    public static checkImageOrientationPatient(group: DicomSeries): void {
        if (
            !DicomSeriesUtil.filterExcluded(group).every((i) => {
                if (i.imageOrientationPatient && i.imageOrientationPatient.length === 6) {
                    const [p1, p2, p3, p4, p5, p6] = i.imageOrientationPatient;

                    if (
                        p1 !== undefined &&
                        areEqualWithinTolerance(p1, 1.0, 0.001) &&
                        p2 !== undefined &&
                        areEqualWithinTolerance(p2, 0.0, 0.001) &&
                        p3 !== undefined &&
                        areEqualWithinTolerance(p3, 0.0, 0.001) &&
                        p4 !== undefined &&
                        areEqualWithinTolerance(p4, 0.0, 0.001) &&
                        p5 !== undefined &&
                        areEqualWithinTolerance(p5, 1.0, 0.001) &&
                        p6 !== undefined &&
                        areEqualWithinTolerance(p6, 0.0, 0.001)
                    ) {
                        return true;
                    }
                }
                log.info(
                    "File '%s' image orientation patient %o",
                    i.file.name,
                    i.imageOrientationPatient,
                );
                return false;
            })
        ) {
            DicomSeriesUtil.excludeWithInfoMessage(
                group,
                `The image orientation patient must be [1.0, 0.0, 0.0, 0.0, 1.0, 0.0]`,
            );
        }
    }

    /**
     * Check distance between slices is roughly the same,
     * withing a ratio {@see RATIO_TOLERANCE_DISTANCE_BETWEEN_SLICES}, to identify missing slices on a set
     *
     * E.g: Given a group with 5 dicom info, each of them with imagePositionPatient = [0,0, valueOfInterest]
     * 1. It calculates the minimum and maximum distance
     * 2. The minimum distance should be in the range of [maximum - ratio, maximum + ratio ]
     * 3. If 2 is not true, it excludes the group
     */
    public static checkDistanceBetweenConsecutiveSlices(group: DicomSeries): void {
        if (!group.isExcluded) {
            const allDistances = this.mapDistancesBetweenSlices(group);
            const minimumDistance = Math.min(...allDistances);
            const maxDistance = Math.max(...allDistances);

            log.info(
                'Distance between slices: minimum distance is %s and maximum distance %s',
                minimumDistance,
                maxDistance,
            );

            const distanceTolerance = maxDistance * RATIO_TOLERANCE_DISTANCE_BETWEEN_SLICES;
            if (areEqualWithinTolerance(minimumDistance, maxDistance, distanceTolerance)) {
                // series distances looks good, nothing to do
                log.debug(
                    'Min and max distances are equal with a tolerance of %s',
                    distanceTolerance,
                );
            } else {
                DicomSeriesUtil.excludeWithErrorMessage(
                    group,
                    `Image distance between slices does not seem correct, possibly missing a slice. Excluding series.`,
                );
                log.error(
                    'Min and max distances are not equal with a tolerance of %s',
                    distanceTolerance,
                );
            }
        }
    }

    /**
     * Map distances of non excluded dicom files
     */
    private static mapDistancesBetweenSlices(group: DicomSeries) {
        const nonExcludedDicoms = this.filterExcluded(group);

        const withoutFirst = drop(1, nonExcludedDicoms);
        return withoutFirst.map((dicomInfo, index): number => {
            const currentImagePosition = DicomInfoUtil.verticalImagePositionPatient(dicomInfo);

            if (isFiniteNumber(currentImagePosition)) {
                const previousDicom = nonExcludedDicoms[index];
                const previousImagePosition =
                    DicomInfoUtil.verticalImagePositionPatient(previousDicom);

                if (isFiniteNumber(previousImagePosition)) {
                    return Math.abs(previousImagePosition - currentImagePosition);
                } else {
                    throw new Error('previous image position should be a valid number');
                }
            } else {
                throw new Error('current image position should be a valid number');
            }
        });
    }

    /**
     * Check all images have the same slice thickness and that the slice thickness
     * is less than 3.0mm. If the slice thickness is greater than 2.0mm generate a
     * warning.
     */
    public static checkSliceThickness(group: DicomSeries): void {
        const result = DicomSeriesUtil.filterExcluded(group)
            // only consider those files with a slice thickness
            .filter((i) => i.sliceThickness)
            .reduce(
                (result, i, index) => {
                    const thickness: number = i.sliceThickness as number;
                    // store the thickness of the first file, and use this to compare
                    // aginst all other files (including the first one)
                    if (index === 0) {
                        result.thickness = thickness;
                    }
                    if (areEqualWithinTolerance(thickness, result.thickness, 0.01)) {
                        if (thickness <= 3.0 /* mm */) {
                            if (thickness > 2.0 /* mm */) {
                                result.warning = true;
                            }
                            return result; // early successful return
                        } // else drop through and fail the series
                    } // else drop through and fail the series
                    result.isExcluded = true;
                    return result;
                },
                {
                    isExcluded: false,
                    thickness: 0.0,
                    warning: false,
                },
            );

        if (result.isExcluded) {
            DicomSeriesUtil.excludeWithErrorMessage(
                group,
                `Images must have a slice thickness less than 3.0mm`,
            );
        } else if (result.warning) {
            DicomSeriesUtil.appendMessage(
                group.messages,
                DicomMessageLevel.Warning,
                `Images should not have a slice thickness greater than 2.0mm`,
            );
        }
    }

    /**
     * Enumerate all images in the series and exclude the series if an on the images
     * are compressed (i.e. not the raw format)
     */
    public static checkCompressedImageFormat(group: DicomSeries): void {
        const allowed = [
            '1.2.840.10008.1.2', //      Implicit VR Endian: Default Transfer Syntax for DICOM
            '1.2.840.10008.1.2.1', //    Explicit VR Little Endian
            '1.2.840.10008.1.2.1.99', // Deflated Explicit VR Little Endian
            '1.2.840.10008.1.2.2', //    Explicit VR Big Endian
        ];

        if (!group.isExcluded) {
            group.items.forEach((i) => {
                if (i.transferSyntaxUid) {
                    if (!allowed.includes(i.transferSyntaxUid)) {
                        DicomSeriesUtil.excludeWithInfoMessage(
                            group,
                            `Image with non-raw transfer syntax found (${i.transferSyntaxUid})`,
                        );
                        //  early return, as the series is marked as excluded.
                    } // else OK
                } // else there is no transfer syntax to check
            });
        }
    }

    /**
     * Check that the image position patient is valid. Without this the
     * images can not be sorted.
     *
     */
    public static checkImagePositionPatient(dicomInfo: DicomInfo): void {
        if (isFiniteNumber(DicomInfoUtil.verticalImagePositionPatient(dicomInfo))) {
            // ok
        } else {
            DicomSeriesUtil.excludeWithInfoMessage(
                dicomInfo,
                `Image '${filename(dicomInfo)}' has invalid image position patient`,
            );
        }
    }

    /**
     * Enumerate all images in the series and exclude the series if an on the images
     * are compressed (i.e. not the raw format)
     */
    public static checkFilesHaveInstanceNumber(group: DicomSeries): void {
        group.items.forEach((dicomInfo) => {
            if (!dicomInfo.instanceNumber) {
                DicomSeriesUtil.excludeWithInfoMessage(dicomInfo, `Instance number not found`);
            } // else the instance number is good
        });
    }

    /**
     * Sort the series by the third dimension of 'Image Position Patient' (0020,0032)
     *
     * This will sort them in reverse order (highest) 'z' value first, with invalid and
     * excluded items last.
     */
    public static sortSeriesByImagePositionPatient(group: DicomSeries): void {
        function zItem(item: DicomInfo, defaultValue: number): number {
            if (Array.isArray(item.imagePositionPatient)) {
                const zValue = item.imagePositionPatient[2];
                return isFiniteNumber(zValue) ? zValue : defaultValue;
            }
            return defaultValue;
        }

        if (!group.isExcluded) {
            const nonPosition = -99999; // any value smaller than the minimum 'z' value
            group.items.sort((l: DicomInfo, r: DicomInfo) => {
                const lPosition: number = !l.isExcluded ? zItem(l, nonPosition) : nonPosition;
                const rPosition: number = !r.isExcluded ? zItem(r, nonPosition) : nonPosition;
                return rPosition - lPosition;
            });
        }
    }

    public static checkHasSufficientFiles(group: DicomSeries): void {
        if (!group.isExcluded) {
            const imageCount = DicomSeriesUtil.getImageCount(group);
            if (imageCount < 50) {
                DicomSeriesUtil.excludeWithInfoMessage(
                    group,
                    `DICOM series has less than 50 images (${imageCount})`,
                );
            }
        }
    }

    /**
     * Multiply the slice spacing by the number of slices to determine if the linear distance
     * is long enough to cover the joint.
     *
     * The distance is gated at 200.0mm
     */
    public static checkSliceSpacing(group: DicomSeries): void {
        if (!group.isExcluded) {
            // TODO: calculate this
            // TODO: calculate this
            // TODO: calculate this
            const sliceSpacing = 3.0;
            const imageCount = DicomSeriesUtil.getImageCount(group);

            if (sliceSpacing * imageCount < 200.0 /* mm */) {
                DicomSeriesUtil.excludeWithInfoMessage(
                    group,
                    `DICOM series has insufficient linear coverage (${sliceSpacing * imageCount}mm)`,
                );
            }
        }
    }

    private static getImageCount(group: DicomSeries): number {
        return DicomSeriesUtil.filterExcluded(group).length;
    }

    private static filterExcluded(group: DicomSeries): DicomInfo[] {
        return group.items.filter((dicomInfo) => !dicomInfo.isExcluded);
    }

    /**
     * Utility that excludes a DicomSeries or DicomInfo, and appends a message (info by default).
     *
     * @param dicomSeriesOrInfo
     * @param message
     * @param level optional, info by default
     * @private
     */
    private static excludeWithMessage(
        dicomSeriesOrInfo: DicomSeries | DicomInfo,
        message: string,
        level = DicomMessageLevel.Info,
    ) {
        if (!dicomSeriesOrInfo.isExcluded) {
            dicomSeriesOrInfo.isExcluded = true;
            DicomSeriesUtil.appendMessage(dicomSeriesOrInfo.messages, level, message);
        }
    }

    private static excludeWithInfoMessage(
        dicomSeriesOrInfo: DicomSeries | DicomInfo,
        message: string,
    ) {
        DicomSeriesUtil.excludeWithMessage(dicomSeriesOrInfo, message, DicomMessageLevel.Info);
    }

    private static excludeWithWarningMessage(
        dicomSeriesOrInfo: DicomSeries | DicomInfo,
        message: string,
    ) {
        DicomSeriesUtil.excludeWithMessage(dicomSeriesOrInfo, message, DicomMessageLevel.Warning);
    }

    private static excludeWithErrorMessage(
        dicomSeriesOrInfo: DicomSeries | DicomInfo,
        message: string,
    ) {
        DicomSeriesUtil.excludeWithMessage(dicomSeriesOrInfo, message, DicomMessageLevel.Error);
    }
}

/**
 * Check if two values are equal within a tolerance.
 */
function areEqualWithinTolerance(a: number, b: number, tolerance: number): boolean {
    return Math.abs(a - b) < tolerance;
}
