<template>
    <div class="mt-5">
        <div class="d-flex justify-content-end">
            <v-spacer />
            <v-checkbox
                density="compact"
                :disabled="!isReady"
                label="Show CT Scans"
                class="d-inline-flex"
                @change="toggleVisibility"
            />
        </div>
        <div v-if="planner.catstacks.visible">
            <div class="range-holder">
                <label class="mt-2">CT Coronal:</label>
                <v-slider
                    step="1"
                    v-model="coronalValue"
                    :min="0"
                    :max="coronalMax"
                    color="input-blue"
                    track-size="2.5"
                    track-color="formus-outline"
                    thumb-label
                    thumb-size="12"
                    hide-details
                />
            </div>
            <div class="range-holder mt-2">
                <label class="mt-2">CT Axial:</label>
                <v-slider
                    step="1"
                    v-model="axialValue"
                    :min="0"
                    :max="axialMax"
                    color="input-blue"
                    track-size="2.5"
                    track-color="formus-outline"
                    thumb-label
                    thumb-size="12"
                    hide-details
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { assert } from '@/util';
    import { computed, type ComputedRef, type WritableComputedRef } from 'vue';
    import { usePlannerStore } from '@/planner/plannerStore';
    import type { CatstackPlaneId } from '@/planner/cat-stack/catstacksData';

    const planner = usePlannerStore();

    const isReady = computed(() => {
        return planner.catstacksData !== null;
    });

    const toggleVisibility = () => {
        assert(planner.catstacksData);

        planner.catstacks.visible = !planner.catstacks.visible;
    };

    function maxValue(id: CatstackPlaneId): ComputedRef<number> {
        return computed(() => planner.catstacksData ? planner.catstacksData[id].catstack.count - 1 : 0);
    }

    const axialMax = maxValue('axial');
    const coronalMax = maxValue('coronal');

    function sliderValue(id: CatstackPlaneId): WritableComputedRef<number> {
        return computed({
            get: () => maxValue(id).value - planner.catstacks[id].sliceIndex,
            set: (value) => {
                planner.catstacks[id].sliceIndex = maxValue(id).value - value;
            }
        });
    }

    const axialValue = sliderValue('axial');
    const coronalValue = sliderValue('coronal');
</script>

<style scoped lang="scss">
    :deep(label) {
        color: #fff;
        font-size: 12px;
    }

    .range-holder {
        display: grid;
        grid-template-columns: 1fr 192px;
        grid-gap: 20px;
    }
</style>
