uniform vec3 uColor;
varying vec3 vNormal;

#include <clipping_planes_pars_fragment>

void main() {
    vec3 normal = normalize(vNormal);
    normal = normal * (-1.0 + 2.0 * float(gl_FrontFacing));
    float NdotV = abs(dot(normal, vec3(0.0, 0.0, 1.0)));
    gl_FragColor = vec4(uColor, 0.5 * (1.0 - NdotV));

    #include <clipping_planes_fragment>
}
