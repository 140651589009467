import { configure, defineRule } from 'vee-validate';
import { localize } from '@vee-validate/i18n';

import { confirmed, email, min, required } from '@vee-validate/rules';
import {
    atLeastOneLowercase,
    atLeastOneNumber,
    atLeastOneSpecialChar,
    atLeastOneUppercase,
} from '@/lib/vee-validate/validation';

export function defineRules() {
    defineRule('email', email);
    defineRule('required', required);
    defineRule('min', min);
    defineRule('confirmed', confirmed);
    defineRule('atLeastOneNumber', atLeastOneNumber);
    defineRule('atLeastOneLowercase', atLeastOneLowercase);
    defineRule('atLeastOneUppercase', atLeastOneUppercase);
    defineRule('atLeastOneSpecialChar', atLeastOneSpecialChar);
}

configure({
    // Generates an English message locale generator
    generateMessage: localize('en', {
        messages: {
            min: '{field} must be at least 8 characters long',
            confirmed: 'password confirmation does not match new password',
        },
    }),
});
