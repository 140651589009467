import { type GenericAbortSignal } from 'axios';

/** Throw if a generic abort signal is aborted */
export function throwIfAborted(signal?: GenericAbortSignal) {
    if (!signal) {
        return;
    }
    if (signal instanceof AbortSignal) {
        signal.throwIfAborted();
    } else if (signal.aborted) {
        throw new Error('Aborted')
    }
}

/** Get the abort reason from a generic abort signal */
export function abortReason(signal: GenericAbortSignal): any {
    return (signal as { reason?: any }).reason;
}
