import type { CaseSettingsInputs, CaseSettingsState } from '@/stores/caseSettings/types';
import { makeDataState } from '@/stores/shared/dataState';
import { cloneDeep } from 'lodash';

const initialFormInput: CaseSettingsInputs = {
    id: null,
    owner_id: null,
    surgery: {
        name: '',
        side: null,
        description: '',
        procedure: 'Primary Total Hip Arthroplasty',
        date: '',
    },
    study: null,
    patient: {
        firstName: '',
        middleNames: '',
        familyName: '',
        sex: null,
        dateOfBirth: '',
    },
    specifications: {
        surgeon: {
            options: [],
            selected: null,
            error: false,
        },
        stems: {
            options: [],
            selected: [],
            preferredSystem: '',
        },
        targets: {
            legLength: '0',
            offset: '0',
        },
        cup: {
            inclinationAngle: '',
            fitMethod: '',
            anteversion: '',
            anteversionAngle: '',
            alignment: '',
        },
    },
};

function makeEmptyCase() {
    return cloneDeep({ case: initialFormInput });
}

const initialState: CaseSettingsState = {
    displayErrors: false,
    _lastSavedCase: makeEmptyCase().case,
    ...makeEmptyCase(),
    ...makeDataState(),
};

export const makeInitialState = (): CaseSettingsState => {
    // Given the state is not a flat structure, it needs to be deep clone
    // e.g.: $reset will not work if the state is not cloned
    return cloneDeep({
        ...initialState,
    });
};
