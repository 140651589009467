import { DateTime } from 'luxon';
import {
    PATIENT_DATE_OF_BIRTH_FORMAT,
    type PatientPersonalInfo,
    type PatientSex,
    type PatientName,
} from './types';
import { type DicomInfo } from '@/lib/dicom/DicomInfo';
import StringUtil from '@/lib/StringUtils';
import { DicomPatientSexValues } from '@/lib/dicom/DicomSeriesUtil';
import { NameUtil } from '@/lib/NameUtil';

export enum PatientComparisonErrorType {
    Name = 'name',
    DateOfBirth = 'date-of-birth',
    Sex = 'sex',
}

export interface PatientComparisonError {
    type: PatientComparisonErrorType;
    values: unknown[];
}

export interface PatientComparison {
    isSame: boolean;
    patient: PatientPersonalInfo;
    anotherPatient: PatientPersonalInfo;
    errorFields?: PatientComparisonError[];
}

export class PatientComparisonUtil {
    /** Maps the patient details in a dicom file to the client/api {@link PatientPersonalInfo} */
    public static fromDicom(dicomInfo: DicomInfo): PatientPersonalInfo {
        const mapPatientName = (info: DicomInfo): PatientName | null => {
            return NameUtil.fromDicom(info.patientName ?? null);
        };

        const mapPatientSex = (info: DicomInfo): PatientSex | null => {
            switch (info.patientSex) {
                case DicomPatientSexValues.Male:
                    return 'male';

                case DicomPatientSexValues.Female:
                    return 'female';

                case DicomPatientSexValues.Other:
                    return 'other';

                default:
                    return null;
            }
        };

        const mapPatientDate = (info: DicomInfo): string | null => {
            if (info.patientBirthDate) {
                return (
                    DateTime.fromJSDate(info.patientBirthDate).toFormat(
                        PATIENT_DATE_OF_BIRTH_FORMAT,
                    ) ?? null
                );
            }

            return null;
        };

        return {
            name: mapPatientName(dicomInfo) ?? undefined,
            sex: mapPatientSex(dicomInfo) ?? undefined,
            birth_date: mapPatientDate(dicomInfo) ?? undefined,
        };
    }

    /**
     * Compare two different patient's metadata.
     *
     * Note: The underlying checks are case insensitive string comparisons.
     */
    public static compare(
        patient: PatientPersonalInfo,
        anotherPatient: PatientPersonalInfo,
    ): PatientComparison {
        /** @returns A string without leading and trailing spaces. If no string is passed, and empty string is returned */
        const comparableString = (str: string | undefined) => (str ?? '').trim();
        const compareName = (): void => {
            if (
                StringUtil.isCaseInsensitiveEqual(
                    comparableString(patient.name?.family),
                    comparableString(anotherPatient.name?.family),
                ) &&
                StringUtil.isCaseInsensitiveEqual(
                    comparableString(patient.name?.given),
                    comparableString(anotherPatient.name?.given),
                ) &&
                StringUtil.isCaseInsensitiveEqual(
                    comparableString(patient.name?.middle),
                    comparableString(anotherPatient.name?.middle),
                )
            ) {
                // nothing to do
            } else {
                errors.push({
                    type: PatientComparisonErrorType.Name,
                    values: [NameUtil.format(patient.name), NameUtil.format(anotherPatient.name)],
                });
            }
        };
        const compareBirthDate = (): void => {
            if (
                StringUtil.isCaseInsensitiveEqual(
                    comparableString(patient.birth_date),
                    comparableString(anotherPatient.birth_date),
                )
            ) {
                // nothing to do
            } else {
                errors.push({
                    type: PatientComparisonErrorType.DateOfBirth,
                    values: [patient.birth_date, anotherPatient.birth_date],
                });
            }
        };
        const compareSex = (): void => {
            if (
                StringUtil.isCaseInsensitiveEqual(
                    comparableString(patient.sex),
                    comparableString(anotherPatient.sex),
                )
            ) {
                // nothing to do
            } else {
                errors.push({
                    type: PatientComparisonErrorType.Sex,
                    values: [patient.sex, anotherPatient.sex],
                });
            }
        };

        const errors: PatientComparisonError[] = [];

        compareName();
        compareBirthDate();
        compareSex();

        return {
            isSame: errors.length === 0,
            patient,
            anotherPatient,
            errorFields: errors,
        };
    }
}
