import { type SafeZoneDataSet, type SpinopelvicCalculations } from '@/components/spinopelvic/types';
import {
    axisArray,
    xAxisInclinationArray,
    yAxisAnteversionArray,
    yAxisCupCSIArray,
} from '@/components/spinopelvic/NomogramPlotUtils';
import {
    ANTEVERSION_10_DEGREES,
    ANTEVERSION_30_DEGREES,
    ANTEVERSION_Y_MAX,
    ANTEVERSION_Y_MIN,
    DATA_POINTS,
    INCLINATION_LOWER_LIMIT,
    INCLINATION_UPPER_LIMIT,
    INCLINATION_X_MAX,
    INCLINATION_X_MIN,
} from '@/components/spinopelvic/constants';

export const makeSafeZoneDataSet = (
    caculations: SpinopelvicCalculations,
): SafeZoneDataSet => {
    const inclinationXArrray = axisArray(INCLINATION_X_MIN, INCLINATION_X_MAX, DATA_POINTS);
    const anteversionYArray = axisArray(ANTEVERSION_Y_MIN, ANTEVERSION_Y_MAX, DATA_POINTS);
    return {
        xAxisArray: inclinationXArrray,
        yAxisArray: anteversionYArray,
        cupLowerArray: yAxisCupCSIArray(
            inclinationXArrray,
            caculations.csiLowerBound,
            caculations.pelvicTilt,
        ),
        cupUpperArray: yAxisCupCSIArray(
            inclinationXArrray,
            caculations.csiUpperBound,
            caculations.pelvicTilt,
        ),
        inclinationLowerLimit: xAxisInclinationArray(
            INCLINATION_LOWER_LIMIT,
            anteversionYArray,
            caculations.pelvicTilt,
        ),
        inclinationUpperLimit: xAxisInclinationArray(
            INCLINATION_UPPER_LIMIT,
            anteversionYArray,
            caculations.pelvicTilt,
        ),
        anteversionLowerLimit: yAxisAnteversionArray(
            inclinationXArrray,
            ANTEVERSION_10_DEGREES,
            caculations.pelvicTilt,
        ),
        anteversionUpperLimit: yAxisAnteversionArray(
            inclinationXArrray,
            ANTEVERSION_30_DEGREES,
            caculations.pelvicTilt,
        ),
    };
};
