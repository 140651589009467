import type { PlannerStore } from '@/planner/plannerStore';
import LPS from '@/formus/anatomy/LPS';
import { Vector3 } from 'three';
import { multiplyScalar } from '@/geometry/vector3';
import { positionalPart } from '@/geometry/matrix';
import type { PerspectiveCameraNode } from '@/planner/3d/perspectiveCamera';
import type { PlannerMode } from '@/planner/plannerState';
import { assertDefined } from '@/util';

type CameraPlacementId = PlannerMode | 'axial' | 'coronal' | 'lateral';

export function setCameraPlacement(store: PlannerStore, placementId: CameraPlacementId) {
    switch (placementId) {
        case 'disabled':
            break;
        case 'default':
            _setPlacement(store.nodes.camera, store.sceneCentre);
            break;
        case 'stem':
            _setPlacement(store.nodes.camera, positionalPart(store.nodes.stemGroup.transform), {
                offsetDirection: store.fittedStem?.axesLocal?.paAxis.direction?.clone().negate(),
            });
            break;
        case 'cup':
            _setPlacement(store.nodes.camera, positionalPart(store.nodes.cupGroup.transform), {
                offsetDirection: store.fittedCup?.apVector.clone().negate(),
            });
            break;
        case 'combined':
            _setPlacement(store.nodes.camera, positionalPart(store.nodes.cupGroup.transform), {
                distance: COMBINED_MODE_DISTANCE,
            });
            break;
        case 'axial':
            _setPlacement(store.nodes.camera, positionalPart(store.nodes.stemGroup.transform), {
                distance: AXIAL_DISTANCE,
                offsetDirection: LPS.Superior,
                up: LPS.Posterior,
            });
            break;
        case 'coronal':
            _setPlacement(store.nodes.camera, positionalPart(store.nodes.stemGroup.transform), {
                distance: CORONAL_LATERAL_DISTANCE,
                offsetDirection: LPS.Anterior,
            });
            break;
        case 'lateral':
            _setPlacement(store.nodes.camera, positionalPart(store.nodes.stemGroup.transform), {
                distance: CORONAL_LATERAL_DISTANCE,
                offsetDirection: LPS.lateral(assertDefined(store.case?.operationalSide, 'operational-side')),
            });
            break;
        default:
            throw Error(`Cannot apply camera-placement for unknown id '${placementId}'`);
    }
}

//--------------------------------------------------------------------------------------------------

/** Default distance from the camera-position to its target **/
const DEFAULT_DISTANCE = 450 as const;

/** Distance from the camera-position to its target in 'combined' mode **/
const COMBINED_MODE_DISTANCE = 300 as const;

/** Distance from the camera-position to its target for axial view **/
const AXIAL_DISTANCE = 300 as const;

/** Distance from the camera-position to its target for coronal and lateral views **/
const CORONAL_LATERAL_DISTANCE = 300 as const;

/** Default camera offset-direction is to the anterior, so the camera will look in an AP direction **/
const DEFAULT_OFFSET_DIRECTION = LPS.Anterior;

/** Default for the camera approximate 'up' direction in world-space */
const DEFAULT_UP = LPS.Superior;

/**
 * Set the position, target and up-direction of a camera
 */
function _setPlacement(
    camera: PerspectiveCameraNode,
    target: Vector3,
    options?: {
        offsetDirection?: Vector3;
        distance?: number;
        up?: Vector3;
    },
) {
    camera.target.copy(target);
    camera.position
        .copy(target)
        .add(
            multiplyScalar(
                options?.offsetDirection ?? DEFAULT_OFFSET_DIRECTION,
                options?.distance ?? DEFAULT_DISTANCE,
            ),
        );
    camera.up.copy(options?.up ?? DEFAULT_UP);
}
