/**
 * Implements software requirements: H1SR-11, H1SR-86
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-11/Default-values-for-cases-can-be-defined-in-the-hip-preferences
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-86/Surgeon-users-are-able-to-define-from-a-selection-of-stem-types-which-stems-to-be-used-in-the-fitting-process
 */

import { type AxiosResponse, HttpStatusCode } from 'axios';
import { isArray, isEmpty } from 'lodash';
import { client, expectedErrorOrRethrow, type UserFriendlyError } from '@/api/http';
import { ById } from '@/lib/sorting';

export interface ApiPreferencesObject {
    total_hip_arthroplasty: {
        preferred_system: string;
        stems: PreferredStem[];
        cup_align_mode: string;
        cup_fit_method: string;
        cup_anteversion_mode: string;
        cup_anteversion_angle: number;
        cup_inclination_angle: number;
    };
    options: {
        stems: PreferredStem[];
    };
}

function isValidApiResponse(response: ApiPreferencesObject) {
    const { options, total_hip_arthroplasty: tha } = response;
    return !isEmpty(options?.stems) && isArray(tha?.stems);
}

export interface UserPreferences {
    options: PreferencesOptions;
    preferences: THAPreferences;
}

export interface THAPreferences {
    preferredSystem: string;
    stems: string[];
    cupAlignMode: string;
    cupFitMethod: string;
    cupAnteversionMode: string;
    cupAnteversionAngle: string;
    cupInclinationAngle: string;
}

export interface PreferencesOptions {
    stems: PreferredStem[];
}

export interface PreferredStem {
    type: string;
    offset: string;
    id: string;
    system: string;
}

export class NoSurgicalPreferencesError extends Error {}

export function mapToState(response: ApiPreferencesObject): THAPreferences {
    const { total_hip_arthroplasty: tha } = response;

    return {
        preferredSystem: tha.preferred_system,
        stems: tha.stems!.map((stem) => stem.id),
        cupAlignMode: tha.cup_align_mode,
        cupFitMethod: tha.cup_fit_method,
        cupAnteversionMode: tha.cup_anteversion_mode,
        cupAnteversionAngle: tha.cup_anteversion_angle ? String(tha.cup_anteversion_angle) : '',
        cupInclinationAngle: tha.cup_inclination_angle ? String(tha.cup_inclination_angle) : '',
    };
}

function createSurgicalPreferences(response: ApiPreferencesObject): UserPreferences {
    return {
        preferences: mapToState(response),
        options: {
            stems: response.options.stems.sort(ById),
        },
    };
}

export const getPreferences = (userId: number): Promise<THAPreferences> => {
    return client
        .get(`/users/${userId}/preferences`)
        .then(({ status, data }: AxiosResponse<ApiPreferencesObject>) => {
            if (status === HttpStatusCode.Ok && data.total_hip_arthroplasty !== undefined) {
                return mapToState(data);
            }
            if (status === HttpStatusCode.NotFound) {
                throw new NoSurgicalPreferencesError('Surgeon does not have surgical preferences');
            } else {
                throw Error(`Failed to load users surgical preferences`);
            }
        });
};

export const myPreferences = (): Promise<UserPreferences> => {
    return client
        .get('/users/me/preferences')
        .then(({ status, data }: AxiosResponse<ApiPreferencesObject>) => {
            if (status === HttpStatusCode.Ok && isValidApiResponse(data)) {
                return createSurgicalPreferences(data);
            } else {
                throw Error('Failed to load my surgical preferences');
            }
        });
};

export async function saveMyPreferences(
    changedPreferences: THAPreferences,
    options: PreferencesOptions,
): Promise<void | UserFriendlyError> {
    const selectedStems = changedPreferences.stems
        .map((stemId: string) => options.stems.filter((option) => option.id === stemId)[0])
        .filter((stemId: any) => !!stemId);

    const total_hip_arthroplasty: ApiPreferencesObject['total_hip_arthroplasty'] = {
        preferred_system: changedPreferences.preferredSystem,
        stems: selectedStems,
        cup_align_mode: changedPreferences.cupAlignMode,
        cup_fit_method: changedPreferences.cupFitMethod,
        cup_anteversion_mode: changedPreferences.cupAnteversionMode,
        cup_anteversion_angle: Number(changedPreferences.cupAnteversionAngle),
        cup_inclination_angle: Number(changedPreferences.cupInclinationAngle),
    };

    const failMessage = 'Failed to save my surgical preferences' as const;

    try {
        const { status, data }: AxiosResponse = await client.put('/users/me/preferences', {
            total_hip_arthroplasty,
        });
        if (status !== 204) {
            return status === HttpStatusCode.BadRequest && data?.detail
                ? `${failMessage}: ${data?.detail}`
                : failMessage;
        }
    } catch (error) {
        return expectedErrorOrRethrow(error, failMessage);
    }
}
