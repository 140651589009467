import { client } from '@/api/http';
import type { ApiPlans } from '@/api/plan/types';
import { type AxiosRequestConfig, HttpStatusCode } from 'axios';
import { AppError } from '@/stores/appErrorStore';
import { errorDetail } from '@/planner/api/errorDetail';

export const fetchPlans = async (
    caseId: number,
    config?: AxiosRequestConfig,
): Promise<ApiPlans | null> => {
    const response = await client.get(`/cases/${caseId}/plans`, config);
    const { status, data } = response;
    if (status === HttpStatusCode.Ok) {
        return data;
    } else if (status === HttpStatusCode.Forbidden) {
        throw new AppError(
            'Case not found' + errorDetail(data),
            'This case does not exist or you do not have permission to view it.',
        );
    } else if (status === HttpStatusCode.NotFound) {
        return null;
    } else {
        throw Error(`Failed to load plan`);
    }
};
