import { MathUtils, Vector3 } from 'three';
import { areAcute, areCollinear, normalize, orthogonalUnit } from '@/geometry/vector3';
import { projectedComponent, projectOrthogonalUnit } from '@/geometry/projection';

export function angleFromPlane(vector: Vector3, planeNormal: Vector3): number {
    if (areCollinear(vector, planeNormal)) {
        // Vector is collinear with plane. Return + or - 90 degrees depending on which side of the
        // plane the vector is on.
        return areAcute(vector, planeNormal) ? 90 : -90;
    }
    const zeroDirection = projectOrthogonalUnit(vector, planeNormal);
    return degreesFromXYComponents(
        projectedComponent(vector, zeroDirection),
        projectedComponent(vector, planeNormal),
    );
}

export function angleInPlanarProjection(
    vector: Vector3,
    zeroDirection: Vector3,
    positiveDirection: Vector3,
    fallbackAngle?: number,
): number {
    // Calculate 'x', 'y', and 'normal' direction vectors
    const xDirection = normalize(zeroDirection);
    const yDirection = projectOrthogonalUnit(positiveDirection, zeroDirection);
    const normal = orthogonalUnit(zeroDirection, positiveDirection);

    if (!areCollinear(normal, vector)) {
        // We can project the vector onto the planar space as normal
        return degreesFromXYComponents(
            projectedComponent(vector, xDirection),
            projectedComponent(vector, yDirection),
        );
    }

    // The vector is orthogonal to the planar space, so the angle it projects to is undefined.
    if (fallbackAngle) {
        return fallbackAngle;
    } else {
        throw Error('Cannot calculate the angle of a vector orthogonal to the projection plane');
    }
}

function degreesFromXYComponents(x: number, y: number): number {
    return MathUtils.radToDeg(Math.atan2(y, x));
}
