<template>
    <v-layout>
        <app-sidebar>
            <router-link :to="{ name: ROUTES.HOME }">
                <app-link icon="mdi-arrow-left" color="red" alone bold>Back to your Cases</app-link>
            </router-link>
        </app-sidebar>

        <app-main-view>
            <app-form-title>Your Surgical Preferences</app-form-title>
            <app-form-subtitle
                >These preferences will be applied to each new Formus Case you create. You can also
                override any of these settings per-Case after it has been created, and before it has
                been processed.
            </app-form-subtitle>

            <app-card v-if="form.state.isReady" size="large" narrow>
                <app-notification
                    v-if="form.state.message"
                    :title="form.state.message"
                    :variant="form.state.hasError ? 'error' : 'success'"
                />

                <app-form-section-title ref="title">Stem</app-form-section-title>
                <app-form-subtitle> Only one stem system can be selected.</app-form-subtitle>
                <app-form-group label="Preferred System">
                    <app-radio-group
                        inline
                        v-model="form.state.preferredSystem"
                        :error-messages="
                            form.hasError('preferredSystem') ? 'Please select an option' : ''
                        "
                    >
                        <v-radio
                            v-for="item in stemSystems"
                            :value="item.key"
                            :key="item.key"
                            :label="item.label"
                        />
                    </app-radio-group>
                </app-form-group>

                <app-form-group label="Types">
                    <app-checkbox-group>
                        <v-checkbox
                            v-model="form.state.stems"
                            :value="item.id"
                            :key="item.id"
                            v-for="(item, index) in availableStems"
                            :label="`${item.type} - ${item.offset}`"
                            density="compact"
                            :hide-details="index !== form.options.stems.length - 1"
                        />
                        {{ form.hasError('stems') ? 'Please select at least one option' : '' }}
                    </app-checkbox-group>
                </app-form-group>

                <app-form-section-title>Cup</app-form-section-title>

                <app-form-group label="Inclination Angle" input-id="inclination-angle-input">
                    <app-text-input
                        v-model="form.state.cup.inclinationAngle"
                        suffix="deg"
                        data-test-id="inclination-angle-input"
                        input-id="inclination-angle-input"
                        :error-messages="
                            form.hasError('cupInclinationAngle')
                                ? 'Please provide a number between 0 and 60'
                                : ''
                        "
                    />
                </app-form-group>

                <app-form-group label="Fitting mode">
                    <app-radio-group
                        inline
                        v-model="form.state.cup.fitMethod"
                        :error-messages="
                            form.hasError('cupFittingMode') ? 'Please select an option' : ''
                        "
                    >
                        <v-radio label="Auto" value="acid"></v-radio>
                        <v-radio label="Fossa" value="fossa"></v-radio>
                    </app-radio-group>
                </app-form-group>

                <app-form-group label="Anteversion mode">
                    <app-radio-group
                        inline
                        v-model="form.state.cup.anteversion"
                        :error-messages="
                            form.hasError('cupAnteversionMode') ? 'Please select an option' : ''
                        "
                    >
                        <v-radio label="Combined" value="auto"></v-radio>
                        <v-radio label="Manual" value="manual"></v-radio>
                    </app-radio-group>
                </app-form-group>

                <app-form-group
                    label="Anteversion Angle"
                    class="mt-12"
                    input-id="anteversion-angle-input"
                    v-if="form.state.cup.anteversion === 'manual'"
                >
                    <app-text-input
                        suffix="deg"
                        v-model="form.state.cup.anteversionAngle"
                        data-test-id="anteversion-angle-input"
                        input-id="anteversion-angle-input"
                        :error-messages="
                            form.hasError('cupAnteversionAngle')
                                ? 'Please provide a number between -5 and 45'
                                : ''
                        "
                    />
                </app-form-group>

                <app-form-group label="Alignment mode">
                    <app-radio-group
                        inline
                        v-model="form.state.cup.alignment"
                        :error-messages="
                            form.hasError('alignmentMode') ? 'Please select an option' : ''
                        "
                    >
                        <v-radio label="CT Scanner Coordinates" value="none"></v-radio>
                        <v-radio label="Anterior Pelvic Plane" value="APP"></v-radio>
                    </app-radio-group>
                </app-form-group>

                <app-form-actions>
                    <app-form-action-notification
                        message="You have unsaved changes on this page"
                        v-if="form.hasUnsavedChanges"
                    />
                    <app-button
                        colour="green"
                        :loading="form.state.isSaving"
                        @click="form.save"
                        :disabled="!form.hasUnsavedChanges"
                        >Save your Surgical Preferences
                    </app-button>
                </app-form-actions>
            </app-card>
        </app-main-view>
    </v-layout>
</template>

<script setup lang="ts">
    /**
     * Implements software requirements: H1SR-11
     *
     * @link https://formuslabs.youtrack.cloud/issue/H1SR-11/Default-values-for-cases-can-be-defined-in-the-hip-preferences
     */
    import { ROUTES } from '@/router';
    import { usePreferences } from '@/stores/preferences';
    import { computed } from 'vue';
    import { availableStemSystems } from '@/types/stemSystems';
    import { useDeveloperSettings } from '@/planner/developerSettings';

    const form = usePreferences();
    await form.load();

    const availableStems = computed(() => {
        return form.options.stems.filter(
            (object: any) => object.system === form.state.preferredSystem,
        );
    });

    const stemSystems = computed(() => {
        return availableStemSystems(useDeveloperSettings().showAvenirImplants);
    });
</script>

<style scoped>
    :deep(.v-messages) {
        font-size: 14px;
        margin-top: 10px;
    }
</style>
