<template>
    <v-dialog
        :persistent="true"
        width="800"
        :model-value="upload.isUploading || upload.isModalError || upload.isWaitingForConfirmation"
    >
        <v-card>
            <app-modal-title> CT Scan Upload</app-modal-title>

            <app-modal-content>
                <v-alert
                    v-if="upload.progress.error"
                    :text="upload.progress.error"
                    type="error"
                    variant="tonal"
                    class="mb-4"
                />

                <app-text v-if="hasSeriesForUpload">
                    Uploading {{ series?.items.length }} Files from 1 DICOM series
                </app-text>
                <app-text v-else>
                    0 DICOM series available for upload from of a total of 1 DICOM series.
                </app-text>

                <div v-if="upload.isUploading">
                    <v-progress-linear
                        class="progress-bar"
                        stream
                        height="10"
                        :max="upload.progress.total ?? 0"
                        :buffer-value="upload.progress.current"
                    />
                </div>

                <v-data-table-virtual
                    :headers="[
                        { title: 'Level', key: 'level' },
                        { title: 'Message', key: 'message' },
                    ]"
                    :items="messages"
                    height="580"
                    item-value="name"
                >
                    <!--
                        Note: An error below is still flagged by eslint but is incorrect: vuetify
                        uses slots with the `item.${key}` format and they probably just need to improve their
                        linting support. See https://vuetifyjs.com/en/api/v-data-table-virtual/#slots
                    -->
                    <!-- suppress VueUnrecognizedSlot -->

                    <!-- eslint-disable vue/valid-v-slot -->
                    <template v-slot:item.level="{ item }">
                        <AppUploadMessageIcon :level="item.level" />
                    </template>
                    <!-- eslint-enable vue/valid-v-slot -->
                </v-data-table-virtual>
            </app-modal-content>

            <app-modal-actions>
                <app-link @click="upload.cancel">Cancel</app-link>
                <app-button
                    v-if="upload.isWaitingForConfirmation"
                    colour="green"
                    @click="upload.userConfirmationUpload"
                    :loading="upload.isUploading"
                >
                    Upload
                </app-button>
                <app-button
                    v-else
                    colour="green"
                    @click="upload.upload"
                    :loading="upload.isUploading"
                    :disabled="upload.isModalError"
                >
                    Upload
                </app-button>
            </app-modal-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import { useCaseUpload } from '@/stores/case-upload/store';
    import AppModalTitle from '@/components/modal/AppModalTitle.vue';
    import AppModalContent from '@/components/modal/AppModalContent.vue';
    import AppModalActions from '@/components/modal/AppModalActions.vue';
    import AppLink from '@/components/AppLink.vue';
    import AppButton from '@/components/AppButton.vue';
    import AppUploadMessageIcon from '@/components/case-settings/AppUploadMessageIcon.vue';
    import { computed } from 'vue';
    import type { DicomSeries } from '@/lib/dicom/DicomSeries';

    const upload = useCaseUpload();

    const series = computed<DicomSeries | null>(() => {
        const result = upload.processDicomsResult;
        return result
            ? result.isValid
                ? result.uploadCandidate?.series ?? null
                : Object.values(result.input)[0] ?? null
            : null;
    });

    const hasSeriesForUpload = computed(() => {
        return upload.processDicomsResult?.isValid ?? false;
    });

    const messages = computed(() => {
        return [
            ...(series.value?.messages ?? []),
            ...(series.value?.items.flatMap((item) => item.messages) ?? []),
        ];
    });
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
    :deep(.v-progress-linear__background, .v-progress-linear__stream) {
        background-color: var(--planner-blue);
        opacity: 0.8;
    }

    :deep(.v-table .v-table__wrapper table tbody tr td:first-child) {
        max-width: 15%;
        width: 15%;
    }
</style>
