import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { type RouteLocationRaw, useRoute } from 'vue-router';
import { watchImmediate } from '@vueuse/core';
import { pollCase } from '@/api/case/pollCase';
import { verify } from '@/lib/verify';
import type { CaseDetailsApiObjectBase } from '@/api/case/types';
import { CaseStatusState } from '@/api/caseSearch';
import { ROUTES } from '@/router';
import { enabledActionsByStatus } from '@/stores/navigation/enabledActionsByStatus';

export const useCaseSyncStore = defineStore('case-sync', () => {
    const route = useRoute();
    const case_ = ref<CaseDetailsApiObjectBase | null>(null);
    const isCaseSpecific = ref<boolean>(false);

    let cancellation: AbortController | null = null;

    function _clearState() {
        case_.value = null;
        cancellation?.abort();
    }

    async function _startCaseSync(caseId: string): Promise<void> {
        cancellation?.abort();
        cancellation = new AbortController();

        const _onFetch = (data: CaseDetailsApiObjectBase | null) => {
            if (data) {
                case_.value = data;
            } else {
                cancellation?.abort();
                case_.value = null;
            }
        };

        await pollCase(Number(caseId), _onFetch, { signal: cancellation.signal });
    }

    const unwatch = watchImmediate(
        () => [route.meta.caseSpecific, route.params.id],
        async ([caseSpecific, caseId]) => {
            isCaseSpecific.value = !!caseSpecific;

            if (caseSpecific) {
                const id = verify(caseId as string, 'caseId should exist on case-specific routes');
                await _startCaseSync(id);
            } else {
                _clearState();
            }
        },
    );

    const statusState = computed<CaseStatusState>(() => {
        return case_.value?.status.state ?? CaseStatusState.Unknown;
    });

    function caseHasStatusState(...states: CaseStatusState[]): boolean {
        return !isCaseError.value && states.find((s) => s === statusState.value) !== undefined;
    }

    const isCaseProcessing = computed(() =>
        caseHasStatusState(CaseStatusState.ProcessingCatStack, CaseStatusState.Processing),
    );

    const isCaseComplete = computed(() => caseHasStatusState(CaseStatusState.Complete));

    const isCaseWarning = computed(() => caseHasStatusState(CaseStatusState.Warning));

    const isCaseNew = computed(() => caseHasStatusState(CaseStatusState.New));

    const isCaseError = computed(() => {
        return case_.value?.status.failed === true;
    });

    return {
        case_,
        notifications: computed(() => case_.value?.notifications ?? []),
        caseName: computed(() => case_.value?.name),
        routeTo: (route: ROUTES): RouteLocationRaw | undefined => {
            if (case_.value === null) {
                return undefined;
            } else {
                const actions = enabledActionsByStatus(case_.value.status);
                return actions.includes(route)
                    ? {
                          name: route,
                          params: { id: case_.value.id },
                      }
                    : undefined;
            }
        },
        isCaseSpecific,
        isCaseComplete,
        isCaseWarning,
        isCaseProcessing,
        isCaseNew,
        isCaseError,
        stop: () => {
            unwatch();
            cancellation?.abort();
        },
    };
});
