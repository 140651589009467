/**
 * Media Storage SOP Class UID (UI)
 * @see {@link http://dicom.nema.org/dicom/2013/output/chtml/part04/sect_I.4.html}
 *
 * CT Image Storage                           1.2.840.10008.5.1.4.1.1.2   Computed Tomography Image IOD
 * Enhanced CT Image Storage                  1.2.840.10008.5.1.4.1.1.2.1 Enhanced CT Image IOD
 * Legacy Converted Enhanced CT Image Storage 1.2.840.10008.5.1.4.1.1.2.2 Legacy Converted Enhanced CT Image IOD
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _TagMediaStorageSopClassUid = 'x00020002';

/**
 * (0002,0010)    TransferSyntaxUID    UI    1    20    1.2.840.10008.1.2.1
 */
export const TagTransferSyntaxUid = 'x00020010';

/**
 * SOP Class UID (UI)
 *
 * This seems to have the same values as {@link _TagMediaStorageSopClassUid}.
 */
export const TagSopClassUid = 'x00020016';

/**
 * An optional code string (CS) of 'YES' or 'NO'.
 *
 * @see {@link https://dicom.innolitics.com/ciods/ct-image/patient/00120062}
 */
export const TagPatientIdentityRemoved = 'x00120062';
export const TagPatientName = 'x00100010';
export const TagPatientDateOfBirth = 'x00100030';
export const TagPatientSex = 'x00100040';

/**
 * @see {@link https://dicom.innolitics.com/ciods/us-image/image-pixel/7fe00010}
 */
export const TagPixelData = 'x7fe00010';

/**
 * Tag    (0008,0060)
 *  Type    Required (1)
 * Keyword    Modality
 * Value Multiplicity    1
 * Value Representation    Code String (CS)
 *
 * @see {@link https://dicom.innolitics.com/ciods/cr-image/general-series/00080060}
 */
export const TagModality = 'x00080060';

/**
 * CS (Code String - up to 16 characters)
 *
 * e.g. "ORIGINAL", "PRIMARY", "AXIAL"
 *      "DERIVED", "SECONDARY" "VRT" "CSAMANIPULATED\RADIAL"
 *
 * http://dicomlookup.com/lookup.asp?sw=Ttable&q=C.8-3
 * (3) Optional (Optional. May or may not be included and could be zero length.)
 * @see {@link https://dicom.innolitics.com/ciods/cr-image/general-image/00080008}
 * @link{http://dicomlookup.com/lookup.asp?sw=Tnumber&q=(0008,0008)}
 */
export const TagImageType = 'x00080008';
export const IMAGE_TYPE_LOCALISER = 'LOCALIZER';
export const IMAGE_TYPE_VRT = 'VRT';

export const SeriesInstanceUid = 'x0020000e';
export const TagInstanceNumber = 'x00200013';

/**
 * @see {@link https://dicom.innolitics.com/ciods/ct-image/image-plane/00200032}
 */
export const TagImagePositionPatient = 'x00200032';
export const TagImageOrientationPatient = 'x00200037';
export const TagPixelSpacing = 'x00280030';
export const TagRows = 'x00280010';
export const TagColumns = 'x00280011';

/**
 * Nominal slice thickness, in mm.
 *
 * DS, with multiplicity of one
 *
 * @see {@link https://dicom.innolitics.com/ciods/ct-image/image-plane/00180050}
 */
export const TagSliceThickness = 'x00180050';

/**
 * YES, NO, or not present
 *
 * (0028,0301)
 * Type    Optional (3)
 * Keyword    BurnedInAnnotation
 * Value Multiplicity    1
 * Value Representation    Code String (CS)
 *
 * @see {@link https://dicom.innolitics.com/ciods/cr-image/general-image/00280301}
 */
export const TagBurnedInAnnotation = 'x00280301';

/**
 * @see {@link https://dicom.innolitics.com/ciods/ct-image/image-pixel/00280004}
 */
export const TagPhotometricIntepretation = 'x00280004';

/**
 * Sequence of Items containing all Attributes that were removed or replaced by other values in the
 * top level Data Set.
 *
 * SQ (sequence), with multiplicity of one
 *
 * @see {@link https://dicom.innolitics.com/ciods/mr-image/sop-common/04000561}
 */
export const OriginalAttributesSequence = 'x04000561';

/**
 * The tag expressed as a string without prefix, where the format is 'xxxxyyyy' where xxxx is the
 * zero padded DICOM group number and yyyy is the zero padded DICOM item number. The DcmJs library
 * uses these tags in a string form (i.e. there is no 'x' prefix as used in the 'dicom-parser' library).
 *
 * @see {@link https://dicom.innolitics.com/ciods/mr-image/sop-common/04000561}
 */
export const OriginalAttributesSequenceTag = '04000561';
