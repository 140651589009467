<template>
    <div class="app-modal-content">
        <slot></slot>
    </div>
</template>

<style scoped>
    .app-modal-content {
        padding: 0 40px;
    }
</style>
