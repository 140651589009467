import { all, any } from 'ramda';

/**
 * @Returns true if the given value has all the given properties and they are all numbers
 */
export function hasNumberProperties(value: unknown, ...properties: string[]) {
    const typedValue = value as unknown as Record<string, any>;
    return all((p) => typeof typedValue[p] === 'number', properties);
}

/**
 * @Returns true if the given value has any of the given properties that is a number
 */
export function hasAnyNumberProperties(value: unknown, ...properties: string[]) {
    const typedValue = value as unknown as Record<string, any>;
    return any((p) => typeof typedValue[p] === 'number', properties);
}
