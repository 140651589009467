<template>
    <li class="app-plan-card-case-details-group">
        <div class="app-plan-card-case-label">{{ props.label }}</div>
        <div class="app-plan-card-case-text">{{ props.value }}</div>
    </li>
</template>

<script setup lang="ts">
    export interface Props {
        label: string;
        value: string;
    }

    const props = defineProps<Props>();
</script>

<style scoped>
    .app-plan-card-case-details-group {
        flex-basis: 50%;
        padding: 10px 0;
    }

    .app-plan-card-case-label {
        color: var(--planner-grey-2);
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.75rem;
    }

    .app-plan-card-case-text {
        color: var(--planner-grey);
    }
</style>
