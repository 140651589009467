export type FemurLandmarkId = (typeof femurLandmarkIds)[number];
export type HemipelvisLandmarkId = (typeof hemipelvisLandmarkIds)[number];
export type PelvisLandmarkId = `${(typeof pelvisLandmarkSides)[number]}${HemipelvisLandmarkId}`;
export type LandmarkId = FemurLandmarkId | HemipelvisLandmarkId | PelvisLandmarkId;

export type FemurLandmarkName = `femur-${FemurLandmarkId}`;
export type HemipelvisLandmarkName = `hemipelvis-${HemipelvisLandmarkId}`;
export type PelvisLandmarkName = `pelvis-${PelvisLandmarkId}`;
export type LandmarkName = FemurLandmarkName | HemipelvisLandmarkName | PelvisLandmarkName;

export type Landmark = {
    name: LandmarkName;
    coordinates: { x: number; y: number; z: number };
};

export type LandmarkGroupName = (typeof landmarkGroups)[number];

export type LandmarkGroup = {
    /** the group name - this is from an atlas or the name of the bone group (e.g. pelvis) */
    name: LandmarkGroupName;

    /** the list of landmarks */
    landmarks: Landmark[];
};

export function findLandmark(
    groups: LandmarkGroup[],
    groupName: LandmarkGroupName,
    landmark: LandmarkId,
): Landmark {
    groups = Array.isArray(groups) ? groups : groups;
    const group = findLandmarkGroup(groups, groupName);
    const landmarkName = makeLandmarkName(groupName, landmark);
    const matching = group.landmarks.filter((m) => m.name == landmarkName);
    if (matching.length == 1) {
        return matching[0];
    } else if (matching.length == 0) {
        throw Error(
            [
                `No landmark matched '${landmarkName}' - available landmarks are:`,
                ...group.landmarks.map((m) => `'${m.name}'`),
            ].join('  \n'),
        );
    } else {
        throw Error(
            [
                `Multiple landmarks matched '${landmarkName}' - matching groups are:`,
                ...matching.map((m) => `'${m.name}'`),
            ].join('  \n'),
        );
    }
}

function findLandmarkGroup(groups: LandmarkGroup[], name: LandmarkGroupName): LandmarkGroup {
    if (!isLandmarkGroupName(name)) {
        throw Error(`Invalid landmark-group name ${name}`);
    }
    const matching = groups.filter((m) => m.name == name);
    if (matching.length == 1) {
        return matching[0];
    } else if (matching.length == 0) {
        throw Error(
            [
                `No landmark-groups matched '${name}' - available groups are:`,
                ...groups.map((m) => `'${m.name}'`),
            ].join('  \n'),
        );
    } else {
        throw Error(
            [
                `Multiple landmark-groups matched '${name}' - matching groups are:`,
                ...matching.map((m) => `'${m.name}'`),
            ].join('  \n'),
        );
    }
}

function makeLandmarkName(group: LandmarkGroupName, landmark: LandmarkId): LandmarkName {
    switch (group) {
        case 'left-femur':
        case 'right-femur':
            if (isFemurLandmarkId(landmark)) {
                return `femur-${landmark}`;
            }
            break;

        case 'left-hemi':
        case 'right-hemi': {
            if (isHemipelvisLandmarkId(landmark)) {
                return `hemipelvis-${landmark}`;
            }
            break;
        }
        case 'pelvis': {
            if (isPelvisLandmarkId(landmark)) {
                return `pelvis-${landmark}`;
            }
            break;
        }
    }
    throw Error(`Invalid landmark-id '${landmark}' for group '${group}'`);
}

const landmarkGroups = ['left-femur', 'right-femur', 'left-hemi', 'right-hemi', 'pelvis'] as const;

const femurLandmarkIds = [
    'hc',
    'mec',
    'lec',
    'gt',
    'gt-resection',
    'gtant',
    'lt',
    'lt-resection',
    'kc',
    'neck-saddle',
] as const;

const hemipelvisLandmarkIds = [
    'ac',
    'an',
    'asis',
    'psis',
    'ps',
    'pt',
    'id',
    'it',
    'atrans',
    'ptrans',
    'fossa',
    'acetabular-rim-superior',
    'acetabular-rim-anterior',
    'acetabular-rim-posterior',
] as const;

const pelvisLandmarkSides = ['l', 'r'] as const;
const pelvisLandmarkIds = pelvisLandmarkSides.flatMap((side) =>
    hemipelvisLandmarkIds.map((landmark) => `${side}${landmark}`),
);

function isLandmarkGroupName(value: string): value is LandmarkGroupName {
    return landmarkGroups.includes(value as LandmarkGroupName);
}

function isFemurLandmarkId(value: string): value is FemurLandmarkId {
    return femurLandmarkIds.includes(value as FemurLandmarkId);
}

function isHemipelvisLandmarkId(value: string): value is HemipelvisLandmarkId {
    return hemipelvisLandmarkIds.includes(value as HemipelvisLandmarkId);
}

function isPelvisLandmarkId(value: string): value is PelvisLandmarkId {
    return pelvisLandmarkIds.includes(value);
}
