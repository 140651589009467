/**
 * A special kind of object that behaves similar to a ref or computed property,
 * but stores values in local storage.
 *
 * Can be used in pinia stores or vue components.
 *
 * The difference to vue refs is that this value will be
 * persisted even when the user closes or refreshes the browser.
 *
 * Note that local storage is not encrypted, anyone with access
 * to the users browser can read or modify data stored here.
 */
export const persisted = (key: string) => ({
    get value() {
        return window.localStorage.getItem(key) || '';
    },
    set value(newValue: string) {
        window.localStorage.setItem(key, newValue);
    },
});
