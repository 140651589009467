import { type App as Application } from 'vue';
import AppButton from '@/components/AppButton.vue';
import AppCard from '@/components/AppCard.vue';
import AppCaseCard from '@/components/case-card/AppCaseCard.vue';
import AppCaseCardBadge from '@/components/case-card/AppCaseCardBadge.vue';
import AppCaseCardContent from '@/components/case-card/AppCaseCardContent.vue';
import AppDivider from '@/components/AppDivider.vue';
import AppFormActions from '@/components/forms/AppFormActions.vue';
import AppFormActionNotification from '@/components/forms/AppFormActionNotification.vue';
import AppFormGroup from '@/components/forms/AppFormGroup.vue';
import AppFormSectionTitle from '@/components/forms/AppFormSectionTitle.vue';
import AppFormSubtitle from '@/components/forms/AppFormSubtitle.vue';
import AppFormTitle from '@/components/forms/AppFormTitle.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppHeading from '@/components/AppHeading.vue';
import AppHint from '@/components/AppHint.vue';
import AppLink from '@/components/AppLink.vue';
import AppLoader from '@/components/AppLoader.vue';
import AppMainView from '@/components/layout/AppMainView.vue';
import AppMenuItem from '@/components/AppMenuItem.vue';
import AppModalActions from '@/components/modal/AppModalActions.vue';
import AppModalContent from '@/components/modal/AppModalContent.vue';
import AppModalTitle from '@/components/modal/AppModalTitle.vue';
import AppNotification from '@/components/forms/AppNotification.vue';
import AppRadioGroup from '@/components/inputs/AppRadioGroup.vue';
import AppSidebar from '@/components/layout/AppSidebar.vue';
import AppText from '@/components/AppText.vue';
import AppTextInput from '@/components/inputs/AppTextInput.vue';
import AppIdleWarning from '@/components/AppIdleWarning.vue';
import AppCheckboxGroup from '@/components/inputs/AppCheckboxGroup.vue';

/**
 * Make components available in every vue file without needing to import them explicitly.
 */
export function registerAppComponents(Vue: Application) {
    // case-card
    Vue.component('AppCaseCard', AppCaseCard);
    Vue.component('AppCaseCardBadge', AppCaseCardBadge);
    Vue.component('AppCaseCardContent', AppCaseCardContent);
    // forms
    Vue.component('AppFormActions', AppFormActions);
    Vue.component('AppFormActionNotification', AppFormActionNotification);
    Vue.component('AppFormGroup', AppFormGroup);
    Vue.component('AppFormSectionTitle', AppFormSectionTitle);
    Vue.component('AppFormSubtitle', AppFormSubtitle);
    Vue.component('AppFormTitle', AppFormTitle);
    Vue.component('AppNotification', AppNotification);
    // inputs
    Vue.component('AppRadioGroup', AppRadioGroup);
    Vue.component('AppCheckboxGroup', AppCheckboxGroup);
    Vue.component('AppTextInput', AppTextInput);
    // layout
    Vue.component('AppHeader', AppHeader);
    Vue.component('AppMainView', AppMainView);
    Vue.component('AppSidebar', AppSidebar);
    // modal
    Vue.component('AppModalActions', AppModalActions);
    Vue.component('AppModalContent', AppModalContent);
    Vue.component('AppModalTitle', AppModalTitle);
    // general
    Vue.component('AppButton', AppButton);
    Vue.component('AppCard', AppCard);
    Vue.component('AppDivider', AppDivider);
    Vue.component('AppHeading', AppHeading);
    Vue.component('AppHint', AppHint);
    Vue.component('AppLink', AppLink);
    Vue.component('AppLoader', AppLoader);
    Vue.component('AppMenuItem', AppMenuItem);
    Vue.component('AppText', AppText);
    Vue.component('AppIdleWarning', AppIdleWarning);
}
