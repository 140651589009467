import { SceneContext, type SceneState } from '@/planner/3d/SceneContext';
import type { AxesNode } from '@/planner/3d/axes';
import type { GridNode } from '@/planner/3d/grid';
import type { GroupNode } from '@/planner/3d/group';
import type { AxisNode } from '@/planner/3d/axis';
import type { PlannerMeshNode } from '@/planner/scene/plannerMesh';
import type { CatstackImageNode } from '@/planner/scene/catstackImage';
import { lpsPoint } from '@/formus/anatomy/LPS';
import { DirectionalLight, HemisphereLight, Scene } from 'three';
import { addObject } from '@/planner/3d/object3d';
import { addPlannerMaterials } from '@/planner/scene/plannerMaterial';

export type PlannerNode =
    | AxesNode
    | GridNode
    | GroupNode
    | AxisNode
    | PlannerMeshNode
    | CatstackImageNode;

export class PlannerSceneContext extends SceneContext<PlannerNode> {}

export function plannerSceneContext(state: SceneState<PlannerNode>): PlannerSceneContext {
    const scene = new Scene();
    scene.up.set(0, 0, 1);
    addStandardLights(scene);

    const sceneContext = new PlannerSceneContext(scene, state);
    addPlannerMaterials(sceneContext);

    return sceneContext;
}

const DIRECTIONAL_LIGHT_COLOR = '#ffffff';
const HEMI_LIGHT_SKY = '#ffffff';
const HEMI_LIGHT_GROUND = '#777a70';
const HEMI_LIGHT_POSITION = lpsPoint({ superior: 2000 });
type StandardLights = Readonly<{
    directional?: DirectionalLight;
    hemisphere: HemisphereLight;
}>;

function addStandardLights(scene: Scene): StandardLights {
    return {
        directional: addObject(scene, () => {
            const light = new DirectionalLight(DIRECTIONAL_LIGHT_COLOR);
            light.intensity = 1;
            light.position.set(0, 0, 1000);
            light.updateMatrix();
            return light;
        }),
        hemisphere: addObject(scene, () => {
            const light = new HemisphereLight(HEMI_LIGHT_SKY, HEMI_LIGHT_GROUND, 5);
            light.position.copy(HEMI_LIGHT_POSITION);
            light.updateMatrix();
            return light;
        }),
    };
}
