/** The type of string S converted from snake-case to camel-case */
export type ToCamelCase<S> = S extends `${infer T}_${infer U}`
    ? `${T}${Capitalize<ToCamelCase<U>>}`
    : S;

/** Convert the given string from snake-case to camel-case */
export function toCamelCase<S extends string>(value: S): ToCamelCase<S> {
    return value
        .toLowerCase()
        .replace(/(_[a-z0-9])/g, (word) => word.slice(-1).toUpperCase()) as ToCamelCase<S>;
}

/** A type with property-keys converted from snake-case to camel-case */
export type PropertiesToCamelCase<Props> = { [K in keyof Props as ToCamelCase<K>]: Props[K] }

/** Convert the given property-keys from sname-case to camel-case */
export function propertiesToCamelCase<Props extends {}>(props: Props): PropertiesToCamelCase<Props> {
    return Object.fromEntries([
        ...Object.entries(props).map(([name, value]) => [toCamelCase(name), value]),
    ]);
}
