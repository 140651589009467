import type { CasePatient } from '@/stores/caseSettings/types';
import type { PatientPersonalInfo } from '@/lib/dicom/types';
import { type PatientComparison, PatientComparisonUtil } from '@/lib/dicom/PatientComparisonUtil';

export function comparePatient(
    casePatient: CasePatient,
    dicomPatient: PatientPersonalInfo,
): PatientComparison {
    const patient: PatientPersonalInfo = {
        name: {
            given: casePatient.firstName,
            middle: casePatient.middleNames,
            family: casePatient.familyName,
        },
        sex: casePatient.sex !== null ? casePatient.sex : 'other',
        birth_date: casePatient.dateOfBirth,
    };

    return PatientComparisonUtil.compare(patient, dicomPatient);
}
