/**
 * Implement software requirements: H1SR-120
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-120/The-software-version-is-displayed-on-user-facing-parts-of-the-software-and-documents
 */

import { DateTime } from 'luxon';

export const parseHeadMetaTag = (tag: string, backup: string | null = null): string => {
    const element = document.querySelector(`head meta[name="${tag}"]`);

    if (element === undefined || element === null) {
        if (backup !== null) {
            return backup;
        }
        throw new Error(`${tag} meta tag missing`);
    }

    const content = element.getAttribute('content');

    if (content === undefined || content === null || content.length === 0) {
        if (backup !== null) {
            return backup;
        }
        throw new Error(`${tag} meta tag content empty`);
    }

    return content;
};

/**
 * These meta tags are expected to be in the html document head.
 */

const WEB_VERSION_TAG = 'web-version';
const WEB_VERSION_SUFFIX_TAG = 'web-version-suffix';
const WEB_RELEASE_DATE_TAG = 'web-deploy-time-utc';
const SPINOPELVIC_FEATURE = 'spinopelvic-feature';
const API_BASE_URL_QUERY_SELECTOR = 'head link[rel="api"]';

/**
 * The build number of the web component.
 */
export const webVersion = () => {
    return parseHeadMetaTag(WEB_VERSION_TAG, '');
};

export const webVersionSuffix = () => {
    return parseHeadMetaTag(WEB_VERSION_SUFFIX_TAG, 'ANZ');
};

/**
 * The unlocalised release date of the web component.
 */
export const webReleaseDate = () => {
    const dateString = parseHeadMetaTag(WEB_RELEASE_DATE_TAG, '');
    return DateTime.fromISO(dateString);
};

/**
 * The base url of the api all resource requests should be made to.
 */
export const apiBaseUrl = () => {
    // note: browser document is not available in webworker context
    if (typeof document === 'undefined') {
        return '';
    }
    return document.querySelector(API_BASE_URL_QUERY_SELECTOR)?.getAttribute('href') || '';
};

/**
 * The spinopelvic feature flag.
 */
export const spinopelvicFeature = () => {
    return parseHeadMetaTag(SPINOPELVIC_FEATURE, 'false') === 'true' || false;
};
