import {
    type Material,
} from 'three';
import { makeSolidMaterial, type SolidMaterialParams } from '@/planner/3d/materials/solidMaterial';
import {
    makeStandardMaterial,
    type StandardMaterialParams,
} from '@/planner/3d/materials/standardMaterial';
import { makePhongMaterial, type PhongMaterialParams } from '@/planner/3d/materials/phongMaterial';
import { makeXrayMaterial, type XrayMaterialParams } from '@/planner/3d/materials/xrayMaterial';

type _MaterialParams =
    | SolidMaterialParams
    | StandardMaterialParams
    | PhongMaterialParams
    | XrayMaterialParams;

export type MaterialParams = Partial<_MaterialParams> & { type: _MaterialParams['type'] };

/**
 * Create a three.js {@link Material} reflecting the given parameters
 */
export function makeMaterial(parameters: MaterialParams): Material {
    switch (parameters.type) {
        case 'solid':
            return makeSolidMaterial(parameters);
        case 'standard':
            return makeStandardMaterial(parameters);
        case 'phong':
            return makePhongMaterial(parameters);
        case 'xray':
            return makeXrayMaterial(parameters);
        default:
            throw Error(`Unknown material type: ${(parameters as { type: unknown }).type}`);
    }
}
