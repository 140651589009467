<template>
    <app-form-section-title>CT Scan</app-form-section-title>

    <app-notification
        v-if="upload.isError"
        title="Upload Failed"
        :message="upload.error"
        variant="error"
        :animate-to="true"
    />

    <app-form-group label="Upload new scan?" v-if="caseStore.hasEnteredPatientData && !caseStore.isCaseDeemedUnsuitable">
        <AppUpload />
    </app-form-group>
    <app-form-subtitle v-else-if="!caseStore.isCaseDeemedUnsuitable">
        Please enter all patient details, then return to this section to upload your CT Scan.
    </app-form-subtitle>

    <div v-if="upload.hasUploaded">
        <app-notification
            title="Your files were successfully uploaded"
            message="We will process your case now. Please return to the case list to track the progress of your case."
            variant="success"
            :animate-to="true"
        />
    </div>

    <AppUploadStudyDetails />
</template>

<script setup lang="ts">
    import { useCaseUpload } from '@/stores/case-upload/store';
    import { useCaseSettings } from '@/stores/caseSettings/store';
    import AppUpload from '@/components/case-settings/AppUpload.vue';
    import AppUploadStudyDetails from '@/components/case-settings/AppUploadStudyDetails.vue';

    const upload = useCaseUpload();
    const caseStore = useCaseSettings();
</script>
