<template>
    <app-form-group label="DICOM Series" v-if="upload.hasScan">
        <app-text-input disabled class="mt-2" value="The DICOM study has been uploaded" />
    </app-form-group>

    <app-form-group label="Series" v-if="upload.hasScan">
        <app-text-input
            disabled
            class="mt-2"
            cy-data="study-dicom-series"
            :value="upload.getStudy?.series"
        />
    </app-form-group>

    <app-form-group label="Files" v-if="upload.hasScan">
        <app-text-input
            disabled
            class="mt-2"
            cy-data="study-dicom-file-count"
            :value="upload.getStudy?.fileCount"
        />
    </app-form-group>
</template>

<script setup lang="ts">
    import { useCaseUpload } from '@/stores/case-upload/store';

    const upload = useCaseUpload();
</script>
