import { Matrix3, Matrix4, Vector3 } from 'three';
import { type AnyVector3, vector3 } from '@/geometry/vector3';
import type { LpsVectors } from '@/formus/anatomy/LPS';

export type XyzVectors = {
    readonly x: Vector3;
    readonly y: Vector3;
    readonly z: Vector3;
};

export function isXyzVectors(basis: XyzVectors | LpsVectors): basis is XyzVectors {
    return 'x' in basis;
}

/**
 * Create the pure rotation matrix that transforms out of the 'basis' defined by the supplied basis vectors
 */
export function matrix4FromBasis(
    basis: XyzVectors | LpsVectors,
    position: AnyVector3 = [0, 0, 0]
): Matrix4 {
    if (isXyzVectors(basis)) {
        const { x, y, z } = basis;
        return new Matrix4().makeBasis(x, y, z).setPosition(vector3(position));
    } else {
        const { left, posterior, superior } = basis;
        return new Matrix4().makeBasis(left, posterior, superior).setPosition(vector3(position));
    }
}

/**
 * Create the pure rotation matrix3 that transforms out of the 'basis' defined by the supplied basis vectors
 */
export function matrix3FromBasis(basis: XyzVectors | LpsVectors): Matrix3 {
    if (isXyzVectors(basis)) {
        const { x, y, z } = basis;
        return new Matrix3().set(
            x.x, y.x, z.x,
            x.y, y.y, z.y,
            x.z, y.z, z.z
        );
    } else {
        const { left, posterior, superior } = basis;
        return new Matrix3().set(
            left.x, posterior.x, superior.x,
            left.y, posterior.y, superior.y,
            left.z, posterior.z, superior.z
        );
    }
}

/**
 * Returns a set of 'basis' vectors for the matrix.
 */
export function getBasis(matrix: Matrix4): XyzVectors {
    const x = new Vector3();
    const y = new Vector3();
    const z = new Vector3();
    matrix.extractBasis(x, y, z);
    return { x, y, z };
}
