import { Matrix4 } from 'three';
import { groupNode, type GroupNode } from '@/planner/3d/group';
import { axisNode, type AxisNode } from '@/planner/3d/axis';
import { catstackImageNode, type CatstackImageNode } from '@/planner/scene/catstackImage';
import { axesNode, type AxesNode } from '@/planner/3d/axes';
import { gridNode, type GridNode } from '@/planner/3d/grid';
import { perspectiveCameraNode, type PerspectiveCameraNode } from '@/planner/3d/perspectiveCamera';
import LPS from '@/formus/anatomy/LPS';
import type { PlannerMeshNode } from '@/planner/scene/plannerMesh';
import { plannerMeshNode } from '@/planner/scene/plannerMesh';
import { type CrossSectionNode, crossSectionNode } from '@/planner/scene/crossSection';

/** Nodes that represent the 3D scene */
export type PlannerNodes = {
    camera: PerspectiveCameraNode;

    ctOrigin: AxesNode;

    sceneCentre: AxesNode;
    sceneGrid: GridNode;

    contralateralFemur: PlannerMeshNode;
    operativeHemipelvis: PlannerMeshNode;
    contralateralHemipelvis: PlannerMeshNode;
    sacrum: PlannerMeshNode;
    metal: PlannerMeshNode;

    /**
     * Group object that is the parent of the femur and femoral inner-cortical surface. Its origin
     * corresponds to centre-of-rotation of the head of the stem *before any stem transformation*
     *
     * Its attachment and translation depends on whether the assembly is in the retracted or native layout:
     *
     * - In **native** layout it is attached to the scene and transformed into its original 'native' position.
     * - In **retracted** layout it is attached to the stem-group, and translated so the head is
     *   socketed in the cup. It's rotation in this layout preserves its relative transformation to the femur
     *   while allowing the femur to retain its native orientation.
     *
     * The rotation of the group depends on whether data is available on the api to create a
     * femoral anatomical-coordinate-system (ACS)
     * If data is available on the API it will be aligned to a
     * and the 'isAligned' property will be true.
     */
    femoralGroup: GroupNode;
    operativeFemur: PlannerMeshNode;
    operativeFemurInternal: PlannerMeshNode;
    femoralShaftAxis: AxisNode;
    femoralProximalShaftAxis: AxisNode;
    femoralNeckAxis: AxisNode;
    femoralAnteversionNeckAxis: AxisNode;
    femoralAnteversionCondylarAxis: AxisNode;

    /**
     * Group object attached to the acetabular-group that is the parent of the cup and liner.
     *
     * It is not translated inside the acetabular-group, so its position implicitly corresponds
     * to the centre-of-rotation of the cup. It is rotated to apply the cup-rotation to the cup and liner.
     */
    cupGroup: GroupNode;

    /** The cup mesh, attached to the cup-group. */
    cup: PlannerMeshNode;

    /** The cup collision-surface, attached to the cup-group. */
    cupCollisionSurface: PlannerMeshNode;

    /** The cup coverage-mesh, attached to the cup-group. */
    cupCoverage: PlannerMeshNode;

    /** The liner mesh, attached to the cup-group. */
    liner: PlannerMeshNode;

    cupCoronalCrossSection: CrossSectionNode;

    cupNormalAxis: AxisNode;
    cupSiAxis: AxisNode;
    cupApAxis: AxisNode;

    /**
     * Group that is the parent of the stem, head and related features, with an origin that
     * corresponds to the centre-of-rotation of the head.
     *
     * Its attachment and transform depends on whether the assembly is in the retracted or native layout:
     *
     * - In **native** layout it is attached to the femoral-group and transformed relative to the femur
     * - In **retracted** layout it is attached to the acetabular-assembly, and translated so the head is
     *   socketed in the cup. It's rotation in this layout preserves its relative transformation to the femur
     *   while allowing the femur to retain its native orientation.
     */
    stemGroup: GroupNode;

    /** The stem object, attached to the stem-group. */
    stem: PlannerMeshNode;

    /** The head object, attached to the stem-group. */
    head: PlannerMeshNode;

    bearing: PlannerMeshNode;

    stemNeckAxis: AxisNode;
    stemShaftAxis: AxisNode;
    stemPaAxis: AxisNode;
    stemCoronalCrossSection: CrossSectionNode;
    stemNeckCrossSection: CrossSectionNode;

    axialCatStack: CatstackImageNode;
    coronalCatStack: CatstackImageNode;
};

export function plannerNodes(): PlannerNodes {
    return {
        camera: perspectiveCameraNode(),

        ctOrigin: axesNode('ct-origin'),

        sceneCentre: axesNode('scene-centre'),
        sceneGrid: gridNode('scene-grid', {
            transform: new Matrix4().makeRotationAxis(LPS.Left, Math.PI / 2),
        }),

        femoralGroup: groupNode('femur-group'),
        operativeFemur: plannerMeshNode('operative-femur', { parent: 'femur-group' }),
        operativeFemurInternal: plannerMeshNode('operative-femur-internal', {
            parent: 'femur-group',
        }),
        femoralShaftAxis: axisNode('femoral-shaft-axis', {
            color: '#e400ff',
            parent: 'femur-group',
        }),
        femoralProximalShaftAxis: axisNode('femoral-proximal-shaft-axis', {
            color: '#7147ff',
            parent: 'femur-group',
        }),
        femoralNeckAxis: axisNode('femoral-neck-axis', { color: '#cbff47', parent: 'femur-group' }),
        femoralAnteversionNeckAxis: axisNode('femoral-anteversion-neck-vector', {
            color: '#fbc81c',
            parent: 'femur-group',
        }),
        femoralAnteversionCondylarAxis: axisNode('femoral-anteversion-condylar-vector', {
            color: '#fd8625',
            parent: 'femur-group',
        }),

        contralateralFemur: plannerMeshNode('contralateral-femur'),
        operativeHemipelvis: plannerMeshNode('operative-hemipelvis'),
        contralateralHemipelvis: plannerMeshNode('contralateral-hemipelvis'),
        sacrum: plannerMeshNode('sacrum'),
        metal: plannerMeshNode('metal'),

        cupGroup: groupNode('cup-group'),
        cup: plannerMeshNode('cup', { parent: 'cup-group' }),
        cupCollisionSurface: plannerMeshNode('cup-collision-surface', { parent: 'cup-group' }),
        cupCoverage: plannerMeshNode('cup-coverage', { parent: 'cup-group' }),
        liner: plannerMeshNode('liner', { parent: 'cup-group' }),
        cupNormalAxis: axisNode('cup-normal-axis', { color: '#fb6749', parent: 'cup-group' }),
        cupApAxis: axisNode('cup-ap-axis', { color: '#acf6a8', parent: 'cup-group' }),
        cupSiAxis: axisNode('cup-si-axis', { color: '#6ac5f4', parent: 'cup-group' }),
        cupCoronalCrossSection: crossSectionNode('cup-coronal-cross-section', {
            parent: 'cup-group',
        }),

        stemGroup: groupNode('stem-group'),
        stem: plannerMeshNode('stem', { parent: 'stem-group' }),
        head: plannerMeshNode('head', { parent: 'stem-group' }),
        bearing: plannerMeshNode('bearing', { parent: 'stem-group' }),
        stemNeckAxis: axisNode('stem-neck-axis', { color: '#ff0346', parent: 'stem-group' }),
        stemShaftAxis: axisNode('stem-shaft-axis', { color: '#00ff59', parent: 'stem-group' }),
        stemPaAxis: axisNode('stem-pa-axis', { color: '#00eaff', parent: 'stem-group' }),
        stemCoronalCrossSection: crossSectionNode('stem-coronal-cross-section', {
            parent: 'stem-group',
        }),
        stemNeckCrossSection: crossSectionNode('stem-neck-cross-section', {
            parent: 'stem-group',
            showPlane: true,
        }),

        axialCatStack: catstackImageNode('axial'),
        coronalCatStack: catstackImageNode('coronal'),
    };
}
