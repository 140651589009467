<template>
    <v-layout>
        <app-sidebar>
            <router-link :to="{ name: ROUTES.HOME }">
                <app-link icon="mdi-arrow-left" color="red" alone bold>Back to your Cases</app-link>
            </router-link>
        </app-sidebar>

        <app-main-view>
            <app-form-title> Admin</app-form-title>
            <app-card size="large" narrow class="">
                <h4>Case</h4>
                <label>name: </label>
                <span class="text-caption">{{ store.case?.name }}</span>
                <br />

                <label>description: </label>
                <span class="text-caption">{{ store.case?.description }}</span>
                <br />

                <label>status: </label>
                <code class="text-caption">{{ store.case?.status }}</code>
                <br />
                <br />
            </app-card>

            <div v-if="store.case">
                <AdminStudyCard
                    v-for="(study, index) in store.studies"
                    :key="index"
                    :study="study"
                    :project="store.case"
                />
            </div>
        </app-main-view>
    </v-layout>
</template>

<script setup lang="ts">
    import AppSidebar from '@/components/layout/AppSidebar.vue';
    import AppLink from '@/components/AppLink.vue';
    import AppCard from '@/components/AppCard.vue';
    import AppMainView from '@/components/layout/AppMainView.vue';
    import AppFormTitle from '@/components/forms/AppFormTitle.vue';
    import { ROUTES } from '@/router';
    import { useAdminCaseStore } from '@/stores/admin/store';
    import AdminStudyCard from '@/views/admin/AdminStudyCard.vue';

    const store = useAdminCaseStore();
    await store.load();
</script>

<style scoped></style>
