import type { CatalogComponentProperties } from './common';
import type { CupSystem } from '@/formus/catalog/cup';
import type { HeadSize } from '@/formus/catalog/head';


export const linerSizes = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'] as const;
export type LinerSize = (typeof linerSizes)[number];

export function isLinerSize(value: string): value is LinerSize {
    return linerSizes.includes(value as LinerSize);
}

export const linerTypes = ['neutral', 'highwall', '10-deg', 'dual_mobility'] as const;
export type LinerType = (typeof linerTypes)[number];

export function isLinerType(value: string): value is LinerType {
    return linerTypes.includes(value as LinerType);
}


/** Properties of a type of liner, independent of its use in any particular case  */
export type CatalogLiner = CatalogComponentProperties & {
    /** The cup-system the liner belongs to e.g. g7 */
    system: CupSystem;

    /** The size of the liner A, B, C... */
    size: LinerSize;

    /** The type of the liner */
    type: LinerType;

    /** The recommended head-size */
    headSize: HeadSize;
};
