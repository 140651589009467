import type { ApiCaseStudyCreateData, CaseScanSeriesMessage } from '@/api/study/types';
import { type DicomMessage, DicomMessageLevel, type DicomSeries } from '@/lib/dicom/DicomSeries';
import type { DicomInfo } from '@/lib/dicom/DicomInfo';

const _mapping = {
    [DicomMessageLevel.Error]: 'error',
    [DicomMessageLevel.Warning]: 'warn',
    [DicomMessageLevel.Info]: 'info',
    [DicomMessageLevel.Diagnostic]: 'debug',
};

/**
 * Make a message in wire format (with enum as a string)
 */
function makeMessage(message: DicomMessage): CaseScanSeriesMessage {
    function _messageToLevel(v: DicomMessageLevel): string {
        return v in _mapping ? _mapping[v] : 'error';
    }

    return {
        level: _messageToLevel(message.level),
        message: message.message,
    };
}

export function mapToApiObject(
    name: string,
    seriesId: string,
    series: DicomSeries,
    webComponentVersion: string,
): ApiCaseStudyCreateData {
    const makeFileMessage = (info: DicomInfo) => {
        return {
            name: info.file.name || '',
            messages: info.messages?.map(makeMessage),
        };
    };

    return {
        name: name,
        series: seriesId,
        file_count: series.items.length,
        messages: series.messages.map(makeMessage),
        excluded_files: series.items?.filter((info) => info.isExcluded).map(makeFileMessage),
        file_messages: series.items?.filter((info) => !info.isExcluded).map(makeFileMessage),
        web_component_version: webComponentVersion,
    };
}
