export type FreePromise<T> = {
    promise: Promise<T>;
    resolve: (value: T | PromiseLike<T>) => void;
    reject: (reason?: any) => void;
}

/**
 * Create a promise together with the functions to resolve and reject it
 */
export function freePromise<T = void>(): FreePromise<T> {
    let resolve: ((value: T | PromiseLike<T>) => void) | null = null;
    let reject: (() => void) | null = null;
    const promise = new Promise<T>((resolve_, reject_) => {
        resolve = resolve_;
        reject = reject_;
    });
    return {
        promise, resolve, reject,
    } as unknown as FreePromise<T>;
}
