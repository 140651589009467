<template>
    <div class="app-modal-actions">
        <slot></slot>
    </div>
</template>

<style scoped>
    .app-modal-actions {
        display: flex;
        justify-content: flex-end;
        padding: 20px 40px 20px;
    }
</style>
