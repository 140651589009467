import type { ApiCaseStudy } from '@/api/study/types';
import type { CaseStudy } from '@/stores/caseSettings/types';

export function mapToState(apiObject: ApiCaseStudy): CaseStudy {
    return {
        name: apiObject.name,
        series: apiObject.series,
        fileCount: apiObject.file_count,
    };
}
