import {
    objectNode,
    type ObjectNode, type ObjectProperties,
    updateObject,
} from '@/planner/3d/object';
import { AxesHelper } from 'three';
import { stopAll, type StopHandle } from '@/util';
import type { SceneContext } from '@/planner/3d/SceneContext';
import type { NodeId } from '@/planner/3d/nodeId';

export type AxesNode = ObjectNode<'axes'>;

export function axesNode(id: NodeId, properties?: ObjectProperties<AxesNode>): AxesNode {
    return objectNode('axes', id, properties);
}

const AXES_HELPER_SIZE = 20;

export function updateAxes(context: SceneContext, node: AxesNode): StopHandle {
    const axes = new AxesHelper(AXES_HELPER_SIZE)
    return stopAll(
        updateObject(context, node, axes),
        () => axes.dispose(),
    );
}
