import type { SpinopelvicInputs } from '@/stores/spinopelvic/types';
import { mapToApiObject, mapToState } from '@/api/spinopelvic/mapping';
import { get, put } from '@/api/spinopelvic/request';
import type { AxiosRequestConfig } from 'axios';

export async function loadSpinopelvic(
    caseId: number,
    config?: AxiosRequestConfig,
): Promise<SpinopelvicInputs | null> {
    const data = await get(caseId, config);

    if (data) {
        return mapToState(data);
    }

    return null;
}

export async function saveSpinopelvic(
    caseId: number,
    data: SpinopelvicInputs,
    config?: AxiosRequestConfig,
) {
    await put(caseId, mapToApiObject(data), config);
}
