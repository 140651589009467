<template>
    <v-row justify="center">
        <v-dialog :model-value="isOpen" persistent width="600">
            <v-card v-if="!store.isSubmitted">
                <Form v-slot="{ validate, meta, errors }">
                    <app-modal-title>Reset your password</app-modal-title>

                    <app-modal-content>
                        <p>
                            Enter the email address you use to login to Formus and we'll send you a
                            link.
                        </p>

                        <app-form-group label="Email" size="fluid">
                            <Field
                                name="email"
                                :rules="{ required: true, email: true }"
                                v-slot="{ field, errors }"
                            >
                                <app-text-input
                                    v-bind="field"
                                    v-model="email"
                                    type="email"
                                    clearable
                                    :error-messages="errors.length ? 'Login email address is not valid' : ''"
                                />
                            </Field>
                        </app-form-group>
                    </app-modal-content>

                    <app-modal-actions>
                        <router-link :to="{ name: ROUTES.LOGIN }">
                            <app-link>Back</app-link>
                        </router-link>
                        <app-button
                            colour="green"
                            :disabled="!meta.dirty || hasErrors(errors)"
                            @click="reset(validate)"
                            >Reset
                        </app-button>
                    </app-modal-actions>
                </Form>
            </v-card>

            <v-card v-else>
                <app-modal-title>Check your Email</app-modal-title>

                <app-modal-content>
                    <p>
                        If a Formus account exists for the email address
                        <strong>{{ email }}</strong> you'll receive an email with further
                        instructions. If you don't see the email in your inbox, check your spam
                        folder.
                    </p>
                    <br />
                    <p>
                        If you still cannot login, please contact
                        <a href="mailto:support@formuslabs.com">Formus support.</a>
                    </p>
                </app-modal-content>

                <app-modal-actions>
                    <app-link @click="back">Back</app-link>
                </app-modal-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script setup lang="ts">
    import { ROUTES } from '@/router';
    import { useForgotPassword } from '@/stores/auth/forgot-password/store';
    import { storeToRefs } from 'pinia';
    import { Field, Form } from 'vee-validate';

    const store = useForgotPassword();

    const { email } = storeToRefs(store);

    /**
     * modal is always open
     */
    const isOpen = true;

    /**
     * form actions
     */
    async function reset(validate: () => Promise<{ valid: boolean }>): Promise<void> {
        const result = await validate();
        if (result.valid) {
            await store.sendEmailWithResetLink();
        }
    }

    const hasErrors = (errors: {}) => {
        return errors && Object.keys(errors).length > 0;
    };

    function back(): void {
        store.$reset();
    }
</script>

<style scoped>
    a {
        color: var(--planner-blue-button);
        text-decoration: underline;
    }
</style>
