import { Vector3 } from 'three';
import { count } from 'ramda';
import type { BodySide } from '@/formus/anatomy/side';

const LEFT = new Vector3(1, 0, 0);
const RIGHT = new Vector3(-1, 0, 0);
const POSTERIOR = new Vector3(0, 1, 0);
const ANTERIOR = new Vector3(0, -1, 0);
const SUPERIOR = new Vector3(0, 0, 1);
const INFERIOR = new Vector3(0, 0, -1);

export type LpsVectors = {
    left: Vector3;
    posterior: Vector3;
    superior: Vector3;
};

export type LpsBasis = LpsVectors & {
    position: Vector3;
};

type MediolateralOffset = {
    left?: number;
    right?: number;
    medial?: number;
    lateral?: number;
    side?: BodySide;
};

type LongitudinalOffset = {
    superior?: number;
    inferior?: number;
};

type AnterioposteriorOffset = {
    anterior?: number;
    posterior?: number;
};

type LpsOffsets = MediolateralOffset & LongitudinalOffset & AnterioposteriorOffset;

/**
 * Values that define an LPS (Left-Posterior-Superior) coordinate system.
 *
 * An LPS system is not unique, in that there can be multiple LPS coordinate systems for a case,
 * but they are all consistent in their basis vectors:
 * - The first coordinate corresponds approximately to the left anatomical direction
 * - The second coordinate corresponds approximately to the posterior anatomical direction
 * - The third coordinate corresponds approximately to the superior anatomical direction
 *
 * Note: these are exposed as getters rather than constants because we are trying to avoid
 * accidentally mutating them.
 */
export default {
    get Left(): Vector3 {
        return LEFT.clone();
    },
    get Right(): Vector3 {
        return RIGHT.clone();
    },
    get Posterior(): Vector3 {
        return POSTERIOR.clone();
    },
    get Anterior(): Vector3 {
        return ANTERIOR.clone();
    },
    get Superior(): Vector3 {
        return SUPERIOR.clone();
    },
    get Inferior(): Vector3 {
        return INFERIOR.clone();
    },
    medial(side: BodySide): Vector3 {
        // The medial direction is opposite the body-side: On the left-side the right direction points
        // to the centre of body whereas on the right-side the left direction points medially
        return side === 'left' ? RIGHT.clone() : LEFT.clone();
    },
    lateral(side: BodySide): Vector3 {
        // The lateral direction is the same as the body-side: On the left-side the left direction points
        // away from centre of body wheras on the right-side the right direction points laterally
        return side === 'left' ? LEFT.clone() : RIGHT.clone();
    },
};

export function lpsIdentityVectors(): LpsVectors {
    return {
        left: LEFT.clone(),
        posterior: POSTERIOR.clone(),
        superior: SUPERIOR.clone(),
    };
}

/** Create an LPS vector corresponding to given LPS offsets */
export function lpsVector(offsets: LpsOffsets): Vector3 {
    return new Vector3(getLeft(offsets), getPosterior(offsets), getSuperior(offsets));
}

/**
 * Create an LPS point corresponding to given LPS offsets from some origin
 * @param offsets The LPS offsets
 * @param origin Optional origin point from which to take the offsets. Zero if not given.
 */
export function lpsPoint(offsets: LpsOffsets, origin?: Vector3): Vector3 {
    const result = lpsVector(offsets)
    return origin ? result.add(origin) : result;
}

const countDefined = count((v) => v !== undefined);

function getLeft(offset: MediolateralOffset): number {
    if (countDefined([offset.left, offset.right, offset.medial, offset.lateral]) > 1) {
        throw Error(`Conflicting mediolateral offset values: ${offset}`);
    }
    if (offset.left) {
        return offset.left;
    } else if (offset.right) {
        return -offset.right;
    } else if (offset.medial) {
        switch (offset.side) {
            case 'left':
                // For a left-side case medial is to the right
                return -offset.medial;
            case 'right':
                // For a right-side case medial is to the left
                return offset.medial;
            case undefined:
                throw Error(`Medial offset given without body-side: ${offset}`);
        }
    } else if (offset.lateral) {
        switch (offset.side) {
            case 'left':
                // For a left-side case lateral is to the left
                return offset.lateral;
            case 'right':
                // For a right-side case lateral is to the right
                return -offset.lateral;
            case undefined:
                throw Error(`Medial offset given without body-side: ${offset}`);
        }
    } else {
        return 0;
    }
}

function getPosterior(offset: AnterioposteriorOffset) {
    if (countDefined([offset.anterior, offset.posterior]) > 1) {
        throw Error(`Conflicting anterioposterior offset values: ${offset}`);
    }
    if (offset.anterior) {
        return -offset.anterior;
    } else if (offset.posterior) {
        return offset.posterior;
    } else {
        return 0;
    }
}

function getSuperior(offset: LongitudinalOffset) {
    if (countDefined([offset.inferior, offset.superior]) > 1) {
        throw Error(`Conflicting longitudinal offset values: ${offset}`);
    }
    if (offset.superior) {
        return offset.superior;
    } else if (offset.inferior) {
        return -offset.inferior;
    } else {
        return 0;
    }
}
