import { makeDataState } from '@/stores/shared/dataState';
import type { DicomStoreState } from '@/stores/dicom/types';
import type { CatStackValue } from '@/components/case-dicom/types';

export type CatStackState = {
    axial: CatStackValue;
    coronal: CatStackValue;
    sagittal: CatStackValue;
};
export const initialState: DicomStoreState = {
    catStacks: {} as CatStackState,
    ...makeDataState(),
};
