import { isString } from 'lodash';
import { isFiniteNumber } from '@/util';

export default class StyleUtil {
    /**
     * Maps a value to px or percentages
     *
     * e.g:
     *  1. 50 => '50px'
     *  2. '50' => '50px'
     *  3. '50px' => '50px'
     *  4. '50%' => '50%'
     */
    public static toWidthOrHeight(value: null | undefined | number | string): string {
        if (value !== null && value !== undefined) {
            // https://stackoverflow.com/questions/30342731/javascript-regex-to-find-numbers-followed-by-px-or
            if (isString(value) && value.match(/[0-9]*\.?[0-9]+(px|%)/i)) {
                return value;
            }

            if (isFiniteNumber(isString(value) ? parseInt(value) : value)) {
                return `${value}px`;
            }
        }

        return '';
    }
}
