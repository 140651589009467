import type { AlignmentMode } from '@/formus/anatomy/pelvis/alignment';

/**
 * Alignment mode (coordinate systems) for measurements related to the pelvis as represented on the API.
 *
 * - 'none' is the CT coordinate system
 * - 'APP' is the APP coordinate system, which is defined relative to the anterior-pelvic-plane
 */
export type ApiAlignmentMode = 'none' | 'APP';

/** Create a domain alignment-mode from its api-specific equivalent */
export function alignmentMode(apiMode: ApiAlignmentMode): AlignmentMode {
    switch (apiMode) {
        case 'none':
            return 'CT';
        case 'APP':
            return 'APP';
        default:
            throw new Error(`Invalid api-alignment-mode: ${apiMode}`);
    }
}
