import {
    Plane,
    Vector3,
    type BufferAttribute,
    type InterleavedBufferAttribute,
    Line3,
    BufferGeometry,
    LineSegments,
    type Matrix4,
} from 'three';
import { type StopHandle, taggedLogger } from '@/util';
import { vertexIndexBuffer, vertexPositionBuffer } from '@/geometry/mesh';
import { type Ref, watchEffect } from 'vue';

const log = taggedLogger('3D');

const _FRONT_SIDE = 1 as const;
const _BACK_SIDE = 2 as const;

/**
 * Update a cross-section-outline
 */
export function updateCrossSectionOutline(
    outlineSegments: LineSegments,
    clippingPlane: Plane,
    meshGeometry: Ref<BufferGeometry | null>,
    meshWorldTransform: Ref<Matrix4>,
): StopHandle {
    return watchEffect(
        () => {
            if (!meshGeometry.value) {
                outlineSegments.geometry.setFromPoints([]);
                return;
            }

            if (!meshGeometry.value.index) {
                log.error('Geometry has no index');
                outlineSegments.geometry.setFromPoints([]);
                return;
            }

            // Convert the plane to local-mesh-space
            const worldToMesh = meshWorldTransform.value.clone().invert();
            const localPlane = new Plane().copy(clippingPlane).applyMatrix4(worldToMesh);

            // Calculate vertex sides
            const position = vertexPositionBuffer(meshGeometry.value);
            const index = vertexIndexBuffer(meshGeometry.value);
            const vertexSide = _calculateVertexSide(position, localPlane);

            // Vertex A, if used, will be on one side of the plane, and vertex P and Q on the other side
            const vertexA = new Vector3();
            const vertexP = new Vector3();
            const vertexQ = new Vector3();
            const lineAP = new Line3();
            const lineAQ = new Line3();
            const intersectionAP = new Vector3();
            const intersectionAQ = new Vector3();
            const segmentPoints: Vector3[] = [];

            function addLineSegment(vA: number, vP: number, vQ: number) {
                vertexA.fromBufferAttribute(position, vA);
                vertexP.fromBufferAttribute(position, vP);
                vertexQ.fromBufferAttribute(position, vQ);
                lineAP.set(vertexA, vertexP);
                lineAQ.set(vertexA, vertexQ);
                const intersectedAP = localPlane.intersectLine(lineAP, intersectionAP);
                const intersectedAQ = localPlane.intersectLine(lineAQ, intersectionAQ);

                if (intersectedAP && intersectedAQ) {
                    segmentPoints.push(intersectionAP.clone(), intersectionAQ.clone());
                } else {
                    log.warning('Plane did not intersect line segment');
                }
            }

            for (let faceIndex = 0; faceIndex <= index.count / 3; ++faceIndex) {
                // Get vertex indices
                const i0 = faceIndex * 3;
                const v0 = index.array[i0];
                const v1 = index.array[i0 + 1];
                const v2 = index.array[i0 + 2];

                // Check which side of the plane the vertices are on
                if (vertexSide[v0] === _FRONT_SIDE) {
                    // vertex 0 is on the front-side
                    if (vertexSide[v1] === _FRONT_SIDE) {
                        // vertex 1 is on the front-side
                        if (vertexSide[v2] === _FRONT_SIDE) {
                            // All vertices are on the front-side, so we can skip this face
                        } else {
                            // Vertices 0 and 1 on the front-side, 2 on the back-side
                            addLineSegment(v2, v0, v1);
                        }
                    } else {
                        // vertex 1 on the back-side
                        if (vertexSide[v2] === _FRONT_SIDE) {
                            // Vertices 0 and 2 on the front-side, 1 on the back-side
                            addLineSegment(v1, v0, v2);
                        } else {
                            // Vertices 0 on the front-side, 1 and 2 on the back-side
                            addLineSegment(v0, v1, v2);
                        }
                    }
                } else {
                    // vertex 0 is on the back-side
                    if (vertexSide[v1] === _FRONT_SIDE) {
                        // vertex 1 is on the front-side
                        if (vertexSide[v2] === _FRONT_SIDE) {
                            // Vertices 1 and 2 on the front-side, 0 on the back-side
                            addLineSegment(v0, v1, v2);
                        } else {
                            // Vertices 1 on the front-side, 0 and 2 on the back-side
                            addLineSegment(v1, v0, v2);
                        }
                    } else {
                        // vertex 1 on the back-side
                        if (vertexSide[v2] === _FRONT_SIDE) {
                            // Vertices 2 on the front-side, 0 and 1 on the back-side
                            addLineSegment(v2, v0, v1);
                        } else {
                            // All vertices are on the back-side, so we can skip this face
                        }
                    }
                }
            }

            outlineSegments.geometry.setFromPoints(segmentPoints);
        }
    );
}

type _PositionBuffer = BufferAttribute | InterleavedBufferAttribute;

function _calculateVertexSide(meshVertexPosition: _PositionBuffer, localPlane: Plane): Int8Array {
    const vertexCount = meshVertexPosition.count;
    const result = new Int8Array(vertexCount);
    const position = new Vector3();
    for (let vertexIndex = 0; vertexIndex < vertexCount; ++vertexIndex) {
        position.fromBufferAttribute(meshVertexPosition, vertexIndex);
        result[vertexIndex] = localPlane.distanceToPoint(position) >= 0 ? _FRONT_SIDE : _BACK_SIDE;
    }
    return result;
}
