import { watchImmediate } from '@vueuse/core';
import {
    CanvasTexture,
    DoubleSide,
    LinearFilter,
    LinearMipMapLinearFilter,
    Mesh,
    MeshBasicMaterial,
    PlaneGeometry,
    RepeatWrapping,
} from 'three';
import { stop, stopAll, type StopHandle } from '@/util';
import { objectNode, type ObjectNode, updateObject } from './object';
import type { SceneContext } from './SceneContext';
import type { NodeId } from './nodeId';

export type ImagePlaneNode = ObjectNode<'image-plane'> & {
    source: HTMLCanvasElement | null;
    sourceVersion: number;
    width: number;
    height: number;
};

export function imagePlaneNode(id: NodeId, properties?: Partial<ImagePlaneNode>): ImagePlaneNode {
    return {
        ...objectNode('image-plane', id, properties),
        source: null,
        sourceVersion: 0,
        width: 0,
        height: 0,
    };
}

export function updateImagePlane(context: SceneContext, node: ImagePlaneNode): StopHandle {
    const mesh = new Mesh();
    const material = new MeshBasicMaterial({
        side: DoubleSide,
    });
    mesh.material = material;
    let geometry: PlaneGeometry | null = null;
    return stopAll(
        updateObject(context, node, mesh),
        watchImmediate(
            () => [node.width, node.height],
            () => {
                if (geometry) {
                    geometry.dispose();
                }
                geometry = new PlaneGeometry(node.width, node.height);
                mesh.geometry = geometry;
            },
        ),
        _updateTexture(node, material),
    );
}

/** Update material texture (aka map) from the source */
function _updateTexture(node: ImagePlaneNode, material: MeshBasicMaterial): StopHandle {
    let textureUpdate: StopHandle | null = null;
    return stopAll(
        watchImmediate(
            () => node.source,
            (source) => {
                stop(textureUpdate);
                if (source === null) {
                    material.map = null;
                    textureUpdate = null;
                    return;
                }

                const texture = new CanvasTexture(source);
                // Filters
                texture.magFilter = LinearFilter;
                texture.minFilter = LinearMipMapLinearFilter;

                // Flip the image horizontally
                texture.wrapS = texture.wrapT = RepeatWrapping;
                // texture.repeat.x = - 1;
                material.map = texture;

                textureUpdate = stopAll(
                    watchImmediate(
                        () => node.sourceVersion,
                        () => { texture.needsUpdate = true; },
                    ),
                    () => {
                        texture.dispose();
                        material.map = null;
                    }
                );
            },
        ),
        () => stop(textureUpdate),
    );
}
