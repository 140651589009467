import {
    objectNode,
    type ObjectNode, type ObjectProperties,
    updateObject,
} from '@/planner/3d/object';
import { Group } from 'three';
import { type StopHandle } from '@/util';
import type { SceneContext } from '@/planner/3d/SceneContext';
import type { NodeId } from '@/planner/3d/nodeId';

export type GroupNode = ObjectNode<'group'>;

export function groupNode(id: NodeId, properties?: ObjectProperties<GroupNode>): GroupNode {
    return objectNode('group', id, properties);
}

export function updateGroup(context: SceneContext, node: GroupNode): StopHandle {
    const group = new Group();
    return updateObject(context, node, group);
}
