import { defineStore } from 'pinia';
import { uniq } from 'ramda';
import { usePlannerStore } from '@/planner/plannerStore';
import { assertNonNull, logger } from '@/util';
import { computed, ref, watch } from 'vue';
import type { Url } from '@/formus/types';
import { getCatalogComponent } from '@/formus/catalog/catalog';
import {
    type CatalogStem,
    type StemOffset,
    type StemProfile,
    type StemSize,
    stemSizeFromString,
    type StemSizeString,
    type StemType,
    stemTypeInfo,
} from '@/formus/catalog/stem';
import { type ApiFittedStem, isSuitableStem } from '@/api/fittedComponents/fittedStem';
import { type HeadOffset } from '@/formus/catalog/head';
import {
    applyStemRotation,
    applyStemTranslation,
    logStemTransformChange,
    type StemTransformChange,
} from '@/planner/stemTransform';
import { type Adjustment, calculateStemAnteversionAdjustment } from '@/planner/adjustments';
import { formatNumberSign } from '@/lib/format/formatNumberSign';

const log = logger();

export type SelectableStem = {
    name: string;
    stemUrl: Url;
    catalogStem: CatalogStem;
    suitable: boolean;
};

export type SelectableStemType = {
    name: string;
    type: StemType;
};

export type SelectableStemSize = {
    stemUrl: Url;
    size: StemSize;
    suitable: boolean;
};

export type SelectableStemOffset = {
    stemUrl: Url;
    offset: StemOffset;
};

export type SelectableHeadOffset = {
    text: string;
    headUrl: Url;
};

export type SelectedComponentInfo = {
    stemProfile: StemProfile;
    stemOffset: StemOffset;
    stemSize: StemSizeString;
    headOffset: HeadOffset;
};

export type StemOverlayStore = {
    stemAnteversion: Adjustment | null;
    selectedStem: Url | undefined;
    selectedHead: Url | undefined;
    info: SelectedComponentInfo | null;
    recommendedStems: SelectableStem[];
    isSelectedStemRecommended: boolean | null;
    selectableTypes: SelectableStemType[];
    selectedType: StemType | null;
    selectableSizes: SelectableStemSize[];
    selectedSize: StemSize | null;
    selectableStemOffsets: SelectableStemOffset[];
    selectableHeadOffsets: SelectableHeadOffset[];
    transformStem: (change: Partial<StemTransformChange>) => void;
    initializeTypeAndSize: () => void;
};

/** The maximum number of recommended stems */
const MAX_RECOMMENDED_STEMS = 3;

/**
 * Head offsets that should be shown.
 *
 * NOTE: This is hard-coded as it was in the Vue 2 app.
 */
const TAPERLOC_HEAD_OFFSET_WHITELIST: HeadOffset[] = [-3, 0, 3, 6] as const;
const AVENIR_HEAD_OFFSET_WHITELIST: HeadOffset[] = [-3.5, 0, 3.5, 7, 10.5] as const;

export const useStemOverlayStore: () => StemOverlayStore = defineStore('stem-overlay', () => {
    const planner = usePlannerStore();

    const allStems = computed<SelectableStem[]>(() => {
        if (planner.fittedComponents && planner.catalog) {
            const catalogStems = planner.catalog.stems;
            return Array.from(planner.fittedComponents.stems.values()).map(
                (stem: ApiFittedStem) => {
                    const catalogStem = getCatalogComponent(catalogStems, stem.catalogUrl);
                    const info = stemTypeInfo(catalogStem.type);
                    return {
                        name: `${info.profile} ${catalogStem.size} ${info.offset}`,
                        stemUrl: stem.catalogUrl,
                        catalogStem,
                        suitable: isSuitableStem(stem),
                    };
                },
            );
        } else {
            return [];
        }
    });

    const selectedStem = computed<Url | undefined>({
        get: () => planner.template?.stemUrl,
        set: (value?: Url) => {
            if (value) {
                planner.setStem(value);
            }
        },
    });

    const selectedHead = computed<Url | undefined>({
        get: () => planner.template?.headUrl,
        set: (value?: Url) => {
            if (value) {
                planner.setHead(value);
            }
        },
    });

    const info = computed<SelectedComponentInfo | null>(() => {
        if (planner.template && planner.catalog) {
            const stem = getCatalogComponent(planner.catalog.stems, planner.template.stemUrl);
            const head = getCatalogComponent(planner.catalog.heads, planner.template.headUrl);
            return {
                stemProfile: stem.profile,
                stemOffset: stem.offset,
                stemSize: stem.size,
                headOffset: head.offset,
            };
        } else {
            return null;
        }
    });

    const suitableStems = computed<SelectableStem[]>(() => {
        return allStems.value.filter((stem: SelectableStem) => stem.suitable);
    });

    const recommendedStems = computed<SelectableStem[]>(() => {
        return suitableStems.value.slice(0, MAX_RECOMMENDED_STEMS);
    });

    const isSelectedStemRecommended = computed<boolean | null>(() => {
        return selectedStem.value
            ? !!recommendedStems.value.find((s) => s.stemUrl === selectedStem.value)
            : null;
    });

    const selectedType = ref<StemType | null>(null);

    // Whenever we change from one valid stem-type to another reset the selected size
    watch(selectedType, (_, previousType) => {
        if (previousType !== null) {
            selectedSize.value = null;
        }
    });

    const selectableTypes = computed<SelectableStemType[]>(() => {
        const uniqueTypes = uniq(allStems.value.map((stem) => stem.catalogStem.type));
        return uniqueTypes
            .map((type) => {
                const info = stemTypeInfo(type);
                return {
                    name: `${info.profile} - ${info.offset}`,
                    type,
                };
            })
            .sort((a, b) => a.name.localeCompare(b.name));
    });

    const selectedSize = ref<StemSize | null>(null);

    // Whenever we select a size stem-type reset the stem-size
    watch(selectedSize, (size) => {
        const selected = selectableSizes.value.find((s) => s.size === size);
        if (selected) {
            planner.setStem(selected.stemUrl);
        }
    });

    const selectableSizes = computed<SelectableStemSize[]>(() =>
        selectedType.value === null
            ? []
            : allStems.value
                  .filter((stem) => stem.catalogStem.type === selectedType.value)
                  .map((stem) => ({
                      stemUrl: stem.stemUrl,
                      size: stemSizeFromString(stem.catalogStem.size),
                      suitable: stem.suitable,
                  }))
                  .sort((stemA, stemB) => {
                      if (stemA.suitable !== stemB.suitable) {
                          return stemA.suitable ? -1 : 1;
                      } else {
                          return stemA.size < stemB.size ? -1 : 1;
                      }
                  }),
    );

    const selectableStemOffsets = computed<SelectableStemOffset[]>(() => {
        if (!planner.catalog || selectedStem.value === undefined) {
            return [];
        }
        // Selectable stems with different offsets are those that match the profile and size of the
        // currently selected stem
        const { profile: selectedProfile, size: selectedSize } = getCatalogComponent(
            planner.catalog.stems,
            selectedStem.value,
        );
        return allStems.value
            .filter(
                (stem) =>
                    stem.catalogStem.profile === selectedProfile &&
                    stem.catalogStem.size === selectedSize,
            )
            .map<SelectableStemOffset>((stem) => ({
                stemUrl: stem.stemUrl,
                offset: stem.catalogStem.offset,
            }));
    });

    const selectableHeadOffsets = computed<SelectableHeadOffset[]>(() => {
        if (!planner.template || !planner.catalog) {
            return [];
        }
        // Selectable heads with different offsets are those that are:
        // 1. in the whitelist and
        // 2. and have a size that matches the current selected head
        // 3. are compatible with the current stem preferred system (avenir vs taperloc)
        const selectedHeadSize = getCatalogComponent(
            planner.catalog.heads,
            planner.template.headUrl,
        ).size;
        return Array.from(planner.catalog.heads.values())
            .filter((head) => {
                const preferredSystem = assertNonNull(
                    planner.case?.preferredStemSystem ?? null,
                    'preferred-stem-system',
                );
                if (preferredSystem === 'taperloc-complete') {
                    return (
                        TAPERLOC_HEAD_OFFSET_WHITELIST.includes(head.offset) &&
                        head.size === selectedHeadSize &&
                        head.system === 'type1-cocr'
                    );
                } else if (preferredSystem === 'avenir-complete') {
                    return (
                        AVENIR_HEAD_OFFSET_WHITELIST.includes(head.offset) &&
                        head.size === selectedHeadSize &&
                        head.system === '1214-cocr'
                    );
                } else {
                    throw new Error(`Unsupported preferred stem-system: ${preferredSystem}`);
                }
            })
            .map((head) => ({
                text: formatNumberSign(head.offset),
                headUrl: head.self,
            }));
    });

    return {
        stemAnteversion: computed<null | Adjustment>(() =>
            calculateStemAnteversionAdjustment(planner),
        ),
        selectedStem,
        selectedHead,
        info,
        isSelectedStemRecommended,
        suitableStems,
        recommendedStems,
        selectableTypes,
        selectedType,
        selectableSizes,
        selectedSize,
        selectableStemOffsets,
        selectableHeadOffsets,
        initializeTypeAndSize: () => {
            if (planner.catalog && planner.template) {
                const catalogStem = getCatalogComponent(
                    planner.catalog.stems,
                    planner.template.stemUrl,
                );
                selectedType.value = catalogStem.type;
                selectedSize.value = stemSizeFromString(catalogStem.size);
            }
        },
        transformStem: (change: Partial<StemTransformChange>) => {
            if (!planner.template || !planner.fittedStem?.axesLocal || !planner.case) {
                log.debug('Ignoring attempt to transform stem');
                return;
            }
            logStemTransformChange(change);

            const newTransform = planner.template.stemTransform.clone();
            applyStemTranslation(newTransform, change, planner.case.operationalSide);
            applyStemRotation(
                newTransform,
                change,
                planner.case.operationalSide,
                planner.fittedStem.axesLocal,
            );

            planner.template.stemTransform = newTransform;
        },
    };
});
