import type { ApiCaseCatStacks } from '@/api/cat-stacks/types';

/**
 * The view-state of a particular catstack
 */
export type CatstackState = {
    /** The current slice-index */
    sliceIndex: number;

    /** The maximum possible slice-index */
    max: number;

    /** The minimum possible slice-index */
    min: number;
};

/**
 * The view-state of the catstacks
 */
export type CatstacksState = {
    /** Whether to show the catstacks */
    visible: boolean;

    /** View-state of the axial catstack */
    axial: CatstackState;

    /** View-state of the coronal catstack */
    coronal: CatstackState;
};

/**
 * Create view-state for the catstacks
 */
export function catstacksState(data: ApiCaseCatStacks): CatstacksState {
    return {
        visible: false,
        axial: {
            min: 0,
            max: data.axial.count - 1,
            sliceIndex: data.axial.count - 1, /* initialise the axial slice to the most inferior */
        },
        coronal: {
            min: 0,
            max: data.coronal.count - 1,
            sliceIndex: 0, // initialise coronal slice at most posterior
        },
    };
}

