<template>
    <div class="d-flex flex-column">
        <v-btn
            @click="settings.showDeveloperTools = false"
            color="planner-grey"
            class="my-2 button"
        >
            Hide tools
        </v-btn>
        <v-select
            v-model="settings.validationLogLevel"
            label="Validation logging level"
            :items="logLevels.filter((level) => level !== 'warn')"
            color="input-blue"
            variant="underlined"
            class="my-2"
            hide-details
        />
        <dev-setting-checkbox v-model="settings.show3dFeatures" label="Show 3d features" />
        <v-btn @click="testError" color="planner-red" class="my-2 button error-button">
            Test Error Handling
        </v-btn>
        <dev-setting-checkbox v-model="settings.animateCameras" label="Animate camera" />
        <dev-setting-checkbox v-model="settings.highlightOutlines" label="Highlight outlines" />
        <dev-setting-checkbox
            v-model="settings.suppressReloadErrors"
            label="Suppress reload errors"
        />
        <dev-setting-checkbox v-model="settings.showAvenirImplants" label="Show Avenir Implants" />
    </div>
</template>

<style scoped>
    .button {
        text-transform: inherit;
    }

    .error-button {
        text-transform: inherit;
    }
</style>

<script setup lang="ts">
    import { useDeveloperSettings } from '@/planner/developerSettings';
    import { logLevels } from '@/util';
    import DevSettingCheckbox from '@/planner/components/developerTools/DevSettingCheckbox.vue';

    const settings = useDeveloperSettings();

    function testError() {
        throw new Error('Test error');
    }
</script>
