<template>
    <div class="approve-plan pb-3">
        <app-button
            expand
            borderless
            colour="green"
            prepend-icon="mdi-check"
            class="cursor-pointer"
            :loading="sync.isSaving"
            :disabled="disableApproveButton"
            @click="store.approvePlan"
        >
            Approve
        </app-button>
        <v-tooltip v-if="!userRoleCanApprovePlan" activator="parent" location="top">
            <span>You do not have permission to approve this case. </span>
        </v-tooltip>
        <v-tooltip v-else-if="!valid" activator="parent" location="top">
            <span>There are incomplete changes. </span>
        </v-tooltip>
        <v-tooltip v-else-if="!changesNotApprovedYet" activator="parent" location="top">
            <span>There are no changes to approve. </span>
        </v-tooltip>
        <app-hint class="hint" v-if="changesNotApprovedYet">
            <app-text inline>Changes have been made since Plan approved.</app-text>
            <v-tooltip
                max-width="450"
                activator="parent"
                location="bottom"
                open-on-hover
                content-class="manual-plan-already-approved-warning"
            >
                <v-container outline class="rounded-sm pa-0 ma-0">
                    <div class="svg-container pa-0">
                        <svg viewBox="0 0 20 10">
                            <path d="M10,0,20,10H0Z" />
                        </svg>
                    </div>

                    <p class="custom-grey-body--text font-weight-bold">
                        Changes have been made since a Manual Plan was approved
                    </p>
                    <p>
                        If you wish these changes to be represented in the downloadable manual Plan
                        for this Case, click the &rsquo;Approve&rsquo; button once you&rsquo;re
                        happy with your changes.
                    </p>
                </v-container>
            </v-tooltip>
        </app-hint>
    </div>
</template>

<script setup lang="ts">
    import AppText from '@/components/AppText.vue';
    import AppButton from '@/components/AppButton.vue';
    import AppHint from '@/components/AppHint.vue';
    import { usePlannerStore } from '@/planner/plannerStore';
    import { useTemplateSyncStore } from '@/planner/template/templateSyncStore';
    import { computed, ref, watch } from 'vue';
    import { useUserStore } from '@/stores/user/store';

    const store = usePlannerStore();
    const user = useUserStore();
    const sync = useTemplateSyncStore();

    const props = withDefaults(defineProps<{
        valid?: boolean;
    }>(), {
        valid: true,
    });

    const currentChangesNotApprovedYet = ref(false);

    watch(
        () => sync.hasUpdate,
        (newValue, oldValue) => {
            // after a change has been made, shows the warning
            if (oldValue && !newValue) {
                currentChangesNotApprovedYet.value = true;
            }
        },
    );

    watch(
        () => store.currentOperation,
        (newValue, oldValue) => {
            // after an app
            if (oldValue === 'approve-plan' && newValue === null) {
                currentChangesNotApprovedYet.value = false;
            }
        },
    );

    /**
     * Check if there are changes that have not been approved yet.
     * This check refers to data loaded when the page loads.
     */
    const oldChangesNotApprovedYet = computed(() => {
        return (
            store.case !== null &&
            store.case.manualTemplateLastApprovedUrl !== null &&
            store.case.manualTemplateLastApprovedUrl !== store.case.manualTemplateCanonicalUrl
        );
    });

    /**
     * The warning is displayed, if we detect that there are changes that have not been approved yet.
     * These changes can be due to multiple reasons, such as changes previously made and detected on load,
     * or changes edited by the user in the current interaction.
     */
    const changesNotApprovedYet = computed(() => {
        return currentChangesNotApprovedYet.value || oldChangesNotApprovedYet.value;
    });

    const colour = `var(--planner-blue-vibrant)`;

    /**
     * Checks if the user possesses the roles required to approve a plan.
     *
     * This is a preliminary role check intended to enhance the user experience by enabling quick validation
     * of whether a user has the basic authority to approve plans. It does not perform a comprehensive permissions check.
     *
     * The API will conduct a thorough permission validation when determining if a user can approve a specific case,
     * based on more granular conditions like case-specific roles or permissions.
     *
     * Given that the planner is only accessible to users already granted access to the case,
     * this lightweight role check provides a fast and sufficient safeguard at the UI level.
     */
    const userRoleCanApprovePlan = computed(() => {
        return user.isAdmin || user.isOrgAdmin || user.isSurgeon;
    });

    const disableApproveButton = computed(() => {
        // Early return if planner mode is disabled as the button should always be disabled
        if (store.plannerMode === 'disabled') {
            return true;
        }

        // Early return if the user does not have the role to approve plans as the button should always be disabled
        if (!userRoleCanApprovePlan.value) {
            return true;
        }

        // Disable the button while the changes are happening or if there are no changes to approve
        return sync.hasUpdate || (!changesNotApprovedYet.value) || !props.valid
    });
</script>

<style scoped lang="scss">
    .approve-plan {
        padding: 0 10px;
    }

    .hint {
        padding-top: 8px;
        padding-bottom: 12px;
        display: flex;
        justify-content: left;
    }

    .manual-plan-already-approved-warning {
        padding: 20px;
        background-color: #20c4f4;
        opacity: initial;
        z-index: 20;
        box-shadow: none;
        border-radius: 0;
        width: 400px;

        .svg-container {
            height: 10px;

            svg {
                fill: v-bind('colour');
                width: 1.25rem;
                height: auto;
                position: absolute;
                top: -8px;
                left: 20px;
                bottom: 100%;
                right: 0.625rem;
            }
        }
    }
</style>
