import { Matrix4, Vector3 } from 'three';

import { matricesApproxEqual, vector3sApproxEqual } from './approxEquals';
import {
    areEqualRotations,
    eulerAngles,
    type EulerAngles,
    eulerAnglesFromMatrix,
    type EulerRotationOrder,
    isEulerAngles,
    matrixFromEulerAngles,
    rotationVector,
} from './eulerRotation';
import { zero3 } from '@/geometry/vector3';
import { isApproxIdentity, isRigidMatrix, positionalPart, rotationalPart } from '@/geometry/matrix';

/**
 * Type alias to use in places we are using a Matrix4 that is always a rigid transform
 */
export type RigidMatrix4 = Matrix4;

/**
 * A rigid-transformation by our definition is any transformation that preserves Euclidean distance between
 * points as well as *handedness*. This means any sequence of rotations or translations results in a
 * rigid-transformation, but reflections are explicitly excluded.
 *
 * See https://en.wikipedia.org/wiki/Rigid_transformation
 */
export type RigidTransform = {
    type: 'rigid-transform';
    matrix: RigidMatrix4 | null;
    translation: Vector3 | null;
    rotation: EulerAngles | null;
};

/**
 * Type-guard for rigid transform
 */
export function isRigidTransform(object?: unknown): object is RigidTransform {
    return !!object && (object as RigidTransform).type === 'rigid-transform';
}

/**
 * Create a rigid transform from a partial object
 */
export function rigidTransform(properties?: Partial<RigidTransform>): RigidTransform {
    return {
        type: 'rigid-transform',
        matrix: null,
        translation: null,
        rotation: null,
        ...properties,
    };
}

/**
 * Create a rigid transform with rotation represented in Euler angles
 */
export function eulerRigidTransform(
    matrix?: Matrix4,
    rotationOrder?: EulerRotationOrder | null,
): RigidTransform {
    return rigidTransform({
        matrix: matrix || null,
        translation: matrix ? new Vector3().setFromMatrixPosition(matrix) : null,
        rotation: matrix
            ? eulerAnglesFromMatrix(matrix, rotationOrder)
            : eulerAngles({ order: rotationOrder }),
    });
}

/**
 * Create the matrix equivalent to the given transform
 */
export function rigidMatrix(transform: RigidTransform): RigidMatrix4 {
    if (transform.matrix) {
        if (!verifyRigidTransform(transform)) {
            throw Error(`Rigid transform is inconsistent: ${transform}`);
        }
        return transform.matrix.clone();
    } else {
        const result = isEulerAngles(transform.rotation)
            ? matrixFromEulerAngles(transform.rotation)
            : new Matrix4();
        return result.setPosition(transform.translation ?? new Vector3());
    }
}

/**
 * Return true if the given transformations are approximately equal
 */
export function areEqualTransforms(a: RigidTransform, b: RigidTransform): boolean {
    if (a === b) {
        return true;
    }

    const isMatrixEqual =
        a.matrix === b.matrix || (!!a.matrix && !!b.matrix && matricesApproxEqual(a.matrix, b.matrix));

    const isTranslationEqual =
        a.translation === b.translation ||
        (!!a.translation && !!b.translation && vector3sApproxEqual(a.translation, b.translation));

    const isRotationEqual =
        a.rotation === b.rotation ||
        (!!a.rotation && !!b.rotation && areEqualRotations(a.rotation, b.rotation));

    return isMatrixEqual && isTranslationEqual && isRotationEqual;
}

/**
 * Return true if the given rigid-transformation representation is consistent.
 *
 * - Not all 4x4 matrices correspond to rigid transformations.
 * - There is some redundancy in the representation, and we can check that the redundant parts match.
 */
export function verifyRigidTransform(representation: RigidTransform): boolean {
    // Check the matrix is rigid
    const matrix = representation.matrix ?? new Matrix4();
    if (!isRigidMatrix(matrix)) {
        return false;
    }

    // Check the rotation representation, if present, matches the rotational effect of
    // the matrix (inside tolerance)
    if (isEulerAngles(representation.rotation)) {
        const impliedRotation = matrixFromEulerAngles(representation.rotation);
        if (!matricesApproxEqual(rotationalPart(impliedRotation), rotationalPart(matrix))) {
            return false;
        }
    }

    // Check the translation representation, if present, matches the translational effect
    // of the matrix (inside tolerance)
    if (representation.translation) {
        if (!vector3sApproxEqual(positionalPart(matrix), representation.translation)) {
            return false;
        }
    }

    return true;
}

/**
 * @returns whether a rigid-transform is almost exactly equivalent to an identity transformation
 */
export function isApproxIdentityTransform({
    matrix,
    translation,
    rotation,
}: RigidTransform): boolean {
    return (
        (!matrix || isApproxIdentity(matrix)) &&
        (!translation || vector3sApproxEqual(new Vector3(), translation)) &&
        (!rotation || vector3sApproxEqual(rotationVector(rotation), zero3()))
    );
}
