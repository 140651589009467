import { Material } from 'three';

export type MaterialId = string | number;

export function isMaterialId(value: unknown): value is MaterialId {
    return typeof value === 'string' || typeof value === 'number';
}

/** Symbol that identifies the material-id property */
const _materialId = Symbol('materialId');

/** Get the material-id that has been set on an object, if any */
export function getMaterialId(object: unknown): MaterialId | undefined {
    return (object as { [_materialId]?: MaterialId })[_materialId];
}

/** Set a node-id on an object */
export function markMaterialId<T extends Material>(material: T, id: MaterialId): T {
    if (Object.isExtensible(material)) {
        Object.defineProperty(material, _materialId, { value: id });
    } else {
        throw Error(`Failed to set nodeId property on object ${material}`);
    }
    return material;
}

export function formatMaterialId(id: MaterialId | null) {
    if (id === null) {
        return '--';
    } else {
        return typeof id === 'number' ? id.toString() : `'${id}'`;
    }
}
