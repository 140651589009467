import { DoubleSide, MeshPhongMaterial } from 'three';
import type { BaseMaterialParams } from '@/planner/3d/materials/params';

/**
 * Default for the diffuse color (main surface color) of the standard
 * {@link https://en.wikipedia.org/wiki/Phong_shading phong shader} applied to 3D objects.
 */
const DEFAULT_COLOR = '#ffffff' as const;

/** Materials apply to both front- and back-sides of an object as a default */
const DEFAULT_SIDE = DoubleSide;

/** Default opacity is to be full opaque i.e. not transparent at all */
const DEFAULT_OPACITY = 1.0 as const;

/**
 * Default color for the {@link https://en.wikipedia.org/wiki/Specular_highlight specular highlight}
 * (shiny bit) applied to the standard {@link https://en.wikipedia.org/wiki/Phong_shading phong shader}.
 * Changing this affects the color and intensity of the specular highlight but not its size.
 */
const DEFAULT_SPECULAR = '#eeeeee' as const;
/**
 * Default shininess for the {@link https://en.wikipedia.org/wiki/Specular_highlight specular highlight}
 * applied to the standard {@link https://en.wikipedia.org/wiki/Phong_shading phong shader}.
 * Changing this affects the size of the specular highlight. It can be any non-negative number and
 * arbitrarily large, but some meaningful values are:
 * * shininess 0 is very matte or flat-looking, with large and less intense specular highlights
 * * shininess 100 is very shiny, with small and bright specular highlights
 */
const DEFAULT_SHININESS = 75.0 as const;

export type PhongMaterialParams = BaseMaterialParams & {
    type: 'phong';
};

/**
 * Create a three.js {@link MeshPhongMaterial}; this kind of material is lit by lights in
 * the scene and can appear like a matte surface or as a shiny/polished surface depending
 * on the parameters it is created with.
 */
export function makePhongMaterial(params?: Partial<PhongMaterialParams>): MeshPhongMaterial {
    const transparent = params?.transparent ?? false;
    return new MeshPhongMaterial({
        name: params?.name ?? 'phong',
        color: params?.color ?? DEFAULT_COLOR,
        side: params?.side ?? DEFAULT_SIDE,
        clippingPlanes: params?.clippingPlanes ?? [],
        opacity: params?.opacity ?? DEFAULT_OPACITY,
        specular: DEFAULT_SPECULAR,
        shininess: DEFAULT_SHININESS,
        transparent,
        depthWrite: !transparent,
        vertexColors: params?.vertexColors ?? false,
    });
}
