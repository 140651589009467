import assert from 'assert';


export function parseVersion(version: string) {
    assert(version);
    // Extract major, minor, patch from the version (ignore any suffix like '-dev')
    const match_result = version.match(/(\d+)\.(\d+)\.(\d+)/)
    if (match_result) {
        const [major, minor, patch] = match_result.slice(1).map(Number);
        return { major, minor, patch };
    }
    return null
}

export function isVersionAfter(version1: string, version2: string) {
    const v1 = parseVersion(version1);
    const v2 = parseVersion(version2);
    if (!v1 || !v2) {
        return false;
    }
    if (v1.major > v2.major) return true;
    if (v1.major < v2.major) return false;
    if (v1.minor > v2.minor) return true;
    if (v1.minor < v2.minor) return false;
    if (v1.patch > v2.patch) return true;
    if (v1.patch < v2.patch) return false;
    return false; // Versions are equal, so version1 is not after version2
}

export function isDualMobilityVisible(version: string | null) : boolean {
    if (!version) {
        return false;
    }
    // enable dual mobility for local environment
    if (version.substring(0, 7) === "DEV-DEV") {
        return true;
    }
    const FIRST_VERSION_DUAL_MOBILITY_AVAILABLE = "v1.18.0"
    return isVersionAfter(version, FIRST_VERSION_DUAL_MOBILITY_AVAILABLE);
}
