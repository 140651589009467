export const atLeastOneNumber = (value: any) => {
    return /\d/.test(value) || 'password must contain at least one number';
};

export const atLeastOneLowercase = (value: any) => {
    return /[a-z]/.test(value) || 'password must contain at least one lowercase letter';
};

export const atLeastOneUppercase = (value: any) => {
    return /[A-Z]/.test(value) || 'password must contain at least one uppercase letter';
};

export const atLeastOneSpecialChar = (value: any) => {
    return (
        /(?=.*[^a-zA-Z\d])/.test(value) || 'password must contain at least one special character'
    );
};
