type PlanProperties = {
    plan_id: string,
    plan_type: 'AUTO' | 'MANUAL',
}

type CaseEventProperties = {
    project_id: string,
    // study_id: string,
    // surgical_template_id: string,
    // surgical_template_history_id: string,
}

type CaseEventOptions = {
    projectId: string,
    // studyId: string,
    // surgicalTemplateId: string,
    // surgicalTemplateHistoryId: string,
}

export function planEventProperties(planId: string, isAutomatedPlan: boolean): PlanProperties {
    return {
        plan_id: planId,
        plan_type: isAutomatedPlan ? 'AUTO' : 'MANUAL',
    };
}

export function caseEventProperties(options: Partial<CaseEventOptions>): Partial<CaseEventProperties> {
    return {
        project_id: options.projectId ?? '',
        // TODO: Add more case event properties later once vue3 code is more established
        // study_id: options.studyId ?? '',
        // surgical_template_history_id: options.surgicalTemplateHistoryId ?? '',
    };
}
