import Hotjar from '@hotjar/browser';
import type { ApiUserObject } from '@/api/user/me';
import { taggedLogger } from '@/util';
import { NameUtil } from '@/lib/NameUtil';
import { parseHeadMetaTag } from '@/lib/headMetaTags';
import { isTestEnvironment } from '@/util/env';

const logger = taggedLogger('hotjar');

// The <meta> tag allows to swap by environment the site id
function getSiteId(): string {
    return parseHeadMetaTag('hotjar-site-id', '5135825');
}

export function configureHotjar() {
    const siteId = Number(getSiteId());
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion, {});
}

export async function identifyUser(user: ApiUserObject) {
    if (isTestEnvironment()) {
        logger.warn('Hotjar.identify() not called in test environment');
    } else {
        logger.info(`Identifying user: ${user.email}`);
        const name = NameUtil.format(user.name);
        const userRoles = user.roles.map((r) => r.name).join(',');
        Hotjar.identify(user.id.toString(), {
            name: name,
            email: user.email,
            user_roles: userRoles,
            lastActive: new Date().toISOString(),
        });
    }
}
