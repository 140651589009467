import { templateRecordState, templateState, type TemplateStateProperties } from '@/formus/template/templateState';

/** Surgical-template 'state' properties as represented on the API */
export type ApiTemplateStateProperties = {
    /** The resource state (as a Uri). */
    state: string;

    /** The record state (as a Uri). */
    record_state: string;

    /** When the template was created. */
    created: string;

    /** When the template was last modified. */
    updated: string;
};

/** Convert template state-properties from the API */
export function templateStateProperties(properties: ApiTemplateStateProperties): TemplateStateProperties {
    const { created, updated } = properties;
    return {
        state: templateState(properties.state),
        recordState: templateRecordState(properties.record_state),
        created,
        updated,
    };
}
