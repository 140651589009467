<template>
    <div class="justify-center">
        <v-overlay
            :model-value="show"
            :persistent="true"
            opacity="0.4"
            scrim="planner-blue-vibrant"
        >
            <v-dialog
                persistent
                max-width="600"
                color="planner-grey-light"
                class="pa-4 pb-2"
                :model-value="show"
                vertical
            >
                <v-card>
                    <app-modal-title>Reload this case</app-modal-title>

                    <app-modal-content>
                        <div class="px-0">
                            {{
                                templateSync.conflict?.sameUser
                                    ? 'You updated this case in another session.'
                                    : 'This case was updated by other user.'
                            }}
                            Please reload the case to get the latest changes.
                        </div>
                    </app-modal-content>

                    <app-modal-actions>
                        <v-row class="ma-2 mb-0">
                            <app-button
                                v-if="user.isAdmin"
                                class="text-right"
                                colour="blue"
                                @click="showDetails = !showDetails"
                                >{{ showDetails ? 'Hide details' : 'Show details' }}
                            </app-button>
                            <v-spacer></v-spacer>
                            <app-button colour="green" @click="reloadPage" class="ml-2">
                                Reload case
                            </app-button>
                        </v-row>
                    </app-modal-actions>
                    <app-modal-content v-if="user.isAdmin" class="">
                        <div v-if="showDetails">
                            <div class="text-caption">This will only be displayed to admins</div>
                            <v-code class="overflow-auto text-pre text-mono my-6 pa-4">
                                {{ templateSync.conflict?.message }}
                            </v-code>
                        </div>
                    </app-modal-content>
                </v-card>
            </v-dialog>
        </v-overlay>
    </div>
</template>

<script setup lang="ts">
    import {
        useTemplateSyncStore,
    } from '@/planner/template/templateSyncStore';
    import { computed, ref } from 'vue';
    import { useUserStore } from '@/stores/user/store';

    const user = useUserStore();
    const templateSync = useTemplateSyncStore();

    const show = computed(() => {
       return templateSync.conflict !== null;
    });

    const showDetails = ref(false);

    const reloadPage = () => {
        window.location.reload();
    };
</script>
