export interface DicomPatientName {
    familyName?: string;
    givenName?: string;
    middleName?: string;
    prefix?: string;
    suffix?: string;
}

export interface PatientName {
    title?: string; // we don't currently ask for this
    given?: string; // aka first name
    middle?: string;
    family?: string; // aka last name
}

export type PatientSex = 'male' | 'female' | 'other';

export interface PatientPersonalInfo {
    name?: PatientName;
    sex?: PatientSex;
    birth_date?: string; // see PATIENT_DATE_OF_BIRTH_FORMAT
}

export const PATIENT_DATE_OF_BIRTH_FORMAT = 'yyyy-MM-dd';
