import { stopAll, type StopHandle, taggedLogger, throwIfAborted } from '@/util';
import { watchImmediate } from '@vueuse/core';
import { getPlyModel } from '@/planner/api/getPlyModel';
import { markRaw, type Raw } from 'vue';
import { BufferGeometry, type Mesh } from 'three';
import type { Url } from '@/formus/types';
import { formatNode, type ObjectNode } from '@/planner/3d/object';
import { type AxiosRequestConfig, CanceledError } from 'axios';
import { useAppErrorStore } from '@/stores/appErrorStore';

const log = taggedLogger('3D');

export type LoadGeometry = (source: Url, config?: AxiosRequestConfig) => Promise<BufferGeometry>;

export type MeshGeometryNode = {
    geometrySource: Url | null;
    geometry: Raw<BufferGeometry> | null;
};

export function meshGeometryNode(properties?: Partial<MeshGeometryNode>): MeshGeometryNode {
    return {
        geometrySource: properties?.geometrySource ?? null,
        geometry: properties?.geometry ?? null,
    };
}

/**
 * Watch the geometrySource property of the given mesh-node, and load its geometry when
 * that property changes
 */
export function updateGeometryFromSource(
    node: MeshGeometryNode & ObjectNode,
    loadGeometry: LoadGeometry = getPlyModel,
): StopHandle {
    let aborter: AbortController | null = null;

    const abortLoading = () => {
        if (aborter !== null) {
            aborter.abort();
            aborter = null;
        }
    };

    return stopAll(
        watchImmediate(
            () => node.geometrySource,
            async () => {
                abortLoading();
                if (node.geometrySource === null) {
                    node.geometry = null;
                } else {
                    aborter = new AbortController();
                    await _loadAndSetNodeGeometry(node, node.geometrySource, loadGeometry, {
                        signal: aborter?.signal,
                    });
                }
            },
        ),
        abortLoading,
    );
}

/**
 * Define a function that will load geometry from a mesh-node geometrySource URL, and then set it
 * as the node's geometry.
 *
 * NOTE: The resulting function will execute the geometry loading in-order i.e. it will wait until
 * one GET is finished before it begins the next. We shouldn't have to do this, but if we don't
 * the mesh-data getting corrupted.
 */
export function makeLoadGeometryInOrder(): LoadGeometry {
    let lastLoad: Promise<any> = Promise.resolve();
    let id: number = 0;

    return (source: Url, config?: AxiosRequestConfig): Promise<BufferGeometry> => {
        const idString = `Load-geometry-${id++} (${new URL(source).pathname})`;
        log.debug('%s: Queued', idString);

        return new Promise<BufferGeometry>((resolve, reject) => {
            lastLoad = lastLoad
                .then(async () => {
                    throwIfAborted(config?.signal);
                    log.debug('%s: Fetching', idString);
                    return await getPlyModel(source, config);
                })
                .then((geometry) => {
                    log.debug('%s: Complete', idString);
                    resolve(geometry);
                })
                .catch((error) => {
                    if (config?.signal?.aborted) {
                        log.debug('%s: Aborted');
                    }
                    reject(error);
                });
        });
    };
}

let _nullGeometry: BufferGeometry | null = null;

export function updateMeshGeometry(node: MeshGeometryNode, mesh: Mesh): StopHandle {
    return watchImmediate(
        () => node.geometry,
        (geometry) => {
            if (geometry !== null) {
                mesh.geometry = geometry;
            } else {
                if (_nullGeometry === null) {
                    _nullGeometry = new BufferGeometry();
                }
                mesh.geometry = _nullGeometry;
            }
        },
    );
}

async function _loadAndSetNodeGeometry(
    node: MeshGeometryNode & ObjectNode,
    source: Url,
    loadGeometry: LoadGeometry,
    config?: AxiosRequestConfig,
): Promise<void> {
    const { pathname } = new URL(source);
    log.debug('Loading geometry for %s from %s', formatNode(node), pathname);

    if (source === null) {
        node.geometry = null;
    } else {
        try {
            const geometry = await loadGeometry(source, config);
            log.info('Loaded geometry for %s', formatNode(node), pathname);

            if (!geometry.index) {
                log.error(
                    'Geometry loaded for %s from %s is not indexed',
                    formatNode(node),
                    source,
                );
            }

            node.geometry = markRaw(geometry);
        } catch (error) {
            if (config?.signal?.aborted) {
                log.debug('Aborted geometry load for %s', formatNode(node));
            } else if (error instanceof CanceledError) {
                log.warn(
                    'CanceledError thrown loading geometry for %s (treating as abort)',
                    formatNode(node),
                );
            } else {
                useAppErrorStore().handleError(error);
            }
        }
    }
}
