import { type App as Application } from 'vue';

export function registerLoggingFilters(Vue: Application) {
    /**
     * we are already going to use suspense now because it means we can handle loading behaviour
     * for all views in one place, this makes the code simpler and reduces code duplication.
     *
     * Suspense has been a standard feature of other component frameworks such as React for a while
     * and will likely be adopted by Vue as well.
     */
    const originalConsoleInfo = console.info;
    console.info = (msg: string) => {
        const silencedLogs = [
            '<Suspense> is an experimental feature and its API will likely change.',
        ];
        if (silencedLogs.includes(msg)) {
            return;
        } else {
            originalConsoleInfo(msg);
        }
    };

    Vue.config.warnHandler = (msg: string, instance: any, trace: string) => {
        const silencedWarnings = ['<Suspense> slots expect a single root node.'];
        if (silencedWarnings.includes(msg)) {
            return;
        } else {
            console.error(`[Vue warn]: ${msg}\n${trace}`);
        }
    };
}
