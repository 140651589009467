import { type Matrix, Matrix4, Matrix3, Vector2, Vector3, Vector4 } from 'three';
import {
    type AnyVector2,
    isVector2,
    isVector2Like,
    isVector2Tuple,
    type PartialXY,
    vector2,
} from '@/geometry/vector2';
import {
    type AnyVector3,
    isVector3,
    isVector3Like,
    isVector3Tuple,
    type PartialXYZ,
    vector3,
} from '@/geometry/vector3';
import {
    type AnyVector4,
    isVector4,
    isVector4Like,
    isVector4Tuple,
    type PartialXYZW,
    vector4,
} from '@/geometry/vector4';

export type VectorN = Vector2 | Vector3 | Vector4;
export type AnyComparable =
    | number
    | Matrix
    | VectorN
    | number[]
    | PartialXY
    | PartialXYZ
    | PartialXYZW;

/**
 * Default tolerance for floating point comparisons. If the difference between values is less than
 * this number they are considered 'approximately equal'
 */
export const DEFAULT_TOLERANCE = 1e-8;

/**
 * Return true if the arguments (numbers, vectors, matrices, or numeric arrays) are approximately equal.
 *
 * This function does some work to coerce arguments so they can be compared, which is very flexible.
 * For reasons of clarity or performance the type-specific functions e.g. {@link vector3sApproxEqual}
 * can be used instead
 *
 * Arguments are approximately equal if each value in the first argument differs by no more than the
 * given tolerance from the corresponding value in the second argument
 */
export function approxEquals(
    left: AnyComparable,
    right: AnyComparable,
    tolerance: number = DEFAULT_TOLERANCE,
): boolean {
    // Compare numbers
    if (typeof left === 'number' && typeof right === 'number') {
        return floatsApproxEqual(left, right, tolerance);
    }

    // If either are comparable as Vector4, then compare as Vector4
    if (isComparableAsVector4(left) || isComparableAsVector4(right)) {
        verifyApproxComparable('left', 'Vector4', left, isComparableAsVector4);
        verifyApproxComparable('right', 'Vector4', right, isComparableAsVector4);
        const lVal = vector4(left as AnyVector4);
        const rVal = vector4(right as AnyVector4);
        return vector4sApproxEqual(lVal, rVal, tolerance);
    }

    // If either are comparable as Vector3, then compare as Vector3
    if (isComparableAsVector3(left) || isComparableAsVector3(right)) {
        verifyApproxComparable('left', 'Vector3', left, isComparableAsVector3);
        verifyApproxComparable('right', 'Vector3', right, isComparableAsVector3);
        const lVal = vector3(left as AnyVector3);
        const rVal = vector3(right as AnyVector3);
        return vector3sApproxEqual(lVal, rVal, tolerance);
    }

    // If either are comparable as Vector2, then compare as Vector2
    if (isComparableAsVector2(left) || isComparableAsVector2(right)) {
        verifyApproxComparable('left', 'Vector2', left, isComparableAsVector2);
        verifyApproxComparable('right', 'Vector2', right, isComparableAsVector2);
        const lVal = vector2(left as AnyVector2);
        const rVal = vector2(right as AnyVector2);
        return vector2sApproxEqual(lVal, rVal, tolerance);
    }

    // If both the same size matrix then compare array representations
    if (
        (left instanceof Matrix3 && right instanceof Matrix3) ||
        (left instanceof Matrix4 && right instanceof Matrix4)
    ) {
        return arraysApproxEqual(left.elements, right.elements, tolerance);
    }

    // If both are arrays then compare corresponding values
    if (Array.isArray(left) && Array.isArray(right)) {
        return arraysApproxEqual(left, right, tolerance);
    }

    // Otherwise we don't know how to do the comparison
    throw Error('Failed to approx-compare values');
}

/** Returns true if we should compare this value as a Vector2 */
function isComparableAsVector2(value: AnyComparable): boolean {
    return isVector2(value) || isVector2Tuple(value) || isVector2Like(value);
}

/** Returns true if we should compare this value as a Vector3 */
function isComparableAsVector3(value: AnyComparable): boolean {
    return isVector3(value) || isVector3Tuple(value) || isVector3Like(value);
}

/** Returns true if we should compare this value as a Vector4 */
function isComparableAsVector4(value: AnyComparable): boolean {
    return isVector4(value) || isVector4Tuple(value) || isVector4Like(value);
}

/** Return true if the given numbers are approximately equal, inside tolerance */
export function floatsApproxEqual(left: number, right: number, tolerance: number = DEFAULT_TOLERANCE): boolean {
    return Math.abs(left - right) < tolerance;
}

/** Return true if the given vectors are approximately equal, inside tolerance */
export function vector4sApproxEqual(left: Vector4, right: Vector4, tolerance: number = DEFAULT_TOLERANCE): boolean {
    return (
        Math.abs(left.x - right.x) < tolerance &&
        Math.abs(left.y - right.y) < tolerance &&
        Math.abs(left.z - right.z) < tolerance &&
        Math.abs(left.w - right.w) < tolerance
    );
}

/** Return true if the given vectors are approximately equal, inside tolerance */
export function vector3sApproxEqual(left: Vector3, right: Vector3, tolerance: number = DEFAULT_TOLERANCE): boolean {
    return (
        Math.abs(left.x - right.x) < tolerance &&
        Math.abs(left.y - right.y) < tolerance &&
        Math.abs(left.z - right.z) < tolerance
    );
}

/** Return true if the given vectors are approximately equal, inside tolerance */
export function vector2sApproxEqual(left: Vector2, right: Vector2, tolerance: number = DEFAULT_TOLERANCE): boolean {
    return (
        Math.abs(left.x - right.x) < tolerance &&
        Math.abs(left.y - right.y) < tolerance
    );
}

/** Return true if the given matrices are approximately equal, inside tolerance */
export function matricesApproxEqual(left: Matrix4, right: Matrix4, tolerance?: number): boolean;
export function matricesApproxEqual(left: Matrix3, right: Matrix3, tolerance?: number): boolean;
export function matricesApproxEqual(left: Matrix, right: Matrix, tolerance?: number): boolean {
    if (left.elements.length === right.elements.length) {
        return arraysApproxEqual(left.elements, right.elements, tolerance ?? DEFAULT_TOLERANCE);
    } else {
        throw new Error('Attempting to compare matrices of different size');
    }
}

/**
 * Return true if the given arrays are the same size and contain equal values, with
 * corresponding values differing by no more than the given tolerance
 */
export function arraysApproxEqual(
    left: number[],
    right: number[],
    tolerance: number = DEFAULT_TOLERANCE,
): boolean {
    return (
        left.length === right.length &&
        left.find((leftElement, index) => Math.abs(leftElement - right[index]) >= tolerance) ===
            undefined
    );
}

function verifyApproxComparable(
    variable: string,
    expectedType: string,
    value: AnyComparable,
    isComparable: (value: AnyComparable) => boolean,
) {
    if (!isComparable(value)) {
        throw new Error(`${variable} is not comparable to ${expectedType}`);
    }
}
