import type {
    CaseSettingsApiObject,
    CaseSettingsOptions,
    PartialCaseApiObjectBase,
} from '@/api/case/types';
import type { CaseSettingsInputs } from '@/stores/caseSettings/types';
import { mapToState as mapStudyToState } from '@/api/study/mapToState';

export function mapToState(
    apiObject: CaseSettingsApiObject | PartialCaseApiObjectBase,
    options: CaseSettingsOptions,
): CaseSettingsInputs {
    const caseHasRequiredData =
        apiObject.id !== null &&
        apiObject.surgeon_id !== null &&
        !!apiObject.surgical_specifications &&
        !!apiObject.patient;

    if (!caseHasRequiredData) {
        console.warn(
            `Mapping a case which seems to not be created in the vue 3 app. 
        This is likely a case created inn vue 2. Will try to map it anyway.`,
            apiObject,
        );
    }

    const specifications = apiObject.surgical_specifications;
    const patient = apiObject.patient;
    return {
        id: apiObject.id,
        owner_id: apiObject.owner_id,
        study: apiObject.study ? mapStudyToState(apiObject.study) : null,
        surgery: {
            name: apiObject.name || '',
            side: apiObject.side,
            description: apiObject.description || '',
            procedure: 'Primary Total Hip Arthroplasty',
            date: apiObject.surgery_date || '',
        },
        patient: {
            firstName: patient?.first_name ?? '',
            middleNames: patient?.middle_name ?? '',
            familyName: patient?.family_name ?? '',
            sex: patient?.sex ?? null,
            dateOfBirth: patient?.birth_date ?? '',
        },
        specifications: {
            surgeon: {
                selected: apiObject.surgeon_id,
                options: options.surgeons,
                error: false,
            },
            targets: {
                legLength: String(specifications?.target_leg_length_change ?? ''),
                offset: String(specifications?.target_offset_change ?? ''),
            },
            stems: {
                preferredSystem: specifications?.preferred_system ?? '',
                selected: (specifications?.stems ?? []).map((stem) => stem.id),
                options: options.stems,
            },
            cup: {
                inclinationAngle: String(specifications?.cup_inclination_angle ?? ''),
                fitMethod: specifications?.cup_fit_method ?? '',
                anteversion: specifications?.cup_anteversion_mode ?? '',
                anteversionAngle: String(specifications?.cup_anteversion_angle ?? ''),
                alignment: specifications?.cup_align_mode ?? '',
            },
        },
    };
}
