import type { Url } from '@/formus/types';
import type { TemplateId } from '@/formus/template/template';

/**
 * Coerce a template-id or template-URL into a template-URL
 */
export function templateUrl(value: TemplateId | Url): Url {
    return typeof value === 'object'
        ? `project/${value.case}/hip/surgical/template/${value.template}`
        : value;
}

/**
 * Coerce a template-id or template-URL into a template-id
 */
export function templateId(value: TemplateId | Url): TemplateId {
    return typeof value === 'object' ? value : templateIdFromUrl(value);
}

/**
 * Pair a template-id or template-URL with its equivalent template-URL or template-id respectively
 */
export function templateIdAndUrl(value: TemplateId | Url): [TemplateId, Url] {
    return typeof value === 'object' ? [value, templateUrl(value)] : [templateId(value), value];
}

/**
 * Extract a template-id from a template-URL
 */
function templateIdFromUrl(url: Url): TemplateId {
    const match = url.match(/project\/([0-9]*)\/hip\/surgical\/template\/([0-9]*)/);
    if (match) {
        return {
            case: Number(match[1]),
            template: Number(match[2]),
        };
    }
    throw Error(`Failed to extract template-id from url '${url}'`);
}

