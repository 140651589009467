<template>
    <v-dialog v-model="isDialogVisible" persistent style="{'width': 'fit-content'}">
        <template v-slot:activator="{ props: activatorProps }">
            <div class="more-spinopelvic-info d-flex justify-end">
                <v-btn
                    variant="flat"
                    v-bind="activatorProps"
                    :disabled="!spinopelvicStore.hasData"
                    @click="onShow"
                >
                    <v-icon medium dense color="planner-blue-button">
                        mdi-information-outline
                    </v-icon>
                    <span class="text-caption pl-2 pt-1"> More </span>
                </v-btn>

                <v-tooltip right activator="parent">
                    <span> Show more hip-spine assessment </span>
                </v-tooltip>
            </div>
        </template>

        <template #default>
            <v-card class="hip-spine-dialog" cy-data="hip-spine-dialog">
                <app-modal-title class="headline" cy-data="title">
                    Hip-Spine Assessment
                </app-modal-title>

                <v-divider />

                <app-modal-content class="mt-5 content">
                    <v-row style="margin: 0">
                        <v-col style="flex-grow: 3">
                            <NomogramPlot
                                :id="1"
                                :cup-inclination="cupInclination"
                                :cup-anteversion="cupAnteversion"
                                :calculations="calculations"
                            />
                        </v-col>

                        <v-col style="flex-grow: 1">
                            <SpinopelvicParameterWarnings
                                :warnings="warnings"
                                :calculations="calculations"
                            />
                        </v-col>
                    </v-row>
                </app-modal-content>

                <app-modal-actions>
                    <v-spacer />
                    <app-button
                        colour="blue-button"
                        class="text-uppercase"
                        variant="flat"
                        @click="onClose"
                    >
                        Close
                    </app-button>
                </app-modal-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script lang="ts" setup>
    import { ref } from 'vue';
    import {
        type SpinopelvicCalculations,
        type SpinopelvicWarnings,
    } from '@/components/spinopelvic/types';
    import { useSpinopelvic } from '@/stores/spinopelvic/store';
    import NomogramPlot from '@/components/spinopelvic/NomogramPlot.vue';
    import SpinopelvicParameterWarnings from '@/components/spinopelvic/SpinopelvicParameterWarnings.vue';
    // import { trackEvent } from '@/analytics/amplitude';
    // import { caseEventProperties } from '@/analytics/event';

    defineProps<{
        warnings: SpinopelvicWarnings;
        calculations: SpinopelvicCalculations;
        cupInclination: number;
        cupAnteversion: number;
    }>();

    const spinopelvicStore = useSpinopelvic();

    const isDialogVisible = ref(false);

    const onShow = () => {
        isDialogVisible.value = true;
        // trackEvent('spinopelvic_dialog_opened', {
        //     ...caseEventProperties(props.hipTemplateStore.project),
        // });
    };

    const onClose = () => {
        isDialogVisible.value = false;
        // trackEvent('spinopelvic_dialog_closed', {
        //     ...caseEventProperties(props.hipTemplateStore.project),
        // });
    };
</script>

<style scoped lang="scss">
    .v-dialog .v-overlay__content {
        width: fit-content;
    }

    //
    //:deep(.table div) {
    //    overflow-x: hidden;
    //    overflow-y: hidden;
    //}
    //
    .hip-spine-dialog {
        width: fit-content;
        margin: auto;
    }
</style>
