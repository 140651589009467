import { type ApiRigidTransform, transformMatrixFromApi } from '@/geometry/apiTransform';
import type { TemplateProperties } from '@/formus/template/templateProperties';

/** Surgical-template properties as represented on the API */
export type ApiTemplateProperties = {
    /**
     * The offset of the centre of the cup from the native centre position
     *
     * The native centre of the joint is the center of femoral head (which may
     * be slightly different to the acetabulum centre
     */
    cup_offset: {
        ap: number;
        si: number;
        ml: number;
    };

    /** The **anatomic** anteversion and inclination of the cup in **degrees**. */
    cup_rotation: {
        anteversion: number;
        inclination: number;
    };

    /**
     * This is the planned offset medial-lateral offset of the procedure. This value is likely to be null
     * unless the surgeon overrides the surgical specifications. It is normal for the value to be a positive single
     * digit number.
     *
     * It is important to note that if this value is changed then the ranking of the stems and heads will
     * be changed (this is a reasonably quick procedure that uses the existing segmented models and the existing
     * stem fitting data).
     *
     * This is the number of milli-metres (mm) that the procedure will add to the patient anatomy.
     *
     * This field will normally be null which indicates that the value should be inherited from the
     * surgical specification (which inherit from the selected surgeon surgical preferences).
     */
    target_offset_change: number | null;

    /**
     * This is the planned leg length offset of the procedure. This value is likely to be null
     * unless the surgeon overrides the surgical specifications. It is normal for the value to be a positive single
     * digit number.
     *
     * It is important to note that if this value is changed then the ranking of the stems and heads will
     * be changed (this is a reasonably quick procedure that uses the existing segmented models and the existing
     * stem fitting data).
     *
     * This is the number of milli-metres (mm) that the procedure will add to the patient anatomy.
     *
     * This field will normally be null which indicates that the value should be inherited from the
     * surgical specification (which inherit from the selected surgeon surgical preferences).
     */
    target_leg_length_change: number | null;

    stem_transform: ApiRigidTransform | null;

    dual_mobility: boolean;
};

export function templateProperties(properties: ApiTemplateProperties): TemplateProperties {
    return {
        cupOffset: properties.cup_offset,
        cupRotation: properties.cup_rotation,
        targetOffsetChange: properties.target_offset_change,
        targetLegLengthChange: properties.target_leg_length_change,
        stemTransform: transformMatrixFromApi(properties.stem_transform),
        dualMobility: properties.dual_mobility,
    };
}
