/**
 * A simple error class that allows a single causing exception to be captured.
 */
export default class Error2 extends Error {
    private readonly childError: Error;

    constructor(message: string, child: Error) {
        super(message);
        this.childError = child;
    }

    get child(): Error {
        return this.childError;
    }

    get stack(): string {
        return (super.stack || '') + this.childError.stack;
    }
}
