<template>
    <div class="app-divider">
        <v-divider />
    </div>
</template>

<style scoped>
    .app-divider {
        margin: 20px 0;
    }
</style>
