<template>
    <v-layout>
        <app-sidebar>
            <router-link :to="{ name: ROUTES.HOME }">
                <app-link icon="mdi-arrow-left" color="red" alone bold>Back to your Cases</app-link>
            </router-link>
        </app-sidebar>

        <app-main-view>
            <app-form-title> Formus Case CT Scans</app-form-title>
            <CatStackContainer v-if="store.isLoaded" />
        </app-main-view>
    </v-layout>
</template>

<script setup lang="ts">
    import { ROUTES } from '@/router';
    import { verify } from '@/lib/verify';
    import { useRoute } from 'vue-router';
    import { useDicomStore } from '@/stores/dicom/store';
    import CatStackContainer from '@/components/case-dicom/CatStackContainer.vue';

    const store = useDicomStore();
    store.$reset();

    const route = useRoute();

    const caseIdParam = verify(route.params.id, 'case id is required');
    const caseId = Number(caseIdParam);
    await store.load(caseId);

    if (store.isError) {
        // If for some reason the cat-stacks did not load correctly, we throw an error which should be caught
        // by the <AppErrorBoundary />
        throw new Error('Could not load cat-stacks')
    }
</script>
