import { logger } from '@/util';

const log = logger('util');

/**
 * A function whose invocation implies stopping some ongoing effect.
 *
 * Should be idempotent - you should be able to call this multiple times, and only the first time
 * will have any effect.
 */
export type StopHandle = () => void;

/**
 * Invoke a stop-handle to stop whatever effect it represents.
 *
 * This is obviously just invoking the handle: it is a function only for clarity.
 */
export function stop(handle: StopHandle | null | undefined) {
    if (handle !== undefined && handle !== null) {
        try {
            handle();
        } catch (e) {
            log.error('Error while invoking stop-handle');
            throw e;
        }
    }
}

/**
 * Create one stop-handle that represents all the defined handles in the given array.
 */
export function stopAll(...handles: (StopHandle | null | undefined)[]): StopHandle {
    return () => handles.forEach(stop);
}
