<template>
    <v-checkbox
        v-model="model"
        :indeterminate="model === undefined"
        color="input-blue"
        hide-details
    />
</template>

<script setup lang="ts">
    const model = defineModel<boolean>();
</script>
