<template>
    <app-card size="large" narrow class="">
        <h4>
            Study

            <span>{{ study.id.study }} </span>
            <span v-if="isActive" class="text-caption"> (active study)</span>
        </h4>
        <label>name: </label>
        <span class="text-caption">{{ study?.name }}</span>

        <br />
        <label>series: </label>
        <span class="text-caption">{{ study?.series }}</span>

        <br />
        <label>file: </label>
        <span class="text-caption">{{ study?.fileCount }}</span>
        <br />
        <br />

        <v-card-actions v-if="isActive">
            <v-spacer />
            <label>Duplicate study: </label>
            <AdminDuplicateStudyFromDicoms class="mx-5" :study="study" />
        </v-card-actions>
    </app-card>
</template>

<script setup lang="ts">
    import AppCard from '@/components/AppCard.vue';
    import AdminDuplicateStudyFromDicoms from '@/admin/AdminDuplicateStudyFromDicoms.vue';
    import type { ApiCase } from '@/planner/api/case';
    import type { ApiStudy } from '@/admin/api/study';
    import { computed } from 'vue';

    const props = defineProps<{ study: ApiStudy; project: ApiCase }>();

    const isActive = computed(() => {
        return props.study.self === props.project?.study;
    });
</script>

<style scoped></style>
