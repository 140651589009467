import { Material, type Mesh, MeshBasicMaterial } from 'three';
import type { StopHandle } from '@/util';
import { assert } from '@/util';
import { watchImmediate } from '@vueuse/core';
import { type Raw, type Ref } from 'vue';
import type { SceneContext } from '@/planner/3d/SceneContext';
import { isMaterialId, type MaterialId } from '@/planner/3d/materials/materialId';

export type MeshMaterial = MaterialId | Raw<Material> | null;

export type DoubleSidedMeshMaterial = { front: MeshMaterial; back: MeshMaterial };

export function isMeshMaterial(value: unknown): value is MeshMaterial {
    return value === null || value instanceof Material || isMaterialId(value);
}

export function isDoubleSidedMaterial(value: unknown): value is DoubleSidedMeshMaterial {
    if (typeof value !== 'object') {
        return false;
    }

    const { front, back } = value as Partial<DoubleSidedMeshMaterial>;
    return isMeshMaterial(front) && isMeshMaterial(back);
}

export function updateMeshMaterial(context: SceneContext, material: Ref<MeshMaterial>, mesh: Mesh): StopHandle {
    return watchImmediate(
        material,
        (material_) => applyMeshMaterial(context, material_, mesh),
    );
}

export function applyMeshMaterial(context: SceneContext, material: MeshMaterial, mesh: Mesh): void {
    if (material === null) {
        mesh.material = _nullMaterial();
    } else if (material instanceof Material) {
        mesh.material = material;
    } else {
        assert(isMaterialId(material));
        mesh.material = context.getMaterial(material);
    }
}

let _nullMaterialInstance: Material | null = null;
const _NULL_MATERIAL_COLOR = '#8400ff' as const;

function _nullMaterial() {
    if (_nullMaterialInstance === null) {
        _nullMaterialInstance = new MeshBasicMaterial({
            transparent: true,
            opacity: 0.2,
            color: _NULL_MATERIAL_COLOR,
        });
    }
    return _nullMaterialInstance;
}
