import { Mesh } from 'three';
import { assert, stop, stopAll, type StopHandle } from '@/util';
import { type ObjectNode, objectNode, updateObject } from '@/planner/3d/object';
import type { SceneContext } from '@/planner/3d/SceneContext';
import {
    type MeshGeometryNode,
    meshGeometryNode,
    updateMeshGeometry,
} from '@/planner/3d/meshGeometry';
import type { NodeId } from '@/planner/3d/nodeId';
import { type RenderOrderNode, renderOrderNode, updateRenderOrder } from '@/planner/3d/renderOrder';
import {
    applyMeshMaterial,
    type DoubleSidedMeshMaterial,
    isDoubleSidedMaterial,
    isMeshMaterial,
    type MeshMaterial,
} from '@/planner/3d/materials/meshMaterial';
import { watchImmediate } from '@vueuse/core';

/** The common state representing a mesh in the scene */
export type MeshNode = ObjectNode<'mesh'> &
    MeshGeometryNode &
    RenderOrderNode & {
    material: MeshMaterial | DoubleSidedMeshMaterial;
};

export function isMeshNode(node: ObjectNode): node is MeshNode {
    return node.type === 'mesh';
}

export function meshNode(id: NodeId, properties?: Partial<MeshNode>): MeshNode {
    return {
        ...objectNode('mesh', id, properties),
        ...meshGeometryNode(properties),
        ...renderOrderNode(properties),
        material: properties?.material ?? null,
    };
}

export function updateMesh(context: SceneContext, node: MeshNode): StopHandle {
    const mesh = new Mesh();
    let backSideUpdate: StopHandle | null = null;

    return stopAll(
        updateObject(context, node, mesh),
        updateMeshGeometry(node, mesh),
        updateRenderOrder(node, mesh),
        watchImmediate(
            () => node.material,
            (material) => {
                stop(backSideUpdate);
                if (isMeshMaterial(material)) {
                    applyMeshMaterial(context, material, mesh);
                } else {
                    assert(isDoubleSidedMaterial(material));
                    applyMeshMaterial(context, material.front, mesh);

                    const backSideMesh = new Mesh();
                    mesh.add(backSideMesh);
                    applyMeshMaterial(context, material.back, backSideMesh);

                    backSideUpdate = stopAll(
                        updateMeshGeometry(node, backSideMesh),
                        updateRenderOrder(node, backSideMesh),
                        watchImmediate(() => node.name, (name) => backSideMesh.name = name ? `${name}-back` : ''),
                        () => mesh.remove(backSideMesh),
                    );
                }
            },
        ),
    );
}
