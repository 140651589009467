import { computed } from 'vue';
import { defineStore } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { ROUTES } from '@/router';

/**
 * Using a ***setup store*** instead of an ***options store*** allows to make use of the vue-router `useRoute`.
 * This store can be consumed by ***options store***, whereas the `useRoute()` can't`be.
 */
export const useRouteStore = defineStore('routing-store', () => {
    const route = useRoute();
    const router = useRouter();

    const isNewCase = computed(() => route.name === ROUTES.NEW_CASE);

    return {
        isNewCase,
        route,
        router
    };
});
