import type { ApiCatalogComponentBase } from '@/api/catalog/base';
import { convertLinks } from '@/api/convertLinks';
import {
    type CatalogStem,
    isStemOffset,
    isStemSizeString,
    isStemSystem,
    isStemType,
    stemTypeInfo,
} from '@/formus/catalog/stem';
import { assertIs } from '@/util';

export type ApiCatalogStem = ApiCatalogComponentBase & {
    /** The stem-offset: the position of the head relative to the femoral part of the stem */
    offset_mode: string;
};

const stemLinkMap = {
    self: 'self',
} as const;

/**
 * Convert a stem representation from the api into a catalog-stem
 */
export function catalogStem(apiStem: ApiCatalogStem): CatalogStem {
    const { name, system, type, size, offset_mode } = apiStem;
    assertIs(isStemSystem, system);
    assertIs(isStemType, type);
    assertIs(isStemSizeString, size);
    assertIs(isStemOffset, offset_mode);

    return {
        name,
        system,
        type,
        size,
        profile: stemTypeInfo(type).profile,
        offset: offset_mode,
        ...convertLinks(apiStem, stemLinkMap),
    };
}
