import { type ColorRepresentation, DoubleSide, Plane, ShaderMaterial } from 'three';
import xrayVertexShader from '@/planner/3d/materials/shaders/xray.vertex.shader.glsl';
import xrayFragmentShader from '@/planner/3d/materials/shaders/xray.fragment.shader.glsl';
import { toColor } from '@/planner/3d/materials/color';

export type XrayMaterialParams = {
    type: 'xray';
    name?: string;
    color?: ColorRepresentation;
    clippingPlanes?: Plane[];
};
/**
 * Default color for xray material
 */
const DEFAULT_COLOR = '#ffffff';

/**
 * Create a three.js {@link ShaderMaterial} that is partially transparent. The transparency
 * of the material depends on the angle the surface is viewed, with a surface being transparent
 * when viewed directly and gaining opacity as the surface becomes tangent to the view direction.
 *
 * The result is some kind of visual approximation of the
 * {@link https://en.wikipedia.org/wiki/Fresnel_equations fresnel effect} on a transparent
 * surface. It is 'x-ray' in the very colloquial sense that objects are transparent, but is
 * actually not as similar to an x-ray as it is images from an electron microscope.
 */
export function makeXrayMaterial(params?: Partial<XrayMaterialParams>): ShaderMaterial {
    const color = toColor(params?.color ?? DEFAULT_COLOR);
    return new ShaderMaterial({
        name: params?.name ?? 'xray',
        uniforms: {
            diffuse: { value: color },
            uColor: { value: color },
        },
        transparent: true,
        depthWrite: false,
        vertexShader: xrayVertexShader,
        fragmentShader: xrayFragmentShader,
        side: DoubleSide,
        clipping: true,
        clippingPlanes: params?.clippingPlanes ?? [],
        clipShadows: true,
    });
}
