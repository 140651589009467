import { isFiniteNumber } from '@/util';
import {
    AngleDefinition,
    OperativeSide,
    type PreoperativePlanRepresentation,
} from '@/components/case-plan/qr-code/PreoperativePlanRepresentation';
import assert from 'assert';
import type { LoadedPlanStoreState } from '@/stores/plan/types';
import { findAngleMeasurement } from '@/formus/anatomy/measurements';
import { NameUtil } from '@/lib/NameUtil';
import { anatomicAngles, toRadiographic } from '@/formus/anatomy/pelvis/acetabularAngles';
import { toDegrees, toRadians } from '@/formus/anatomy/pelvis/anteversionInclination';
import type { ApiPlan, ApiPlanMeasurements } from '@/api/plan/types';
import type { ApiCompleteTemplate } from '@/api/template/completeTemplate';

export enum BodySide {
    Left = 'left',
    Right = 'right',
}

export type BodySideType = BodySide.Left | BodySide.Right;

const uglySize = /^\s*([.\d]+)\s*(?:mm)?\s*$/;

/**
 * Reverse map horrible cup sizes of the form '10 mm' to a simple number.
 * Some of the numbers are numbers (rather than strings), so handle that as well.
 */
function formatSizeToNumber(aSize?: string | number): number {
    if (typeof aSize === 'number') {
        return aSize;
    } else if (typeof aSize === 'string') {
        const matches = aSize.match(uglySize);
        if (matches?.[1]) {
            if (aSize.includes('.')) {
                return Number.parseFloat(matches[1]);
            } else {
                return Number.parseInt(matches[1]);
            }
        }
    }
    throw new Error(`size not valid ${aSize}`);
}

function makeOperativeSide(side?: BodySideType): OperativeSide {
    switch (side) {
        case BodySide.Left:
            return OperativeSide.Left;
        case BodySide.Right:
            return OperativeSide.Right;
        default:
            throw new Error('no operative side');
    }
}

/**
 * @return the value of a number rounded to the nearest integer.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
 */
function asWholeNumber(angle: number): number {
    return Math.round(angle);
}

/** Make a string of a similar form to that which is displayed in the PDF report. */
function makeCaseName(projectId: number, referenceNumber: string): string {
    return `Case ${projectId} (Plan ${referenceNumber})`;
}

export function makeQrCodeData(
    store: LoadedPlanStoreState,
    template: ApiCompleteTemplate,
    plan: ApiPlan,
): PreoperativePlanRepresentation {
    const project = store.project;
    const surgeonName = store.project.surgeon?.name;
    const study = store.study;
    const studyMeasurements = study.measurements;
    const planMeasurements = plan.measurements;

    const preoperativeSide = project.side as unknown as BodySideType;
    assert.ok(!!preoperativeSide, 'no preoperative side');

    // assert.ok(!!surgeon, 'no surgeon');

    const operativeSide = makeOperativeSide(preoperativeSide);

    assert.ok(!!plan, 'no plan');
    assert.ok(!!studyMeasurements, 'no study measurements');
    assert.ok(!!planMeasurements, 'no plan measurements');
    assert.ok(!!template, 'no template');

    const stem = template.stem;
    const cup = template.cup;
    const liner = template.liner;
    const head = template.head;

    assert.ok(!!stem, 'no stem');
    assert.ok(!!head, 'no head');
    assert.ok(!!cup, 'no cup');
    assert.ok(!!liner, 'no liner');

    const cupSize = formatSizeToNumber(cup.size);
    const stemSize = formatSizeToNumber(stem.size);
    const headSize = formatSizeToNumber(head.size);

    const groups = study.measurements.groups;

    const femoralAnteversion = findAngleMeasurement(
        groups,
        { bone: 'femur', side: preoperativeSide },
        'hip_femur_anteversion_angle',
    ).value;

    const acetabularAnteversionValue = findAngleMeasurement(
        groups,
        'pelvis',
        preoperativeSide === BodySide.Left
            ? 'hip_pelvis_anteversion_left'
            : 'hip_pelvis_anteversion_right',
    ).value;

    const acetabularInclinationValue = findAngleMeasurement(
        groups,
        'pelvis',
        preoperativeSide === BodySide.Left
            ? 'hip_pelvis_abduction_left'
            : 'hip_pelvis_abduction_right',
    ).value;

    const combinedVersion = getCombinedVersion(planMeasurements);
    const combinedLegLengthDifference = planMeasurements.leg_length_change;
    const combinedLegOffsetDifference = planMeasurements.offset_change;

    const stemAnteversionValue = planMeasurements.stem_angle_anteversion;
    const stemResectionlessTrochanterValue = planMeasurements.resection_distances_lesser_trochanter;

    const createdOn = plan.created_on;
    assert.ok(!!createdOn, 'no created_on plan');

    assert.ok(isFiniteNumber(femoralAnteversion), 'femurAnteversionValue is not a finite number');
    assert.ok(
        isFiniteNumber(acetabularAnteversionValue),
        'acetabularAnteversionValue is not a finite number',
    );
    assert.ok(
        isFiniteNumber(acetabularInclinationValue),
        'acetabularInclinationValue is not a finite number',
    );
    assert.ok(isFiniteNumber(stemAnteversionValue), 'stemAnteversionValue is not a finite number');
    assert.ok(
        isFiniteNumber(stemResectionlessTrochanterValue),
        'stemResectionlessTrochanterValue is not a finite number',
    );
    assert.ok(isFiniteNumber(combinedVersion), 'combinedVersion is not a finite number');
    assert.ok(
        isFiniteNumber(combinedLegLengthDifference),
        'combinedLegLengthDifference is not a finite number',
    );
    assert.ok(
        isFiniteNumber(combinedLegOffsetDifference),
        'combinedLegOffsetDifference is not a finite number',
    );

    const anatomicAnglesRadians = anatomicAngles(toRadians(template.cup_rotation));
    const radiographicCupRotation = toDegrees(toRadiographic(anatomicAnglesRadians));

    return {
        qid: 1,
        id: makeCaseName(store.project!.id, plan.reference_number),
        n: project.name,
        nm: NameUtil.format(surgeonName),
        hs: operativeSide,
        dt: createdOn,
        def: AngleDefinition.Radiographic,
        cupa: {
            av: asWholeNumber(radiographicCupRotation.anteversion),
            in: asWholeNumber(radiographicCupRotation.inclination),
        },
        lega: {
            ll: asWholeNumber(combinedLegLengthDifference),
            os: asWholeNumber(combinedLegOffsetDifference),
            /* the 'ap' is not calculated at this time */
        },
        arpa: {
            av: asWholeNumber(acetabularAnteversionValue),
            in: asWholeNumber(acetabularInclinationValue),
        },
        cnm: cup.system,
        csz: cupSize,
        lnr: `${liner.size} (${liner.type})`,
        snm: stem.system,
        stp: stem.type,
        ssz: stemSize,
        hdm: headSize,
        hdp: head.offset,
        ncp: asWholeNumber(stemResectionlessTrochanterValue),
        fv: asWholeNumber(femoralAnteversion),
        sv: asWholeNumber(stemAnteversionValue),
        cv: asWholeNumber(combinedVersion),
    };
}

/**
 * Formula:
 * -------
 * Widmer and Zurfluh (2004) proposed the following formula, suggesting that cup anteversion has a higher impact
 * on combined version.
 *
 * Cup anteversion + 0.7 x Stem Anteversion = 37.3° (Target combined version)
 */
function getCombinedVersion(reportMeasurements: ApiPlanMeasurements): number | null {
    const scaledStemVersion = isFiniteNumber(reportMeasurements.stem_angle_anteversion)
        ? 0.7 * reportMeasurements.stem_angle_anteversion
        : null;

    return sumIfFinite(scaledStemVersion, reportMeasurements.cup_anteversion ?? null);
}

/**
 * Sum two numbers if they are "finite", based on {@link isFiniteNumber}. Otherwise return null
 */
function sumIfFinite(maybeNumber: number | null, maybeOtherNumber: number | null): number | null {
    return isFiniteNumber(maybeNumber) && isFiniteNumber(maybeOtherNumber)
        ? maybeNumber + maybeOtherNumber
        : null;
}
