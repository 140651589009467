import { defineStore } from 'pinia';
import { type ComponentPublicInstance, computed, ref } from 'vue';
import { logger } from '@/util';
import Bugsnag from '@bugsnag/js';

const log = logger('app-error');

export class AppError extends Error {
    displayMessage: string;

    constructor(message: string, displayMessage: string) {
        super(message);
        this.displayMessage = displayMessage;
        this.name = 'AppError';
        Object.setPrototypeOf(this, AppError.prototype);
    }
}

/**
 * A store for top-level error handling
 */
export const useAppErrorStore = defineStore('app-error', () => {
    const _hasError = ref(false);
    const _message = ref<null | string>(null);

    function handleError(error: unknown): void {
        if (error instanceof AppError) {
            _message.value = error.displayMessage;

            log.error(
                'App error:\n%s - display message:\n%s',
                error.stack ?? error,
                error.displayMessage,
            );
            Bugsnag.notify(error);
        } else if (error instanceof Error) {
            log.error('App error:\n%s', error.stack ?? error);
            Bugsnag.notify(error);
        } else {
            log.error("App error '%s':", error);
            Bugsnag.notify(new Error(String(error)));
        }

        _hasError.value = true;
    }

    return {
        /** The error flag: true if an unexpected error has occurred */
        hasError: computed(() => _hasError.value),
        message: computed(() => _message.value),
        handleError,
        /** Handle an error that originates from Vue, setting the error flag */
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        handleVueError: (
            error: unknown,
            _instance: ComponentPublicInstance | null,
            _info: string,
        ): void => {
            // TODO: check if we want to log more vue-specific info here?
            handleError(error);
        },
        /**
         * Catch errors that are thown by a sync or async function, setting the error state
         */
        catchErrors: (action: () => void | Promise<void>): void => {
            try {
                const result = action();
                if (result instanceof Promise) {
                    result.catch(handleError);
                }
            } catch (error) {
                handleError(error);
            }
        },
        reset() {
            _hasError.value = false;
            _message.value = null;
        },
    };
});
