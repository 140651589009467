import { type AxiosRequestConfig, HttpStatusCode } from 'axios';
import { client } from '@/api/http';
import { errorDetail } from '@/planner/api/errorDetail';
import type { ComponentCatalog } from '@/formus/catalog/catalog';
import { type ApiCatalogCup, catalogCup } from '@/api/catalog/catalogCup';
import { type ApiCatalogLiner, catalogLiner } from '@/api/catalog/catalogLiner';
import { type ApiCatalogHead, catalogHead } from '@/api/catalog/catalogHead';
import { type ApiCatalogStem, catalogStem } from '@/api/catalog/catalogStem';
import { type ApiCatalogBearing, catalogBearing } from '@/api/catalog/catalogBearing';
import { getLink } from '@/api/links';
import type { ApiCatalogComponentBase } from '@/api/catalog/base';
import type { Url } from '@/formus/types';
import type { CatalogComponentProperties } from '@/formus/catalog/common';

export async function getComponentCatalog(config?: AxiosRequestConfig): Promise<ComponentCatalog> {
    const url = 'component/catalogue/hip';
    const { status, data } = await client.get<_ApiComponentCatalog>(url, config);

    if (status === HttpStatusCode.Ok) {
        return {
            cups: mapComponents(data.cups, catalogCup),
            liners: mapComponents(data.liners, catalogLiner),
            stems: mapComponents(data.stems, catalogStem),
            heads: mapComponents(data.heads, catalogHead),
            bearings:
                data.bearings !== undefined
                    ? mapComponents(data.bearings, catalogBearing)
                    : new Map(),
        };
    }

    throw Error(`Failed to load components from ${url}` + errorDetail(data));
}

/** This is approximately the unconverted type we get back from the API */
type _ApiComponentCatalog = {
    cups: ApiCatalogCup[];
    liners: ApiCatalogLiner[];
    stems: ApiCatalogStem[];
    heads: ApiCatalogHead[];
    bearings?: ApiCatalogBearing[];
};

/**
 * Convert a list of api-catalog-component representations into a map from catalog-component
 * urls to catalog-components
 * */
function mapComponents<T extends ApiCatalogComponentBase, U extends CatalogComponentProperties>(
    apiComponents: T[],
    map: (component: T) => U,
): Map<Url, U> {
    return new Map(apiComponents.map((apiComponent) => [getLink(apiComponent), map(apiComponent)]));
}
