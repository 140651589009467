import { defineStore } from 'pinia';
import { resetPassword } from '@/api/authenticator';

export const useForgotPassword = defineStore('forgot-password-store', {
    state: () => ({
        isDialogVisible: true,
        isLoading: false,
        isSubmitted: false,
        isSubmitting: false,
        email: '',
        error: '',
    }),
    actions: {
        async sendEmailWithResetLink(): Promise<void> {
            this.error = '';
            this.isSubmitted = false;
            this.isSubmitting = true;

            try {
                await resetPassword(this.email);
                this.isSubmitted = true;
            } catch (e) {
                this.error = `Password reset failed`;
            } finally {
                this.isSubmitting = false;
            }
        },
    },
});
