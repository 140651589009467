/**
 * Implement software requirement: H1SR-114
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-114/Revoke-user-authentication-after-set-period-of-user-inactivity
 */

/**
 * Browser events that signal the user is still active.
 */
const USER_ACTIONS = ['mousemove', 'scroll', 'keydown', 'touchstart'];

export type TrackingCallbackParams = { onUserAction: EventListenerOrEventListenerObject };

export const startTracking = ({ onUserAction }: TrackingCallbackParams) => {
    USER_ACTIONS.forEach((eventName) => {
        window.addEventListener(eventName, onUserAction);
    });
};

export const stopTracking = ({ onUserAction }: TrackingCallbackParams) => {
    USER_ACTIONS.forEach((eventName) => {
        window.removeEventListener(eventName, onUserAction);
    });
};
