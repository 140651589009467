import { type AxiosRequestConfig, HttpStatusCode } from 'axios';
import { type ApiLinks } from '@/api/links';
import type { Url } from '@/formus/types';
import { client } from '@/api/http';
import { errorDetail } from '@/planner/api/errorDetail';
import type { MeasurementGroup } from '@/formus/anatomy/measurements';
import {
    type ConvertedRepresentation,
    convertRepresentation,
} from '@/planner/api/convertRepresentation';

type MeasurementsProperties = {
    groups: MeasurementGroup[];
};

const studyMeasurementsLinkMap = {
    self: 'self',
    study: 'up',
} as const;

/** Identifies a set of study-mesurements on the API */
export type ApiStudyMeasurementsId = { case: number; study: number; measurements: number };

export type ApiStudyMeasurements = ConvertedRepresentation<
    MeasurementsProperties,
    typeof studyMeasurementsLinkMap
> & {
    id: ApiStudyMeasurementsId;
};

export async function getStudyMeasurements(
    measurementsId: ApiStudyMeasurementsId | Url,
    config?: AxiosRequestConfig,
): Promise<ApiStudyMeasurements> {
    let id, url;
    if (typeof measurementsId === 'object') {
        id = measurementsId;
        url = `project/${id.case}/study/${id.study}/measurements/${id.measurements}`;
    } else {
        url = measurementsId;
        id = studyMeasurementsIdFromUrl(url);
    }

    const { status, data } = await client.get<MeasurementsProperties & ApiLinks>(url, config);
    if (status === HttpStatusCode.Ok) {
        return {
            id,
            ...convertRepresentation(data, studyMeasurementsLinkMap),
        };
    }

    throw Error(`Failed to load study-measurements from ${url}` + errorDetail(data));
}

export function studyMeasurementsIdFromUrl(url: Url): ApiStudyMeasurementsId {
    const match = url.match(/project\/([0-9]*)\/study\/([0-9]*)\/measurements\/([0-9]*)/);
    if (match) {
        return {
            case: Number(match[1]),
            study: Number(match[2]),
            measurements: Number(match[3]),
        };
    }
    throw Error(`Failed to extract study-measurements-id from url '${url}'`);
}
