<template>
    <v-main class="app-main-view">
        <v-container fluid class="pa-0">
            <slot></slot>
        </v-container>
    </v-main>
</template>

<script setup lang="ts">
    import { toRefs } from 'vue';
    export interface Props {
        fullpage?: boolean;
    }
    const props = defineProps<Props>();
    const { fullpage } = toRefs(props);

    const marginRight = fullpage.value ? '0' : '5rem';
</script>

<style scoped>
    .app-main-view {
        margin-top: 2.5rem;
        margin-right: v-bind('marginRight');
    }
</style>
