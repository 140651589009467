import FileSaver from 'file-saver';

import AxiosBrowserCacheUrlMutation from '@/lib/AxiosBrowserCacheUrlMutation';
import { type AsyncZippable, zip } from 'fflate';
import { promisify } from 'util';
import { client } from '@/api/http';
import { HttpStatusCode } from 'axios';
import { logger } from '@/util';
import type { ApiPlanFile } from '@/api/plan/types';

const log = logger('downloadModel');

const asyncZip = promisify(zip);

export interface PlanFileRepresentationAndBlob {
    file: ApiPlanFile;
    content: Promise<Blob>;
}

/**
 * Download and save all 3D models from a case plan to the local machine.
 *
 * The process involves:
 *  - getting meta-data for each file
 *  - downloading the file content with a rate limiter
 *  - zipping the files
 *  - presenting a save-as dialog
 */
export const downloadModel = async (
    filesPaths: ApiPlanFile[],
    zipFilename: string,
): Promise<void> => {
    log.info('%d file to get meta-data for', filesPaths.length);
    const theFiles = await createRequests(filesPaths);

    log.info('Preparing zip with %d files', filesPaths.length);
    const fileList: AsyncZippable = {};
    for (const aFile of theFiles) {
        const content = await aFile.content;
        fileList[aFile.file.name] = new Uint8Array(await content.arrayBuffer());
    }

    log.info('Zipping %d files', filesPaths.length);
    const zipContent = new Blob([await asyncZip(fileList)], { type: 'application/zip' });

    log.info('Save Zip file %s', zipFilename);
    FileSaver.saveAs(zipContent, zipFilename);
};

const createRequests = async (files: ApiPlanFile[]): Promise<PlanFileRepresentationAndBlob[]> => {
    return await Promise.all(
        files.map(async (file: ApiPlanFile) => {
            return {
                // the file with materialised file information
                file: file,
                // the content which is rate limited and likely to be an unfulfilled promise
                content: downloadFileContent(file.href, file.name),
            };
        }),
    );
};

/**
 * Download the model file.
 *
 * Note: If the responseType is set to 'Blob' then firefox appears to hang in the download. To
 * get the download to complete in both Firefox and Chrome, set the response type to 'ArrayBuffer' and
 * then use the response to construct a blob.
 */
const downloadFileContent = async (fileUrl: string, filename: string): Promise<Blob> => {
    // eslint-disable-next-line testing-library/no-debugging-utils
    log.debug('GET plan model %s', fileUrl);
    const response = await client.get<ArrayBuffer>(
        fileUrl,
        AxiosBrowserCacheUrlMutation.makeMutationOption({
            headers: {
                // Let the service decide the best image representation
                accept: 'model/*;q=0.8,image/*;q=0.4',
            },
            // arraybuffer, blob, stream, text, json, document
            responseType: 'arraybuffer',
            // Disable response transformation for this request. Models should be transferred without
            // any mutation or modification.
            transformResponse: (data /*: any, headers?: any */) => {
                return data;
            },
        }),
    );
    // eslint-disable-next-line testing-library/no-debugging-utils
    log.debug('GET %s : %d', fileUrl, response.status);
    if (response.status === HttpStatusCode.Ok && response.data) {
        return new Blob([response.data]);
    } else {
        throw new Error(`Failed to download plan model '${filename}`);
    }
};
