import { type ApiLinks, getLink } from '@/api/links';
import type { Url } from '@/formus/types';
import { type ToCamelCase, toCamelCase } from '@/planner/api/toCamelCase';
import type { LinkMap } from '@/api/convertLinks';

/**
 * A more consise version of a linked-representation type that:
 *
 * 1. Has had the links in Map converted into properties
 * 2. Has had the existing properties in Props changed from snake_case to camelCase
 */
export type ConvertedRepresentation<Props, Map extends LinkMap> = { [Name in keyof Map]: Url } & {
    [K in keyof Props as ToCamelCase<K>]: Props[K];
};

/**
 * Convert a linked-representation into a more consise form, with:
 *
 * 1. Links in Map converted into properties
 * 2. Existing properties in Props renamed from snake_case to camelCase
 */
export function convertRepresentation<Repr extends ApiLinks, Map extends LinkMap>(
    repr: Repr,
    linkMap: Map,
): ConvertedRepresentation<Repr, Map> {
    return {
        ...(Object.fromEntries([
            ...Object.entries(linkMap).map(([name, selector]) => [name, getLink(repr, selector)]),
            ...Object.entries(repr).map(([name, value]) => [toCamelCase(name), value]),
        ]) as ConvertedRepresentation<Repr, Map>),
    };
}
