import type { ApiPlan, ApiPlansCase, ApiPlansStudy } from '@/api/plan/types';
import type { PreferredStem } from '@/api/preferences';
import type { ApiAlignmentMode } from '@/planner/api/alignmentMode';
import type { ApiCompleteTemplate } from '@/api/template/completeTemplate';

export type CaseApiSpecification = {
    stems: PreferredStem[];
    target_leg_length_change: number;
    target_offset_change: number;
    cup_anteversion_mode: string;
    cup_anteversion_angle: number | null;
    cup_inclination_angle: number;
    cup_fit_method: string;
    cup_align_mode: ApiAlignmentMode;
};

export type PlanStoreState = {
    project: ApiPlansCase | null;
    study: ApiPlansStudy | null;
    specifications: CaseApiSpecification | null;
    automatedTemplate: ApiCompleteTemplate | null;
    manualTemplate: ApiCompleteTemplate | null;
    automatedPlan: ApiPlan | null;
    manualPlan: ApiPlan | null;
};

export type LoadedPlanStoreState = {
    project: ApiPlansCase;
    study: ApiPlansStudy;
    specifications: CaseApiSpecification;
    automatedPlan: ApiPlan | null;
    manualPlan: ApiPlan | null;
};

export enum PlanType {
    Automated = 'automated',
    Manual = 'manual',
}
