import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import type { BugsnagPluginVueResult } from '@bugsnag/plugin-vue';
import { parseHeadMetaTag, webVersion } from '@/lib/headMetaTags';
import { verify } from '@/lib/verify';

const LOCAL_STAGE = 'local'

function bugsnagApiKey(): string {
    return parseHeadMetaTag('bugsnag-api-key', 'a1151e09196fc93e8bfb5605af9ffa2d');
}

function bugsnagReleaseStage(): string {
    return parseHeadMetaTag('bugsnag-release-stage', LOCAL_STAGE);
}

export function configureBugsnag(): BugsnagPluginVueResult | null {
    const apiKey = bugsnagApiKey();
    const releaseStage = bugsnagReleaseStage();
    if (apiKey && releaseStage && releaseStage !== LOCAL_STAGE) {
        Bugsnag.start({
            appType: 'client',
            apiKey,
            plugins: [new BugsnagPluginVue()],
            appVersion: webVersion(),
            releaseStage,
        });

        return verify(Bugsnag.getPlugin('vue'), 'no bugsnagVue');
    }

    return null;
}
