/**
 * Implements software requirements: H1SR-11
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-11/Default-values-for-cases-can-be-defined-in-the-hip-preferences
 */
import { computed, reactive, ref, type Ref } from 'vue';
import { defineStore } from 'pinia';
import { isEqual } from 'lodash';
import type { PreferredStem } from '@/api/preferences';
import { myPreferences, saveMyPreferences, type THAPreferences } from '@/api/preferences';
import { isValid, validators } from '@/stores/preferencesValidation';
import { isUserFriendlyErrorMessage } from '@/api/http';

export interface CupFormInputs {
    inclinationAngle: string;
    fitMethod: string;
    anteversion: string;
    anteversionAngle: string;
    alignment: string;
}

export interface FormInputs {
    preferredSystem: string;
    stems: string[];
    cup: CupFormInputs;
}

export interface PreferencesFormState extends FormInputs {
    isReady: boolean;
    isSaving: boolean;
    isDirty: boolean;
    hasError: boolean;
    message: string;
}

const initialCupFormInputs = {
    inclinationAngle: '',
    fitMethod: '',
    anteversion: '',
    anteversionAngle: '',
    alignment: '',
};

const initialState = {
    isReady: false,
    isSaving: false,
    isDirty: false,
    hasError: false,
    message: '',
    preferredSystem: '',
    stems: [],
    cup: initialCupFormInputs,
};

export const usePreferences = defineStore('preferences', () => {
    const options = ref<{ stems: PreferredStem[] }>({ stems: [] });

    const state = reactive<PreferencesFormState>({ ...initialState });

    /**
     * knowing the last saved state, we can figure out
     * whether or not the user has any unsaved changes.
     */
    const lastSavedState: Ref<FormInputs> = ref<FormInputs>({
        preferredSystem: '',
        stems: [],
        cup: initialCupFormInputs,
    });

    const dereffed = ({ preferredSystem, stems, cup }: FormInputs) => ({
        preferredSystem: preferredSystem,
        stems: [...stems].sort(),
        cup: { ...cup },
    });

    const hasUnsavedChanges = computed(() => !isEqual(lastSavedState.value, dereffed(state)));

    /**
     * check that the given form `field` has been filled out correctly.
     */
    function hasError(field: string): boolean {
        /**
         * field value either has an error (true) or not (false)
         */
        if (validators[field] === undefined) {
            throw new Error(`no validator for ${field}`);
        }
        if (state.isDirty === false) {
            return false;
        }
        /**
         * note: vue expects validators to return true when
         * there is an error and false when there is no error.
         */
        return !validators[field](state);
    }

    /**
     * get users preferences from api and map into local form state.
     */
    async function load() {
        try {
            const result = await myPreferences();

            options.value.stems = [...result.options.stems];
            state.preferredSystem = result.preferences.preferredSystem;
            state.stems = [...result.preferences.stems];
            state.cup = {
                inclinationAngle: result.preferences.cupInclinationAngle ?? '',
                fitMethod: result.preferences.cupFitMethod ?? '',
                anteversion: result.preferences.cupAnteversionMode ?? '',
                anteversionAngle: result.preferences.cupAnteversionAngle ?? '',
                alignment: result.preferences.cupAlignMode ?? '',
            };
            lastSavedState.value = dereffed(state);
        } finally {
            state.hasError = false;
            state.message = '';
            state.isReady = true;
            state.isSaving = false;
            state.isDirty = false;
        }
    }

    /**
     * send valid local form state to api and handle any errors.
     */
    async function save() {
        state.hasError = false;
        state.message = '';
        state.isSaving = true;

        if (!isValid(state)) {
            state.message =
                'Please try again, or contact the Formus support team via the help option at the top right of the page.';
            state.hasError = true;
            state.isSaving = false;
            state.isDirty = true;
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            return;
        }

        const changedPreferences: THAPreferences = {
            cupAlignMode: state.cup.alignment,
            cupAnteversionAngle: state.cup.anteversionAngle,
            cupAnteversionMode: state.cup.anteversion,
            cupFitMethod: state.cup.fitMethod,
            cupInclinationAngle: state.cup.inclinationAngle,
            stems: state.stems.filter((stem) => stem.includes(state.preferredSystem)),
            preferredSystem: state.preferredSystem,
        };

        const result = await saveMyPreferences(changedPreferences, options.value);
        if (isUserFriendlyErrorMessage(result)) {
            state.hasError = true;
            state.message = result;
        } else {
            lastSavedState.value = dereffed(state);
            state.message = 'Your Surgical Preferences were successfully updated!';
            state.isDirty = false;
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
        state.isSaving = false;
    }

    return { state, options, load, save, hasError, hasUnsavedChanges };
});
