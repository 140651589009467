import { useCaseSettings } from '@/stores/caseSettings/store';
import { ref, watch } from 'vue';
import { DataState } from '@/stores/shared/dataState';

export function useSavingHooks() {
    const store = useCaseSettings();

    const isSaving = ref(false);
    const showError = ref(false);

    const onSuccessHandler = (callback: () => void) => (value: DataState) => {
        if (value === DataState.Saving) {
            isSaving.value = true;
        } else if (value === DataState.Idle) {
            // If transitioned from updating state to idle state
            if (isSaving.value) {
                callback();
                isSaving.value = false;
            }
        }
    };

    const onErrorHandler = (callback: () => void) => (value: DataState) => {
         if (value === DataState.Error) {
            // If transitioned from updating state to idle state
            callback();
            showError.value = true;
        } else {
            showError.value = false;
        }
    };

    const watchSuccessOnSaving = (cb: () => void) => {
        return watch(() => store.$state.state, onSuccessHandler(cb));
    };

    const watchErrorOnSaving = (cb: () => void) => {
        return watch(() => store.$state.state, onErrorHandler(cb));
    };

    return { watchSuccessOnSaving, watchErrorOnSaving };
}
