/**
 * Implements software requirements: H1SR-68, H1SR-149
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-68/User-can-authenticate-with-their-email-as-a-username-and-their-chosen-password
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-149/Keep-user-passwords-secure
 */

import type { AxiosResponse } from 'axios';
import { HttpStatusCode } from 'axios';
import { isEmpty } from 'lodash';
import { client, expectedErrorOrRethrow, type UserFriendlyError } from '@/api/http';
import { cache } from '@/api/cache';

import anylogger from 'anylogger';

const log = anylogger('authenticator');

export interface Credentials {
    email: string;
    password: string;
}

function validCredentials(credentials: Credentials): boolean {
    return !isEmpty(credentials?.email || isEmpty(credentials?.password));
}

export interface Tokens {
    refresh: string;
    access: string;
}

function areTokensValid(tokens: Tokens): boolean {
    return !isEmpty(tokens?.refresh || isEmpty(tokens?.access));
}

/**
 * Attempt to log the user in using the given username and password combination.
 */
export const obtainTokens = async (
    credentials: Credentials,
): Promise<Tokens | UserFriendlyError> => {
    if (!validCredentials(credentials)) {
        return 'Login failed, your email address and/or password are invalid';
    }

    try {
        const { status, data, request }: AxiosResponse = await client.post<Tokens>(
            '/token/',
            credentials,
        );
        if (status === HttpStatusCode.Ok && areTokensValid(data)) {
            await cache(request, { minutes: 5 });
            return { refresh: data.refresh, access: data.access };
        } else if (status === HttpStatusCode.Forbidden) {
            return 'Login failed, your email address and/or password are incorrect';
        } else if (status === HttpStatusCode.BadRequest && data?.detail) {
            return `Failed to login: ${data?.detail}`;
        } else {
            return 'Failed to login';
        }
    } catch (error) {
        return expectedErrorOrRethrow(error, 'Failed to login');
    }
};

/**
 * this resource is only called indirectly whenever the current access token has expired.
 */
export async function refreshTokens(refreshToken: Tokens['refresh']): Promise<
    [
        {
            refresh: any;
            access: any;
        } | null,
        HttpStatusCode,
    ]
> {
    if (isEmpty(refreshToken)) {
        return Promise.reject(Error('Refresh token invalid'));
    }

    const { status, data, request } = await client.post<Tokens>('/token/refresh/', {
        refresh: refreshToken,
    });
    if (status === HttpStatusCode.Ok && areTokensValid(data)) {
        await cache(request, { minutes: 5 });
        return [{ refresh: data.refresh, access: data.access }, HttpStatusCode.Ok];
    }

    if (status === HttpStatusCode.Forbidden) {
        // The token authentication flow failed. Need to authenticate the user again
        return [null, HttpStatusCode.Forbidden];
    }

    throw Error('Failed to refresh tokens');
}

export const resetPassword = async (email: string): Promise<boolean | never> => {
    try {
        /**
         * To support existing apps running in vue 2 and vue 3 we add this extra parameter.
         */
        const clientVersion = 'vue-3';
        const { status }: AxiosResponse = await client.post<Tokens>('authenticator/reset', {
            email,
            client_version: clientVersion,
        });
        if (status === HttpStatusCode.Accepted) {
            return true;
        } else {
            throw new Error(`unexpected status ${status}`);
        }
    } catch (error) {
        log.error(error);
        throw new Error(`error resetting password`);
    }
};

export const setPassword = async (
    email: string,
    token: string,
    password: string,
): Promise<[boolean, AxiosResponse | null]> => {
    const response: AxiosResponse = await client.post<Tokens>('authenticator/set', {
        email,
        token,
        password,
    });
    if (response.status === HttpStatusCode.Accepted) {
        return [true, null];
    } else {
        return [false, response];
    }
};
