import type { CatalogComponentProperties } from './common';
import type { CupSystem } from '@/formus/catalog/cup';
import type { LinerSize } from '@/formus/catalog/liner';


// for now we only use head size 28 for dual mobility bearings
export const bearingInnerDiameters = [22, 28] as const;
export type BearingInnerDiameter = (typeof bearingInnerDiameters)[number];
export type BearingInnerDiameterString = `${BearingInnerDiameter} mm`;
const bearingInnerDiameterRegex = /(\d+) mm/

export function isBearingInnerDiameter(value: number): value is BearingInnerDiameter {
    return bearingInnerDiameters.includes(value as BearingInnerDiameter);
}

export function bearingInnerDiameterString(size: BearingInnerDiameter): BearingInnerDiameterString {
    return `${size} mm`;
}

export function isBearingInnerDiameterString(value: string): value is BearingInnerDiameterString {
    return !!value.match(bearingInnerDiameterRegex)
}

export function bearingInnerDiameterFromString(value: string): BearingInnerDiameter {
    const match = value.match(bearingInnerDiameterRegex);
    if (!match) {
        throw Error(`Invalid bearing-diameter string '${value}'`)
    }
    const result = parseInt(match[1]);
    if (!isBearingInnerDiameter(result)) {
        throw Error(`Invalid bearing-diameter ${result}`)
    }
    return result;
}

export const bearingOuterDiameters = [32, 34, 36, 38, 40, 42, 44, 46, 50, 54, 60] as const;
export type BearingOuterDiameter = (typeof bearingOuterDiameters)[number];
export type BearingOuterDiameterString = `${BearingOuterDiameter} mm`;
const bearingOuterDiameterRegex = /(\d+) mm/

export function isBearingOuterDiameter(value: number): value is BearingOuterDiameter {
    return bearingOuterDiameters.includes(value as BearingOuterDiameter);
}

export function bearingOuterDiameterString(size: BearingOuterDiameter): BearingOuterDiameterString {
    return `${size} mm`;
}

export function isBearingOuterDiameterString(value: string): value is BearingOuterDiameterString {
    return !!value.match(bearingOuterDiameterRegex)
}

export function bearingOuterDiameterFromString(value: string): BearingOuterDiameter {
    const match = value.match(bearingOuterDiameterRegex);
    if (!match) {
        throw Error(`Invalid bearing-diameter string '${value}'`)
    }
    const result = parseInt(match[1]);
    if (!isBearingOuterDiameter(result)) {
        throw Error(`Invalid bearing-diameter ${result}`)
    }
    return result;
}


/** Properties of a type of bearing, independent of its use in any particular case  */
export type CatalogBearing = CatalogComponentProperties & {
    /** The cup-system the bearing belongs to e.g. g7 */
    system: CupSystem;

    /** The size of the liner A, B, C... */
    linerSize: LinerSize;

    /** The inner diameter of the bearing */
    innerDiameter: BearingInnerDiameter;

    /** The outer diameter of the bearing */
    outerDiameter: BearingOuterDiameter;
};
