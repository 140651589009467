varying vec3 vNormal;

#include <clipping_planes_pars_vertex>

void main() {
    vNormal = normalMatrix * normal;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);

    // TODO review this
    // TODO Could be using #include <clipping_planes_vertex> if, the 'if' clause were only dependent on NUM_CLIPPING_PLANES
    // TODO add documentation why ! defined(PHYSICAL) && ! defined(PHONG) && ! defined(MATCAP)
    #if NUM_CLIPPING_PLANES > 0 && ! defined(PHYSICAL) && ! defined(PHONG) && ! defined(MATCAP)
    vClipPosition = - mvPosition.xyz;
    #endif
}
