import { defineStore } from 'pinia';
import { setPassword } from '@/api/authenticator';
import { useRouteStore } from '@/stores/routeStore';
import router, { ROUTES } from '@/router';
import { HttpStatusCode } from 'axios';

export const useResetPassword = defineStore('reset-password-store', {
    state: () => ({
        hasTokenExpired: false,
        isDialogVisible: true,
        isLoading: false,
        isSubmitting: false,
        showConfirmPassword: false,
        showPassword: false,
        email: '',
        error: '',
        password: '',
        passwordConfirmation: '',
        token: '',
    }),
    actions: {
        init(): void {
            this.$reset();
            const { route } = useRouteStore();
            this.email = route.query.e as string;
            this.token = route.query.t as string;
        },
        async doPasswordReset(): Promise<void> {
            this.error = '';
            this.isSubmitting = true;

            try {
                const [success, response] = await setPassword(
                    this.email,
                    this.token,
                    this.password,
                );

                if (success) {
                    await router.push({ name: ROUTES.HOME });
                } else {
                    if (response && response.status === HttpStatusCode.Forbidden) {
                        this.hasTokenExpired = true;
                    } else {
                        this.error = 'Password reset failed, please contact support.';
                    }
                }
            } catch (err: any) {
                this.error = 'Password reset failed, please contact support.';
            } finally {
                this.isSubmitting = false;
            }
        },
    },
});
