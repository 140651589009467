/**
 * Implements software requirements: H1SR-114
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-114/Revoke-user-authentication-after-set-period-of-user-inactivity
 */

import { defineStore } from 'pinia';
import { ref } from 'vue';

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const FIFTY_FIVE_MINUTES = ONE_MINUTE * 55;
const ONE_HOUR = ONE_MINUTE * 60;

export interface AppConfig {
    http: {
        exponentialBackoffMultiplifer: number;
    };
    timeout: {
        warning: number;
        limit: number;
    };
}

export const useConfig = defineStore('config', () => {
    /**
     * how long the app should wait before automatically retrying a failed http request.
     */
    const exponentialBackoffMultiplifer = ref(ONE_SECOND);

    /**
     * how long the app should wait before automatically logging the user out.
     */
    const timeoutWarning = ref(FIFTY_FIVE_MINUTES);
    const timeoutLimit = ref(ONE_HOUR);

    return {
        http: {
            exponentialBackoffMultiplifer,
        },
        timeout: {
            warning: timeoutWarning,
            limit: timeoutLimit,
        },

        /**
         * Composition API does not expose $patch from options api, so we make our own patch.
         */
        patch: (newConfig: Partial<AppConfig>) => {
            exponentialBackoffMultiplifer.value =
                newConfig.http?.exponentialBackoffMultiplifer ??
                exponentialBackoffMultiplifer.value;
            timeoutWarning.value = newConfig.timeout?.warning ?? timeoutWarning.value;
            timeoutLimit.value = newConfig.timeout?.limit ?? timeoutLimit.value;
        },
    };
});
