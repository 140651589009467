/**
 * Format the elapsed time as seconds.
 */
import { isFiniteNumber } from '@/util';

export const formatElapsedSeconds = (time: number | unknown): string => {
    if (isFiniteNumber(time)) {
        const seconds = (new Date().getTime() - time) / 1000;
        return `${seconds} seconds`;
    } else {
        return '--';
    }
};
