import type { Url } from '@/formus/types';
import type { AnatomicalOffset } from '@/formus/anatomy/pelvis/anatomicalOffset';
import type { AcetabularAngles } from '@/formus/anatomy/pelvis/acetabularAngles';
import type { Matrix4 } from 'three';
import type { Adjustments } from '@/planner/plannerState';
import type { DeepNullable } from '@/lib/CustomTypes';
import type { SyncTemplate } from '@/formus/template/template';
import { radToDeg } from '@/util';

/**
 * This is the surgical-template state that is pushed to the server via the template-sync store
 */
export type ManualTemplateState = {
    /** The url of the selected cup catalog-component */
    cupUrl: Url;

    /** The url of the selected liner catalog-component */
    linerUrl: Url;

    /** The url of the dual mobility bearing catalog-component */
    bearingUrl: Url | null;

    /** The url of the selected cup catalog-component */
    stemUrl: Url;

    /** The url of the selected head catalog-component */
    headUrl: Url;

    /** The offset of the cup */
    cupOffset: AnatomicalOffset;

    /** The anatomical rotation of the cup in radians */
    cupRotation: AcetabularAngles<'anatomic'>;

    /** dual mobility */
    dualMobility: boolean;

    /** The transformation of the stem-group relative to its fitted placement */
    stemTransform: Matrix4;

    /**
     * The manual template targets are null until
     * they are overridden by the user when optimising targets
     */
    targets: DeepNullable<Adjustments>;
};

/**
 * Create a template-update from a manual-template-state and, optionally, a new set of targets.
 *
 * Note that if new targets are used in an update pushed to the API the fitted-components will change and
 * need to be reloaded.
 */
export function syncTemplate(
    template: ManualTemplateState,
    overrideTargets?: Adjustments,
): SyncTemplate {
    return {
        cup: template.cupUrl,
        liner: template.linerUrl,
        stem: template.stemUrl,
        head: template.headUrl,
        targetLegLengthChange: overrideTargets?.legLength ?? template.targets.legLength,
        targetOffsetChange: overrideTargets?.offset ?? template.targets.offset,
        cupOffset: { ...template.cupOffset },
        cupRotation: {
            anteversion: radToDeg(template.cupRotation.anteversion),
            inclination: radToDeg(template.cupRotation.inclination),
        },
        dualMobility: template.dualMobility,
        stemTransform: template.stemTransform.clone(),
    };
}
