/**
 * The OperativeSide is a property that can be set to the following String values:
 *  - "R" for Right hip side
 *  - "L" for Left hip side
 *  - "" for unknown/not selected
 */
export enum OperativeSide {
    Unknown = '',
    Left = 'L',
    Right = 'R',
}

/**
 * The AngleDefinition is a property that can be set to the following String values:
 *  - "anat" for anatomical cup angle definitions
 *  - "oper" for operative cup angle definitions
 *  - "rad" for radiographic cup angle definitions
 *
 *  Note: Formus currently uses the Anatomical definition universally
 *
 *  @see
 *    - {@link https://drive.google.com/file/d/139OwoeV0kYf8Spxfe3-1oFXaUiolH3X8/view?usp=sharing}
 *      Murray 1992 definition and measurement of a cetabular orientation
 */
export enum AngleDefinition {
    /**
     * Anatomical cup angle definitions. The axis and planes are used to calculate
     * angles based on known pelvic landmarks. The Formus product normally uses this
     * definition of angles.
     */
    Anatomical = 'anat',

    /** operative cup angle definitions. Combination of known pelvic landmarks and operating table measurements */
    Operative = 'oper',

    /** radiographic cup angle definitions. The Intellijoint product normally uses this definition on angles. */
    Radiographic = 'rad',
}

/**
 * The angle in 3d space represented by two
 */
export interface AngleRepresentation {
    /**
     * Anteversion.
     *
     * An angle in degrees.
     */
    av: number;

    /**
     * Inclination
     *
     * An angle in degrees.
     */
    in: number;
}

/**
 * The Leg Length Target type contains the target measurements for the length, offset, and ap
 * changes of the femur.
 *
 * Unsure if this is the LL and offset targets our user set OR the planned LL and offset which to IJS
 * is the operative target.
 */
export interface LegLengthTargetRepresentation {
    /**
     * Leg length target measured in millimetres (mm).
     *
     * This is calculated as:
     * ```
     *     <Femoral offset> + <Acetabular offset>
     * ```
     * Where:
     *  - **Femoral offset**: The distance from the native femoral head centre to the
     *      new head centre in the inferior-to superior direction. This value is store
     *      server side on {@link HipStemRepresentation.ll_diff}
     *
     *  @see {@link https://docs.google.com/presentation/d/11Pmya0pl4vhe672Kf28tnwJaMc5TM7qj/edit#slide=id.p72}
     */
    ll: number;

    /**
     * Offset target measured in millimetres (mm)
     *
     * The combined leg offset, is also known as the functional offset.
     * **Formula**: Femoral offset + Acetabular offset
     * 1. **Femoral offset**: the distance from the native head centre to the femoral head centre
     *    in the medial direction.
     *    This value is store server side on {@property HipStemRepresentation.offset_diff}
     *
     * How adjustment affect this value
     * --------------------------------
     * Adjustments that increase the leg offset:
     *   - If the femoral head is moved laterally (opposite to the sagittal plane).
     *   - If the acetabular cup is moved laterally (opposite to the sagittal plane).
     *
     * Adjustments that decrease the leg offset:
     *   - If the femoral head is moved laterally (towards the sagittal plane).
     *   - If the acetabular cup is moved laterally (towards the sagittal plane).
     *
     * @see https://docs.google.com/presentation/d/11Pmya0pl4vhe672Kf28tnwJaMc5TM7qj/edit#slide=id.p73
     */
    os: number;

    /**
     * Anterior/Posterior target offset measured in millimetres (mm) (+ve is anterior, -ve is posterior)
     *
     * NOTE: This is currently not calculated in Formus Hip, and as such the value will not be
     * set in the data structure. Logically this could be thought of as zero; but semantically
     * an undefined (not present) value is slightly different.
     */
    ap?: number;
}

/**
 * The CustomField type contains two String properties
 */
export interface CustomFieldRepresentation {
    lb: string;
    val: string;
}

export interface ResourceIdRepresentation {
    /*
     * An optional 'href' or URL for the resource. This URL can be used by an http client to
     * access the resource on the formus labs API (providing the requester has authentication credentials and
     * is authorised. to access the information).
     *
     * The Formus software will put the URI of a formus resource into this field. It must be considered to
     * be an opaque value (i.e. do not deconstruct this string, nor should a specific format be assumed)
     */
    h?: string;

    /**
     * An internal reference identifier for the resource. This is is short integer number. It is
     * a way to identify a resource for a user, but overall not a good way. It is presented to allow
     * constrained UI to have something to display. The Formus API will not consume this number.
     */
    id?: number;
}

/**
 * Case Information
 */
export interface PreoperativeCaseRepresentation {
    /**
     * Case/Patient Identifier
     *
     * This is a free form string that must uniquely identify the case. For a Formus Labs case this
     * will identify the `plan` that is being used. A Formus HIP case may have many plans (e.g. the
     * automated plan generated by the software, or a manual plan generated by the user/surgeon).
     *
     * A short description of the case with identifiers. This is likely to be displayed in
     * the UI of the integration. It is loosely of the form:
     *
     *   'Case <case #> (Plan <sth #>.<plan #>)'
     *
     * where:
     *   'case #' is a unique number for the case. This is a simple intger number.
     *   'sth #' is the version of the surgical template resource used to produce the plan. This is
     *      a simple integer number.
     *   'plan #' is the unique id of the plan. This number alone will uniquely identify the plan. This
     *      is a simple integer number.
     *
     * e.g. "Case 1234 (Plan 456.789)"
     */
    id: string;

    /**
     * The user entered name of the case. It may contain unicode and is allowed
     * to be at least 256 characters long.
     */
    n?: string;
    /**
     * Surgeon Name
     *
     * The full name of the surgeon in a single field. Unlike other medical data exchange formats
     * this is not broken into individual name components. The general form of this string will
     * be:
     *        `<prefix> <given name> <middle names> <family name> <suffix>`
     *
     * Note: The Formus Labs software has the give name(s) and the family name, but does not explicitly
     * store the name prefix or suffix. This means the 'Dr' or 'Mr' is not available.
     *
     * Note: The surgeon name may include unicode (UTF-8) characters.
     */
    nm: string;

    /**
     * Hip Operative side.
     *
     * A mandatory field with {@link OperativeSide.Left} or {@link OperativeSide.Right}
     */
    hs: OperativeSide;

    /**
     * Plan date
     *
     * This is just a date of the form in ISO-8601. This is the date when the plan was created.
     *
     * Notes:
     *  1. this is not the operative date.
     *  2. the JSON data format has no type for a date. Date fields must be encoded
     * as either a number or a string.
     *
     * @see {@link https://en.wikipedia.org/wiki/ISO_8601}
     */
    dt: string;

    /**
     * The cup anteversion and inclination angle definition.
     *
     * The formus Labs software will always set this to {@link AngleDefinition.Anatomical}.
     */
    def: AngleDefinition;
}

/**
 *  Plan Measurements
 */
export interface PreoperativePlanMeasurementsRepresentation {
    /**
     * Cup position (coronal). The CupPosition type contains the inclination and anteversion angle fields in degrees
     * @deprecated Do we need both coronal and APP in this document - No, only need APP
     * Note: This field will not be provided by the Formus software.
     */
    cupc?: AngleRepresentation;

    /**
     * Cup position (APP). This is a required field (for both the inclination and anteversion).
     *
     * These are provided directly from the surgical template:
     * ```
     * av = <surgical template>.cup_rotation.anteversion
     * in = <surgical template>.cup_rotation.inclination
     * ```
     *
     * - **Precision**: Number are exposed as a whole number - i.e. 0 decimal digits of precision.
     */
    cupa: AngleRepresentation;

    /**
     * Leg length and Offset change resulting from the planned cup and stem (coronal)
     *
     * @deprecated Do we need both coronal and APP in this document - No, only need APP
     * Note: This field will not be provided by the Formus software.
     */
    legc?: LegLengthTargetRepresentation;

    /**
     * Leg length and Offset change resulting from the planned cup and stem (APP)
     *
     * The Formus Labs software automatically calculates the 'll' and 'os' parameters, but not the 'ap' measurement.
     * This is displayed in the manual workflow templating in the 'Adjustments' section as:
     *    - Leg Length
     *    - Leg Offset
     * Both measurements are displayed with whole milli-metre precision.
     *
     * NOTE: the 'ap' parameter will **not** be calculated and may be omitted from the data.
     */
    lega: LegLengthTargetRepresentation;

    /**
     * Anteversion and inclination angles of the native acetabular rim plane (ARP) (coronal)
     * Note: This field will not be provided by the Formus software.
     */
    arpc?: AngleRepresentation;

    /**
     * Anteversion and inclination angles of the native acetabular rim plane (ARP) (APP)
     *
     * - **Precision**: Number are exposed as a whole number - i.e. 0 decimal digits of precision.
     *
     * - **Source**: These values exist already on the study measurements.
     *
     * - **Client side**: They are already displayed in the native measurements in the client UI.
     *
     * @see 'hip_pelvis_anteversion_left' | 'hip_pelvis_anteversion_right' on compute.
     * @see 'hip_pelvis_abduction_left' | 'hip_pelvis_abduction_right' on compute.
     */
    arpa: AngleRepresentation;
}

/**
 * Implant Details
 */
export interface PreoperativePlanImplantRepresentation {
    /**
     * Cup Name. This is the system of the cup. At this time the only supported cup system is the
     * Zimmer Biomet G7 ('g7') Acetabular System.
     *
     * e.g. 'G7'
     *
     * @see {@link https://www.zimmerbiomet.com/medical-professionals/hip/product/g7-acetabular-system.html}
     */
    cnm: string;

    /**
     * Cup Size. The cup size is a measurement in millimetres of the outer rim of the cup (in the
     * range 42mm - 62mm).  The size of the cup will have a direct relationship to the liners that
     * are supported.
     *
     * The size (diameter) of the cup, measured in millimetres (mm)
     */
    csz: number;

    /**
     * The name of the cup liner "[size] ([type])"
     *
     * This is a combination of the size of the liner (an alphabetical letter from 'B'...'J') and
     * the liner type. Liner sizes range from B to H while Formus currently only supports liner
     * type "neutral" (liners if type 'highwall' and '10-deg' are not available at this time).
     *
     * e.g. "C (neutral)"
     */
    lnr: string;

    /**
     * The name of the stem component (system)
     *
     * Note: ZB catalog components have a system/offset mode/type/size, where
     *  - system is always 'Taperloc'
     *  - offset mode is 'Standard Offset', 'High Offset', or 'XR'
     *  - type is 'Reduced Distal', 'Full Profile', or 'Microplasty'
     *  - size is a whole number in the range 4-24 (not all combinations have all sizes)
     *
     * While missing from the IJS definition, this field probably should be the system and type.
     *
     *  e.g. 'Taperloc Microplasty'
     */
    snm: string;

    /**
     * The offset mode of the stem component
     *
     * e.g. 'High Offset'
     */
    stp: string;

    /** Stem component size */
    ssz: number;

    /**
     * The head size (diameter) measured in millimetres (mm).
     *
     * Notes:
     *   - This is listed as a double, but it will just be a number
     *   - The only currently supported head size is 32 (mm).
     */
    hdm: number;

    /**
     * The head offset, one of (-3, 0, +3, +6). Although the Zimmer Biomet product range has support for
     * head offsets of -6, -3, 0, +3, +6, +9 & +12 it is a product decision not to use the higher magnitude
     * offsets in automatic planning, as well as not letting the surgeon/user the ability to select those
     * components during manual planning.
     *
     * Note: This is listed as a double, but it is known clinically as a signed number.
     */
    hdp: number;
}

/**
 * Other misc fields
 */
export interface PreoperativePlanOtherRepresentation {
    /**
     * The native femoral version in degrees (as a whole number).
     *
     * From the study native measurements (see 'hip_femur_anteversion_angle')
     *
     * Note:
     *
     * 1. Coordinate system
     *   ------------------
     *   This angle is independent of the coordinate system.
     *
     * 2. Level of precision
     *   -------------------
     *   Formus present angles with many decimals of precision. Given the decimal part is not needed, these values
     *   will be rounded to the nearest integer.
     *   e.g.:
     *   From Formus measurement => 14.8342341432323 => to 'fv' require format => 15
     *   From Formus measurement => 14.1342341432323 => to 'fv' require format => 14
     */
    fv: number;

    /**
     * Stem version (aka: 'anteversion')
     *
     * @see HipTemplateMeasurementName.StemAnteversion
     */
    sv: number;

    /**
     * Neck Cut Position. This is the distance above the lesser trochanter that the femoral head is
     * resected.
     *
     * Lesser Trochanter (LT) resection distance. This is taken from the surgical template `stem` measurement
     * called `resection.lesser-trochanter`.
     */
    ncp: number;

    /** A custom field. This is not set by the Formus product. */
    f1?: CustomFieldRepresentation;
    /** A custom field. This is not set by the Formus product. */
    f2?: CustomFieldRepresentation;
    /** A custom field. This is not set by the Formus product. */
    f3?: CustomFieldRepresentation;

    /**
     * Combined version measured in degrees (as a whole number - i.e. 0 decimal digits of precision)
     *
     * **Formula**
     * ---
     *
     * The combined version is calculated as:
     * ```
     *     combined version = Cup anteversion + 0.7 x Stem Anteversion
     * ```
     * where:
     *    <cup anteversion> is from the surgical template
     *
     * **Target**
     * ---
     *
     * Should be targeted for 37.3° degrees to minimise impingement in the hip.
     *
     * Note:
     * 1. **stem anteversion** value is a stem measurement (from the stem fitted - components data)
     * 2. **cup anteversion** value is a cup measurement (from the cup fitted - components data).
     * 3. the `cup_rotation.anteversion` on the surgical template should be treated as an input
     * value to the surgical templating process (and may be undefined), whereas the measure value
     * should be treated as an output of the surgical templating process.
     */
    cv: number;

    /**
     * Pelvic tilt measured in degrees (as a whole number - i.e. 0 decimal digits of precision)
     *
     * Note: This is not measured by Formus. This angle is a function of the spine and the pelvis. Until
     * the Formus Labs software segments and adds landmarks to the lower spine this will not be calculated.
     */
    pt?: number;
}

/**
 * This document describes the data used to transfer a preoperative plan from the Formus Labs
 * HIP product to an external execution system. The first such example is the Intellijoint HIP (TM)
 * tool.
 *
 * As a strategy to reduce time to demo (c.f. time to market), a simple integration transport strategy
 * is being used which is to use an image with a QR code to deal with the last metre air gap (c.f. using a
 * ethernet style connection (e.g. 802.11 WiFi))
 *
 *      IntellijointHip allows for the entry of information about the case by scanning a
 *      QR code with the workstation webcam. The QR code must contain text in a JSON format
 *      encoded in UTF-8. TheJSON text should be compact (new extra whitespace/newlines) in
 *      order to reduce the size ofthe data andVersion required for the QR code.
 *
 * @see {@link https://en.wikipedia.org/wiki/JSON#Data_types}
 * @see {@link https://www.intellijointsurgical.com/}
 */
export interface PreoperativePlanRepresentation
    extends PreoperativeCaseRepresentation,
        PreoperativePlanMeasurementsRepresentation,
        PreoperativePlanImplantRepresentation,
        PreoperativePlanOtherRepresentation {
    /**
     * A logical document version number - always `1` at this time
     *
     * This attribute will always be serialised first so the raw JSON document should always start with
     * the prefix of '{"quid:1' (as a sanity check). The value should be used as a discriminator for
     * conversion to the correct data type (assuming multiple versions).
     */
    qid: number;
}
