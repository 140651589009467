/**
 * Returns a new text without any special character replaced with space
 *
 * E.g: ". Hello - W_orlD123 ".replace(/[^a-zA-Z0-9]/g, '') => "HelloWorlD123"
 */
import { capitalize } from 'lodash';

export const replaceNonLettersOrNumbersWithEmpty = (text: string) => {
    return text.replace(/[^a-zA-Z0-9]/g, '');
};

/**
 * Returns the word pluralized
 *
 * maybePluralize(0, 'turtle'); // "turtles"
 * maybePluralize(1, 'turtle'); // "1 turtle"
 * maybePluralize(2, 'turtle'); // "2 turtles"
 * maybePluralize(3, 'fox', 'es'); "3 foxes"
 */
export const maybePluralize = (count: number, noun: string, suffix = 's') =>
    `${noun}${count !== 1 ? suffix : ''}`;

export default class StringUtil {
    /**
     * Format the string as json with 2 character spaces:
     * e.g:
     * JSON.stringify({ x: 5, y: 6 }, null, 2)) =>
     * '{
     *     "x": 5,
     *     "y": 6
     *  }'
     */
    public static jsonPrettyFormat(data: Record<string, unknown> | null | undefined): string {
        if (data) {
            return JSON.stringify(data, null, 2);
        }

        return '';
    }

    /**
     * Converts the first character of string to upper case and the remaining to lower case.
     */
    public static capitalize(string: string): string {
        return capitalize(string);
    }

    /** Whether two strings are case-insensitive the same: */
    public static isCaseInsensitiveEqual(string: string, other: string): boolean {
        return string.toLowerCase() === other.toLowerCase();
    }
}
