<template>
    <v-btn
        radius="2"
        :prepend-icon="prependIcon"
        :disabled="disabled"
        :loading="loading"
        @click="emit('click')"
    >
        <slot></slot>
    </v-btn>
</template>

<script setup lang="ts">
    /**
     * this is a wrapper around vuetify `VBtn`.
     *
     * @see https://vuetifyjs.com/en/api/v-btn/ for list of available props
     */

    import { toRefs } from 'vue';

    const emit = defineEmits(['click']);

    export interface Props {
        colour?: string;
        size?: 'large' | 'medium';
        expand?: boolean;
        borderless?: boolean;
    }
    export interface VBtnProps {
        prependIcon?: string;
        disabled?: boolean;
        loading?: boolean;
    }
    const props = withDefaults(defineProps<Props & VBtnProps>(), {
        colour: 'grey',
        size: 'medium',
        expand: false,
        borderless: false,
    });
    const { expand, size, colour, borderless } = toRefs(props);

    const buttonWidth = expand.value ? '100%' : 'inherit';
    const buttonHeight = size.value === 'large' ? '60px' : '40px';
    const buttonMargin = borderless.value ? '0' : '20px';

    const buttonColour = `var(--planner-${colour.value})`;
    const buttonHoverColour = `var(--planner-${colour.value}-rich)`;
</script>

<style scoped>
    button {
        border-radius: 2px;
        background-color: v-bind('buttonColour');
        box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.1) !important;
        color: var(--planner-white);
        margin-bottom: v-bind('buttonMargin') !important;
        width: v-bind('buttonWidth') !important;
        height: v-bind('buttonHeight') !important;
        text-transform: inherit;
        text-indent: inherit;
        letter-spacing: inherit;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }
    button:hover {
        background: v-bind('buttonHoverColour');
        box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.1) !important;
    }
    button:disabled {
        background: var(--planner-grey-light-2);
        color: var(--planner-grey-2);
    }
    button:active {
        box-shadow: none;
    }
</style>
