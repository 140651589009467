<template>
    <v-icon :icon="icon" class="message-icon"></v-icon>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import type { DicomMessageLevel } from '@/lib/dicom/DicomSeries';

    interface Props {
        level: DicomMessageLevel;
    }
    const props = defineProps<Props>();

    const icon = computed(() => {
        if (props.level >= 400) {
            return 'mdi-information-outline';
        } else if (props.level >= 200) {
            return 'mdi-alert';
        } else {
            return 'mdi-alert-octagon';
        }
    });

    const iconColour = computed(() => {
        if (props.level >= 400) {
            return 'var(--planner-blue-button)';
        } else if (props.level >= 300) {
            return 'var(--planner-yellow)';
        } else if (props.level >= 200) {
            return 'var(--planner-red)';
        } else {
            return 'var(--planner-red)';
        }
    });
</script>

<style scoped>
    .message-icon {
        color: v-bind('iconColour');
    }
</style>
