import type { CatalogComponentProperties } from './common';
import { isFiniteNumber } from '@/util';
import type { Url } from '@/formus/types';
import type { LinerSize } from '@/formus/catalog/liner';
import type { HeadSize } from '@/formus/catalog/head';

export const cupSystems = ['g7'] as const;
export type CupSystem = (typeof cupSystems)[number];

export function isCupSystem(value: string): value is CupSystem {
    return cupSystems.includes(value as CupSystem);
}


export const cupTypes = ['pps', 'neutral', 'highwall'] as const;
export type CupType = (typeof cupTypes)[number];

export function isCupType(value: string): value is CupType {
    return cupTypes.includes(value as CupType);
}

export const cupSizes = [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68] as const;
export type CupSize = (typeof cupSizes)[number];

export function isCupSize(value: string | number): value is CupSize {
    const intValue = typeof value === 'string' ? parseInt(value) : value;
    return isFiniteNumber(intValue) && cupSizes.includes(intValue as CupSize);
}

export function isCupSizeString(value: string): value is `${CupSize}` {
    const size = parseInt(value)
    return isFiniteNumber(size) && isCupSize(size)
}

/** Properties of a type of cup, independent of its use in any particular case */
export type CatalogCup = CatalogComponentProperties & {
    /** The system the cup belongs, which is currently always 'g7' */
    system: CupSystem;

    /** The type of the cup */
    type: CupType;

    /** The size of the cup, which is the outer diameter of the cup as a string */
    size: `${CupSize}`;

    /** The outer diameter of the cup */
    outerDiameter: CupSize;

    collisionMask: Url;

    /** The corresponding liner-size */
    linerSize: LinerSize;

    /** The recommended head-size to use with this cup */
    recommendedHeadSize: HeadSize;
};

