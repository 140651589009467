import { assert, taggedLogger } from '@/util';
import { useAppErrorStore } from '@/stores/appErrorStore';
import { type Ref } from 'vue';
import { freePromise } from '@/util/freePromise';

const log = taggedLogger('execute-operation');

/** Planner operations, during which we disable the UI to allow the operation to complete */
export type PlannerOperationId = 'load-case' | 'set-targets' | 'approve-plan';

export type PlannerOperationFn = (signal: AbortSignal) => Promise<void>;

/**
 * Allows executing a relatively long-running asynchronous 'operation' while disabling
 * the user-interface.
 */
export type PlannerOperationExecutor = {
    /**
     * Execute an operation
     *
     * @param operationId an identifier for the operation
     * @param operation the action to execute
     */
    executeOperation: (
        operationId: PlannerOperationId,
        operation: PlannerOperationFn,
    ) => Promise<void>;

    /**
     * Abort the current operation, if there is one
     */
    abortOperation: () => void;
};

export function plannerOperationExecutor(
    currentOperation: Ref<PlannerOperationId | null>,
): PlannerOperationExecutor {
    let controller: AbortController | null = null;
    let lastOperation: Promise<void> = Promise.resolve();
    let aborting: boolean = false;

    return {
        executeOperation: async (
            operationId: PlannerOperationId,
            operation: PlannerOperationFn,
        ): Promise<void> => {
            // If we aborted the last operation then wait for it to complete
            // We do NOT want to wait for the operation to complete otherwise because it
            // is an **error**
            if (aborting) {
                await lastOperation;
                aborting = false;
            }

            // Check that we are not in an error state
            if (useAppErrorStore().hasError) {
                log.error('Ignoring operation %s owing to error', operationId);
                return;
            }

            // Check that we are not already in an operation
            const currentOp = currentOperation.value;
            if (currentOp !== null) {
                useAppErrorStore().handleError(
                    Error(
                        `Cannot execute operation ${operationId}: already executing ${currentOp}`,
                    ),
                );
                return;
            }

            // Create a new abort-controller for this execution
            controller = new AbortController();
            const signal = controller.signal;

            currentOperation.value = operationId;

            const { promise, resolve } = freePromise<void>();
            lastOperation = promise;

            try {
                await operation(signal);
                log.info('Completed operation %s', operationId);

                assert(
                    currentOperation.value === operationId,
                    `Current operation changed to ${currentOperation.value} ` +
                        `during execution of ${operationId}`,
                );
            } catch (error) {
                if (signal?.aborted) {
                    log.info('Aborted operation %s', operationId);
                } else {
                    useAppErrorStore().handleError(error);
                }
            } finally {
                controller = null;
                currentOperation.value = null;
                resolve();
            }
        },
        abortOperation: () => {
            if (controller) {
                aborting = true;
                controller.abort();
                controller = null;
            }
        },
    };
}
