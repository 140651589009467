import { DoubleSide, MeshStandardMaterial } from 'three';
import type { BaseMaterialParams } from '@/planner/3d/materials/params';

/**
 * Default color for standard material
 */
const DEFAULT_COLOR = '#ffffff' as const;

/** Materials apply to both front- and back-sides of an object as a default */
const DEFAULT_SIDE = DoubleSide;

/** Default opacity is to be full opaque i.e. not transparent at all */
const DEFAULT_OPACITY = 1.0 as const;
/**
 * Default {@link  https://threejs.org/docs/#api/en/materials/MeshStandardMaterial.roughness roughness}
 * for standard material is 1 (fully-diffuse).
 */
const DEFAULT_ROUGHNESS = 1.0 as const;
/**
 * Default {@link  https://threejs.org/docs/#api/en/materials/MeshStandardMaterial.metalness metalness}
 * for standard material is 0 (non-metallic).
 */
const DEFAULT_METALNESS = 0.0 as const;

export type StandardMaterialParams = BaseMaterialParams & {
    type: 'standard';
    roughness?: number;
    metalness?: number;
};

/**
 * Create a three.js {@link https://threejs.org/docs/#api/en/materials/MeshStandardMaterial};
 * this is an approximately 'physically correct' model for lighting, meaning that it gives a
 * good result for a range of different surface types with less tweaking needed.
 */
export function makeStandardMaterial(
    params?: Partial<StandardMaterialParams>,
): MeshStandardMaterial {
    const transparent = params?.transparent ?? false;
    return new MeshStandardMaterial({
        name: params?.name ?? 'standard',
        color: params?.color ?? DEFAULT_COLOR,
        side: params?.side ?? DEFAULT_SIDE,
        clippingPlanes: params?.clippingPlanes ?? [],
        opacity: params?.opacity ?? DEFAULT_OPACITY,
        roughness: params?.roughness ?? DEFAULT_ROUGHNESS,
        metalness: params?.metalness ?? DEFAULT_METALNESS,
        transparent,
        depthWrite: !transparent,
        vertexColors: params?.vertexColors ?? false,
    });
}
