import { type Material, type Matrix4, Object3D, type Scene } from 'three';
import { formatNodeId, type NodeId, markNodeId } from './nodeId';
import { formatNode, type ObjectNode } from '@/planner/3d/object';
import { getNode, type NodeMap, calculateWorldTransform } from '@/planner/3d/nodeMap';
import { useDeveloperSettings } from '@/planner/developerSettings';
import { formatMaterialId, markMaterialId, type MaterialId } from '@/planner/3d/materials/materialId';

export type SceneState<Node extends ObjectNode> = {
    readonly nodes: NodeMap<Node>;
};

export function sceneState<Node extends ObjectNode>(): SceneState<Node> {
    return {
        nodes: new Map<NodeId, Node>(),
    };
}

/** The minimal state of a scene, with:
 * methods
 * */
export class SceneContext<
    Node extends ObjectNode = ObjectNode,
    State extends SceneState<Node> = SceneState<Node>,
> {
    constructor(
        public readonly scene: Scene,
        public readonly state: State,
    ) {}

    /** A map of which nodes map to which objects */
    readonly objectMap: Map<NodeId, Object3D> = new Map();
    readonly materialMap: Map<MaterialId, Material> = new Map();

    get nodes(): NodeMap<Node> {
        return this.state.nodes;
    }

    tryGetNode(id: NodeId): Node | undefined {
        return this.state.nodes.get(id);
    }

    getNode(id: NodeId): Node {
        return getNode(this.state.nodes, id);
    }

    worldTransform(id: NodeId): Matrix4 {
        return calculateWorldTransform(this.state.nodes, id);
    }

    addNode(node: Node, object: Object3D) {
        // Check the node does not already exist in the scene
        if (this.tryGetNode(node.id)) {
            if (!useDeveloperSettings().suppressReloadErrors) {
                throw new Error(`${formatNode(node)} already exists in scene`);
            }
        }

        this.state.nodes.set(node.id, node);
        this.objectMap.set(node.id, object);
        markNodeId(object, node.id);

        return node;
    }

    removeNode(id: NodeId): void {
        // Check the node does not already exist in the scene
        const node = this.tryGetNode(id);
        if (!node) {
            throw new Error(`Cannot find node ${formatNodeId(id)} to remove in scene`);
        }

        // Reparent child nodes
        this.state.nodes.forEach((otherNode: ObjectNode) => {
            if (otherNode.parent === id) {
                otherNode.parent = node.parent;
            }
        });
        this.state.nodes.delete(id);
        this.objectMap.delete(id);
    }

    getObject(id: NodeId): Object3D {
        const result = this.objectMap.get(id);
        if (result !== undefined) {
            return result;
        } else {
            throw Error(`Could not get Object3D for node ${formatNodeId(id)}`);
        }
    }

    getParentObject(node: ObjectNode): Object3D {
        return node.parent === null ? this.scene : this.getObject(node.parent);
    }

    tryGetMaterial(id: MaterialId): Material | undefined {
        return this.materialMap.get(id);
    }

    getMaterial(id: MaterialId): Material {
        const result = this.materialMap.get(id);
        if (result !== undefined) {
            return result;
        } else {
            throw Error(`Could not get Material ${formatMaterialId}`);
        }
    }

    addMaterial(id: MaterialId, material: Material): void {
        // Check the node does not already exist in the scene
        if (this.tryGetMaterial(id)) {
            if (!useDeveloperSettings().suppressReloadErrors) {
                throw new Error(`Material with id ${material} already exists in scene`);
            }
        }
        markMaterialId(material, id);
        this.materialMap.set(id, material);
    }
}
