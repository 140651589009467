import { type DicomPatientName, type PatientName } from '@/lib/dicom/types';
import type { CaseApiPatient } from '@/api/case/types';
import type { UsernameApiObject } from '@/api/plan/types';

export class NameUtil {
    /**
     * Format a {@link PatientName} to a human readable string.
     */
    public static format(name?: PatientName | UsernameApiObject | null): string {
        if (name) {
            if (name.family && name.given && name.middle) {
                return `${name.given} ${name.middle} ${name.family}`;
            } else if (name.family && name.given) {
                return `${name.given} ${name.family}`;
            } else if (name.family) {
                return `${name.family}`;
            } else if (name.given) {
                return `${name.given}`;
            }
        }
        return '';
    }

    public static formatCaseApiPatient(patient: CaseApiPatient | null): string {
        if (patient) {
            if (patient.family_name && patient.first_name && patient.middle_name) {
                return `${patient.first_name} ${patient.middle_name} ${patient.family_name}`;
            } else if (patient.family_name && patient.first_name) {
                return `${patient.first_name} ${patient.family_name}`;
            } else if (patient.family_name) {
                return `${patient.family_name}`;
            } else if (patient.first_name) {
                return `${patient.first_name}`;
            }
        }
        return '';
    }

    /**
     * Cast a {@link DicomPatientName} to a {@link PatientName}
     */
    public static fromDicom(name?: DicomPatientName | null): PatientName | null {
        if (name) {
            return {
                title: name.prefix,
                family: name.familyName,
                given: name.givenName,
                middle: name.middleName,
            };
        }

        return null;
    }
}
