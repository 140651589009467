<template>
    <h1><slot></slot></h1>
</template>

<style scoped>
    h1 {
        font-size: 28px;
        line-height: 46px;
        font-weight: 500;
        color: var(--planner-black-rich);
        margin: 0 0 20px;
    }
</style>
