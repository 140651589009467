import type { Url } from '@/formus/types';

/** Deprecated explicit representation of a link */
export type Link = {
    rel: string;
    href: Url;
    title: string | null;
};

/**
 * An api representation with an explicit 'links' property.
 *
 * This is for compatibility with the old LinkedRepresentation, which is overcomplicated and should
 * be considered deprecated.
 */
export type ApiLinks = {
    name?: string;
    links: Link[];
};

export type LinkSelector = {
    title?: string | null;
    rel?: string | null;
    optional?: boolean;
};

/**
 * Get the link that matches the given selector, throwing if the link does not exist or is not unique
 * (the selector) matches multiple links
 */
export function getLink(object: ApiLinks, selector: LinkSelector | string = 'self'): string {
    selector = normalizeSelector(selector);
    const matchingLinks = selectLinks(object, selector);

    if (matchingLinks.length === 1) {
        // Exactly one match
        return matchingLinks[0].href;
    } else if (matchingLinks.length == 0 && selector.optional) {
        // No matches, but the selector is optional
        return '';
    }

    // Otherwise we have an error
    const objectDescription = apiObjectDescription(object);
    const selectorDescription = formatSelector(selector);

    if (matchingLinks.length == 0) {
        // No matches
        throw Error(`No link on API object ${objectDescription} matched ${selectorDescription}`);
    } else {
        // Multiple matches
        const linkDescription = (link: Link): string =>
            `rel: '${link.rel}', href: '${link.href}'` +
            (link.title ? `, title: '${link.title}'` : '');
        throw Error(
            [
                `Multiple links on API object${objectDescription} matched ${selectorDescription}:`,
                ...matchingLinks.map(linkDescription),
            ].join('\n  '),
        );
    }
}

function formatSelector(selector: LinkSelector): string {
    return [
        selector.rel ? `rel: '${selector.rel}'` : null,
        selector.title ? `title: '${selector.title}'` : null,
        selector.optional ? 'optional: true' : null,
    ]
        .filter((s) => s != null)
        .join(', ');
}

function apiObjectDescription(object: ApiLinks): string {
    const objectName = object.name ? `API object '${object.name}'` : 'API object';
    const selfLinks = selectLinks(object, 'self');
    if (selfLinks.length === 1) {
        return `${objectName} ${selfLinks[0].href}`;
    } else {
        return objectName;
    }
}

/**
 * Get all the links that match the given selector
 */
function selectLinks(repr: ApiLinks, selector_: LinkSelector | string): Link[] {
    const selector: LinkSelector = normalizeSelector(selector_);
    return repr.links.filter(
        (link) =>
            (selector.title === undefined || link.title === selector.title) &&
            (selector.rel === undefined || link.rel === selector.rel),
    );
}

function normalizeSelector(selector: LinkSelector | string): LinkSelector {
    return typeof selector === 'string' ? { rel: selector } : selector;
}
