import type { PlannerMode } from '@/planner/plannerState';
import type { CrossSectionId } from '@/planner/scene/crossSection';

/** Identifies groups of objects in the 3d scene whose visibility can be toggled on and off */
export type GroupToggleId = 'femur' | 'pelvis' | 'implant';

/** Identifies rendering-mode toggles */
export type ModeToggleId = 'xray';

/**
 * Identifies visibility toggles - these are the buttons at the top of the planner-view that toggle
 * the various visibility options and modes.
 */
export type VisibilityToggleId = GroupToggleId | ModeToggleId | CrossSectionId;

/** The state of a visibility toggle button */
export type VisibilityToggleState = 'on' | 'off' | 'hidden';

/**
 * A set of [toggle-states]{@link VisibilityToggleState}, one for each [toggle id]{@link VisibilityToggleId}
 */
export type VisibilityToggles = {
    [Id in VisibilityToggleId]: VisibilityToggleState;
};

/** The initial state of visibility toggles for the given planner-mode */
export function initialVisibilityToggles(mode: PlannerMode): VisibilityToggles {
    return _INITIAL_VISIBILITY_TOGGLES[mode];
}

const _DEFAULT_HIDDEN: VisibilityToggles = {
    femur: 'hidden',
    pelvis: 'hidden',
    implant: 'hidden',
    xray: 'hidden',
    'stem-neck-cross-section': 'hidden',
    'stem-coronal-cross-section': 'hidden',
    'cup-coronal-cross-section': 'hidden',
} as const;

const _INITIAL_VISIBILITY_TOGGLES: { [Mode in PlannerMode]: VisibilityToggles } = {
    default: {
        ..._DEFAULT_HIDDEN,
        femur: 'on',
        pelvis: 'on',
        implant: 'on',
        xray: 'off',
    },
    disabled: {
        ..._DEFAULT_HIDDEN,
        femur: 'on',
        pelvis: 'on',
        implant: 'on',
        xray: 'off',
    },
    stem: {
        ..._DEFAULT_HIDDEN,
        femur: 'on',
        pelvis: 'off',
        implant: 'on',
        xray: 'off',
        'stem-neck-cross-section': 'off',
        'stem-coronal-cross-section': 'off',
    },
    cup: {
        ..._DEFAULT_HIDDEN,
        femur: 'off',
        pelvis: 'on',
        implant: 'on',
        xray: 'on',
        'cup-coronal-cross-section': 'off',
    },
    combined: {
        ..._DEFAULT_HIDDEN,
        femur: 'on',
        pelvis: 'on',
        implant: 'on',
        xray: 'off',
    },
} as const;
