import { assertIs } from '@/util';
import { convertLinks } from '@/api/convertLinks';
import type { ApiCatalogComponentBase } from '@/api/catalog/base';
import {
    type CatalogHead,
    isHeadOffset,
    isHeadSizeString,
    isHeadSystem,
    isHeadType,
} from '@/formus/catalog/head';

export type ApiCatalogHead = ApiCatalogComponentBase & {
    offset: number;
};

const headLinkMap = {
    self: 'self',
} as const;

/**
 * Convert a head representation from the api into a catalog-head
 */
export function catalogHead(apiHead: ApiCatalogHead): CatalogHead {
    const { name, system, type, size, offset } = apiHead;
    assertIs(isHeadSystem, system);
    assertIs(isHeadType, type);
    assertIs(isHeadSizeString, size);
    assertIs(isHeadOffset, offset);
    return {
        name,
        system,
        type,
        size,
        offset,
        ...convertLinks(apiHead, headLinkMap),
    };
}
