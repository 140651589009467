import { parseHeadMetaTag } from '@/lib/headMetaTags';

const SHOW_TOP_BANNER = 'show-top-banner';

/**
 * The non-clinical use feature flag.
 */
export const showTopBanner = (): boolean => {
    return parseHeadMetaTag(SHOW_TOP_BANNER, 'false') === 'true' || false;
};

export const topBannerText = (): string => {
    const DEFAULT_BANNER = 'Investigational Device - To Be Used by Qualified Investigators Only';
    return parseHeadMetaTag('top-banner-text', DEFAULT_BANNER);
};
