<template>
    <div class="case-title">{{ caseSync.caseName }}</div>
    <div class="case-menu-items">
        <app-menu-item
            icon="mdi-folder-information"
            text="Case Information"
            tooltip
            :to="caseSync.routeTo(ROUTES.EDIT_CASE)"
            leftmost
        />
        <app-menu-item
            icon="mdi-camera"
            text="CT Scans"
            tooltip
            :to="caseSync.routeTo(ROUTES.CT_SCAN)"
        />
        <app-menu-item
            icon="mdi-cube"
            text="3D Planner"
            tooltip
            :to="caseSync.routeTo(ROUTES.PLANNER)"
        />
        <app-menu-item
            icon="mdi-file-account"
            text="Plans"
            tooltip
            :to="caseSync.routeTo(ROUTES.PLANS)"
        />

        <app-menu-item
            v-if="useUserStore().isAdmin && caseSync.case_"
            icon="mdi-tools"
            text="Admin"
            tooltip
            :to="adminCaseRoute(caseSync.case_?.id!)"
        />
    </div>
</template>

<script setup lang="ts">
    /**
     * Implements software requirement: H1SR-80, H1SR-85
     *
     * @link https://formuslabs.youtrack.cloud/issue/H1SR-80/The-Home-Button-Formus-Logo-directs-the-user-to-the-dashboard
     * @link https://formuslabs.youtrack.cloud/issue/H1SR-85/Revoke-user-authentication-when-user-clicks-the-logout-button
     */
    import AppMenuItem from '@/components/AppMenuItem.vue';
    import { adminCaseRoute, ROUTES } from '@/router';
    import { useCaseSyncStore } from '@/stores/navigation/useCaseSyncStore';
    import { useUserStore } from '@/stores/user/store';

    const caseSync = useCaseSyncStore();
</script>

<style scoped>
    .case-title {
        margin-top: 20px;
        position: absolute;
        left: calc(50vw - 200px);
        top: 0;
        width: 400px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #fff;
        font-weight: 400;
        line-height: 2rem;
        font-size: 1.5rem;
        letter-spacing: normal;
        text-transform: uppercase;
    }

    .case-menu-items {
        z-index: 910;
        position: absolute;
        left: calc(50vw - 110px);
        top: 60px;
        display: flex;
    }
</style>
