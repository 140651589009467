const DEGREES_TO_RADIANS = Math.PI / 180;
const RADIANS_TO_DEGREES = 180 / Math.PI;

/** Convert an angle represented in degrees into radians */
export function degToRad(degrees: number): number {
    return degrees * DEGREES_TO_RADIANS;
}

/** Convert an angle represented in radians into degrees */
export function radToDeg(radians: number): number {
    return radians * RADIANS_TO_DEGREES;
}

/** Round a number so it has *digits* after the decimal point */
export function round(value: number, digits: number): number {
    const factor = Math.pow(10, digits);
    return Math.round(value * factor) / factor;
}
