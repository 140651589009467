import { isInteger } from '@/lib/validation';

/**
 * Parsing the resource link to obtain the resource id that maps to the database entry.
 * TODO: Ideally the API should just return the id value in it's API response
 * @param url - API resource link in the format of https://domain/path/to/resource/:id
 * where :id is an integer value that maps to the resource id in the database
 */
export function getIdFromUrl(url?: string): string {
    if (!url) {
        return '';
    }

    const parts = url.split('/');
    const id = parts.at(-1);

    if (id && isInteger(id)) {
        return id;
    }
    return '';
}
