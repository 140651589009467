import { asyncTimeout, logger } from '@/util';
import type { AxiosRequestConfig } from 'axios';
import { getCaseDetails } from '@/api/case/request';
import type { CaseDetailsApiObjectBase } from '@/api/case/types';

const log = logger('poll-case');

const POLL_INTERVAL_MILLISECONDS = 30 * 1000;

/**
 * Repeatedly fetch a case until it is cancelled
 */
export async function pollCase(
    caseId: number,
    onFetch: (case_: CaseDetailsApiObjectBase | null) => void,
    options?: {
        signal?: AbortSignal;
    },
): Promise<void> {
    const config: AxiosRequestConfig = { signal: options?.signal };

    const fetch = async (): Promise<CaseDetailsApiObjectBase | null> => {
        const case_ = await getCaseDetails(caseId, config);
        return case_ ? case_ : null;
    };

    try {
        do {
            options?.signal?.throwIfAborted();

            // Attempt to fetch the case
            const case_ = await fetch();

            options?.signal?.throwIfAborted();

            onFetch(case_);

            options?.signal?.throwIfAborted();

            await asyncTimeout(POLL_INTERVAL_MILLISECONDS, options?.signal);
            // eslint-disable-next-line no-constant-condition
        } while (true);
    } catch (e: unknown) {
        if (config.signal?.aborted) {
            log.debug('case polling cancelled');
            return;
        }

        throw e;
    }
}
