import { Matrix4, Object3D, Vector3 } from 'three';
import { positionalPart } from '@/geometry/matrix';

/**
 * Add an Object3D to a parent object, optionally with a given local-transform.
 *
 * **Note:** this sets
 * [Object3D.matrixAutoUpdate]{@link https://threejs.org/docs/#api/en/core/Object3D.matrixAutoUpdate}
 * to false, implying we will set the [matrix]{@link https://threejs.org/docs/#api/en/core/Object3D.matrix}
 * property directly, and changes to the Object3Ds position and rotation properties will have no effect.
 */
export function addObject<T extends Object3D>(
    parent: Object3D,
    object: T | (() => T),
    localTransform?: Matrix4,
): T {
    object = object instanceof Object3D ? object : object();
    if (localTransform) {
        object.matrix.copy(localTransform);
    }
    object.matrixAutoUpdate = false;
    parent.add(object);
    return object;
}

export function setPosition(object: Object3D, vector: Vector3) {
    object.position.set(...vector.toArray());

    if (object.matrixAutoUpdate) {
        // nothing to do, three js will update it when needed.
    } else {
        object.matrix.setPosition(vector);
    }
    object.updateMatrixWorld(true);
}

/**
 * Set the local transform (object-to-parent, aka {@link Object3D.matrix}) of an {@link Object3D}. If the object has
 * no parent, or the parent is the {@link THREE.Scene}, this transform is the same as the object's object-to-world
 * or {@link Object3D.matrixWorld} once it has been updated.
 *
 * **Note** There are two different ways to set an object's transformation, and they are not compatible to use
 * on the same object:
 * 1. Setting {@link Object3D.matrixAutoUpdate} = true, modifying the position, quaternion, and scale properties and
 *  then calling {@link Object3D.updateMatrix}
 * 1. Setting {@link Object3D.matrixAutoUpdate} = false and modifying the object's {@link Object3D.matrix} directly.
 *  In this case we should NOT call {@link Object3D.updateMatrix} as it will clobber the manual changes made to the
 *  matrix, recalculating the matrix from position, scale, and so on.
 *
 * @see https://threejs.org/docs/#manual/en/introduction/Matrix-transformations
 */
export function setLocalTransform(object: Object3D, matrix: Matrix4): void {
    object.position.setFromMatrixPosition(matrix);
    object.setRotationFromMatrix(matrix);

    if (object.matrixAutoUpdate) {
        // nothing to do, three js will update it when needed.
    } else {
        object.matrix.copy(matrix);
    }

    // The object needs to be explicitly forced to update it's matrixWorld property
    object.updateMatrixWorld(true);
}

/**
 * Set the local transform (object-to-parent, aka {@link Object3D.matrix}) of an {@link Object3D} so that it has
 * a given world-transform, aka {@link Object3D.matrixWorld}, taking account of its parent transform if it exists.
 */
export function setWorldTransform(object: Object3D, matrix: Matrix4): void {
    if (object.parent) {
        // If the object has a parent we need to work out its local transform relative to the parent.
        const parentTransform = worldTransform(object.parent);
        const objectToParent = parentTransform.invert().multiply(matrix);
        setLocalTransform(object, objectToParent);
    } else {
        // If the object has no parent we just need to set its transform directly
        setLocalTransform(object, matrix);
    }
}

/**
 * Get the object-to-world transform of an {@link Object3D} i.e. its {@link Object3D.matrixWorld}, ensuring that
 * it is updated first.
 */
export function worldTransform(object: Object3D): Matrix4 {
    object.updateWorldMatrix(true, false);
    return object.matrixWorld.clone();
}

/**
 * Get the position of an object in world-space
 */
export function worldPosition(object: Object3D): Vector3 {
    return positionalPart(worldTransform(object));
}

/**
 * Given:
 * - a source {@link Object3D} or source-to-world transform matrix
 * - a target {@link Object3D} or target-to-world transform matrix
 *
 * Create a source-to-target transform matrix i.e. the matrix that takes source points and vectors to target points
 * and vectors.
 *
 * **Note** that for an {@link Object3D} like a {@link Mesh} the object-to-world transform is given by
 * {@link Object3D.matrixWorld}.
 *
 * @param source A source object *or* source-to-world transform.
 * @param target A target object *or* target-to-world transform.
 */
export function sourceToTarget(source: Matrix4 | Object3D, target: Matrix4 | Object3D): Matrix4 {
    /**
     * This is a copy of an old world-transform function. Copying here only because I am paranoid
     * about side effects.
     * TODO: Replace with standard world-transform function {@link worldTransform}
     * */
    function oldWorldTransform(object: Object3D): Matrix4 {
        object.updateMatrixWorld(true);
        return object.matrixWorld.clone();
    }

    const sourceMatrix = source instanceof Matrix4 ? source.clone() : oldWorldTransform(source);
    const targetMatrix = target instanceof Matrix4 ? target.clone() : oldWorldTransform(target);

    // The given transforms are S (source -> world) and T (target -> world).
    // The desired transform is source -> target which is given by source -> world followed by world -> target.
    // world -> target is the inverse of T
    // So the resulting composition needs to be S followed by inverse(T)
    // Matrix multiplication composes transforms from *right to left* so as a matrix multiplication the composition
    // is inverse(T) * S
    return targetMatrix.invert().multiply(sourceMatrix);
}

/**
 * Set the world-transform of this object without changing the world-transforms of its children
 */
export function shiftWorldTransform(object: Object3D, transform: Matrix4) {
    const childrenAndTransforms = removeChildren(object);
    setWorldTransform(object, transform);
    for (const [c, t] of childrenAndTransforms) {
        object.attach(c);
        setWorldTransform(c, t);
    }
}

/**
 * Set the local-transform of this object without changing the world-transforms of its children
 */
export function shiftLocalTransform(object: Object3D, transform: Matrix4) {
    const childrenAndTransforms = removeChildren(object);
    setLocalTransform(object, transform);
    for (const [c, t] of childrenAndTransforms) {
        object.attach(c);
        setWorldTransform(c, t);
    }
}

/**
 * Remove the children of this object, returning them along with their original transform matrix in world space
 */
function removeChildren(object: Object3D): [Object3D, Matrix4][] {
    const children = Array.from(object.children);
    return children.map((c) => {
        const transform = worldTransform(c);
        object.remove(c);
        return [c, transform];
    });
}
