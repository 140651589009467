import { assert } from '@/util';

export function all<T>(condition: (value: T) => any, array: T[]): boolean;
export function all<T>(array: T[]): boolean;

export function all<T>(arg0: T[] | ((value: T) => any), arg1?: T[]): boolean {
    if (Array.isArray(arg0)) {
        assert(arg1 === undefined);
        return arg0.reduce((prev, cur) => prev && !!cur, true);
    } else {
        assert(arg1 !== undefined);
        return arg1.map(arg0).reduce((prev, cur) => prev && !!cur, true);
    }
}

export function any<T>(condition: (value: T) => any, array: T[]): boolean;
export function any<T>(array: T[]): boolean;

export function any<T>(arg0: T[] | ((value: T) => any), arg1?: T[]): boolean {
    if (Array.isArray(arg0)) {
        assert(arg1 === undefined);
        return arg0.reduce((prev, cur) => prev || !!cur, false);
    } else {
        assert(arg1 !== undefined);
        return arg1.map(arg0).reduce((prev, cur) => prev || !!cur, false);
    }
}
