import {
    stopAll,
    stop,
    type StopHandle,
    taggedLogger,
    assertNonNull,
    degToRad,
    assertDefined,
} from '@/util';
import { Euler, MathUtils, Matrix4 } from 'three';
import { markRaw, watchEffect } from 'vue';
import type { ApiCaseCatStack } from '@/api/cat-stacks/types';
import { loadImageAsUrl } from '@/api/cat-stacks/loadImage';
import { imagePlaneNode, type ImagePlaneNode, updateImagePlane } from '@/planner/3d/imagePlane';
import type { Url } from '@/formus/types';
import { watchImmediate } from '@vueuse/core';
import type { PlannerStore } from '@/planner/plannerStore';
import { useAppErrorStore } from '@/stores/appErrorStore';
import type { CatstackPlaneId } from '@/planner/cat-stack/catstacksData';
import type { PlannerSceneContext } from '@/planner/scene/plannerSceneContext';

const log = taggedLogger('cat-stack');

export type CatstackImageNode = ImagePlaneNode & {
    planeId: CatstackPlaneId;
    image: HTMLImageElement | null;
};

export function catstackImageNode(
    id: CatstackPlaneId,
    properties?: Partial<CatstackImageNode>,
): CatstackImageNode {
    const transform = new Matrix4().makeRotationFromEuler(
        new Euler(degToRad(id === 'axial' ? 180 : 90), 0, 0),
    );
    return {
        ...imagePlaneNode(id, { transform, visible: false, ...properties }),
        planeId: id,
        image: null,
    };
}

export function updateCatstackImage(
    context: PlannerSceneContext,
    store: PlannerStore,
    node: CatstackImageNode,
): StopHandle {
    return stopAll(
        updateImagePlane(context, node),
        _updateSize(store, node),
        _updatePosition(store, node),
        _updateVisibility(store, node),
        _updateImage(store, node),
        _updateSourceCanvas(store, node),
    );
}

function _updatePosition(store: PlannerStore, node: CatstackImageNode): StopHandle {
    return watchEffect(() => {
        if (store.catstacksData !== null) {
            const data = store.catstacksData[node.planeId];
            const sliceSpacing = data.axis.length / (data.catstack.count - 1);
            const offset = sliceSpacing * store.catstacks[node.planeId].sliceIndex;
            node.transform.setPosition(
                data.axis.direction.clone().multiplyScalar(offset).add(data.axis.origin),
            );
        }
    });
}

function _updateSize(store: PlannerStore, node: CatstackImageNode): StopHandle {
    return watchImmediate(
        () => (store.catstacksData ? store.catstacksData[node.planeId].catstack.world : null),
        (size) => {
            if (size) {
                node.width = size.width;
                node.height = size.height;
            }
        },
    );
}

function _updateVisibility(store: PlannerStore, node: CatstackImageNode): StopHandle {
    return watchImmediate(
        () => store.catstacks.visible,
        (visible) => {
            node.visible = visible;
        },
    );
}

function _updateImage(store: PlannerStore, node: CatstackImageNode): StopHandle {
    let loadingImage: StopHandle | null = null;
    return watchImmediate(
        () =>
            !store.isLoading && store.catstacksData
                ? store.catstacksData[node.planeId].catstack.image_url
                : null,
        (sourceUrl) => {
            stop(loadingImage);
            if (sourceUrl !== null) {
                loadingImage = _loadImage(node, sourceUrl);
            } else {
                node.image = null;
            }
        },
    );
}

function _loadImage(node: CatstackImageNode, sourceUrl: Url): StopHandle {
    const aborter = new AbortController();
    const signal = aborter.signal;

    useAppErrorStore().catchErrors(async () => {
        try {
            log.info('Loading image for %s cat-stack\n  from %s', node.planeId, sourceUrl);
            const data = await loadImageAsUrl(sourceUrl, { signal });
            const image = new Image();
            image.src = assertDefined(data, 'catstack-image-url');

            await new Promise<void>((resolve, reject) => {
                let onLoaded: (() => void) | null = null;
                onLoaded = () => {
                    log.info('Loaded image for %s cat-stack\n  from %s', node.planeId, sourceUrl);
                    image.removeEventListener('load', assertNonNull(onLoaded, 'onLoaded'));
                    node.image = markRaw(image);
                    resolve();
                };
                signal.addEventListener('abort', () => {
                    log.debug(
                        'Aborting load of image for %s cat-stack\n  from %s',
                        node.planeId,
                        sourceUrl,
                    );
                    image.removeEventListener('load', assertNonNull(onLoaded, 'onLoaded'));
                    reject(signal.reason);
                });
                image.addEventListener('load', onLoaded, false);
            });
        } catch (error) {
            if (signal.aborted) {
                log.debug('Aborted loading image for %s from %s', node.planeId ?? '?', sourceUrl);
            } else {
                throw error;
            }
        }
    });

    return () => aborter.abort();
}

export function _updateSourceCanvas(store: PlannerStore, node: CatstackImageNode): StopHandle {
    let update: StopHandle | null = null;
    return stopAll(
        watchImmediate(
            () => !store.isLoading && store.catstacksData && node.image,
            (loaded) => {
                stop(update);

                if (!loaded) {
                    node.source = null;
                    return;
                }

                const catstack = assertNonNull(store.catstacksData, 'cat-stack-data')[node.planeId]
                    .catstack;
                const canvas = document.createElement('canvas');

                // canvas width and height require sizes to be power of two
                canvas.width = MathUtils.ceilPowerOfTwo(catstack.world.width);
                canvas.height = MathUtils.ceilPowerOfTwo(catstack.world.height);
                update = watchImmediate(
                    () => store.catstacks[node.planeId].sliceIndex,
                    (value) => _redrawCanvas(node, catstack, canvas, value),
                );
                node.source = canvas;
            },
        ),
        () => stop(update),
    );
}

type _Canvas2dOffset = {
    x: number;
    y: number;
};

/**
 * Get the canvas offset from a given slice value
 *
 * We use the returned offset to move the catstack image (the full sprite image that contains
 * all the individual CT images) of the canvas, so only the desired CT slice is visible in the canvas
 */
export function _canvasOffsetFromSliceIndex(
    catStack: ApiCaseCatStack,
    sliceIndex: number,
): _Canvas2dOffset {
    const sliceSize = catStack.slice;
    const image = catStack.image;

    const total = catStack.count;
    const sliceWidth = sliceSize.width;
    const sliceHeight = sliceSize.height;
    const rows = image.height / sliceSize.height;

    const currentColumn = Math.ceil((total - sliceIndex + 1) / rows);
    const currentRow = total - sliceIndex + 1 - rows * (currentColumn - 1);

    const slicePositionX = -sliceWidth * (currentColumn - 1);
    const slicePositionY = -sliceHeight * (currentRow - 1);

    if (log.enabledFor('debug')) {
        log.debug(
            [
                'Calculated canvas offset from slice: ',
                `currentSlice: ${sliceIndex}`,
                `rows: ${rows}`,
                `currentColumn: ${currentColumn}`,
                `currentRow: ${currentRow}`,
                `slicePositionX: ${slicePositionX}`,
                `slicePositionY: ${slicePositionY}`,
                `x: ${slicePositionX / -1}`,
                `y: ${slicePositionY / -1}`,
            ].join('\n  '),
        );
    }

    return {
        x: slicePositionX / -1,
        y: slicePositionY / -1,
    };
}

function _redrawCanvas(
    node: CatstackImageNode,
    catstack: ApiCaseCatStack,
    canvas: HTMLCanvasElement,
    sliceIndex: number,
): void {
    const canvasOffset = _canvasOffsetFromSliceIndex(catstack, sliceIndex);
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    log.debug(
        [
            `Redrawing canvas for ${node.planeId} catstack. Parameters:`,
            `offsetX: ${canvasOffset.x}`,
            `offsetY: ${canvasOffset.y}`,
            `sliceWidth: ${catstack.slice.width}`,
            `sliceHeight: ${catstack.slice.height}`,
            `imgWidth: ${canvas.width}`,
            `imgHeight: ${canvas.height}`,
        ].join('\n  '),
    );

    ctx.drawImage(
        assertNonNull(node.image, 'cat-stack image'),
        canvasOffset.x,
        canvasOffset.y,
        catstack.slice.width,
        catstack.slice.height,
        0,
        0,
        canvas.width, // canvas size
        canvas.height,
    );

    // Increment sourceVersion to trigger a material update
    node.sourceVersion += 1;
}
