<template>
    <aside>
        <div>
            <slot></slot>
        </div>

        <div class="aside-footer">
            <span>
                Copyright © <br />
                Formus Labs 2019-2021
            </span>

            <nav>
                <router-link :to="{ name: ROUTES.ABOUT }">About</router-link>
                <router-link :to="{ name: ROUTES.TERMS }">Terms of Use</router-link>
                <router-link :to="{ name: ROUTES.PRIVACY }">Privacy</router-link>
            </nav>

            <span>{{ versionStore.versionWithPrefix }}</span>
        </div>
    </aside>
</template>

<style scoped>
    aside {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 2.5rem 2.5rem 5rem;
        min-width: 200px;
        border-right: 1px solid var(--planner-grey-light);
    }
    .aside-footer * {
        display: block;
    }
    span,
    a {
        font-size: 0.75rem;
        line-height: 1.25rem;
        font-weight: 500;
    }
    span {
        color: var(--planner-grey);
    }
    a {
        color: var(--planner-blue-button);
    }
    nav {
        padding: 20px 0;
    }
</style>
<script setup lang="ts">
    import { ROUTES } from '@/router';
    import { useVersion } from '@/stores/version';

    const versionStore = useVersion();
    await versionStore.load();
</script>
