import assert from 'assert';
import { toValue, watch, type WatchOptions, type WatchSource } from 'vue';

export type AsyncWatchOptions = WatchOptions & {
    /** Optional signal to cancel the watch */
    signal?: AbortSignal;
};

export type WatchUntilResult = 'complete' | 'aborted';

/**
 * Create a promise that will complete when a watched value becomes true
 * @param source The value to watch
 * @param options options values
 */
export function asyncWatchUntil(
    source: WatchSource<boolean>,
    options?: AsyncWatchOptions,
): Promise<WatchUntilResult> {
    return new Promise<WatchUntilResult>((resolve) => {
        /**
         * The stop function is called before the promise is resolved, and will call the watch-stop-handle
         * and unsubscribe from the abort-signal if it is given.
         */
        let stop: (() => void) | null = null;

        if (options?.signal?.aborted) {
            resolve('aborted');
            return;
        }

        if (options?.immediate && toValue(source)) {
            resolve('complete');
            return;
        }

        // Set up the watch
        const watchStopHandle = watch(
            source,
            (value) => {
                if (value) {
                    if (stop !== null) {
                        stop();
                    }
                    resolve('complete');
                }
            },
            options,
        );

        const abortSignal = options?.signal;
        if (abortSignal) {
            // If an abort-signal is given then subscribe to the event
            const onAbort = () => {
                assert(stop !== null);
                stop();
                resolve('aborted');
            };
            abortSignal.addEventListener('abort', onAbort);

            // Stopping needs to unsubscribe from the event as well as stopping the watch
            stop = () => {
                abortSignal.removeEventListener('abort', onAbort);
                watchStopHandle();
            };
        } else {
            // If no abort-signal is given then the stopping just needs to stop the watch
            stop = watchStopHandle;
        }
    });
}

export function asyncWatchImmediateUntil(
    source: WatchSource<boolean>,
    options?: AsyncWatchOptions,
): Promise<WatchUntilResult> {
    return asyncWatchUntil(source, { ...options, immediate: true });
}

