import type { PlannerObjectId } from '@/planner/scene/plannerObjectId';
import type { SceneContext } from '@/planner/3d/SceneContext';
import { makeMaterial, type MaterialParams } from '@/planner/3d/materials/makeMaterial';
import type { RenderingMode } from '@/planner/plannerState';
import { BackSide, FrontSide } from 'three';

const _colors = {
    /** Bone-color; used for bones. */
    'bone': '#b4aa91',
    /** Bone-color when in xray-mode; used for bones. */
    'bone-xray': '#ecdfc2',
    /** Purple color used on metal 'bones' */
    'metal': '#8872a1',
    /** Purple color used on metal 'bones' when in x-ray mode*/
    'metal-xray': '#d4bcff',
    /** Femoral inner-cortical-surface color */
    'bone-ics': '#cdcdcd',
    /** Femoral inner-cortical-surface color when in x-ray mode */
    'bone-ics-xray': '#ffffff',
    /** Default implant color. */
    'implant-white': '#ffffff',
    /** Default implant color when in xray-mode. */
    'implant-white-back': '#d2d4ce',
    /** Default implant color when in xray-mode. */
    'implant-white-xray': '#ffffff',
    /** Stem color when in xray-mode. */
    'implant-grey-xray': '#c0c0c0',
    /** Stem color when in xray-mode. */
    'implant-blue': '#7cabf5',
    /** Stem color when in xray-mode. */
    'implant-blue-back': '#6a94cf',
    /** Stem color when in xray-mode. */
    'implant-blue-xray': '#7cabf5',
    /** Coverage-mask color. */
    'coverage': '#cf4747',
    /** Coverage-mask color when in x-ray mode */
    'coverage-xray': '#f81e1e',
} as const;

const _materials: Record<string, MaterialParams> = {
    'bone-front': { type: 'standard', color: _colors['bone'], roughness: 1, side: FrontSide },
    'bone-back': { type: 'solid', color: _colors['bone'], side: BackSide },
    'bone-xray': { type: 'xray', color: _colors['bone-xray'] },
    'operative-femur-front': {
        type: 'standard',
        color: _colors['bone'],
        opacity: 0.5,
        transparent: true,
        side: FrontSide,
        roughness: 1,
    },
    'operative-femur-back': {
        type: 'standard',
        color: _colors['bone'],
        side: BackSide,
        roughness: 1,
    },
    'operative-femur-xray': { type: 'xray', color: _colors['bone-xray'] },
    'operative-femur-ics-front': {
        type: 'standard',
        color: _colors['bone-ics'],
        opacity: 0.5,
        transparent: true,
        side: FrontSide,
        roughness: 1,
    },
    'operative-femur-ics-back': {
        type: 'standard',
        color: _colors['bone-ics'],
        side: BackSide,
        roughness: 1,
    },
    'operative-femur-ics-xray': { type: 'xray', color: _colors['bone-ics-xray'] },
    'contralateral-femur-front': {
        type: 'standard',
        color: _colors['bone'],
        roughness: 1,
        side: FrontSide,
    },
    'contralateral-femur-back': { type: 'solid', color: _colors['bone'], side: BackSide },
    'contralateral-femur-xray': { type: 'xray', color: _colors['bone-xray'] },
    'metal-front': { type: 'standard', color: _colors['metal'], roughness: 1, side: FrontSide },
    'metal-back': { type: 'solid', color: _colors['metal'], side: BackSide },
    'metal-xray': { type: 'xray', color: _colors['metal-xray'] },
    'implant-white-front': {
        type: 'standard',
        color: _colors['implant-white'],
        roughness: 1,
        side: FrontSide,
    },
    'implant-white-back': { type: 'solid', color: _colors['implant-white-back'], side: BackSide },
    'implant-white-xray': { type: 'xray', color: _colors['implant-white-xray'] },
    'implant-blue-front': {
        type: 'standard',
        color: _colors['implant-blue'],
        roughness: 1,
        side: FrontSide,
    },
    'implant-blue-back': { type: 'solid', color: _colors['implant-blue-back'], side: BackSide },
    'implant-blue-xray': { type: 'xray', color: _colors['implant-blue-xray'] },
    'coverage': { type: 'standard', color: _colors['coverage'], roughness: 1 },
    'coverage-xray': {
        type: 'solid',
        color: _colors['coverage-xray'],
        opacity: 0.6,
        transparent: true,
    },
} as const;

/** Get the material-id for a planner object */
export function plannerMaterialId(
    plannerId: PlannerObjectId,
    mode: RenderingMode,
    side?: 'frontside' | 'backside',
): PlannerMaterialId {
    switch (mode) {
        case 'normal':
            return _normalMaterialId(plannerId, side);
        case 'xray':
            return _xrayMaterialId(plannerId);
        default:
            throw new Error(`Unknown rendering mode '${mode}'`);
    }
}

/** Create and add planner-materials to the context */
export function addPlannerMaterials(context: SceneContext) {
    Object.entries(_materials).forEach(([id, params]) =>
        context.addMaterial(id, makeMaterial(params)),
    );
}

export type PlannerMaterialId = keyof typeof _materials;

export const plannerMaterialIds = Object.keys(_materials) as PlannerMaterialId[];

function _normalMaterialId(
    plannerId: PlannerObjectId,
    side?: 'frontside' | 'backside',
): PlannerMaterialId {
    switch (plannerId) {
        case 'operative-femur':
            return side === 'frontside' ? 'operative-femur-front' : 'operative-femur-back';
        case 'operative-femur-internal':
            return side === 'frontside' ? 'operative-femur-ics-front' : 'operative-femur-ics-back';
        case 'contralateral-femur':
            return side === 'frontside' ? 'contralateral-femur-front' : 'contralateral-femur-back';
        case 'metal':
            return side === 'frontside' ? 'metal-front' : 'metal-back';
        case 'stem':
            return side === 'frontside' ? 'implant-blue-front' : 'implant-blue-back';
        case 'cup':
        case 'liner':
        case 'head':
        case 'bearing':
            return side === 'frontside' ? 'implant-white-front' : 'implant-white-back';
        case 'cup-coverage':
        case 'cup-collision-surface':
            return 'coverage';
        default:
            return side === 'frontside' ? 'bone-front' : 'bone-back';
    }
}

function _xrayMaterialId(plannerId: PlannerObjectId): PlannerMaterialId {
    switch (plannerId) {
        case 'operative-femur':
            return 'operative-femur-xray';
        case 'operative-femur-internal':
            return 'operative-femur-ics-xray';
        case 'contralateral-femur':
            return 'contralateral-femur-xray';
        case 'metal':
            return 'metal-xray';
        case 'stem':
            return 'implant-blue-xray';
        case 'cup':
        case 'liner':
        case 'head':
        case 'bearing':
            return 'implant-white-xray';
        case 'cup-coverage':
        case 'cup-collision-surface':
            return 'coverage-xray';
        default:
            return 'bone-xray';
    }
}
