import type { PlanStoreState } from '@/stores/plan/types';

export const initialState: PlanStoreState = {
    project: null,
    study: null,
    specifications: null,
    automatedTemplate: null,
    manualTemplate: null,
    automatedPlan: null,
    manualPlan: null,
};
