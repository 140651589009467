<template>
    <div class="app-case-card-badge">
        <v-icon :icon="icon" />
        <div class="app-case-card-badge-triangle" />
        <v-tooltip activator="parent" :text="tooltip" location="bottom"></v-tooltip>
    </div>
</template>

<script setup lang="ts">
    import { type CaseStatus, CaseStatusState } from '@/api/caseSearch';
    import { computed } from 'vue';

    export interface Props {
        caseStatus: CaseStatus;
    }

    const props = defineProps<Props>();

    const iconMap = {
        [CaseStatusState.New]: 'mdi-dots-horizontal',
        [CaseStatusState.ProcessingCatStack]: 'mdi-dots-horizontal',
        [CaseStatusState.Processing]: 'mdi-dots-horizontal',
        [CaseStatusState.Complete]: 'mdi-check-bold',
        [CaseStatusState.Warning]: 'mdi-check-bold',
        [CaseStatusState.Unknown]: 'mdi-help',
        error: 'mdi-exclamation-thick',
    };

    const colourMap = {
        [CaseStatusState.New]: 'var(--planner-grey-light)',
        [CaseStatusState.ProcessingCatStack]: 'var(--planner-blue-vibrant)',
        [CaseStatusState.Processing]: 'var(--planner-blue-vibrant)',
        [CaseStatusState.Complete]: 'var(--planner-green)',
        [CaseStatusState.Warning]: 'var(--planner-yellow)',
        [CaseStatusState.Unknown]: 'var(--planner-grey-light)',
        error: 'var(--planner-orange)',
    };
    const tooltipMap = {
        [CaseStatusState.New]: 'Awaiting imaging',
        [CaseStatusState.ProcessingCatStack]: 'Processing',
        [CaseStatusState.Processing]: 'Processing',
        [CaseStatusState.Complete]: 'Plan available',
        [CaseStatusState.Warning]: 'Manual planning required',
        [CaseStatusState.Unknown]: 'Unknown',
        error: 'Technical issue',
    };

    const statusKey = computed(() => {
        if (props.caseStatus.failed) {
            return 'error';
        }

        return props.caseStatus.state;
    });

    const statusColour = computed(() => colourMap[statusKey.value]);

    const icon = computed(() => iconMap[statusKey.value]);

    const tooltip = computed(() => tooltipMap[statusKey.value]);
</script>

<style scoped>
    .app-case-card-badge {
        display: flex;
        align-items: center;
        justify-content: center;
        background: v-bind('statusColour');
        color: #fff;
        min-width: 40px;
        height: 40px;
        border-radius: 2px;
        position: relative;
        margin-right: 20px;
    }

    .app-case-card-badge-triangle {
        width: 0;
        position: absolute;
        bottom: -6px;
        border-top: 8px solid v-bind('statusColour');
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
    }
</style>
