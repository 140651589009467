import { degToRad, radToDeg } from '@/util';

/** Angle as given in */
export type AnteversionInclination = {
    /** Anteversion angle  */
    anteversion: number;

    /** Inclination angle */
    inclination: number;
};

/** Convert a set of acetabular-angles from radians to degrees */
export function toDegrees<T extends AnteversionInclination>(angles: T): T {
    return {
        ...angles,
        anteversion: radToDeg(angles.anteversion),
        inclination: radToDeg(angles.inclination),
    };
}

/** Convert a set of acetabular-angles from degrees to radians */
export function toRadians<T extends AnteversionInclination>(angles: T): T {
    return {
        ...angles,
        anteversion: degToRad(angles.anteversion),
        inclination: degToRad(angles.inclination),
    };
}
