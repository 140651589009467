import type { PlannerState } from '@/planner/plannerState';
import { findFittedCup } from '@/planner/api/fittedComponents';
import { type Matrix4, Vector3 } from 'three';
import { addVectors, multiplyScalar, vector3 } from '@/geometry/vector3';
import type { AnatomicalOffset } from '@/formus/anatomy/pelvis/anatomicalOffset';
import type { LpsBasis } from '@/formus/anatomy/LPS';
import { matrixFromApi } from '@/geometry/apiMatrix';
import { computeBearingUrl, computeLinerUrl } from '@/planner/componentUrls';
import { useAppErrorStore } from '@/stores/appErrorStore';
import { round } from '@/util';

/**
 * The offset of the cup as represented in the planner
 */
export type CupOffset = {
    depth: number;
    ap: number;
    si: number;
};

/**
 * A representation of the fitted up that is derived from fitted-cup data on the API.
 */
export type FittedCup = {
    /** 'Global' basis */
    basis: LpsBasis & {
        lateral: Vector3;
        inferior: Vector3;
    };

    /** The native hip-joint centre in world/CT space. */
    nativeHjc: Vector3;

    /**
     * The fitted hip-joint-centre in world/CT space.
     *
     * This is the centre-of rotation of the fitted-head inside the fitted-cup. The cup-group
     * transform is originally at this point, so the cup and liner effectively rotate around it.
     * Also, when the stem-group is retracted it is moved so its position, which corresponds to
     * the centre-of-rotation of the head, matches this point.
     *
     * This is obtained as the 'head-centre' of the fitted liner on the API. Note that the fitted
     * head on the API is in it's 'native' position: relative to the stem which is fitted to the
     * native position of the femur. This means we can't use the initial transform of the head
     * component to find the fitted hip-joint-centre.
     */
    fittedHjc: Vector3;

    /** The normal or **depth** direction of cup offset */
    normal: Vector3;

    /** The inferior direction of cup offset */
    siVector: Vector3;

    /** The posterior direction of cup offset */
    apVector: Vector3;

    /** The original fitted cup-transform, in CT/world space */
    cupTransform: Matrix4;

    /** The original fitted cup-transform, in CT/world space */
    linerTransform: Matrix4;
};

export function computeFittedCup(state: PlannerState): FittedCup | null {
    try {
        if (!state.fittedComponents || !state.catalog || !state.template || !state.case) {
            return null;
        }
        const apiCup = findFittedCup(
            state.fittedComponents,
            state.catalog,
            state.template.cupUrl,
            computeLinerUrl(state),
            computeBearingUrl(state),
        );
        const globalCs = apiCup.global_cs;

        return {
            basis: {
                left:
                    state.case.operationalSide === 'left'
                        ? vector3(globalCs.ml.vector)
                        : vector3(globalCs.ml.vector).negate(),
                posterior: vector3(globalCs.ap.vector),
                superior: vector3(globalCs.si.vector).negate(),
                position: vector3(globalCs.origin),
                lateral: vector3(globalCs.ml.vector),
                inferior: vector3(globalCs.si.vector),
            },
            nativeHjc: vector3(globalCs.origin),
            fittedHjc: vector3(apiCup.liner.head_centre),
            normal: vector3(apiCup.normal),
            siVector: vector3(apiCup.si_vector),
            apVector: vector3(apiCup.ap_vector),
            cupTransform: matrixFromApi(apiCup.tmatrix),
            linerTransform: matrixFromApi(apiCup.liner.tmatrix),
        };
    } catch (error) {
        useAppErrorStore().handleError(error);
        return null;
    }
}

/**
 * Get the offset of the cup in world-space from api values
 */
export function cupWorldOffsetFromApi(
    fittedCup: FittedCup,
    templateOffset: AnatomicalOffset,
): Vector3 {
    return addVectors(
        multiplyScalar(fittedCup.basis.lateral, templateOffset.ml),
        multiplyScalar(fittedCup.basis.posterior, templateOffset.ap),
        multiplyScalar(fittedCup.basis.inferior, templateOffset.si),
    );
}

/**
 * Get the offset of the cup as used in the UI from api values
 */
export function cupOffsetFromApi(
    fittedCup: FittedCup,
    templateOffset: AnatomicalOffset,
): CupOffset {
    const worldOffset = cupWorldOffsetFromApi(fittedCup, templateOffset);
    return {
        depth: -worldOffset.dot(vector3(fittedCup.normal)),
        ap: worldOffset.dot(vector3(fittedCup.apVector)),
        si: worldOffset.dot(vector3(fittedCup.siVector)),
    };
}

export function cupWorldOffsetFromUI(fittedCup: FittedCup, uiOffset: CupOffset): Vector3 {
    return addVectors(
        multiplyScalar(fittedCup.normal, -uiOffset.depth),
        multiplyScalar(fittedCup.apVector, uiOffset.ap),
        multiplyScalar(fittedCup.siVector, uiOffset.si),
    );
}

export function cupOffsetFromUI(fittedCup: FittedCup, uiOffset: CupOffset): AnatomicalOffset {
    const worldOffset = cupWorldOffsetFromUI(fittedCup, uiOffset);
    return {
        ml: round(worldOffset.dot(fittedCup.basis.lateral), 2),
        ap: round(worldOffset.dot(fittedCup.basis.posterior), 2),
        si: round(worldOffset.dot(fittedCup.basis.inferior), 2),
    };
}
