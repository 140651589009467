import { meshNode, type MeshNode, updateMesh } from '@/planner/3d/mesh';
import type { PlannerState } from '@/planner/plannerState';
import { stopAll, type StopHandle } from '@/util';
import { type LoadGeometry, updateGeometryFromSource } from '@/planner/3d/meshGeometry';
import { plannerMaterialId } from '@/planner/scene/plannerMaterial';
import { type PlannerMeshId, type PlannerObjectId } from '@/planner/scene/plannerObjectId';
import { renderOrder } from '@/planner/scene/renderOrder';
import type { PlannerSceneContext } from '@/planner/scene/plannerSceneContext';
import { watchEffect } from 'vue';
import { isMeshVisible } from '@/planner/scene/meshVisibility';

/** The common state representing a mesh in the scene */
export type PlannerMeshNode = MeshNode & {
    readonly plannerId: PlannerMeshId;
    showWireframe: boolean;
};

export function plannerMeshNode(
    meshID: PlannerMeshId,
    properties?: Partial<PlannerMeshNode>,
): PlannerMeshNode {
    return {
        plannerId: meshID,
        showWireframe: properties?.showWireframe ?? false,
        ...meshNode(meshID, {
            name: meshID,
            ...properties,
            renderOrder: renderOrder[meshID],
        }),
    };
}

const _SINGLE_SIDED_MESHES: PlannerObjectId[] = ['cup-collision-surface', 'cup-coverage'] as const;

export function updatePlannerMesh(
    context: PlannerSceneContext,
    state: PlannerState,
    node: PlannerMeshNode,
    loadGeometry: LoadGeometry,
): StopHandle {

    return stopAll(
        updateMesh(context, node),
        watchEffect(() => node.visible = isMeshVisible(state.visibility, node.plannerId)),
        watchEffect(() => {
            switch (state.renderingMode) {
                case 'normal':
                    if (_SINGLE_SIDED_MESHES.includes(node.plannerId)) {
                        node.material = plannerMaterialId(node.plannerId, 'normal');
                    }
                    node.material = {
                        front: plannerMaterialId(node.plannerId, 'normal', 'frontside'),
                        back: plannerMaterialId(node.plannerId, 'normal', 'backside')
                    }
                    break;
                case 'xray':
                    node.material = plannerMaterialId(node.plannerId, 'xray');
                    break;
                default:
                    throw new Error(`Unknown rendering-mode ${state.renderingMode}`);
            }
        }),
        // Geometry
        updateGeometryFromSource(node, loadGeometry),
    );
}
