import dicomParser, { type DataSet } from 'dicom-parser';
import { data } from 'dcmjs';
import { type FileTypeResult } from 'file-type';
import { fromBlob } from 'file-type/browser';
import assert from 'assert';
import { groupBy } from 'ramda';
import { cleanTags } from '@/lib/dicom/anonymizer';
import Error2 from '@/lib/dicom/error';
import {
    type DicomGroups,
    type DicomMessage,
    DicomMessageLevel,
    type DicomSeries,
} from '@/lib/dicom/DicomSeries';
import { DicomSeriesUtil } from '@/lib/dicom/DicomSeriesUtil';
import ContentType from '@/lib/mimetype';
import { OriginalAttributesSequenceTag } from '@/lib/dicom/DicomTags';
import { type DicomPatientName } from '@/lib/dicom/types';
import type { DicomInfo } from '@/lib/dicom/DicomInfo';
import { logger } from '@/util';

const log = logger('DicomUtils');

/**
 * DICOM (Digital Imaging and Communications In Medicine) utilities.
 *
 * see
 *  - https://stackoverflow.com/questions/28585800/anonymize-dicom-files-with-javascript
 *  - https://github.com/dcmjs-org/dcmjs
 *  - https://github.com/pieper/sites/tree/gh-pages/dcmio
 *
 *  This example:
 *    http://pieper.github.io/sites/dcmio/test.html
 * has source here
 *  - https://github.com/pieper/sites/blob/gh-pages/dcmio/index.html
 */
export default class DicomUtils {
    /**
     * Group the dicom files by using the patient identity function
     * @see {@link DicomSeriesUtil.patientIdentityKey}
     */
    public static async groupByIdentity(infos: DicomInfo[]): Promise<Record<string, DicomInfo[]>> {
        return groupBy<DicomInfo>(DicomSeriesUtil.patientIdentityKey, infos);
    }

    public static async makeValidatedDicomSeries(infos: DicomInfo[]): Promise<DicomGroups> {
        return Promise.all(infos)
            .then((dicomInfos) => {
                let patientNameAccumulator: DicomInfo | undefined;
                dicomInfos.forEach((dicomInfo: DicomInfo) => {
                    DicomSeriesUtil.excludeIfPatientIdentityRemoved(dicomInfo);
                    patientNameAccumulator = DicomSeriesUtil.excludeIfPatientNamesAreNotTheSame(
                        dicomInfo,
                        patientNameAccumulator,
                    );
                    patientNameAccumulator = DicomSeriesUtil.excludeIfPatientSexIsNotTheSame(
                        dicomInfo,
                        patientNameAccumulator,
                    );
                    patientNameAccumulator =
                        DicomSeriesUtil.excludeIfPatientDateOfBirthIsNotTheSame(
                            dicomInfo,
                            patientNameAccumulator,
                        );
                });
                return dicomInfos;
            })
            .then((infos) => {
                const series = DicomUtils.groupSeries(infos);

                log.info('Found %s series from %d files', Object.keys(series).length, infos.length);
                for (const [id, s] of Object.entries(series)) {
                    if (!s.isExcluded) {
                        log.info('Series %s has %d files', id, s.items.length);
                        DicomSeriesUtil.eachNonExcludedDicomInfo(
                            s,
                            DicomSeriesUtil.checkExcludeDicomdir,
                        );
                        DicomSeriesUtil.eachNonExcludedDicomInfo(
                            s,
                            DicomSeriesUtil.checkModalityIsCt,
                        );
                        DicomSeriesUtil.eachNonExcludedDicomInfo(
                            s,
                            DicomSeriesUtil.checkForScoutImages,
                        );
                        DicomSeriesUtil.eachNonExcludedDicomInfo(
                            s,
                            DicomSeriesUtil.checkForVolumeRenderingTechnique,
                        );

                        DicomSeriesUtil.checkForMultipleImages(s); // TODO: implement
                        DicomSeriesUtil.checkImageSizes(s);
                        DicomSeriesUtil.checkCompressedImageFormat(s);
                        DicomSeriesUtil.checkFilesHaveInstanceNumber(s);
                        DicomSeriesUtil.checkFor512x512Image(s);
                        DicomSeriesUtil.checkPixelSpacing(s);
                        DicomSeriesUtil.checkImageOrientationPatient(s);
                        DicomSeriesUtil.checkSliceThickness(s);
                        DicomSeriesUtil.eachNonExcludedDicomInfo(
                            s,
                            DicomSeriesUtil.checkImagePositionPatient,
                        );
                        DicomSeriesUtil.eachNonExcludedDicomInfo(
                            s,
                            DicomSeriesUtil.checkForBurnedInAnnotation,
                        );
                        DicomSeriesUtil.eachNonExcludedDicomInfo(
                            s,
                            DicomSeriesUtil.checkHasPhotometricInterpretation,
                        );

                        DicomSeriesUtil.sortSeriesByImagePositionPatient(s);
                        DicomSeriesUtil.checkDistanceBetweenConsecutiveSlices(s);
                        // TODO: thickness check

                        DicomSeriesUtil.checkHasSufficientFiles(s);
                        DicomSeriesUtil.checkSliceSpacing(s);
                    } else {
                        log.info('Series %s already excluded', id);
                    }
                }
                return series;
            });
    }

    /**
     * Group the DICOM information by 'Series Instance UID' (00020,000e).
     *
     * Note: files that have been excluded are placed into a group with
     * the magic name 'NoGroup'. This group/series is automatically marked
     * as being excluded so that it can not become a candidate for selection as
     * the active series.
     */
    public static groupSeries(infos: DicomInfo[]): DicomGroups {
        return _groupBy<DicomInfo, DicomSeries>(
            infos,
            (i) => {
                return i.isExcluded || !i.seriesInstanceUid ? 'NoGroup' : i.seriesInstanceUid;
            },
            (key: string | number) => {
                log.info('New group', key);

                if (key !== 'NoGroup') {
                    // Create a standard empty group (with a valid UID)
                    return {
                        isExcluded: false,
                        messages: [],
                        items: [],
                    };
                } else {
                    // Create the 'magic' group of files that are excluded from the results.
                    const info = {
                        isExcluded: true,
                        messages: [],
                        items: [],
                    };
                    DicomSeriesUtil.appendMessage(
                        info.messages,
                        DicomMessageLevel.Info,
                        `Series is being ignored`,
                    );
                    return info;
                }
            },
            (g, i) => {
                g.items.push(i);
            },
        );
    }

    /**
     * Read the file 'magic' to determine the content type.
     *
     * @see {@link https://www.npmjs.com/package/file-type}
     * @see {@link https://github.com/sindresorhus/file-type}
     *
     */
    public static async readFileType(f: File): Promise<FileTypeResult> {
        if (f) {
            const result = await fromBlob(f);
            if (result) {
                return result;
            }
            throw new Error(`Failed to determine file type (${f.name})`);
        }
        throw new Error(`Failed to determine file type (no filename)`);
    }

    /**
     *  Use the 'dicomParser' library to parse a DICOM file.
     *
     *  This will read the DICOM file into memory so that the records can be evaluated.
     */
    public static parseFile(f: File): Promise<DataSet> {
        return new Promise<DataSet>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (ev: ProgressEvent<FileReader>) => {
                if (ev.target) {
                    const arrayBuffer = ev.target.result;
                    const dataSet = this.parseDicom(arrayBuffer);
                    if (dataSet) {
                        resolve(dataSet);
                        this.dumpDataSet(dataSet);
                    }
                }
                reject(new Error('Failed to parse DICOM file'));
            };
            log.debug("Read '%s'", f.name);
            reader.readAsArrayBuffer(f);
        });
    }

    private static parseDicom(
        arrayBuffer: Uint8Array | ArrayBuffer | string | null,
    ): DataSet | undefined {
        if (arrayBuffer instanceof Uint8Array) {
            return dicomParser.parseDicom(arrayBuffer as Uint8Array);
        } else if (arrayBuffer instanceof ArrayBuffer) {
            return dicomParser.parseDicom(new Uint8Array(arrayBuffer as ArrayBuffer));
        } else {
            log.error('Dicom file must be an ArrayBuffer (or Uint8Array)');
        }
    }

    private static dumpDataSet(dataSet: DataSet): void {
        log.info(
            'Dataset: %d elements, %d warnings',
            Object.keys(dataSet.elements).length,
            dataSet.warnings.length,
        );

        if (dataSet.warnings) {
            dataSet.warnings.forEach((w) => {
                log.warn('data set: %s', w);
            });
        }
    }

    /**
     * The DICOM support in javascript seems to be fragmented and what is available
     * is poorly documented (if documented at all) and incomplete. I large portion of the
     * code in the wild deals with the UI display of DICOM files - we are only want to deal
     * with them as data files (for segmentation and to extract the CT image). Originally
     * we wanted to load the images with the 'DicomParser' package, however although this
     * offers great support for loading, it offers no support for mutating and serialising
     * the image (as it uses a readonly fixed byte buffer to store the entire file)
     *
     * Reading and writing a DICOM file should be a reasonably straightforward exercise. A
     * DICOM file is a well known binary file.
     *
     * This code is a cobbled together bits of code starting from the comment here
     * {@link https://github.com/cornerstonejs/dicomParser/issues/53} by Steve Pieper. He
     * seems to have taken the work from Wie Wei Wu and put it into the dcmjs code base.
     * This code parses the file into a series of objects that are mutable.
     *
     * Given a dataset, write it to a {@link Blob}. This uses the dcmjs library to perform
     * the serialisation of the data.
     *
     * This @link http://pieper.github.io/sites/dcmio/test.html} test example is currently
     * ahead of the dcmjs code base (v0.6.2)
     *
     * @see {@link https://github.com/dcmjs-org/dcmjs/blob/master/src/datasetToBlob.js}
     */
    public static makeAnonymousDicomFile(f: File): Promise<File> {
        return DicomUtils.readDicom(f)
            .then((dicomData) => DicomUtils.anonymiseDicomData(dicomData))
            .then((dicomData) => DicomUtils.removeKnownBadDicomData(dicomData))
            .then((dicomData) => DicomUtils.serialiseToBlob(dicomData, f.name))
            .then((blob) => new File([blob], f.name, { type: ContentType.Dicom }));
    }

    /**
     * Read a DICOM file using dcmjs library.
     *
     * @see {@link http://pieper.github.io/sites/dcmio/test.html}
     */
    public static readDicom(f: File): Promise<data.DicomDict> {
        return new Promise<data.DicomDict>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                if (e.target) {
                    try {
                        const dicomData = data.DicomMessage.readFile(
                            e.target.result as ArrayBuffer,
                        );
                        resolve(dicomData);
                    } catch (e: unknown) {
                        assert.ok(e instanceof Error);
                        reject(new Error(`Error reading DICOM file '${f.name}': ${e.message}`));
                    }
                } else {
                    reject(new Error('Unexpected event target of DICOM load'));
                }
            };
            reader.readAsArrayBuffer(f);
        });
    }

    /**
     * Use the ported copy of the anonymise function to anonymise a DICOM file that
     * has been read using the dcmjs library.
     *
     * Note: this function will mutate the dictionary passed to it (i.e. it will not make
     * a new copy).
     */
    private static anonymiseDicomData(dicomData: data.DicomDict): data.DicomDict {
        const dataDict = dicomData.dict;
        cleanTags(dataDict);
        // PatientIdentityRemoved
        dicomData.upsertTag('00120062', 'CS', ['YES']); // CS - stands for Code String (e.g: Yes or No values)
        // Anonymisation method
        dicomData.upsertTag('00120063', 'LO', ['Powered by Formus Labs']);
        return dicomData;
    }

    /**
     * This filter will modify the DICOM data dictionary and remove attributes that are
     * known to be a problem. The issues are known deveiations from the DICOM standard
     *
     * The DCMJS DICOM serialiser is reasonably rigid in enforcing that the fields are valid.
     *
     * The known problems are:
     *
     *   1. Date fields (vr=== 'DA') where the string is not of the form 'YYYMMDD'
     *
     *     What we have seen is that equipment is putting dates in ISO-8601 style datetime strings. As
     *     an example "11/27/2019 12:47:52 AM". These strings have no time zone information.
     *
     *     Note: Instead of removing these fields, where they are well formed ISO-8601 datetime
     *     strings, the code could convert them to a simple date.
     */
    private static removeKnownBadDicomData(dicomData: data.DicomDict): data.DicomDict {
        const dataDict = dicomData.dict;

        for (const [tag, v] of Object.entries<data.DicomDictValue>(dataDict)) {
            if (v.vr === 'DA') {
                const value = v.Value;
                if (Array.isArray(value)) {
                    if (
                        !value.every((item) => {
                            return typeof item === 'string' && item.length === 8;
                        })
                    ) {
                        log.debug('remove DA tag %s, value %o', tag, value);
                        delete dataDict[tag];
                    }
                } else if (typeof value === 'string' && value.length !== 8) {
                    log.debug('remove DA tag %s, value %o', tag, value);
                    delete dataDict[tag];
                } else {
                    log.info('DA tag %s ignored, value %o', tag, value);
                }
            } else if (tag === OriginalAttributesSequenceTag && v.vr === 'SQ') {
                log.debug('OriginalAttributesSequence removed from DICOM');
                delete dataDict[tag];
            } // else not a 'DA' (date) type so ignore it.
        }
        return dicomData;
    }

    /**
     * Serialise a DICOM file read with the dcmjs library to a Blob.
     */
    private static serialiseToBlob(dicomData: data.DicomDict, filename: string): Blob {
        try {
            return new Blob([dicomData.write()], { type: ContentType.Dicom });
        } catch (e: unknown) {
            assert.ok(e instanceof Error);
            throw new Error2(`Error writing DICOM file '${filename}': ${e.message}`, e);
        }
    }

    public static formatPatientName(pn: DicomPatientName): string {
        if (pn) {
            const s: string[] = [];
            if (pn.prefix) {
                s.push(pn.prefix);
            }
            if (pn.givenName) {
                s.push(pn.givenName);
            }
            if (pn.middleName) {
                s.push(pn.middleName);
            }
            if (pn.familyName) {
                s.push(pn.familyName);
            }
            if (pn.suffix) {
                s.push(pn.suffix);
            }
            return s.join(' ');
        }
        return '';
    }

    public static isSeriesOkForAutoUpload(series: DicomSeries): boolean {
        function isWarning(m: DicomMessage): boolean {
            return m.level <= DicomMessageLevel.Warning;
        }

        // Check there are no series warnings
        if (!series.messages.some((m) => isWarning(m))) {
            // check there are no file warnings
            if (
                !series.items
                    .filter((item) => !item.isExcluded)
                    .some((file) => file.messages.some((m) => isWarning(m)))
            ) {
                return true;
            } else {
                log.debug('Some files have warnings');
            }
        } else {
            log.debug('Series has warnings');
        }
        return false;
    }
}

/**
 * A typescript group-by function implemented using reduce().
 *
 * @param items the array (list) of items to group
 * @param selector the functor to select (or calculate) the grouping key.
 * @param groupFactory an optional factory to create a group (defaults to an array of items)
 * @param itemAppender an optional functor to add items to the group (default to array.push())
 */
function _groupBy<T, G>(
    items: T[],
    selector: (item: T) => string | number,
    groupFactory: (key: string | number) => G,
    itemAppender: (group: G, item: T) => void,
): { [key: string]: G } {
    const initial: { [key: string]: G } = {};

    return items.reduce((groups, value) => {
        // calculate/get the key (this must be string like)
        const key = selector(value);
        // get or create the group (warning assignment within expression)
        const group: G = groups[key] || (groups[key] = groupFactory(key));
        // add the item to the group
        itemAppender(group, value);
        return groups;
    }, initial);
}
