const planStates = ['new', 'processing', 'completed', 'error'] as const;
export type PlanState = (typeof planStates)[number];

export type PlanStateUri = `https://schema.formuslabs.com/state/plan/${PlanState}`;

const planStateRegex: RegExp = /https:\/\/schema\.formuslabs\.com\/state\/plan\/(.*)/;

export function planState(uri: string): PlanState {
    const match = uri.match(planStateRegex);
    if (match) {
        const result = match[1];
        if (isPlanState(result)) {
            return result;
        }
    }
    throw Error(`Invalid plan-state string: ${uri}`);
}

export function planStateUri(value: PlanState): PlanStateUri {
    return `https://schema.formuslabs.com/state/plan/${value}`;
}

export function isPlanState(value: string): value is PlanState {
    return planStates.includes(value as PlanState);
}
