<template>
    <v-dialog width="600" v-model="isVisible">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn
                v-bind="activatorProps"
                color="planner-blue-button"
                text="from DICOM files"
                variant="flat"
                class="mx-2 text-lowercase rounded-0"
            ></v-btn>
        </template>
        <v-card>
            <app-notification
                v-if="showNotification"
                variant="info"
                :animate-to="true"
                title="Study duplicated successfully"
            />

            <app-modal-title class="headline"> Duplicate Study</app-modal-title>

            <app-modal-content>
                Create a new study using the existing DICOM files.
                <br />
            </app-modal-content>
            <app-modal-content>
                <v-checkbox dense :disabled="isWorking" v-model="sendEmail" label="Send email" />
                <v-checkbox
                    dense
                    :disabled="isWorking"
                    v-model="sendNotifications"
                    label="Send notifications"
                />
            </app-modal-content>
            <app-modal-actions>
                <v-spacer />
                <app-button color="planner-grey-light" @click="isVisible = false" class="mx-4">
                    Close
                </app-button>
                <app-button color="planner-blue-button" @click="onCreate" :loading="isWorking">
                    Create
                </app-button>
            </app-modal-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import { ref, watch } from 'vue';
    import { useAdminCaseStore } from '@/stores/admin/store';
    import type { ApiStudy } from '@/admin/api/study';

    const props = defineProps<{ study: ApiStudy }>();

    const isWorking = ref(false);
    const isVisible = ref(false);

    const sendEmail = ref(false);
    const sendNotifications = ref(false);

    const showNotification = ref(false);

    const store = useAdminCaseStore();

    watch(
        () => isVisible.value,
        (value) => {
            if (!value) {
                sendEmail.value = false;
                sendNotifications.value = false;
                showNotification.value = false;
            }
        },
    );

    const onCreate = async () => {
        try {
            isWorking.value = true;
            await store.duplicateCase(props.study, {
                send_email: sendEmail.value,
                create_notifications: sendNotifications.value,
            });

            showNotification.value = true;

            await store.load();
        } finally {
            isWorking.value = false;
        }
    };
</script>

<style scoped></style>
