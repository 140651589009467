import { type ApiLinks, getLink } from '@/api/links';
import type { Url } from '@/formus/types';
import type { ApiFittedStem } from '@/api/fittedComponents/fittedStem';
import type { ApiFittedCup } from '@/api/fittedComponents/fittedCup';
import type { ApiFittedHead } from '@/api/fittedComponents/fittedHead';
import type { ApiFittedLiner } from '@/api/fittedComponents/fittedLiner';
import type { ApiFittedBearing } from '@/api/fittedComponents/fittedBearing';
import { client } from '@/api/http';
import { type AxiosRequestConfig, HttpStatusCode } from 'axios';
import { errorDetail } from '@/planner/api/errorDetail';

export type ApiFittedComponents = {
    cups: Map<Url, ApiFittedCup>;
    stems: Map<Url, ApiFittedStem>;
};

/**
 * Fetch the API-representation of fitted-components from the given url.
 *
 * The fitted-components are at the 'components' link on the surgical template.
 */
export async function getFittedComponents(
    url: Url,
    config?: AxiosRequestConfig,
): Promise<ApiFittedComponents> {
    const { status, data } = await client.get<_ApiFittedComponents>(url, config);
    if (status === HttpStatusCode.Ok) {
        return {
            cups: new Map(data.cups.map(_fittedCup)),
            stems: new Map(data.stems.map(_fittedStem)),
        };
    } else {
        throw Error(`Failed to get fitted components from '${url}'` + errorDetail(data));
    }
}

/** Fitted components on the API have a 'component' link in their links rather than a catalogUrl property */
type _OmitCatalogUrl<T> = Omit<T, 'catalogUrl'>;
type _ApiStem = _OmitCatalogUrl<ApiFittedStem> & { head: _OmitCatalogUrl<ApiFittedHead> };
type _ApiCup = _OmitCatalogUrl<ApiFittedCup> & { liner: _OmitCatalogUrl<ApiFittedLiner> } & {
    bearing: _OmitCatalogUrl<ApiFittedBearing>;
};

/** This is approximately the unconverted type we get back from the API */
type _ApiFittedComponents = ApiLinks & {
    stems: _ApiStem[];
    cups: _ApiCup[];
};

function _fittedCup(apiCup: _ApiCup): [Url, ApiFittedCup] {
    const catalogUrl = getLink(apiCup, 'component');
    const convertedCup: ApiFittedCup = {
        ...apiCup,
        catalogUrl,
        liner: {
            ...apiCup.liner,
            catalogUrl: getLink(apiCup.liner, 'component'),
        },
        bearing: apiCup.bearing
            ? {
                  ...apiCup.bearing,
                  catalogUrl: getLink(apiCup.bearing, 'component'),
              }
            : null,
    };
    return [catalogUrl, convertedCup];
}

function _fittedStem(apiStem: _ApiStem): [Url, ApiFittedStem] {
    const catalogUrl = getLink(apiStem, 'component');
    const convertedStem: ApiFittedStem = {
        ...apiStem,
        catalogUrl,
        head: {
            ...apiStem.head,
            catalogUrl: getLink(apiStem.head, 'component'),
        },
    };
    return [catalogUrl, convertedStem];
}
