<template>
    <v-menu
        max-height="500"
        max-width="500"
        content-class="notifications-menu"
        transition="fade-transition"
        :open-on-hover="true"
        location="bottom"
        offset="30"
        open-delay="0"
        close-delay="0"
    >
        <template v-slot:activator="{ props }">
            <v-badge
                v-if="isReady && amountOfNewMessages"
                :content="amountOfNewMessages"
                color="planner-green-rich"
            >
                <app-menu-item
                    class="cursor-pointer"
                    icon="mdi-bell"
                    text="Case Notifications"
                    @focus="props.onFocus"
                    @blur="props.onBlur"
                    @mouseenter="
                        (...args: any[]) => {
                            // @ts-ignore is needed because the type of the event is not being inferred correctly
                            props.onMouseenter(...args);
                            markNotificationsAsRead();
                        }
                    "
                    @mouseleave="props.onMouseleave"
                />
            </v-badge>
            <app-menu-item
                v-else
                class="cursor-pointer"
                icon="mdi-bell"
                text="Case Notifications"
                @focus="props.onFocus"
                @blur="props.onBlur"
                @mouseenter="props.onMouseenter"
                @mouseleave="props.onMouseleave"
            />
        </template>

        <v-card dense min-width="400" color="planner-blue-rich" class="notifications-card">
            <v-list dense color="planner-rich-blue" class="pa-5 overflow-y-auto">
                <v-list-item
                    v-if="store.notifications.length < 1"
                    dense
                    class="no-notification-container"
                >
                    No notifications yet
                </v-list-item>
                <NotificationListItem
                    v-else
                    :value="item"
                    v-for="(item, index) in notifications"
                    :key="index"
                />
            </v-list>
        </v-card>
    </v-menu>
</template>

<script setup lang="ts">
    import { computed, ref, watch } from 'vue';
    import { useCaseSyncStore } from '@/stores/navigation/useCaseSyncStore';
    import NotificationListItem from '@/components/header/notifications/CaseNotificationListItem.vue';

    const store = useCaseSyncStore();

    /** The number of new messages that have not been read yet. */
    const amountOfReadMessages = ref(0);

    const markNotificationsAsRead = () => {
        amountOfReadMessages.value = store.notifications.length;
    };

    const isReady = computed(() => {
        return store.case_ !== null;
    });

    /**
     * There is not logic to keep track of this value in the browser session yet.
     * e.g.: in the browser's local storage.
     *
     * So, all notifications are mark as read on load. Only the new notifications (while the component is mounted)
     * will appear as unread.
     */
    watch(isReady, (newValue) => {
        if (newValue) {
            markNotificationsAsRead();
        }
    });

    /**
     * The number of new messages that have not been read yet.
     * These are the messages that have been added to the store
     * since the last time the user opened the notifications menu.
     */
    const amountOfNewMessages = computed(() => {
        return store.notifications.length - amountOfReadMessages.value;
    });

    const notifications = computed(() => {
        return [...store.notifications].sort((a, b) => {
            const dateA = new Date(a.timestamp);
            const dateB = new Date(b.timestamp);

            // Sort in descending order (most recent first)
            return dateB.getTime() - dateA.getTime();
        });
    });
</script>

<style scoped lang="scss">
    .notifications-menu {
        .v-list {
            background: var(--v-planner-blue-rich);
            color: var(--v-planner-grey-lighten-1);
        }
    }
</style>
