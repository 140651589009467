import { AxesHelper, Matrix4, type Object3D } from 'three';
import { stopAll, type StopHandle, taggedLogger } from '@/util';
import { watchImmediate } from '@vueuse/core';
import { watchEffect } from 'vue';
import { formatNodeId, type NodeId } from '@/planner/3d/nodeId';
import type { SceneContext } from '@/planner/3d/SceneContext';

const log = taggedLogger('3D');

/**
 * The base type of all nodes, approximately corresponding to an
 * [Object3D]{@link https://threejs.org/docs/#api/en/core/Object3D}
 */
export type ObjectNode<T extends string = string> = {
    readonly type: T;
    readonly id: NodeId;
    parent: NodeId | null;
    name: string | null;
    transform: Matrix4;
    visible: boolean;
    showAxes: boolean;
    selected: boolean;
};

/** Properties that can be used to initialize an object-node */
export type ObjectProperties<Node extends ObjectNode> = Partial<Omit<Node, 'type' | 'id'>>;

export function objectNode<T extends string>(
    type: T,
    id: NodeId,
    properties?: ObjectProperties<ObjectNode>,
): ObjectNode<T> {
    return {
        type,
        id,
        parent: properties?.parent ?? null,
        transform: properties?.transform ?? new Matrix4(),
        name: properties?.name ?? null,
        visible: properties?.visible ?? true,
        showAxes: properties?.showAxes ?? false,
        selected: properties?.selected ?? false,
    };
}

export function formatNode(node: ObjectNode): string {
    return (
        `${node.type}-node ${formatNodeId(node.id)}` +
        (node.name && node.name != node.id ? ` '${node.name}'` : '')
    );
}

export function updateObject<Node extends ObjectNode>(
    context: SceneContext<Node>,
    node: Node,
    object: Object3D,
): StopHandle {
    return stopAll(
        _updateObjectInScene(context, node, object),
        _updateObjectTransform(node, object),
        _updateObjectParent(context, node, object),
        _updateObjectName(node, object),
        _updateObjectVisibility(node, object),
        _updateObjectAxes(node, object),
    );
}

function _updateObjectInScene<Node extends ObjectNode>(
    context: SceneContext<Node>,
    node: Node,
    object: Object3D,
): StopHandle {
    context.addNode(node, object);
    return () => context.removeNode(node.id);
}

function _updateObjectTransform(node: ObjectNode, object: Object3D): StopHandle {
    object.matrixAutoUpdate = false;
    return watchImmediate(
        () => node.transform,
        (value) => object.matrix.copy(value),
        { deep: true },
    );
}

function _updateObjectParent(
    context: SceneContext,
    node: ObjectNode,
    object: Object3D,
): StopHandle {
    return stopAll(
        watchImmediate(
            () => node.parent,
            (parent, oldParent) => {
                _logParentChange(node, parent, oldParent);
                // Deliberately *add* the object to the parent here rather than attaching.
                // This will keep the local-transformation as defined on the node
                context.getParentObject(node).add(object);
            },
        ),
        () => context.getParentObject(node).remove(object),
    );
}

function _logParentChange(
    node: ObjectNode,
    parent: NodeId | null,
    oldParent: NodeId | null | undefined,
): void {
    if (!log.enabledFor('debug')) {
        return;
    }
    const newParentId = parent ?? 'scene';
    if (oldParent === undefined) {
        log.debug(`Adding ${formatNode(node)} to parent ${formatNodeId(newParentId)}`);
        return;
    }

    const oldParentId = oldParent ?? 'scene';
    log.debug(`Swapping ${formatNode(node)} from parent ${formatNodeId(oldParentId)} to parent ${oldParent}`);
}

function _updateObjectName(node: ObjectNode, object: Object3D): StopHandle {
    return watchImmediate(
        () => node.name,
        (name) => (object.name = name ?? ''),
    );
}

const OBJECT_AXES_SIZE = 20;

function _updateObjectAxes(node: ObjectNode, object: Object3D): StopHandle {
    let axes: AxesHelper | null = null;
    return watchEffect(() => {
        if (node.showAxes) {
            if (axes === null) {
                axes = new AxesHelper(OBJECT_AXES_SIZE);
            }
            object.add(axes);
        } else {
            if (axes !== null) {
                object.remove(axes);
            }
        }
    });
}

function _updateObjectVisibility(node: ObjectNode, object: Object3D): StopHandle {
    return watchImmediate(
        () => node.visible,
        (value) => (object.visible = value),
    );
}
