<template>
    <div class="planner-overlay">
        <v-sheet class="side-panel-container">
            <planner-status class="planner-status" />

            <v-overlay
                contained
                scrim="white"
                opacity="0.5"
                v-model="disabled"
                :aria-disabled="true"
            />

            <planner-sync-error />

            <Form v-slot="{ meta }" ref="plannerForm">
                <v-expansion-panels
                    variant="accordion"
                    class="app-expansion-panels"
                    v-model="expandedPanel"
                >
                    <v-expansion-panel value="stem">
                        <expansion-panel-title
                            title="Stem"
                            :description="stemPanelDescription"
                            image="stem"
                            :stem-warning="!store.isLoading && !stemStore.recommendedStems.length"
                        />
                        <v-expansion-panel-text>
                            <stem-panel />
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel value="cup">
                        <expansion-panel-title
                            title="Cup"
                            :description="cupPanelDescription"
                            image="cup"
                        />
                        <v-expansion-panel-text>
                            <cup-panel />
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel value="combined">
                        <expansion-panel-title title="Combined" image="hip" />
                        <v-expansion-panel-text>
                            <combined-offsets />
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
                <app-divider />
                <approve-plan :valid="meta.valid" />
            </Form>
            <div v-if="developerSettings.showDeveloperTools">
                <app-divider />
                <v-expansion-panels class="app-expansion-panels">
                    <v-expansion-panel>
                        <expansion-panel-title title="Developer Tools" />
                        <v-expansion-panel-text>
                            <developer-tools />
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </v-sheet>

        <div class="top-middle-container">
            <visibility-toggle-bar />
        </div>

        <div class="top-right-container">
            <adjustments-measurements />
        </div>

        <div class="bottom-left-container">
            <span class="app-version">
                {{ versionStore.versionWithPrefix }}
            </span>
        </div>

        <div class="bottom-right-container">
            <snap-to-view />
            <catstack-controls />
        </div>

        <slot />
    </div>
</template>

<script setup lang="ts">
    import { computed, nextTick, ref, watch } from 'vue';
    import { usePlannerStore } from './plannerStore';
    import ExpansionPanelTitle from './components/ExpansionPanelTitle.vue';
    import StemPanel from '@/planner/components/StemPanel.vue';
    import CupPanel from './components/CupPanel.vue';
    import CombinedOffsets from './components/CombinedPanel.vue';
    import ApprovePlan from './components/ApprovePlan.vue';
    import CatstackControls from './components/CatstackControls.vue';
    import VisibilityToggleBar from '@/planner/components/VisibilityToggleBar.vue';
    import AdjustmentsMeasurements from '@/planner/components/AdjustmentsMeasurements.vue';
    import { assert } from '@/util';
    import { useCupOverlayStore } from '@/planner/cupOverlayStore';
    import { useStemOverlayStore } from '@/planner/stemOverlayStore';
    import PlannerStatus from '@/planner/components/PlannerStatus.vue';
    import DeveloperTools from '@/planner/components/developerTools/DeveloperTools.vue';
    import { useDeveloperSettings } from '@/planner/developerSettings';
    import SnapToView from '@/planner/components/SnapToView.vue';
    import { formatNumberSign } from '@/lib/format/formatNumberSign';
    import PlannerSyncError from '@/planner/components/PlannerSyncError.vue';
    import { useVersion } from '@/stores/version';
    import { Form } from 'vee-validate';

    const store = usePlannerStore();
    const cupStore = useCupOverlayStore();
    const stemStore = useStemOverlayStore();
    const versionStore = useVersion();
    const developerSettings = useDeveloperSettings();

    /**
     * True if the translucent white overlay should be shown, disabling input to the
     * main panel.
     *
     * Clicking on the overlay calls set(false), and logs a warning if the ref is not settable.
     * We do not want clicking to disable the overlay, so we make setting a no-op.
     */
    const disabled = computed({
        get: () => store.currentOperation !== null,
        set: () => {},
    });

    const expandedPanel = computed<'cup' | 'stem' | 'combined' | null>({
        get: () => {
            switch (store.plannerMode) {
                case 'default':
                case 'disabled':
                    return null;
                default:
                    return store.plannerMode;
            }
        },
        set: (value) => {
            assert(store.plannerMode !== 'disabled', 'planner-store is disabled');
            store.setMode(value ?? 'default');
        },
    });

    const stemPanelDescription = computed<string>(() => {
        if (!stemStore.info) {
            return '';
        }
        const { stemProfile, stemOffset, stemSize, headOffset } = stemStore.info;
        const shortStemProfile = stemProfile === 'Reduced Distal' ? 'Reduced' : stemProfile;
        return `${shortStemProfile} ${stemOffset} ${stemSize} / ${formatNumberSign(headOffset)} Head`;
    });

    const cupPanelDescription = computed<string>(() =>
        cupStore.selectedCupSize ? `Size ${cupStore.selectedCupSize}` : '',
    );

    // const plannerForm = ref<Form | null>(null);
    // // At the moment we only trigger the validation when the selected type changes
    // watch(
    //     () => stemStore.selectedType,
    //     async () => {
    //         if (plannerForm.value) {
    //             await plannerForm.value.validate();
    //         }
    //     },
    // );
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
    .planner-overlay {
        background: var(--planner-grey);
    }

    .side-panel-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 400px;
        max-height: 100%;
        z-index: 20;
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-gutter: stable both-edges;
    }

    .planner-status {
        position: absolute;
        right: 4px;
        top: 8px;
        z-index: 3000;
    }

    .app-expansion-panels :deep(.v-expansion-panel__shadow) {
        box-shadow: none;
    }

    .app-expansion-panels :deep(.v-expansion-panel-title__overlay) {
        opacity: 0;
    }

    .top-middle-container {
        position: absolute;
        top: 0;
        left: calc(50vw - 200px);
        right: calc(50vw - 200px);
        z-index: 10;
    }

    .top-right-container {
        position: absolute;
        top: 0;
        right: 0;
        margin: 20px;
        width: 400px;
        z-index: 10;
    }

    .bottom-left-container {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 20px;
        color: white;
        z-index: 10;
    }

    .bottom-right-container {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 20px;
        color: white;
        z-index: 10;
    }

    .app-version {
        font-size: 0.75rem;
        line-height: 1.25rem;
        font-weight: 500;
        color: var(--planner-grey);
    }
</style>
