import { defineStore } from 'pinia';
import { computed, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { verify } from '@/lib/verify';
import { createStudyFromFiles } from '@/api/case/request';
import { useVersion } from '@/stores/version';
import { type ApiCase, getCase } from '@/planner/api/case';
import { client } from '@/api/http';
import { type ApiStudy, getStudy } from '@/admin/api/study';

type StudyProcessOptionsRepresentation = {
    /**
     * Whether workflow should create 'notifications' for the user as to progress. This allows
     the study workflow code to be used for both interactive and non-interactive workflows (i.e.
     a study processing where we don't want to alert the user).
     */
    create_notifications: boolean;

    /**
     * Whether workflow should send a success or failure email to the surgeon/case creator. This allows
     * the study workflow code to be used for both interactive and non-interactive workflows (i.e.
     * a study processing where we don't want to alert the user).
     */
    send_email: boolean;
};

export type StudyCopyCreateDataRepresentation = {
    /**
     * Create a new study based on an exiting study.
     * The study must exist, and must be at a state that is consistent with copying data. i.e.
     *
     * Note: to make a new study using DICOM files, the study must be at 'CatStack' state
     */
    name: string;

    /** the URI of an existing study (that must be in the same project). */
    study: string;

    operation: 'files';

    /** An optional set of options to allow the caller to modify processing behaviour. */
    options?: StudyProcessOptionsRepresentation;

    web_component_version: string;
};

export type AdminState = {
    caseId: number | null;
    case: ApiCase | null;
    studies: ApiStudy[] | null;
};

export function adminState(): AdminState {
    return {
        caseId: null,
        case: null,
        studies: null,
    };
}

export const useAdminCaseStore = defineStore('admin-case', () => {
    const route = useRoute();
    // const caseStore = useCaseSyncStore();

    const state = reactive<AdminState>(adminState());

    const caseId = computed(() => {
        const caseIdParam = verify(route.params.id, 'case id is required');
        return Number(caseIdParam);
    });

    const load = async () => {
        const case_ = await getCase(caseId.value);
        const studiesCollection = await client.get(case_.studies);

        const studies = await Promise.all(
            studiesCollection.data.items.map(async (study: { id: string }) => {
                return await getStudy(study.id);
            }),
        );

        state.case = case_;
        state.caseId = caseId.value;
        state.studies = studies;
    };

    const duplicateCase = async (study: ApiStudy, data: StudyProcessOptionsRepresentation) => {
        const webComponentVersion = useVersion().webComponentVersion;
        await createStudyFromFiles(caseId.value, {
            name: study.name,
            study: study.self,
            operation: 'files',
            options: data,
            web_component_version: webComponentVersion,
        });
    };

    return {
        case: computed(() => state.case),
        studies: computed(() => state.studies),
        load,
        duplicateCase,
    };
});
