<template>
    <app-card size="large" class="app-plan-card-wrapper">
        <div class="app-plan-card-content">
            <app-form-section-title>{{ title }}</app-form-section-title>
            <app-form-title>
                {{ project.name }}
            </app-form-title>

            <ul class="app-plan-card-case-details">
                <PlanDetailItem label="Formus Case Number" :value="formattedCaseNumber" />
                <PlanDetailItem label="Plan generated" :value="formattedDate" />
                <PlanDetailItem label="Patient" :value="formattedPatientName" />
                <PlanDetailItem
                    :label="`${formattedSystem} ${formattedStemShape}`"
                    :value="formattedStemDescription"
                />
                <PlanDetailItem label="G7 CUP" :value="`Size ${formattedCupSize}`" />
                <PlanDetailItem label="Femoral Head" :value="formattedHeadDescription" />
                <PlanDetailItem label="G7 Liner" :value="formattedLinerDescription" />
                <PlanDetailItem label="Stem Anteversion" :value="formattedStemAnteversion" />
                <PlanDetailItem
                    label="Cup anteversion / inclination"
                    :value="`${formattedCupAnteversion} / ${formattedCupInclination}`"
                />
                <PlanDetailItem
                    label="Resection height (lt/gt/saddle)"
                    :value="formattedResectionDistances"
                />
            </ul>
        </div>

        <PlanActions :project="project" :plan="plan" />

        <v-divider v-if="showSpinopelvic" class="my-5" />
        <div v-if="showSpinopelvic">
            <PlanItemSpinopelvic :id="plan.id" :plan="plan" />
        </div>
    </app-card>
</template>

<script setup lang="ts">
    import AppCard from '@/components/AppCard.vue';
    import AppFormTitle from '@/components/forms/AppFormTitle.vue';
    import { formatLength } from '@/lib/format/formatLength';
    import { formatDate } from '@/lib/format/formatDate';
    import { PlanType } from '@/stores/plan/types';
    import { computed } from 'vue';
    import PlanDetailItem from '@/components/case-plan/PlanDetailItem.vue';
    import PlanActions from '@/components/case-plan/PlanActions.vue';
    import { DateTime } from 'luxon';
    import { formatAngle } from '@/lib/format/formatAngle';
    import { NameUtil } from '@/lib/NameUtil';
    import { formatStemLabel, formatStemProfile, formatStemSystem, stemTypeInfo } from '@/formus/catalog/stem';
    import {
        type AcetabularAngles,
        anatomicAngles,
        toRadiographic,
    } from '@/formus/anatomy/pelvis/acetabularAngles';
    import { toDegrees, toRadians } from '@/formus/anatomy/pelvis/anteversionInclination';
    import { catalogStem } from '@/api/catalog/catalogStem';
    import type { ApiPlan, ApiPlansCase } from '@/api/plan/types';
    import { spinopelvicFeature } from '@/lib/headMetaTags';
    import PlanItemSpinopelvic from '@/components/case-plan/PlanItemSpinopelvic.vue';
    import { useSpinopelvic } from '@/stores/spinopelvic/store';
    import { formatNumberSign } from '@/lib/format/formatNumberSign';

    export interface Props {
        project: ApiPlansCase;
        plan: ApiPlan;
    }

    const props = defineProps<Props>();

    const title = computed(() => {
        if (props.plan.type === PlanType.Manual) {
            return 'User Approved Plan';
        } else if (props.plan.type === PlanType.Automated) {
            return 'Formus Plan';
        } else {
            return '';
        }
    });

    const formattedDate = computed(() => {
        return formatDate(DateTime.fromISO(props.plan.created_on));
    });

    const formattedResectionDistances = computed(() => {
        const measurements = props.plan.measurements;
        if (measurements) {
            const greaterTrochanterDistance = measurements.resection_distances_greater_trochanter;
            const lesserTrochanterDistance = measurements.resection_distances_lesser_trochanter;
            const saddleDistance = measurements.resection_distances_saddle;

            const formatedLT = formatLength(lesserTrochanterDistance, 0);
            const formatedGT = formatLength(greaterTrochanterDistance, 0);
            const formatedSaddle = formatLength(saddleDistance, 0);

            return `${formatedLT} / ${formatedGT} / ${formatedSaddle}`;
        }

        return '--/--/--';
    });

    const formattedStemShape = computed((): string => {
        const stem = catalogStem(props.plan.template.stem);
        return stem ? `(${formatStemProfile(stemTypeInfo(stem.type).profile)})` : '';
    });

    const formattedSystem = computed((): string => {
        const stem = catalogStem(props.plan.template.stem);
        return stem ? formatStemSystem(stem.system) : '';
    });

    const formattedStemDescription = computed((): string => {
        const stem = catalogStem(props.plan.template.stem);
        return stem ? formatStemLabel(stem) : '';
    });

    const formattedStemAnteversion = computed((): string => {
        const measurements = props.plan.measurements;
        if (measurements) {
            return formatAngle(measurements.stem_angle_anteversion ?? null);
        }
        return '--';
    });

    const formattedCupSize = computed((): string => {
        return props.plan.template.cup?.size ?? '';
    });

    const formattedHeadDescription = computed((): string => {
        const head = props.plan.template.head;
        if (head) {
            const size = head.size;
            const offset = formatNumberSign(head.offset);

            if (size && offset) {
                return `${formatLength(parseInt(size), 0)} ${offset} Offset`;
            }
        }

        return '';
    });

    const formattedLinerDescription = computed((): string => {
        const liner = props.plan.template.liner;
        const size = liner?.size;
        const type = liner?.type;

        if (size && type) {
            return `${size} (${type})`;
        }

        return '';
    });

    const radiographicRotation = computed((): AcetabularAngles<'radiographic'> => {
        const anatomicAnglesRadians = anatomicAngles(toRadians(props.plan.template.cup_rotation));
        return toDegrees(toRadiographic(anatomicAnglesRadians));
    });

    const formattedCupAnteversion = computed((): string => {
        return formatAngle(radiographicRotation.value?.anteversion ?? null);
    });

    const formattedCupInclination = computed((): string => {
        return formatAngle(radiographicRotation.value?.inclination ?? null);
    });

    const formattedPatientName = computed((): string => {
        const patient = props.project.patient;

        if (patient) {
            return NameUtil.formatCaseApiPatient(patient);
        }

        return '';
    });

    const formattedCaseNumber = computed(() => {
        const id = props.project.id;
        return id ? String(id) : '';
    });

    const showSpinopelvic = computed(() => {
        return spinopelvicFeature() && useSpinopelvic().hasData;
    });
</script>

<style scoped>
    ul,
    li {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .app-plan-card-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .app-plan-card-content {
        display: flex;
        flex-basis: 0;
        flex-grow: 1;
        flex-direction: column;
    }

    .app-plan-card-case-details {
        display: flex;
        flex-wrap: wrap;
    }
</style>
