import type { SpinopelvicInputs } from '@/stores/spinopelvic/types';
import type { ApiObject } from '@/api/spinopelvic/types';

export function mapToApiObject(inputs: SpinopelvicInputs): ApiObject {
    return {
        standing_pelvic_tilt:
            inputs.standingPelvicTilt !== '' ? Number(inputs.standingPelvicTilt) : null,
        pelvic_femoral_angle:
            inputs.pelvicFemoralAngle !== '' ? Number(inputs.pelvicFemoralAngle) : null,
        lumbar_lordosis: inputs.lumbarLordosis !== '' ? Number(inputs.lumbarLordosis) : null,
        sacral_slope: inputs.sacralSlope !== '' ? Number(inputs.sacralSlope) : null,
        pelvic_tilt: inputs.pelvicTilt !== '' ? Number(inputs.pelvicTilt) : null,
    };
}

export function mapToState(api: ApiObject): SpinopelvicInputs {
    return {
        standingPelvicTilt: `${api.standing_pelvic_tilt ?? ''}`,
        pelvicFemoralAngle: `${api.pelvic_femoral_angle ?? ''}`,
        lumbarLordosis: `${api.lumbar_lordosis ?? ''}`,
        sacralSlope: `${api.sacral_slope ?? ''}`,
        pelvicTilt: `${api.pelvic_tilt ?? ''}`,
    };
}