<template>
  <v-menu
      :model-value="value"
      max-height="500"
      max-width="500"
      content-class="profile-menu"
      transition="fade-transition"
      :open-on-hover="true"
      location="bottom"
      offset="30"
      open-delay="0"
      close-delay="0"
  >
    <template v-slot:activator="{ props }">
      <app-menu-item
          class="cursor-pointer"
          icon="mdi-account"
          text="Profile"
          @focus="props.onFocus"
          @blur="props.onBlur"
          @mouseenter="props.onMouseenter"
          @mouseleave="props.onMouseleave"
      />
    </template>
    <v-card dense min-width="400" color="planner-blue-rich" class="notifications-card">
      <v-list dense color="planner-rich-blue" class="pa-5 overflow-y-auto">
        <v-list-item
            cy-data="header-dropdown-list-item"
            class="font-weight-bold text-planner-blue-button"
        >
          {{ username }}
        </v-list-item>
        <v-divider />
        <v-list-item
            :to="{ name: ROUTES.PREFERENCES }"
            tag="router-link"
            cy-data="header-dropdown-list-item"
        >
          Preferences
        </v-list-item>

        <v-divider />

        <v-list-item
            @click="auth.logout"
            :to="{ name: ROUTES.LOGIN }"
            tag="router-link"
            cy-data="header-dropdown-list-item"
            append-icon="mdi-logout"
        >
          Logout
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script setup lang="ts">

    import {taggedLogger} from '@/util';
    import {useRoute} from 'vue-router';
    import {ROUTES} from '@/router';
    import {useAuth} from '@/stores/auth';
    import {useUserStore} from "@/stores/user/store";
    import {NameUtil} from "@/lib/NameUtil";
    import {computed} from "vue";
    import { isTestEnvironment } from '@/util/env';

    const log = taggedLogger('profile-menu');
    const route = useRoute();
    const auth = useAuth();

    const userStore = useUserStore();
    const username = computed(() => NameUtil.format(userStore.user?.name))

    /**
     * This is a hack to get the integration test suite to work.
     * Ideally a component should not been modified with test behaviour but we could not find a way to
     * make testing-library work with this
     */
    const value = computed(() => (isTestEnvironment()));
</script>

<style scoped lang="scss">
.profile-menu {
  .v-list {
    background: var(--v-planner-blue-rich);
    color: var(--v-planner-grey-lighten-1);
  }
}
</style>
