/**
 * Implements software requirements: H1SR-68, H1SR-149
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-68/User-can-authenticate-with-their-email-as-a-username-and-their-chosen-password
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-149/Keep-user-passwords-secure
 */

import { defineStore } from 'pinia';
import { ref } from 'vue';
import { obtainTokens } from '@/api/authenticator';
import type { Credentials } from '@/api/authenticator';
import { isUserFriendlyErrorMessage } from '@/api/http';
import { cachedAuthTokens, clearCache } from '@/api/cache';

export enum LOGOUT_REASON {
    MANUAL = 'MANUAL',
    IDLE_TIMEOUT = 'IDLE_TIMEOUT',
}

export const useAuth = defineStore('auth', () => {
    const isLoggedIn = ref(false);
    const logoutReason = ref('');

    /**
     * Determine whether the user is already
     * logged in after refreshing the page.
     *
     * we just need to check for a refresh token
     * here as we can use that to reauthenticate if necessary.
     */
    cachedAuthTokens().then((result) => {
        isLoggedIn.value = !!result;
    });

    /**
     * Attempt to login the user with the credentials provided.
     *
     * @returns user friendly error message on failure
     * @example const [successful, message] = await auth.login(credentials)
     */
    const login = async (credentials: Credentials): Promise<[boolean, string]> => {
        const result = await obtainTokens(credentials);

        if (isUserFriendlyErrorMessage(result)) {
            return [false, result];
        } else {
            isLoggedIn.value = true;
            return [true, ''];
        }
    };

    /**
     * Logout the user.
     *
     * Clear auth tokens from response cache.
     *
     * @param reason info message shown on the login screen
     */
    const logout = async (reason = LOGOUT_REASON.MANUAL) => {
        logoutReason.value = reason;
        isLoggedIn.value = false;
        await clearCache();
    };

    return { isLoggedIn, logoutReason, login, logout };
});
