export const STANDING_PELVIC_TILT_LIMIT = 19;
export const LUMBAR_LORDOSIS_LIMIT = 45;
export const PFA_LOWER_LIMIT = 150;
export const PFA_UPPER_LIMIT = 230;
export const PI_LL_LIMIT = 10;
export const OVER_PI_LL_CSI_LOWER_BOUND = 215;
export const UNDER_PI_LL_CSI_LOWER_BOUND = 205;
export const OVER_PI_LL_CSI_UPPER_BOUND = 235;
export const UNDER_PI_LL_CSI_UPPER_BOUND = 245;
export const ANTEVERSION_10_DEGREES = 10;
export const ANTEVERSION_30_DEGREES = 30;
export const INCLINATION_LOWER_LIMIT = 30;
export const INCLINATION_UPPER_LIMIT = 50;

// Plot
export const INCLINATION_X_MIN = 10;
export const INCLINATION_X_MAX = 70;
export const ANTEVERSION_Y_MIN = -10;
export const ANTEVERSION_Y_MAX = 50;
export const DATA_POINTS = 500;