import { Euler, Matrix4, Vector3 } from 'three';
import { type RigidMatrix4 } from './rigidTransform';
import { vector3sApproxEqual } from './approxEquals';
import { isRigidMatrix } from '@/geometry/matrix';

/**
 * Identifier for the order that rotation are applied in an euler-rotation.
 *
 * Note that the rotation-order, following three-js, is the reverse of the order the matrices would be
 * multiplied to the points. So a point transformed by an 'XYZ' rotation will first be multiplied
 * by the z-rotation, then the y-rotation and then the x-rotation. To add to the confusion, this means
 * the matrix multiplication will be in the order as read so for an 'XYZ' rotation
 *
 * ` p_rotated = R_x * R_y * R_z * p `
 */
export type EulerRotationOrder = 'XYZ' | 'YZX' | 'ZXY' | 'XZY' | 'YXZ' | 'ZYX';

/**
 * The rotation order implied when no particular order is given
 */
export const defaultEulerOrder: EulerRotationOrder = 'XYZ';

/**
 * Representation of a rotation in Euler angles.
 *
 * Euler angles describe a rotational transformation by rotating an object on its various axes in specified
 * amounts per axis and a specified axis order.
 *
 * The angles are specified in **radians**.
 *
 * See https://threejs.org/docs/#api/en/math/Euler
 */
export type EulerAngles = {
    type: 'euler-angles';
    x: number | null;
    y: number | null;
    z: number | null;
    order: EulerRotationOrder | null;
};

/**
 * Guard function to identify euler-angles
 */
export function isEulerAngles(representation?: unknown): representation is EulerAngles {
    return !!representation && (representation as EulerAngles).type === 'euler-angles';
}

/**
 * Create a rotation as represented in Euler angles
 */
export function eulerAngles(values?: Partial<EulerAngles>): EulerAngles {
    return {
        type: 'euler-angles',
        order: values?.order ?? null,
        x: values?.x ?? null,
        y: values?.y ?? null,
        z: values?.z ?? null,
    };
}

export function eulerAnglesFromMatrix(
    matrix: RigidMatrix4,
    order?: EulerRotationOrder | null,
): EulerAngles {
    if (!isRigidMatrix(matrix)) {
        throw Error(`Cannot create euler angles: not a rigid matrix: ${matrix}`);
    }
    const angles = new Euler().setFromRotationMatrix(matrix, order ?? undefined);
    return eulerAngles({ x: angles.x, y: angles.y, z: angles.z, order });
}

/**
 * Create the matrix representation of an euler-angles transformation
 */
export function matrixFromEulerAngles(representation: EulerAngles): RigidMatrix4 {
    return new Matrix4().makeRotationFromEuler(
        new Euler(
            representation.x ?? undefined,
            representation.y ?? undefined,
            representation.z ?? undefined,
            representation.order ?? defaultEulerOrder,
        ),
    );
}

export function areEqualRotations(a: EulerAngles, b: EulerAngles): boolean {
    if (a === b) {
        return true;
    }

    const isTypeEqual = a.type === b.type;
    const isOrderEqual = a.order === b.order;
    const isXYZEqual = vector3sApproxEqual(rotationVector(a), rotationVector(b));

    return isTypeEqual && isOrderEqual && isXYZEqual;
}

/** A utility used internally for simplicity when comparing values */
export function rotationVector(a: EulerAngles): Vector3 {
    return new Vector3(a.x ?? undefined, a.y ?? undefined, a.z ?? undefined);
}
