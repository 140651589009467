const templateStates = ['new', 'processing', 'manual', 'completed', 'error'] as const;
export type TemplateState = typeof templateStates[number];

export type TemplateStateUri =
    `https://schema.formuslabs.com/state/surgical/template/${TemplateState}`;

const templateStateRegex: RegExp =
    /https:\/\/schema\.formuslabs\.com\/state\/surgical\/template\/(.*)/;

export function templateState(uri: string): TemplateState {
    const match = uri.match(templateStateRegex);
    if (match) {
        const result = match[1];
        if (isTemplateState(result)) {
            return result;
        }
    }
    throw Error(`Invalid template-state string: ${uri}`);
}

export function templateStateUri(value: TemplateState): TemplateStateUri {
    return `https://schema.formuslabs.com/state/surgical/template/${value}`;
}

export function isTemplateState(value: string): value is TemplateState {
    return templateStates.includes(value as TemplateState);
}


const templateRecordStates = ['new', 'processing', 'completed'] as const;
export type TemplateRecordState = typeof templateRecordStates[number];

export type TemplateRecordStateUri =
    `https://schema.formuslabs.com/state/surgical/template/record/${TemplateRecordState}`;

const templateRecordStateRegex: RegExp =
    /https:\/\/schema\.formuslabs\.com\/state\/surgical\/template\/record\/(.*)/;

export function templateRecordState(uri: string): TemplateRecordState {
    const match = uri.match(templateRecordStateRegex);
    if (match) {
        const result = match[1];
        if (isTemplateRecordState(result)) {
            return result;
        }
    }
    throw Error(`Invalid template-record-state string: ${uri}`);
}

export function templateRecordStateUri(value: TemplateRecordState): TemplateRecordStateUri {
    return `https://schema.formuslabs.com/state/surgical/template/record/${value}`;
}

export function isTemplateRecordState(value: string): value is TemplateRecordState {
    return templateRecordStates.includes(value as TemplateRecordState);
}

/** Properties of a surgical-template that are effectively read-only on the client */
export type TemplateStateProperties = {
    /** The resource state. */
    state: TemplateState;

    /** The record state. */
    recordState: TemplateRecordState;

    /** When the template was created. */
    created: string;

    /** When the template was last modified. */
    updated: string;
};
