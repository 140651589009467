export type NodeId = number | string;

/**
 * Format a node-id as a string
 */
export function formatNodeId(id: NodeId | null): string {
    if (id === null) {
        return '--'
    } else {
        return (typeof id === 'number') ? id.toString() : `'${id}'`;
    }
}

/** Symbol that identifies the node-id property */
const _nodeId = Symbol('nodeId');

/** Get the node-id that has been set on an object, if any */
export function getNodeId(object: unknown): NodeId | undefined {
    return (object as { [_nodeId]?: NodeId })[_nodeId];
}

/** Set a node-id on an object */
export function markNodeId<T extends Object>(object: T, id: NodeId): T {
    if (Object.isExtensible(object)) {
        Object.defineProperty(object, _nodeId, { value: id });
    } else {
        throw Error(`Failed to set nodeId property on object ${object}`);
    }
    return object;
}
