import { defineStore } from 'pinia';
import { usePlannerStore } from '@/planner/plannerStore';
import { computed, type Ref, ref } from 'vue';
import type { ApiFittedCup } from '@/api/fittedComponents/fittedCup';
import {
    type AnteversionInclination,
    toDegrees,
    toRadians,
} from '@/formus/anatomy/pelvis/anteversionInclination';
import {
    radiographicAngles,
    toAnatomic,
    toRadiographic,
} from '@/formus/anatomy/pelvis/acetabularAngles';
import { type CupOffset, cupOffsetFromApi, cupOffsetFromUI } from '@/planner/fittedCup';
import type { CatalogCup } from '@/formus/catalog/cup';
import { isDualMobilityVisible } from '@/lib/versioning';

const _DUAL_MOBILITY_CUP_SIZE_THRESHOLD = 46;

/** Store that adapts the state of the cup for the overlay user-interface */
export type CupOverlayStore = {
    /** Readonly array of the available cups */
    readonly availableCups: ApiFittedCup[];

    /** The index of the currently selected cup in availableCups */
    selectedCupIndex: number | undefined;

    /** Size of the selected cup, in mm */
    readonly selectedCupSize: number | undefined;

    /** Anteversion rotation, in degrees */
    anteversion: number | undefined;

    /** Inclination rotation, in degrees */
    inclination: number | undefined;

    /** Depth-offset, in mm */
    depthOffset: number;

    /** Anterior-posterior offset, in mm */
    apOffset: number;

    /** superior-inferior offset, in mm */
    siOffset: number;

    /** Cup-coverage, if defined, is from 0-1, with 1 being 100% coverage */
    coverage: number | null | 'calculating';

    /** Dual mobility mode */
    dualMobility: boolean;

    /** Dual mobility mode */
    dualMobilityEnabled: boolean;

    /** Dual mobility UI is invisible for old cases */
    dualMobilityVisible: boolean;
};

export const useCupOverlayStore: () => CupOverlayStore = defineStore('cup-overlay', () => {
    const planner = usePlannerStore();

    const availableCups = computed<ApiFittedCup[]>(() => {
        if (planner.fittedComponents) {
            const available_cups = Array.from(planner.fittedComponents.cups.values()).sort(
                (cupA, cupB) => cupA.outer_diameter - cupB.outer_diameter,
            );

            if (dualMobility.value) {
                while (available_cups[0].outer_diameter <= _DUAL_MOBILITY_CUP_SIZE_THRESHOLD) {
                    available_cups.shift();
                }
            }

            return available_cups;
        } else {
            return [];
        }
    });

    const selectedCupIndex = computed<number | undefined>({
        get: () => {
            const template = planner.template;
            if (!template) {
                return undefined;
            }
            const index = availableCups.value.findIndex(
                (cup) => cup.catalogUrl === template.cupUrl,
            );
            return index >= 0 ? index : undefined;
        },
        set: (index: number | undefined) => {
            if (index !== undefined) {
                planner.setCup(availableCups.value[index].catalogUrl);
            }
        },
    });

    const selectedCup = computed<CatalogCup | undefined>(() => {
        const url = planner.template?.cupUrl;
        return url ? planner.catalog?.cups.get(url) ?? undefined : undefined;
    });

    const selectedCupSize = computed<number | undefined>(
        () => selectedCup.value?.outerDiameter ?? undefined,
    );

    function rotationInRadiographicDegrees(): AnteversionInclination | undefined {
        return planner.template
            ? toDegrees(toRadiographic(planner.template.cupRotation))
            : undefined;
    }

    function setRotationInRadiographicDegrees(angles: Partial<AnteversionInclination>): void {
        if (!planner.template) {
            return;
        }
        const currentRadiographicRotation = rotationInRadiographicDegrees();
        if (currentRadiographicRotation) {
            const newRadiographicAngles = radiographicAngles({
                ...currentRadiographicRotation,
                ...angles,
            });
            planner.template.cupRotation = toAnatomic(toRadians(newRadiographicAngles));
        }
    }

    const anteversion = computed<number | undefined>({
        get: () => rotationInRadiographicDegrees()?.anteversion ?? undefined,
        set: (value: number | undefined) => {
            if (value !== undefined) {
                setRotationInRadiographicDegrees({ anteversion: value });
            }
        },
    });

    const inclination = computed<number | undefined>({
        get: () => rotationInRadiographicDegrees()?.inclination ?? undefined,
        set: (value: number | undefined) => {
            if (value !== undefined) {
                setRotationInRadiographicDegrees({ inclination: value });
            }
        },
    });

    const offset = computed<CupOffset>(() =>
        planner.template && planner.fittedCup
            ? cupOffsetFromApi(planner.fittedCup, planner.template.cupOffset)
            : { depth: 0, ap: 0, si: 0 },
    );

    function setOffset(values: Partial<CupOffset>) {
        if (planner.template && planner.fittedCup) {
            const updatedOffset = { ...offset.value, ...values };
            planner.template.cupOffset = cupOffsetFromUI(planner.fittedCup, updatedOffset);
        }
    }

    const depthOffset = computed<number>({
        get: () => offset.value.depth,
        set: (depth) => setOffset({ depth }),
    });

    const apOffset = computed<number>({
        get: () => offset.value.ap,
        set: (ap) => setOffset({ ap }),
    });

    const siOffset = computed<number>({
        get: () => offset.value.si,
        set: (si) => setOffset({ si }),
    });

    const coverage: Ref<number | null | 'calculating'> = ref(null);

    const dualMobility = computed<boolean>({
        get: () => (planner.template ? planner.template.dualMobility : false),
        set: planner.setDualMobility,
    });

    return {
        availableCups,
        selectedCupSize,
        selectedCupIndex,
        anteversion,
        inclination,
        depthOffset,
        apOffset,
        siOffset,
        coverage,
        dualMobility,
        dualMobilityEnabled: computed(() =>
            selectedCupSize.value
                ? selectedCupSize.value <= _DUAL_MOBILITY_CUP_SIZE_THRESHOLD
                : false,
        ),
        dualMobilityVisible: computed(() =>
            isDualMobilityVisible(planner.case?.caseVersion ?? null),
        ),
    };
});
