import type { MatrixArray16 } from '@/geometry/apiMatrix';
import type { NumberArray3 } from '@/geometry/apiVector';
import type { ApiLinks } from '@/api/links';
import type { ApiFittedHead } from '@/api/fittedComponents/fittedHead';
import type { Url } from '@/formus/types';

export type ApiFittedStem = ApiLinks & StemTransformProperties & {
    /** The url of the stem catalog-component, as given by the 'component' link */
    catalogUrl: Url;

    tmatrix: MatrixArray16;

    /** femoral stem anteversion angle */
    angle_anteversion: number;

    /** leg length difference between the anatomic and the new femur head centre */
    ll_diff: number;

    /** The offset difference between the anatomic and the new femur head centre */
    offset_diff: number;

    hjc: NumberArray3;

    /** The neck axis vector: used for extension-flexion rotation */
    neck_axis: NumberArray3;

    /** The posterior-anterior axis: used for varus-valgus rotation */
    pa_axis: NumberArray3;

    /**
     * Possible values are '0' and '1'
     * '0' is suitable
     * '1' is not suitable
     *
     * The idea was to have values in the range 0-1, but that did not happen yet
     */
    suitability_score: number;

    /**
     * The resection plane coordinates are in the scene coordinate system (not in the femur or
     * stem coordinates)
     */
    resection_plane: {
        normal: NumberArray3;
        origin: NumberArray3;
        x: NumberArray3;
        y: NumberArray3;
    };

    head: ApiFittedHead;
} & (StemTransformProperties | undefined);

export function isSuitableStem(stem: ApiFittedStem) {
    return stem.suitability_score === 0;
}

/**
 * These properties were added to the api-stem, and old cases may not have them.
 */
type StemTransformProperties = {
    /** Shaft axis direction - used for retroversion/anteversion rotation */
    shaft_axis_direction: NumberArray3;

    /** Shaft axis position */
    shaft_axis_point: NumberArray3;

    /**
     * Pivot point for posterior-anterior and extension-flexion rotation: most medial point of the
     * stem on the calcar plane. Aka the calcar-superiomedial landmark.
     */
    medial_pivot_point: NumberArray3;
};
