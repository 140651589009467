import type { PlannerMeshId } from '@/planner/scene/plannerObjectId';

/**
 * The render-order that should be applied to 3D objects in the hip planning scene.
 *
 * These orders could be set individually for each object in the scene but are relative to each other across,
 * so these are all defined here for clarity.
 *
 * @note The operative femur outer-surface and the inner-cortical-surface are out-of-order in a purely spatial sense.
 * It was decided that the inner-cortical-surface needed to be more visible, so is being rendered on-top of the
 * outer-surface.
 *
 * @see {https://threejs.org/docs/#api/en/core/Object3D.renderOrder}
 */
export const renderOrder: Record<PlannerMeshId, number> = {
    // NeckCutPlane = 1,
    'contralateral-femur': 0,
    'contralateral-hemipelvis': 0,
    'operative-hemipelvis': 0,
    'sacrum': 0,
    'metal': 0,
    'cup-coverage': 1,
    'cup-collision-surface': -1,
    'cup': -2,
    'liner': -3,
    'operative-femur-internal': -4,
    'operative-femur': -5,
    'head': -6,
    'bearing': -6,
    'stem': -7,
};
