import type { MaybeRefOrGetter } from 'vue';
import { toValue } from 'vue';

export function verify<T>(object: MaybeRefOrGetter<T>, msg: string): NonNullable<T> {
    const value = toValue(object);
    if (value) {
        return value;
    }
    console.warn(`Failed to verify: ${msg}`);
    throw new Error(msg);
}
