import { DateTime, type DurationLike } from 'luxon';
import type { Tokens } from '@/api/authenticator';

type Request = { response: any; status: number; statusText: string };

const CACHE_NAME = 'formuslabs-hip-planner';
const REFRESH_TOKEN_URL_PATH = '/token/refresh/';
const TOKEN_URL_PATH = '/token/';

export async function cache(request: Request, ttl: DurationLike): Promise<void> {
    const cache = await caches.open(CACHE_NAME);
    const cachedResponse = new Response(request.response, {
        status: request.status,
        statusText: request.statusText,
        headers: {
            expires: DateTime.now().plus(ttl).toISO(),
        },
    });
    return cache.put(REFRESH_TOKEN_URL_PATH, cachedResponse);
}

export async function cachedResponse<T>(key: string): Promise<T | void> {
    const cache = await caches.open(CACHE_NAME);

    const cachedResponse = await cache.match(key);

    if (!cachedResponse) {
        return;
    }

    return cachedResponse.json();
}

export async function unexpiredResponse<T>(key: string): Promise<T | void> {
    const cache = await caches.open(CACHE_NAME);

    const cachedResponse = await cache.match(key);
    if (!cachedResponse) {
        return;
    }

    const expiresHeader = cachedResponse.headers.get('expires');
    if (expiresHeader) {
        const expires = DateTime.fromISO(expiresHeader);
        // is the current datetime past the point at which the tokens have expired?
        const isExpired = DateTime.now().diff(expires, 'seconds').seconds > 0;
        if (isExpired) {
            return;
        }
    }

    return cachedResponse.json();
}

export async function cachedAuthTokens(): Promise<Tokens | void> {
    return (
        (await cachedResponse<Tokens>(REFRESH_TOKEN_URL_PATH)) ||
        (await cachedResponse<Tokens>(TOKEN_URL_PATH))
    );
}

export async function unexpiredAuthTokens(): Promise<Tokens | void> {
    return (
        (await unexpiredResponse<Tokens>(REFRESH_TOKEN_URL_PATH)) ||
        (await unexpiredResponse<Tokens>(TOKEN_URL_PATH))
    );
}

export async function clearCache() {
    const cache = await caches.open(CACHE_NAME);
    return Promise.all([cache.delete(TOKEN_URL_PATH), cache.delete(REFRESH_TOKEN_URL_PATH)]);
}
