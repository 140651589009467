import { findFittedCup, findFittedStem } from '@/planner/api/fittedComponents';
import { Matrix4, Vector3 } from 'three';
import { matrixFromApi } from '@/geometry/apiMatrix';
import { positionalPart } from '@/geometry/matrix';
import { matrix4FromBasis } from '@/geometry/basis';
import { lpsIdentityVectors } from '@/formus/anatomy/LPS';
import type { PlannerState } from '@/planner/plannerState';
import { assertNonNull, logger } from '@/util';
import type { NumberArray3 } from '@/geometry/apiVector';
import { vector3 } from '@/geometry/vector3';
import { logValidation } from '@/planner/logValidation';
import { formatMatrixBasis, formatVector, indent, joinIndented } from '@/geometry/formatMath';
import { computeHeadUrl } from '@/planner/componentUrls';
import { useAppErrorStore } from '@/stores/appErrorStore';
import type { ApiFittedStem } from '@/api/fittedComponents/fittedStem';
import type { ApiFittedCup } from '@/api/fittedComponents/fittedCup';

const log = logger();

/** An axis the stem is rotated around for manual stem positioning */
export type FittedStemAxis = {
    position: Vector3;
    direction: Vector3;
};

export type FittedStemAxes = {
    /** The neck axis used for extension-flexion rotation, in stem-group space */
    neckAxis: FittedStemAxis;

    /** The shaft axis used for retroversion-anteversion rotation, in stem-group space */
    shaftAxis: FittedStemAxis;

    /** The posterior-anterior axis used for varus-valgus rotation, in stem-group space */
    paAxis: FittedStemAxis;
};

/**
 * A representation of the fitted stem that is derived from fitted-stem data on the API.
 *
 * It gives the fitted-head transform, as well as various features of the stem expressed in
 * stem-group space, and is used for calculating stem placement.
 */
export type FittedStem = {
    /**
     * The fitted-head-transform, in CT/world space. It is *positioned* at the fitted-head
     * centre but *aligned* to the femoral coordinate system.
     *
     * In a 'retracted' arrangement when the stem and femur have not been translated to
     * dock the head into the cup:
     * - It is the transform of the femoral-group
     * - The transform of the stem-group is found by multiplying it to the manual-stem-transform
     * - If there is no manual-stem-transform it is the same as the stem-group transform
     * */
    transform: Matrix4;

    /** The stem transform, in stem-group space */
    stemLocal: Matrix4;

    /** The head transform, in stem-group space */
    headLocal: Matrix4;

    /** The bearing transform, in stem-group space */
    bearingLocal: Matrix4 | null;

    /**
     * Axes of the stem represented in stem-space
     *
     * Old cases on the API may not have the axes needed for manual stem positioning, in
     * which case this value will be null
     * */
    axesLocal: FittedStemAxes | null;

    resectionPlaneLocal: {
        normal: Vector3;
        origin: Vector3;
        x: Vector3;
        y: Vector3;
    };
};

export function computeFittedStem(state: PlannerState): FittedStem | null {
    try {
        if (!state.fittedComponents || !state.catalog || !state.template) {
            return null;
        }
        const apiStem = findFittedStem(
            state.fittedComponents,
            state.catalog,
            state.template.stemUrl,
            computeHeadUrl(state),
        );
        const headTransform = matrixFromApi(apiStem.head.tmatrix);
        const stemTransform = matrixFromApi(apiStem.tmatrix);
        const headPosition = positionalPart(headTransform);
        const transform = matrix4FromBasis(
            state.femoralFeatures?.stemTranslationBasis ?? lpsIdentityVectors(),
            headPosition,
        );

        const apiCup = findFittedCup(
            state.fittedComponents,
            state.catalog,
            state.template.cupUrl,
            state.template.linerUrl,
            state.template.bearingUrl,
        );

        let bearingTransform: Matrix4 | null = null;
        if (state.template.dualMobility) {
            if (apiCup.bearing) {
                bearingTransform = computeBearingTransform(apiStem, apiCup);
            } else {
                useAppErrorStore().handleError(
                    new Error(
                        'Dual-mobility is set on template but fitted-cup has no bearing transform',
                    ),
                );
                return null;
            }
        }

        // Matrix used to transform world/CT space to stem-group local space
        const toStemGroup = transform.clone().invert();
        const axisToStemGroup = (
            position: NumberArray3,
            direction: NumberArray3,
        ): FittedStemAxis => {
            return {
                position: vector3(position).applyMatrix4(toStemGroup),
                direction: vector3(direction).transformDirection(toStemGroup),
            };
        };

        let axesLocal: FittedStemAxes | null;
        if (apiStem.medial_pivot_point) {
            axesLocal = {
                neckAxis: axisToStemGroup(apiStem.medial_pivot_point, apiStem.neck_axis),
                shaftAxis: axisToStemGroup(apiStem.shaft_axis_point, apiStem.shaft_axis_direction),
                paAxis: axisToStemGroup(apiStem.medial_pivot_point, apiStem.pa_axis),
            };
        } else {
            log.warn('Stem representation is missing stem-transformation data (old case?)');
            axesLocal = null;
        }

        const result: FittedStem = {
            transform,
            // When the stem-group is at the fitted-head transform the stem and
            // head meshes should be at their fitted transforms.
            // This lets us calculate their relative transformations.
            stemLocal: toStemGroup.clone().multiply(stemTransform),
            headLocal: toStemGroup.clone().multiply(headTransform),
            bearingLocal: bearingTransform ? toStemGroup.clone().multiply(bearingTransform) : null,
            axesLocal,
            resectionPlaneLocal: {
                origin: vector3(apiStem.resection_plane.origin).applyMatrix4(toStemGroup),
                normal: vector3(apiStem.resection_plane.normal).transformDirection(toStemGroup),
                x: vector3(apiStem.resection_plane.x).transformDirection(toStemGroup),
                y: vector3(apiStem.resection_plane.y).transformDirection(toStemGroup),
            },
        };

        _logFittedStem(result);

        return result;
    } catch (error) {
        useAppErrorStore().handleError(error);
        return null;
    }
}

function _logFittedStem(fittedStem: FittedStem): void {
    logValidation(
        joinIndented(2)([
            'Fitted-stem computed:',
            'transform:',
            ...indent(2)(formatMatrixBasis(fittedStem.transform, { precision: 5 })),
            'stem:',
            ...indent(2)(formatMatrixBasis(fittedStem.stemLocal, { precision: 5 })),
            'head:',
            ...indent(2)(formatMatrixBasis(fittedStem.headLocal, { precision: 5 })),
            'bearing:',
            ...indent(2)(
                fittedStem.bearingLocal
                    ? formatMatrixBasis(fittedStem.bearingLocal, { precision: 5 })
                    : ['null']
            ),
            ...indent(2)(_formatFittedStemAxis('neck-axis', fittedStem.axesLocal?.neckAxis)),
            ...indent(2)(_formatFittedStemAxis('pa-axis', fittedStem.axesLocal?.paAxis)),
            ...indent(2)(_formatFittedStemAxis('shaft-axis', fittedStem.axesLocal?.shaftAxis)),
            'resection-plane:',
            `  origin: ${formatVector(fittedStem.resectionPlaneLocal.origin)}`,
            `  normal: ${formatVector(fittedStem.resectionPlaneLocal.normal)}`,
            `  x: ${formatVector(fittedStem.resectionPlaneLocal.x)}`,
            `  y: ${formatVector(fittedStem.resectionPlaneLocal.y)}`,
        ])
    );
}

function _formatFittedStemAxis(name: string, axis?: FittedStemAxis): string[] {
    return axis
        ? [
              name,
              `  position: ${formatVector(axis.position)}`,
              `  direction: ${formatVector(axis.direction)}`,
          ]
        : [`${name}: undefined`];
}

/**
 * This function calculates the transform for the bearing so that the bearing is aligned with the head.
 * The bearing transform from the API would align the bearing with the liner.
 * So it has to be recalculated. We could've just used the head transform from
 * the API. However, for the head, the head origin and the head center are at the
 * same point. While for the bearing, the bearing origin is at the bottom hole center.
 * So it has to be adjusted to account for the offset between the bearing origin and
 * the bearing head center along the neck axis direction.
 * @returns bearing transform matrix that aligns the bearing with the head
 */
function computeBearingTransform(apiStem: ApiFittedStem, apiCup: ApiFittedCup): Matrix4 {
    const bearing = assertNonNull(apiCup.bearing, 'fitted-cup-bearing');
    const apiBearingTransform = matrixFromApi(bearing.tmatrix);

    // const apiHeadCentre = vector3(apiStem.hjc);
    const apiHeadTransform = matrixFromApi(apiStem.head.tmatrix);
    const apiHeadCentre = positionalPart(apiHeadTransform);

    const apiBearingHeadCentre = vector3(bearing.head_centre);
    const shift = apiHeadCentre.clone().sub(apiBearingHeadCentre);
    const translationTransform = new Matrix4().makeTranslation(shift);
    const shiftedBearingTransform = translationTransform.clone().multiply(apiBearingTransform);

    const apiStemNeckAxis = vector3(apiStem.neck_axis)
    apiStemNeckAxis.normalize();
    const apiBearingCentreLineAxis = vector3(bearing.centre_line_axis);
    apiBearingCentreLineAxis.normalize();

    const axis = apiBearingCentreLineAxis.clone().cross(apiStemNeckAxis);
    axis.normalize();
    const angle = apiBearingCentreLineAxis.angleTo(apiStemNeckAxis);
    const rotationTransform = new Matrix4().makeRotationAxis(axis, angle);

    const translationToHeadCentre = new Matrix4().makeTranslation(apiHeadCentre);
    const rotationAroundHeadCentre = translationToHeadCentre.clone().multiply(rotationTransform.clone().multiply(translationToHeadCentre.clone().invert()));

    return rotationAroundHeadCentre.clone().multiply(shiftedBearingTransform);
}
