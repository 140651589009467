<template>
    <div class="app-planner-view-modes">
        <visibility-toggle id="femur" />
        <visibility-toggle id="pelvis" />
        <visibility-toggle id="implant" />
        <visibility-toggle id="xray" />
        <div class="app-planner-view-mode-divider" v-if="anyCrossSectionShown" />
        <visibility-toggle id="stem-neck-cross-section" />
        <visibility-toggle id="stem-coronal-cross-section" />
        <visibility-toggle id="cup-coronal-cross-section" />
    </div>
</template>

<script setup lang="ts">
    import VisibilityToggle from '@/planner/components/VisibilityToggle.vue';
    import { usePlannerStore } from '@/planner/plannerStore';
    import { computed } from 'vue';
    import { any } from '@/util';
    import type { VisibilityToggleId } from '@/planner/visibilityToggles';

    const plannerStore = usePlannerStore();

    const anyCrossSectionShown = computed<boolean>(() =>
        any<VisibilityToggleId>(
            (id) => plannerStore.visibility[id] !== 'hidden',
            ['stem-neck-cross-section', 'stem-coronal-cross-section', 'cup-coronal-cross-section'],
        ),
    );
</script>

<style scoped>
    .app-planner-view-modes {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }

    .app-planner-view-mode-divider {
        border-right: 1px solid var(--planner-grey-inverted);
        margin: 0 10px;
        height: 60px;
    }
</style>
