<template>
    <div class="app-loader">
        <slot></slot>
        <div class="app-progress-bar">
            <v-progress-linear indeterminate color="blue"></v-progress-linear>
        </div>
    </div>
</template>

<style scoped>
    .app-loader {
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
    }
</style>
