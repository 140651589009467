/**
 * Implements software requirements: H1SR-71, H1SR-93, H1SR-94
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-93/User-can-select-a-surgeon-for-a-case
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-71/When-a-user-creates-their-first-case-they-are-required-to-fill-out-User-preferences-before-being-able-to-proceed-with-the-case
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-94/Surgeon-preferences-are-inherited-into-Surgical-Specifications-and-are-able-to-be-viewed-edited
 */
import { defineStore } from 'pinia';

import { getPreferences, NoSurgicalPreferencesError, type THAPreferences } from '@/api/preferences';
import { cloneDeep, isEqual } from 'lodash';
import { validators } from '@/stores/caseSettings/validation';
import assert from 'assert';
import { useSpinopelvic } from '@/stores/spinopelvic/store';
import type { CaseSettingsOptions, SurgeonOption } from '@/api/case/types';
import {
    makeDataStateGetters,
    setInError,
    setInValidating,
    withStateLoading,
    withStateSaving,
} from '@/stores/shared/dataState';
import type {
    CasePatient,
    CaseSettingsInputs,
    CaseSettingsState,
    CaseSpecifications,
    CaseStudy,
} from '@/stores/caseSettings/types';
import { isValid } from '@/stores/validation';
import {
    getSurgeonPreferences,
    loadCaseSettings,
    loadCaseSettingsOptions,
    saveCaseSettings,
} from '@/stores/caseSettings/services';
import { makeInitialState } from '@/stores/caseSettings/initialState';
import { isNotEmpty } from '@/lib/validation';
import { useUserStore } from '@/stores/user/store';
import { unassignSurgeon } from '@/api/case/request';
import { verify } from '@/lib/verify';
import { nextTick } from 'vue';
import { useCaseSyncStore } from '@/stores/navigation/useCaseSyncStore';

export const useCaseSettings = defineStore({
    id: 'caseSettings',
    state: (): CaseSettingsState => {
        // Given the state is not a flat structure, it needs to be deep clone
        // e.g.: $reset will not work if the state is not cloned
        return makeInitialState();
    },
    getters: {
        ...makeDataStateGetters(),
        /**
         * There is no flag at the moment in the database to indicate whether a case is suitable or not.
         * Heuristic: If the cases exists and the surgeon is not selected, the case is deemed unsuitable.
         */
        isCaseDeemedUnsuitable(state: CaseSettingsState): boolean {
            const { isCaseNew, isCaseProcessing } = useCaseSyncStore();
            const noSurgeon = state.case.specifications.surgeon.selected === null;
            const isExistingCase = !!this.case.id;
            return isExistingCase && noSurgeon && !isCaseNew && !isCaseProcessing;
        },
        isCaseReadonly(): boolean {
            return this.isCaseDeemedUnsuitable;
        },
        isSpecificationsEnabled(state: CaseSettingsState): boolean {
            return state.case.specifications.surgeon.selected !== null;
        },
        patient(state: CaseSettingsState): CasePatient {
            return state.case.patient;
        },
        specifications(state: CaseSettingsState): CaseSpecifications {
            return state.case.specifications;
        },
        study(state: CaseSettingsState): CaseStudy | null {
            return state.case.study;
        },
        hasStudy(): boolean {
            return !!this.study;
        },
        hasEnteredPatientData(state: CaseSettingsState): boolean {
            return state.case.patient && arePatientBasicDetailsCompleted(state.case.patient);
        },
        currentSurgeon(): SurgeonOption | null {
            return (
                this.specifications.surgeon.options.find(
                    (s) => s.id === this.specifications.surgeon.selected,
                ) ?? null
            );
        },
        isValid(state: CaseSettingsState): boolean {
            return useSpinopelvic().isValid && isValid(validators, state.case);
        },
        isDirty(state: CaseSettingsState): boolean {
            const makeComparable = (inputs: CaseSettingsInputs): CaseSettingsInputs => {
                return {
                    ...cloneDeep(inputs),
                    study: null,
                };
            };

            return (
                !isEqual(makeComparable(state._lastSavedCase), makeComparable(state.case)) ||
                useSpinopelvic().isDirty
            );
        },
        /**
         * Determine whether the current user is allowed to remove surgeon from a project
         * Conditions: admins or sales rep who is also the owner of this project
         */
        canUnassignSurgeon(): boolean {
            const userStore = useUserStore();
            const currentUserId = userStore.user?.id;
            const caseOwner = this.case.owner_id;
            return (
                userStore.isAdmin ||
                userStore.isOrgAdmin ||
                (userStore.isSales && !!caseOwner && !!currentUserId && caseOwner === currentUserId)
            );
        },
    },
    actions: {
        hasFieldError(field: string): boolean {
            const validator = validators[field];
            assert.ok(!!validator, `no validator for ${field}`);
            return !validator(this.case);
        },
        async loadNewCase() {
            await withStateLoading(this, async () => {
                const options = await loadCaseSettingsOptions();
                const surgeonId = options.surgeons[0].id;
                const preferences = await getSurgeonPreferences(surgeonId);

                this._setInitialNewState(options, surgeonId, preferences);
            });
        },
        async loadExistingCase(caseId: number) {
            await withStateLoading(this, async () => {
                const caseSettings = await loadCaseSettings(caseId);

                this._setInitialEditState(caseSettings);
            });
        },
        async save(isNewCase: boolean, webComponentVersion: string) {
            setInValidating(this);
            this.displayErrors = true;

            if (!this.isValid) {
                // Wait for the next tick to ensure watchers of the state have run
                await nextTick(() => {
                    setInError(this, {
                        title: 'Please review your input',
                        message:
                            'Your changes were not saved. Please review the errors marked in red below and try again.',
                    });
                });
                return;
            }

            await withStateSaving(this, async () => {
                const caseId = await saveCaseSettings(isNewCase, this.case, webComponentVersion);
                // FIXME: This request is after the case was saved in the api / db. If it happens to fail,
                //       there is not way to roll it back, so we will end with the case settings saved,
                //       but the spinopelvic not. There should be an endpoint for the whole settings
                await useSpinopelvic().save(caseId);

                this.case.id = caseId;
                this._setLastSavedCase();
            });
        },
        /** Override the user case settings with those of the selected surgeon. */
        async onUpdateSurgeon(surgeonId: number) {
            this.case.specifications.surgeon.selected = surgeonId;
            this.case.specifications.surgeon.error = false;

            try {
                const preferences = await getPreferences(surgeonId);

                this._setSurgeonSpecifications(preferences);
            } catch (err) {
                if (err instanceof NoSurgicalPreferencesError) {
                    this.case.specifications.surgeon.error = true;
                    return;
                } else {
                    throw err;
                }
            }
        },
        async unassignSurgeon(): Promise<void> {
            await withStateSaving(this, async () => {
                const caseId = verify(this.case.id, 'Case ID is required to unassign surgeon');

                await unassignSurgeon(caseId);

                // update surgeon changes
                this.case.specifications.surgeon.selected = null;
                this.case.specifications.surgeon.error = false;
            });

            if (this.isError) {
                this.case.specifications.surgeon.error = true;
                return;
            }
        },
        _setInitialNewState(
            options: CaseSettingsOptions,
            surgeonId: number,
            preferences: THAPreferences | null,
        ) {
            // only assign surgeon and surgeon preferences when there is only 1 option
            const assignSurgeon = options.surgeons.length === 1;

            this.case.specifications.stems.options = [...options.stems];
            this.case.specifications.surgeon.options = [...options.surgeons];
            this.case.specifications.surgeon.selected = assignSurgeon ? surgeonId : null;

            if (assignSurgeon && preferences) {
                this._setSurgeonSpecifications(preferences);
            } else {
                console.warn('Surgeon does not have preferences');
            }

            this._setLastSavedCase();
        },
        _setInitialEditState(caseSettings: CaseSettingsInputs) {
            this.case = caseSettings;
            this._setLastSavedCase();
        },
        _setSurgeonSpecifications(preferences: THAPreferences): void {
            this.case.specifications.stems.selected = [...preferences.stems];
            this.case.specifications.stems.preferredSystem = preferences.preferredSystem;
            this.case.specifications.cup = {
                inclinationAngle: preferences.cupInclinationAngle,
                fitMethod: preferences.cupFitMethod,
                anteversion: preferences.cupAnteversionMode,
                anteversionAngle: preferences.cupAnteversionAngle,
                alignment: preferences.cupAlignMode,
            };
        },
        _setLastSavedCase() {
            this._lastSavedCase = cloneDeep(this.case);
        },
    },
});

/** @return true if the basic data of the patient is completed (sex, date of birth, and family, given name) */
function arePatientBasicDetailsCompleted(patient: CasePatient): boolean {
    return (
        isNotEmpty(patient.sex) &&
        isNotEmpty(patient.dateOfBirth) &&
        isNotEmpty(patient.firstName) &&
        isNotEmpty(patient.familyName)
    );
}
