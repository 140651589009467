<template>
    <div class="app-card">
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
    import { toRefs } from 'vue';
    export interface Props {
        size?: 'large' | 'medium';
        narrow?: boolean;
        borderless?: boolean;
    }
    const props = withDefaults(defineProps<Props>(), {
        size: 'medium',
        narrow: false,
        borderless: false,
    });
    const { size, narrow, borderless } = toRefs(props);

    const cardWidth = narrow.value ? '800px' : '1020px';

    let cardPadding = '20px';
    if (size.value === 'large') {
        cardPadding = '40px';
    }
    if (borderless.value) {
        cardPadding = '0';
    }
</script>

<style scoped>
    .app-card {
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 10px 0 rgba(0, 0, 0, 0.02);
        border: 1px solid var(--planner-grey-light);
        margin-bottom: 20px;
        padding: v-bind('cardPadding');
        max-width: v-bind('cardWidth');
    }
</style>
