<template>
    <v-menu
        max-height="500"
        max-width="500"
        content-class="support-menu"
        transition="fade-transition"
        :open-on-hover="true"
        location="bottom"
        offset="30"
        open-delay="0"
        close-delay="0"
    >
        <template v-slot:activator="{ props }">
            <app-menu-item
                class="cursor-pointer"
                icon="mdi-help-circle-outline"
                text="Support"
                @focus="props.onFocus"
                @blur="props.onBlur"
                @mouseenter="props.onMouseenter"
                @mouseleave="props.onMouseleave"
            />
        </template>
        <v-card dense min-width="400" color="planner-blue-rich" class="notifications-card">
            <v-list dense color="planner-rich-blue" class="pa-5 overflow-y-auto">
                <v-list-item
                    href="https://support.formuslabs.com/hc/en-us"
                    target="_blank"
                    cy-data="header-dropdown-list-item"
                >
                    Knowledge Base
                </v-list-item>

                <v-divider v-if="showSurveyLink" />

                <v-list-item
                    v-if="showSurveyLink"
                    @click="onShowSurveyClick"
                    target="_blank"
                    class="font-weight-bold"
                    cy-data="header-dropdown-list-item"
                >
                    Provide Instant Feedback
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script setup lang="ts">
    import Hotjar from '@hotjar/browser';

    import { taggedLogger } from '@/util';
    import { ref, watch } from 'vue';
    import { useRoute } from 'vue-router';

    const log = taggedLogger('support-menu');

    const route = useRoute();

    /**
     * At the moment hotjar does not allow to show a survey multiple times
     * (if the user does not navigate to other or refresh the page)
     */
    const showSurveyLink = ref(true);

    watch(
        route,
        () => {
            showSurveyLink.value = true;
        },
        { immediate: true, deep: true },
    );

    const onShowSurveyClick = () => {
        showSurveyLink.value = false;

        Hotjar.event('show-survey');

        if (import.meta.env.DEV) {
            log.info('survey in local development is not displayed');
        }
    };
</script>

<style scoped lang="scss">
    .support-menu {
        .v-list {
            background: var(--v-planner-blue-rich);
            color: var(--v-planner-grey-lighten-1);
        }
    }

    .survey-container {
        display: none; /* Initially hidden */
        /* Additional styling if needed */
    }
</style>
