import {
    asyncTimeout,
    stop,
    stopAll,
    type StopHandle,
    taggedLogger,
    assert,
    assertNonNull,
} from '@/util';
import { computed, isReactive, reactive, watch } from 'vue';
import type { SyncTemplate, TemplateId } from '@/formus/template/template';
import type { Url } from '@/formus/types';
import { putTemplate } from '@/api/template/putTemplate';
import { type ManualTemplateState, syncTemplate } from '@/planner/template/manualTemplateState';
import { defineStore } from 'pinia';
import { asyncWatchUntil } from '@/util/asyncWatchUntil';
import { useAppErrorStore } from '@/stores/appErrorStore';
import { getTemplate } from '@/api/template/getTemplate';
import {
    formatTemplateDifferences,
    templatesAreEqual,
} from '@/planner/template/templateComparison';
import { templateUrl } from '@/api/template/templateUrl';
import { getIdFromUrl } from '@/lib/getIdFromUrl';
import { useUserStore } from '@/stores/user/store';

const log = taggedLogger('template-sync');

export type TemplateSyncConflict = {
    message: string;
    sameUser: boolean;
};

export const useTemplateSyncStore = defineStore('template-sync', () => {
    let syncing: StopHandle | null = null;

    const _state = reactive<_TemplateSyncState>({
        templateUrl: '',
        expectedTemplate: null,
        queuedUpdate: null,
        nextUpdateId: 1,
        isSaving: false,
        forceUpdate: false,
        conflict: null,
    });

    function _reset(templateId: TemplateId | Url, template: ManualTemplateState) {
        _state.templateUrl = templateUrl(templateId);
        _state.expectedTemplate = syncTemplate(template);
        _state.queuedUpdate = null;
        _state.isSaving = false;
        _state.forceUpdate = false;
        _state.conflict = null;
    }

    return {
        _state,
        isRunning: computed(() => !!_state.templateUrl),
        /** True if there is an update queued or currently being saved */
        hasUpdate: computed(() => _state.queuedUpdate !== null || _state.isSaving),
        isSaving: computed(() => _state.isSaving),
        conflict: computed(() => _state.conflict),
        /** Reset the state and start synchronising the template */
        startSync: (templateId: TemplateId | Url, template: ManualTemplateState) => {
            log.info('Start syncing template %s', templateUrl(templateId));
            stop(syncing);
            _reset(templateId, template);
            syncing = _sync(_state, template);
        },
        stopSync: () => {
            stop(syncing);
        },
    };
});

/** State of the synchroniser, as used by the store */
type _TemplateSyncState = {
    /** The url of the template being synced */
    templateUrl: Url;

    /**
     * The expected state of the template on the API.
     *
     * This is initially the initial template when sync is started, and then updated whenever an
     * update is pushed.
     */
    expectedTemplate: SyncTemplate | null;

    /** The update to be sent next. This will be overwritten */
    queuedUpdate: _TemplateSyncUpdate | null;

    /** Logging-identifier for the next update to be queued */
    nextUpdateId: number;

    /** Indicates that we are either pushing an update or re-fetching directly afterwards */
    isSaving: boolean;

    /** Indicates that we should push a queued update as-soon-as-possible */
    forceUpdate: boolean;

    /** Indicates a conflict in the */
    conflict: TemplateSyncConflict | null;
};

/**
 * A pending update for the surgical-template
 */
export type _TemplateSyncUpdate = {
    /** Identifier for the update: used for logging */
    updateId: number;

    /** The time the update is scheduled for */
    scheduledTime: number;

    /** The template that should be applied */
    template: SyncTemplate;
};

/** Time in milliseconds to debounce updates to push to the API */
const _UPDATE_INTERVAL = 1000;

/** Time in milliseconds to between checking for conflicts */
const _CONFLICT_CHECK_INTERVAL = 5000;

function _sync(state: _TemplateSyncState, template: ManualTemplateState): StopHandle {
    const aborter = new AbortController();
    assert(isReactive(state));

    useAppErrorStore().catchErrors(async () => {
        try {
            await _run(state, aborter.signal);
        } catch (error) {
            if (aborter.signal.aborted) {
                return;
            } else {
                throw error;
            }
        }
    });

    return stopAll(
        // Watch the manual template for changes and queue an update when it changes
        watch(
            template,
            (template) => {
                const updateId = state.nextUpdateId++;

                state.queuedUpdate = {
                    updateId,
                    scheduledTime: Date.now() + _UPDATE_INTERVAL,
                    template: syncTemplate(template),
                };
                log.info('Queueing update %d', updateId);
            },
            { deep: true },
        ),
        // When we stop also signal the abort
        () => {
            if (!aborter.signal.aborted) {
                log.info('Stop syncing template');
                aborter.abort();
                state.templateUrl = '';
            }
        },
    );
}

async function _run(state: _TemplateSyncState, signal: AbortSignal): Promise<void> {
    assert(isReactive(state));

    let waitCount = 0;

    while (!signal.aborted) {
        const now = Date.now();

        if (state.queuedUpdate) {
            waitCount = 0;

            const { updateId, template, scheduledTime } = state.queuedUpdate;
            // There is a queued update, so check if it should be applied
            if (now >= scheduledTime || state.forceUpdate) {
                // The scheduled-time for the update has elapsed, so apply it
                state.queuedUpdate = null;
                log.info('Putting update %d', updateId);
                state.isSaving = true;
                await putTemplate(state.templateUrl, template, { signal });
                log.info('Put update %d', updateId);
                state.isSaving = false;
                state.expectedTemplate = template;
            } else {
                // Wait for the scheduled update time
                const waitTime = scheduledTime - now;
                log.info('Waiting %d ms to put queued update %d...', waitTime, updateId);
                await _waitForTimeoutOrChange(waitTime, () => state.forceUpdate, signal);
            }
        } else {
            log[waitCount++ === 10 ? 'info' : 'debug']('No queued update, waiting...');
            await _waitForTimeoutOrChange(
                _CONFLICT_CHECK_INTERVAL,
                () => state.queuedUpdate !== null,
                signal,
            );

            if (state.queuedUpdate) {
                log.debug('Skip template conflict-check (has queued update to push).');
            } else {
                state.conflict = await _checkForConflict(
                    state.templateUrl,
                    assertNonNull(state.expectedTemplate, 'last-update'),
                    signal,
                );
                if (state.conflict) {
                    return;
                }
            }
        }
    }
}

async function _waitForTimeoutOrChange(
    delay: number,
    change: () => boolean,
    signal: AbortSignal,
): Promise<void> {
    const changed = asyncWatchUntil(change, { signal });
    const timeout = asyncTimeout(delay, signal);
    await Promise.race([changed, timeout]);
}

async function _checkForConflict(
    templateId: TemplateId | Url,
    expected: SyncTemplate,
    signal: AbortSignal,
): Promise<TemplateSyncConflict | null> {
    log.debug('Fetching template to check for conflict');
    const fetched = await getTemplate(templateId, { signal });

    if (templatesAreEqual(expected, fetched)) {
        log.debug('No conflicts detected');
        return null;
    } else {
        const message = [
            'Fetched changes to surgical template are incompatible:',
            ...formatTemplateDifferences(expected, fetched),
        ].join('\n  ');
        log.error('Merge error: %s', message);

        return {
            message,
            sameUser: getIdFromUrl(fetched.updater) === useUserStore().userId,
        };
    }
}
