/**
 * Implements software requirements: H1SR-120, H1SR-147
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-120/The-software-version-is-displayed-on-user-facing-parts-of-the-software-and-documents
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-147/Display-Unique-Device-Identifier-on-About-page-adjacent-to-UDI-symbol
 */

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { DateTime } from 'luxon';
import { getVersion } from '@/api/version';
import { webReleaseDate, webVersion, webVersionSuffix } from '@/lib/headMetaTags';

export const useVersion = defineStore('version', () => {
    const isLoading = ref(false);
    const deployedAt = ref('');
    const acidVersion = ref('');

    const load = async () => {
        isLoading.value = true;

        try {
            const result = await getVersion();
            deployedAt.value = String(DateTime.fromISO(result.acidDeployTime));
            acidVersion.value = result.acid;
        } finally {
            isLoading.value = false;
        }
    };

    const webComponentVersion = computed(() => webVersion());

    /**
     * Version number is a combination of web component version and acid components version.
     *
     * Note that region is hardcoded for the time being.
     */
    const versionNumber = computed(() =>
        [acidVersion.value, webComponentVersion.value, webVersionSuffix()].join('-'),
    );

    /**
     * The unique device identifier specifies the GTIN and software version number
     */
    const gtin = '(01)09421906798005(10)';
    const uniqueDeviceId = computed(() => `${gtin}${versionNumber.value}`);
    const versionWithPrefix = computed(() => `V${versionNumber.value}`);

    /**
     * The release date is whichever component (web, api&rest) was released last.
     */
    const SHORT_DATE = 'yyyy-MM-dd';
    const releaseDate = computed(() =>
        DateTime.max(DateTime.fromISO(deployedAt.value), webReleaseDate()).toFormat(SHORT_DATE),
    );

    return { isLoading, load, webComponentVersion, versionNumber, versionWithPrefix, uniqueDeviceId, releaseDate };
});
