/**
 * Type-guard for a finite number.
 * Values of NaN, Infinite, -Infinite, null, undefined, strings, objects, arrays will all evaluate to False
 *
 * Value of 0, 1, numbers will evaluate to True
 *
 * This is essentially {@link Number.isFinite} but written in the form of a
 * [type-guard]{@link https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates}
 */
export function isFiniteNumber(value: unknown): value is number {
    return Number.isFinite(value);
}
