import type { SpinopelvicInputs, SpinopelvicState } from '@/stores/spinopelvic/types';
import { makeDataState } from '@/stores/shared/dataState';
import { cloneDeep } from 'lodash';

export const initialInputs: SpinopelvicInputs = {
    standingPelvicTilt: '',
    pelvicFemoralAngle: '',
    lumbarLordosis: '',
    sacralSlope: '',
    pelvicTilt: '',
};

export const initialState: SpinopelvicState = {
    ...makeDataState(),
    current: cloneDeep(initialInputs),
    lastSaved: cloneDeep(initialInputs),
};
