import type { CaseSettingsInputs } from '@/stores/caseSettings/types';
import type { CaseSettingsApiObjectCreate } from '@/api/case/types';

export function mapToApiObject(
    caseSettings: CaseSettingsInputs,
    webComponentVersion: string,
): CaseSettingsApiObjectCreate {
    const selectedStems = caseSettings.specifications.stems.selected
        .map(
            (stemId: string) =>
                caseSettings.specifications.stems.options.filter(
                    (option) => option.id === stemId,
                )[0],
        )
        .filter((stemId: any) => !!stemId);

    const specifications = caseSettings.specifications;
    const patient = caseSettings.patient;
    const surgery = caseSettings.surgery;
    return {
        id: caseSettings.id,
        web_component_version: webComponentVersion,
        name: surgery.name,
        description: surgery.description,
        surgery_date: surgery.date,
        side: surgery.side,
        surgeon_id: specifications.surgeon.selected,
        patient: {
            first_name: patient.firstName,
            middle_name: patient.middleNames,
            family_name: patient.familyName,
            sex: patient.sex,
            birth_date: patient.dateOfBirth,
        },
        surgical_specifications: {
            preferred_system: specifications.stems.preferredSystem,
            stems: selectedStems.filter(
                (stem) => stem.system === specifications.stems.preferredSystem),
            target_leg_length_change: Number(specifications.targets.legLength),
            target_offset_change: Number(specifications.targets.offset),
            cup_anteversion_angle: Number(specifications.cup.anteversionAngle),
            cup_anteversion_mode: specifications.cup.anteversion,
            cup_inclination_angle: Number(specifications.cup.inclinationAngle),
            cup_fit_method: specifications.cup.fitMethod,
            cup_align_mode: specifications.cup.alignment,
        },
    };
}
