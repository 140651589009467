<template>
    <v-layout>
        <app-sidebar>
            <router-link :to="{ name: ROUTES.HOME }">
                <app-link icon="mdi-arrow-left" color="red" alone bold>Back to your Cases</app-link>
            </router-link>
        </app-sidebar>

        <app-main-view>
            <app-form-title> Formus Case Plans</app-form-title>
            <PlanCard
                v-if="store.state.automatedPlan"
                :project="store.state.project"
                :plan="store.state.automatedPlan"
            />
            <PlanCard
                v-if="store.state.manualPlan"
                :project="store.state.project"
                :plan="store.state.manualPlan"
            />
        </app-main-view>
    </v-layout>
</template>

<script setup lang="ts">
    import { ROUTES } from '@/router';
    import { usePlansStore } from '@/stores/plan/store';
    import PlanCard from '@/components/case-plan/PlanCard.vue';
    import { onBeforeUnmount } from 'vue';
    import { useRoute } from 'vue-router';
    import { verify } from '@/lib/verify';
    import { useSpinopelvic } from '@/stores/spinopelvic/store';

    const route = useRoute();
    const caseIdParam = verify(route.params.id, 'case id is required');
    const caseId = Number(caseIdParam);
    const store = usePlansStore();
    await store.load(caseId);

    await useSpinopelvic().load(caseId);

    onBeforeUnmount(() => {
        store.stopSync();
    });
</script>
