export function assert(condition: any, message?: string): asserts condition {
    if (!condition) {
        throw new Error(`Assertion failed${message ? ': ' + message : ''}`);
    }
}

/** Throw if invoking of the function is not truthy */
export function assertIs<T, U extends T>(
    fn: (value: T) => value is U,
    value: T,
): asserts value is U {
    if (!fn(value)) {
        const functionName = fn.name || 'anonymous function';
        throw new Error(`Assertion failed in ${functionName} with value ${JSON.stringify(value)}`);
    }
}

/**
 * Verify that a value is defined (is not undefined) and return that value
 */
export function assertDefined<T>(value: T | undefined, name: string): T {
    assert(value !== undefined, `${name} was expected to be defined but was undefined`);
    return value;
}

/**
 * Verify that a value is not null and return that same value
 */
export function assertNonNull<T>(value: T | null, name: string): NonNullable<T> {
    assert(value !== null, `{${name} was expected to be non-null but was null`);
    return value as NonNullable<T>;
}
