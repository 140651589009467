import { stopAll, type StopHandle } from '@/util';
import { watchImmediate } from '@vueuse/core';
import LPS from '@/formus/anatomy/LPS';
import { useDeveloperSettings } from '@/planner/developerSettings';
import type { PlannerStore } from '@/planner/plannerStore';
import { setCameraPlacement } from '@/planner/scene/cameraPlacement';

/** Updates to the planner-store that are not specific to the components */
export function updatePlanner(store: PlannerStore): StopHandle {
    const settings = useDeveloperSettings();

    return stopAll(
        watchImmediate(
            () => store.visibility.xray,
            (value) => store.renderingMode = value === 'on' ? 'xray' : 'normal',
        ),
        watchImmediate(
            () => store.plannerMode,
            (mode) => setCameraPlacement(store, mode)
        ),
        watchImmediate(
            () => store.sceneCentre,
            (centre) => {
                store.nodes.sceneCentre.transform.setPosition(centre);
                store.nodes.sceneGrid.transform.setPosition(centre);
                store.nodes.camera.target.copy(centre);
                store.nodes.camera.position = centre.clone().add(LPS.Anterior.multiplyScalar(450));
            },
        ),
        watchImmediate(
            () => (settings.animateCameras !== false) && !store.isLoading,
            (value) => (store.nodes.camera.animate = value ?? false),
        ),
        watchImmediate(
            () => settings.show3dFeatures ?? false,
            (show) => {
                [store.nodes.sceneCentre, store.nodes.sceneGrid, store.nodes.ctOrigin].forEach(
                    (node) => (node.visible = show),
                );
            },
        ),
    );
}
