import { DoubleSide, MeshBasicMaterial } from 'three';
import type { BaseMaterialParams } from '@/planner/3d/materials/params';

/** Default color for solid material */
const DEFAULT_COLOR = '#ffffff' as const;

/** Default is for materials to apply to both front- and back-sides of an object */
const DEFAULT_SIDE = DoubleSide;

/** Default opacity is to be full opaque i.e. not transparent at all */
const DEFAULT_OPACITY = 1.0 as const;

export type SolidMaterialParams = BaseMaterialParams & {
    type: 'solid';
};

/**
 * Create a three.js {@link MeshBasicMaterial}; this kind of material appears as a flat color,
 * without any shadowing or highlights caused by lights in the scene.
 */
export function makeSolidMaterial(params?: Partial<SolidMaterialParams>): MeshBasicMaterial {
    const transparent = params?.transparent ?? false;
    return new MeshBasicMaterial({
        name: params?.name ?? 'solid',
        color: params?.color ?? DEFAULT_COLOR,
        side: params?.side ?? DEFAULT_SIDE,
        clippingPlanes: params?.clippingPlanes ?? [],
        opacity: params?.opacity ?? DEFAULT_OPACITY,
        transparent,
        depthWrite: !transparent,
        vertexColors: params?.vertexColors ?? false,
    });
}
