<template>
    <div class="container">
        <v-slider
            class="slider"
            v-model="model"
            :min="min"
            :max="max"
            :step="props.step"
            :color="colors['input-blue']"
            track-size="2.5"
            :track-color="colors['formus-outline']"
            thumb-label="always"
            thumb-size="12"
            hide-details
            :disabled="disabled"
        />
        <v-btn
            size="56"
            class="plus-minus-button"
            elevation="1.5"
            @click="decrement"
            :disabled="disabled || minDisabled"
        >
            <v-icon size="24" icon="mdi-minus" :color="colors['input-blue']" />
        </v-btn>
        <v-btn
            size="56"
            class="plus-minus-button"
            elevation="1.5"
            @click="increment"
            :disabled="disabled || maxDisabled"
            :color="colors['increment-grey']"
        >
            <v-icon size="24" icon="mdi-plus" :color="colors['input-blue']"></v-icon>
        </v-btn>
    </div>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped>
    .slider {
        align-self: end;
    }

    .container {
        display: flex;
        gap: 15px;
        align-items: center;
    }

    .plus-minus-button {
        padding: 10px;
        border-radius: 16px;
    }

    :deep(.v-slider-track__background) {
        opacity: 1;
    }
</style>

<script setup lang="ts">
    import colors from './colors';
    import { taggedLogger } from '@/util';
    import { computed } from 'vue';
    import { floatsApproxEqual } from '@/geometry/approxEquals';

    const log = taggedLogger('planner-slider');

    const props = defineProps({
        min: { type: Number, default: 0 },
        max: { type: Number, default: 100 },
        step: { type: Number, default: 1 },
        disabled: { type: Boolean, default: false },
    });
    const model = defineModel<number>();

    function increment() {
        if (model.value !== undefined) {
            model.value += props.step;

            if (model.value > props.max) {
                model.value = props.max;
                log.debug(`Value ${model.value} is greater than max ${props.max}. Setting to max.`);
            }
        }
    }

    function decrement() {
        if (model.value !== undefined) {
            model.value -= props.step;

            if (model.value < props.min) {
                model.value = props.min;
                log.debug(`Value ${model.value} is less than min ${props.min}. Setting to min.`);
            }
        }
    }

    const minDisabled = computed(() => {
        if (model.value === undefined) {
            return true;
        }

        return floatsApproxEqual(model.value, props.min, 0.01);
    });

    const maxDisabled = computed(() => {
        if (model.value === undefined) {
            return true;
        }

        return floatsApproxEqual(model.value, props.max, 0.01);
    });
</script>
