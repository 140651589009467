import '@/assets/main.css';
import { type ComponentPublicInstance, createApp } from 'vue';
import { createPinia } from 'pinia';
import 'vuetify/styles';
import 'anylogger-console';
import App from '@/App.vue';
import router from '@/router';
import { registerAppComponents } from '@/plugins/appComponents';
import { registerLoggingFilters } from '@/plugins/loggingFilters';

import { defineRules } from './plugins/vee-validation';
import { useAppErrorStore } from '@/stores/appErrorStore';
import { configureBugsnag } from '@/plugins/bugsnag';
import { registerVuetify } from '@/plugins/vuetify';
import { configureAmplitude } from '@/plugins/amplitude';
import { configureHotjar } from '@/plugins/hotjar';
import { taggedLogger } from '@/util';

const log = taggedLogger('main');
log.info(`Loading app with environment variables: \n meta.end = ${JSON.stringify(import.meta.env)}`);

if (import.meta.env.MODE === 'mocked-api') {
    console.log('Dev mocked-api mode');
    const { worker } = await import('@mocks/browser');
    await worker.start();
}

defineRules();

const bugsnagVue = configureBugsnag();
configureHotjar()

/**
 * app variable is named `Vue` to resolve an issue in pycharm where
 * the registered components and plugins were not being inferred properly.
 */
const Vue = createApp(App);

if (bugsnagVue) {
    Vue.use(bugsnagVue);
}
configureAmplitude();
Vue.use(createPinia());
Vue.use(router);
Vue.use(registerVuetify());
Vue.use(registerAppComponents);
Vue.use(registerLoggingFilters);
Vue.config.errorHandler = (
    err: unknown,
    instance: ComponentPublicInstance | null,
    info: string,
) => {
    useAppErrorStore().handleVueError(err, instance, info);
};

Vue.mount('#app');