import type { CatalogComponentProperties } from '@/formus/catalog/common';
import { parseInt } from 'lodash';

export const headSystems = ['type1-cocr', '1214-cocr'] as const;
export type HeadSystem = (typeof headSystems)[number];

export function isHeadSystem(value: string): value is HeadSystem {
    return headSystems.includes(value as HeadSystem);
}

export const headSizes = [22, 28, 32, 36] as const;
export type HeadSize = (typeof headSizes)[number];
export type HeadSizeString = `${HeadSize} mm`;
const headSizeRegex = /(\d+) mm/;

export function isHeadSize(value: number): value is HeadSize {
    return headSizes.includes(value as HeadSize);
}

export function headSizeString(size: HeadSize): HeadSizeString {
    return `${size} mm`;
}

export function isHeadSizeString(value: string): value is HeadSizeString {
    return !!value.match(headSizeRegex);
}

export function headSizeFromString(value: string): HeadSize {
    const match = value.match(headSizeRegex);
    if (!match) {
        throw Error(`Invalid head-size string '${value}'`);
    }
    const result = parseInt(match[1]);
    if (!isHeadSize(result)) {
        throw Error(`Invalid head-size ${result}`);
    }
    return result;
}

// Note: All head-types are currently just an empty string
export const headTypes = [''] as const;
export type HeadType = (typeof headTypes)[number];

export function isHeadType(value: string): value is HeadType {
    return headTypes.includes(value as HeadType);
}

const cocrOffsets = [-3.5, 0, 3.5, 7, 10.5] as const;
const type1Offsets = [-6, -3, 0, 3, 6, 9, 12] as const;
export const headOffsets = [...type1Offsets, ...cocrOffsets] as const;
export type HeadOffset = (typeof headOffsets)[number];

export function isHeadOffset(value: number): value is HeadOffset {
    return headOffsets.includes(value as HeadOffset);
}

/** Properties of a type of head, independent of its use in any particular case  */
export type CatalogHead = CatalogComponentProperties & {
    /** The system the head belongs to, which is currently always 'type1-cocr' */
    system: HeadSystem;

    /** The size of the head */
    size: HeadSizeString;

    /** All head-types are currently just an empty string */
    type: HeadType;

    /** Offset */
    offset: HeadOffset;
};
