import { type ApiLinks, getLink, type LinkSelector } from '@/api/links';
import type { Url } from '@/formus/types';

/** A map of property-names to selectors for a link */
export type LinkMap = Record<string, string | LinkSelector>;

export type MappedLinks<Map extends LinkMap> = { [Name in keyof Map]: Url };

/**
 * Convert links in the given link-map
 */
export function convertLinks<Map extends LinkMap>(repr: ApiLinks, linkMap: Map): MappedLinks<Map> {
    return Object.fromEntries(
        Object.entries(linkMap).map(([name, selector]) => [name, getLink(repr, selector)]),
    ) as MappedLinks<Map>;
}

