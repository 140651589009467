<template>
    <div :class="`app-menu-item-wrapper app-menu-item-${tooltip ? 'tooltip' : 'label'}`">
        <component :is="to ? 'router-link' : 'div'" :to="to">
            <div class="app-menu-item" role="button" :aria-label="text">
                <span v-if="!tooltip">{{ text }}</span>
                <v-tooltip activator="parent" location="bottom" v-if="tooltip" :aria-label="text">
                    {{ text }}
                </v-tooltip>
                <div class="icon-wrapper">
                    <v-icon :icon="icon" />
                </div>
            </div>
        </component>
    </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

export interface Props {
    text: string;
    icon: string;
    tooltip?: boolean;
    to?: RouteLocationRaw;
    leftmost?: boolean;
}

const props = defineProps<Props>();
const {text, icon, tooltip, to, leftmost} = toRefs(props);

const leftMargin = computed(() => {
    return leftmost.value ? '0' : '20px';
})

const labelBackgroundColour = computed(() => {
    return props.to ? 'var(--planner-blue)' : 'var(--planner-grey-light)';
});

const backgroundHoverColour = computed(() => {
    return props.to
        ? 'var(--planner-blue-button)'
        : 'var(--planner-grey-light)';
});

const cursor = computed(() => {
    return props.to ? 'pointer' : 'initial';
});
</script>


<style>
    .app-menu-item {
        display: flex;
        align-items: center;
        color: #fff;
        cursor: v-bind('cursor');
    }

    .app-menu-item span {
        padding-right: 8px;
        font-size: 14px;
        font-weight: 600;
    }

    .app-menu-item .icon-wrapper {
        display: flex;
        height: 40px;
        width: 40px;
        border-radius: 100%;
        padding: 8px;
        transition: background-color 0.2s ease-in-out;
    }

    .app-menu-item-label {
        margin-left: 40px;
    }

    .app-menu-item-label .icon-wrapper {
        background-color: var(--planner-blue);
    }

    .app-menu-item-label:hover .icon-wrapper {
        background-color: var(--planner-blue-button);
    }

    .app-menu-item-tooltip {
        margin-left: v-bind('leftMargin');
    }

    .app-menu-item-tooltip .icon-wrapper {
        background-color: v-bind('labelBackgroundColour');
    }

    .app-menu-item-tooltip:hover .icon-wrapper {
        background-color: v-bind('backgroundHoverColour');
    }
</style>
