import { Euler, Matrix4, Vector2, Vector3, Vector4 } from 'three';
import { positionalPart } from './matrix';
import { getBasis, isXyzVectors, type XyzVectors } from './basis';
import { radToDeg } from '@/util';
import type { LpsVectors } from '@/formus/anatomy/LPS';

const DEFAULT_PRECISION = 5;
type FloatFormatOptions = { precision?: number };

export function formatFloat(value: number, options: FloatFormatOptions = {}): string {
    const factor = 10 ** (options.precision ?? DEFAULT_PRECISION);
    return `${Math.round(value * factor) / factor}`;
}

export function formatDistance(value: number, options: FloatFormatOptions = {}): string {
    return `${formatFloat(value, options)}mm`;
}

export function formatArea(value: number, options: FloatFormatOptions = {}): string {
    return `${formatFloat(value, options)}mm\u00b2`;
}

export function formatDegrees(value: number, options: FloatFormatOptions = {}): string {
    return `${formatFloat(value, options)}\u00b0`;
}

export function formatRadiansAsDegrees(value: number, options: FloatFormatOptions = {}): string {
    return `${formatFloat(radToDeg(value), options)}\u00b0`;
}

export function formatRadians(value: number, options: FloatFormatOptions = {}): string {
    return formatFloat(value, options);
}

const DEFAULT_DEGREES = false;
type AngleFormatOptions = FloatFormatOptions & { degrees?: boolean };

export function formatAngle(value: number, options: AngleFormatOptions = {}): string {
    return options.degrees ?? DEFAULT_DEGREES
        ? formatRadiansAsDegrees(value, options)
        : formatRadians(value, options);
}

const DEFAULT_VALUE_SEPARATOR = ', ';
type ArrayFormatOptions = FloatFormatOptions & { valueSeparator?: string };

export function formatFloatArray(value: number[], options: ArrayFormatOptions = {}): string {
    return `[${value
        .map((item: number) => formatFloat(item, options))
        .join(options.valueSeparator ?? DEFAULT_VALUE_SEPARATOR)}]`;
}

export function formatVector(
    value: Vector2 | Vector3 | Vector4,
    options: ArrayFormatOptions = {},
): string {
    return formatFloatArray(value.toArray(), options);
}

export function formatEuler(
    angles: Euler,
    options: ArrayFormatOptions & AngleFormatOptions = {},
): string {
    return `[${(angles.toArray() as number[])
        .slice(0, -1)
        .map((angle: number) => formatAngle(angle, options))
        .join(options.valueSeparator ?? DEFAULT_VALUE_SEPARATOR)}]`;
}

const DEFAULT_LINE_SEPARATOR = '\n';

export function formatMatrixEuler(matrix: Matrix4, options: AngleFormatOptions = {}): string[] {
    return [
        `position: ${formatVector(positionalPart(matrix), options)}`,
        `rotation: ${formatEuler(new Euler().setFromRotationMatrix(matrix), options)}`,
    ];
}

export function formatMatrixBasis(value: Matrix4, options: ArrayFormatOptions = {}): string[] {
    const position = positionalPart(value);
    const { x, y, z } = getBasis(value);
    return [
        `position: ${formatVector(position, options)}`,
        `x: ${formatVector(x, options)}`,
        `y: ${formatVector(y, options)}`,
        `z: ${formatVector(z, options)}`,
    ];
}

export function formatBasis(
    basis: XyzVectors | LpsVectors,
    options: ArrayFormatOptions = {},
): string[] {
    if (isXyzVectors(basis)) {
        const { x, y, z } = basis;
        return [
            `x: ${formatVector(x, options)}`,
            `y: ${formatVector(y, options)}`,
            `z: ${formatVector(z, options)}`,
        ];
    } else {
        const { left, posterior, superior } = basis;
        return [
            `left: ${formatVector(left, options)}`,
            `posterior: ${formatVector(posterior, options)}`,
            `superior: ${formatVector(superior, options)}`,
        ];
    }
}

export function indent(level?: number): (strings: string[]) => string[] {
    if (!level) {
        return (s: string[]) => s;
    } else {
        const indentString = ' '.repeat(level);
        return (strings: string[]) => {
            if (!level) {
                return strings;
            }
            return strings.map((s) => indentString + s);
        };
    }
}

export function joinIndented(level?: number, separator?: string): (strings: string[]) => string {
    const separator_ = (separator ?? DEFAULT_LINE_SEPARATOR) + (level ? ' '.repeat(level) : '');
    return (s: string[]) => {
        return s.join(separator_);
    };
}
