<template>
    <div class="app-title-copy"><slot></slot></div>
</template>

<style scoped>
    .app-title-copy {
        font-size: 14px;
        line-height: 23px;
        color: var(--planner-grey);
        margin: 0 0 40px;
        max-width: 800px;
    }
</style>
