<template>
    <app-card class="w-100 ml-n4">
        <app-text class="headline mb-4">
            Intellijoint Hip Plan QR Code
            <div class="qr-code-logos-container ma-0">
                <img :src="formusLogo" width="150" alt="Formus Labs Logo" />
                <br />
                <img :src="zimmerLogo" width="150" alt="Zimmer Logo" />
            </div>
        </app-text>
        <app-text class="text-body-1 pl-5 pb-2">
            <span class="font-weight-bold"> Case </span>: {{ caseName }} (Plan
            {{ plan.reference_number }})
        </app-text>
        <app-text class="text-body-1 pl-5 pb-2">
            <span class="font-weight-bold"> Surgeon </span>: {{ surgeonName }}
        </app-text>
        <app-text v-if="formattedPatientName" class="text-body-1 pl-5 pb-2">
            <span class="font-weight-bold"> Patient </span>: {{ formattedPatientName }}
        </app-text>
        <v-card-text class="text-center qr-code">
            <QRCode :size="500" :plan="props.plan" />
        </v-card-text>
    </app-card>
</template>

<script setup lang="ts">
    import formusLogo from '@/assets/images/formus-logo-colour-pdf.png';
    import zimmerLogo from '@/assets/images/zimmer-logo-colour-pdf.png';
    import { computed } from 'vue';
    import QRCode from '@/components/case-plan/qr-code/QRCode.vue';
    import { NameUtil } from '@/lib/NameUtil';
    import type { ApiPlan, ApiPlansCase } from '@/api/plan/types';

    export interface Props {
        project: ApiPlansCase;
        plan: ApiPlan;
    }

    const props = defineProps<Props>();

    const caseName = computed((): string => {
        return props.project.name;
    });

    const surgeonName = computed((): string => {
        const surgeon = props.project.surgeon;

        if (surgeon) {
            return NameUtil.format(surgeon.name);
        }

        return '';
    });

    const formattedPatientName = computed((): string => {
        const patient = props.project.patient;

        if (patient) {
            return NameUtil.formatCaseApiPatient(patient);
        }

        return '';
    });
</script>
