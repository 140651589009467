import { defineStore } from 'pinia';
import { cloneDeep, isEmpty } from 'lodash';
import { UploadEvent } from '@/lib/webworkers/types';
import DicomUtils from '@/lib/dicom/DicomUtil';
import { DicomMessageLevel } from '@/lib/dicom/DicomSeries';
import { PatientComparisonUtil } from '@/lib/dicom/PatientComparisonUtil';
import { DicomSeriesUtil } from '@/lib/dicom/DicomSeriesUtil';
import { isUserFriendlyErrorMessage } from '@/api/http';
import type { PatientPersonalInfo } from '@/lib/dicom/types';
import uploadWorker from '@/lib/webworkers/uploadWorker.ts?worker&url';
import { apiBaseUrl } from '@/lib/headMetaTags';
import { assertDefined } from '@/util';
import type { CasePatient, CaseSettingsState, CaseStudy } from '@/stores/caseSettings/types';
import type { UploadState } from '@/stores/case-upload/types';
import { useCaseSettings } from '@/stores/caseSettings/store';
import { ScanUploadState } from '@/stores/case-upload/ScanUploadState';
import assert from 'assert';
import { validatePatientIdentities } from '@/stores/case-upload/validatePatientIdentities';
import { DicomInfoFactory } from '@/lib/dicom/DicomInfoFactory';
import {
    type DicomValidatedResultOutput,
    validateDicoms,
} from '@/stores/case-upload/validateDicoms';
import { comparePatient } from '@/stores/case-upload/comparePatient';
import { createStudy, startStudyProcess } from '@/api/case/request';
import { mapToApiObject } from '@/api/study/mapToApiObject';
import { useVersion } from '@/stores/version';
import type { DicomInfo } from '@/lib/dicom/DicomInfo';

async function saveStudy(
    fileName: string,
    caseId: number,
    toUpload: DicomValidatedResultOutput,
    webComponentVersion: string,
) {
    const studyBody = mapToApiObject(fileName, toUpload.id, toUpload.series, webComponentVersion);
    return await createStudy(caseId, studyBody);
}

const startUpload = async (
    caseId: number,
    webComponentVersion: string,
    state: UploadState,
    caseSettings: CaseSettingsState,
) => {
    state.state = ScanUploadState.UploadingData;

    const toUpload = state.processDicomsResult?.uploadCandidate;

    assert.ok(!!toUpload, 'no candidate to upload');

    state.progress.error = null;
    state.progress.total = toUpload?.series?.items.length ?? null;
    state.progress.current = 0;

    const fileName = 'drag-n-drop upload';
    const studyId = await saveStudy(fileName, caseId, toUpload, webComponentVersion);

    const worker = new Worker(uploadWorker, { type: 'module' });
    state.progress.worker = worker;

    worker.onmessage = async ({ data }: MessageEvent<UploadEvent>) => {
        if (data === UploadEvent.Next) {
            state.progress.current += 1;
        }
        if (data === UploadEvent.Completed) {
            try {
                await startStudyProcess(caseId, studyId, webComponentVersion);

                state.hasUploaded = true;
                state.state = ScanUploadState.Initial;

                caseSettings.case.study = {
                    name: fileName,
                    series: toUpload.id,
                    fileCount: toUpload.series.items.length,
                };
            } catch (err) {
                state.state = ScanUploadState.Error;
                state.error = 'Could not start study processing. Upload again';
            }
        }
    };

    worker.onerror = (error) => {
        console.error(error);
        state.progress.error = 'Error uploading files, please contact support for assistance';
        state.state = ScanUploadState.Error;
    };

    worker.postMessage({
        baseUrl: apiBaseUrl(),
        caseId: caseId,
        studyId: studyId,
        files: cloneDeep(toUpload.series?.items),
        event: UploadEvent.Start,
    });
};

export const useCaseUpload = defineStore({
    id: 'case-upload',
    state: (): UploadState => {
        return {
            state: ScanUploadState.Initial,
            error: '', // this error is shown in the case settings page (used when file parsing fails, before starting the worker)
            hasUploaded: false,

            progress: {
                error: '', // this error is shown in the progress dialog
                current: 0,
                total: null, // total number of files to be uploaded can vary
                worker: null,
            },

            patientIdentityCheck: null,
            patientComparison: null,
            processDicomsResult: null, // the dicom series to be uploaded
        };
    },
    getters: {
        hasMultiplePatientIdentities(state): boolean {
            return state.state === ScanUploadState.PatientInconsistentIdentityError;
        },
        isPatientDicomMismatch(state): boolean {
            return state.state === ScanUploadState.EnteredPatientVsDicomDataMismatch;
        },
        isUploading(state): boolean {
            return state.state === ScanUploadState.UploadingData;
        },
        isWaitingForConfirmation(state): boolean {
            return state.state === ScanUploadState.WaitingForUploadConfirmation;
        },
        isError(state): boolean {
            return state.state === ScanUploadState.Error;
        },
        isModalError(state): boolean {
            return state.state === ScanUploadState.InModalError;
        },
        hasScan(state): boolean {
            return state.hasUploaded || !!this.getStudy;
        },
        getStudy(): CaseStudy | null {
            return useCaseSettings().case.study;
        },
        patient(state): PatientPersonalInfo {
            assert.ok(state.patientIdentityCheck, 'patientIdentityCheck is not defined');
            const sampleDicomFile = state.patientIdentityCheck.sampleFile;

            return PatientComparisonUtil.fromDicom(sampleDicomFile);
        },
    },
    actions: {
        async onUploadFile(files: File[]) {
            this.$reset();

            this.error = '';

            try {
                if (isEmpty(files)) {
                    this.error = 'No files uploaded';
                    return;
                }

                this.state = ScanUploadState.CollectingData;

                const dicoms = await Promise.all(files.map(DicomInfoFactory.makeDicomInfo));

                await this._processDicoms(dicoms);

                if (this.state === ScanUploadState.CollectingData) {
                    // Only continue with the upload if it is not in another fatal / recoverable state
                    await this.upload();
                }
            } catch (error: any) {
                this.state = ScanUploadState.Error;

                if (isUserFriendlyErrorMessage(error)) {
                    this.error = error;
                } else {
                    console.error(error);
                    this.error = 'Error preparing files for upload';
                }
            }
        },

        cancel() {
            if (this.isUploading) {
                console.info('Cancelling files upload');
                if (this.progress.worker) {
                    this.progress.worker.terminate();
                }
            }

            this.state = ScanUploadState.Initial;
        },

        async uploadAnyway(): Promise<void> {
            assert.ok(this.isPatientDicomMismatch, 'wrong state');

            const candidate = this.processDicomsResult?.uploadCandidate?.series;
            assert.ok(!!candidate, 'no candidate');

            // this message is already added to the series now even
            // though the user hasn't technically confirmed yet.
            DicomSeriesUtil.appendMessage(
                candidate.messages,
                DicomMessageLevel.Info,
                'Single series confirmed by user even though manually ' +
                    'entered patient details do not match dicom metadata',
            );

            await this.upload();
        },

        async upload(): Promise<void> {
            const candidate = this.processDicomsResult?.uploadCandidate?.series;
            assert.ok(!!candidate, 'no candidate');

            if (DicomUtils.isSeriesOkForAutoUpload(candidate)) {
                DicomSeriesUtil.appendMessage(
                    candidate.messages,
                    DicomMessageLevel.Info,
                    'Single series auto-uploaded',
                );

                const caseId = useCaseSettings().case.id;
                assert.ok(caseId, 'case id is not defined');

                await startUpload(
                    caseId,
                    useVersion().webComponentVersion,
                    this,
                    useCaseSettings(),
                );
            } else {
                this.state = ScanUploadState.WaitingForUploadConfirmation;
            }
        },

        async userConfirmationUpload(): Promise<void> {
            DicomSeriesUtil.appendMessage(
                assertDefined(this.processDicomsResult?.uploadCandidate?.series?.messages, 'dicom-messages'),
                DicomMessageLevel.Info,
                'Series uploaded by user',
            );

            const caseId = useCaseSettings().case.id;
            assert.ok(caseId, 'case id is not defined');
            await startUpload(caseId, useVersion().webComponentVersion, this, useCaseSettings());
        },

        async _processDicoms(dicoms: DicomInfo[]): Promise<void> {
            this.patientIdentityCheck = await validatePatientIdentities(dicoms);
            if (!this.patientIdentityCheck.isValid) {
                this.state = ScanUploadState.PatientInconsistentIdentityError;
                return;
            }

            this.processDicomsResult = await validateDicoms(dicoms);
            if (!this.processDicomsResult.isValid) {
                this.state = ScanUploadState.InModalError;
                this.error = 'Finding one validated series failed';
                return;
            }

            const casePatient: CasePatient = useCaseSettings().case.patient;
            const comparison = comparePatient(casePatient, this.patient);

            if (!comparison.isSame) {
                this.patientComparison = comparison;
                this.state = ScanUploadState.EnteredPatientVsDicomDataMismatch;
                return;
            }
        },
    },
});
