import { objectNode, type ObjectNode, type ObjectProperties, updateObject } from '@/planner/3d/object';
import { GridHelper } from 'three';
import { type StopHandle } from '@/util';
import type { SceneContext } from '@/planner/3d/SceneContext';
import type { NodeId } from '@/planner/3d/nodeId';

export type GridNode = ObjectNode<'grid'>;

export function gridNode(id: NodeId, properties?: ObjectProperties<GridNode>): GridNode {
    return objectNode('grid', id, properties);
}

export function updateGrid(context: SceneContext, node: GridNode): StopHandle {
    const grid = new GridHelper(2000, 20, '#808080', '#4a4a4a');
    return updateObject(context, node, grid);
}
