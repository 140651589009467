<template>
    <v-expansion-panel-title>
        <div class="icon" v-if="imageUrl">
            <v-badge v-if="stemWarning" class="mt-2" color="white" offset-x="25" overlap>
                <template #badge>
                    <v-icon size="25" color="planner-yellow">mdi-alert</v-icon>
                    <v-tooltip right activator="parent">
                        <span> Review stem selection </span>
                    </v-tooltip>
                </template>
                <img :src="imageUrl" :alt="title" />
            </v-badge>
            <img v-else :src="imageUrl" :alt="title" />
        </div>
        <div class="wrapper">
            <div class="title">{{ title }}</div>
            <div v-if="description" class="description">
                {{ description }}
            </div>
        </div>
    </v-expansion-panel-title>
</template>

<script setup lang="ts">
    import stemImageUrl from '@/assets/planner/stem-icon.png';
    import cupImageUrl from '@/assets/planner/cup-icon.png';
    import hipImageUrl from '@/assets/planner/hip-combined-icon.png';
    import { computed } from 'vue';

    export interface Props {
        image?: 'stem' | 'cup' | 'hip';
        title: string;
        description?: string;
        stemWarning?: boolean;
    }

    const props = withDefaults(defineProps<Props>(), { stemWarning: false });

    const imageUrlMap: Record<string, string> = {
        stem: stemImageUrl,
        cup: cupImageUrl,
        hip: hipImageUrl,
    };

    const imageUrl = computed(() => (props.image ? imageUrlMap[props.image] : undefined));
</script>

<style scoped>
    .wrapper {
        display: flex;
        flex-direction: column;
        height: 35px;
        justify-content: center;
    }

    .title {
        font-weight: 500;
        line-height: 1rem;
        margin-bottom: 2px;
        font-size: 0.75rem;
        letter-spacing: 0.1666666667em;
        text-transform: uppercase;
    }

    .description {
        font-weight: 700;
        font-size: 0.8125rem;
        line-height: 1rem;
        color: var(--planner-black-2);
    }

    .icon img {
        width: 40px;
        height: 40px;
        position: relative;
        left: -10px;
    }
</style>
