<template>
    <app-notification
        v-if="
            show &&
            !upload.hasUploaded &&
            !(store.isError || upload.error) &&
            !store.isCaseDeemedUnsuitable
        "
        title="Your Case Settings were successfully saved!"
        message="You are now able to upload CT scans"
        variant="success"
        :animate-to="true"
    />
</template>

<script setup lang="ts">
    import { useCaseSettings } from '@/stores/caseSettings/store';
    import { onBeforeUnmount, ref } from 'vue';
    import { useCaseUpload } from '@/stores/case-upload/store';
    import { useSavingHooks } from '@/stores/shared/useSavingHooks';

    const store = useCaseSettings();
    const upload = useCaseUpload();

    const show = ref(false);

    const { watchSuccessOnSaving } = useSavingHooks();

    const watcher = watchSuccessOnSaving(() => {
        show.value = true;
    });

    onBeforeUnmount(() => {
        watcher();
    });
</script>
