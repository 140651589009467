import type { DicomInfo } from '@/lib/dicom/DicomInfo';
import DicomUtils from '@/lib/dicom/DicomUtil';
import assert from 'assert';

export class DicomPatientIdentityCheck {
    constructor(public readonly identities: DicomInfo[][]) {}

    get isValid() {
        return this.identities.length === 1;
    }

    get value() {
        assert.ok(this.isValid, 'cannot get value of invalid patient');
        return this.identities[0];
    }

    get sampleFile() {
        return this.value[0];
    }
}

export async function validatePatientIdentities(
    dicoms: DicomInfo[],
): Promise<DicomPatientIdentityCheck> {
    const groupedByPatientId = await DicomUtils.groupByIdentity(dicoms);

    if (!groupedByPatientId) {
        throw new Error('Failed to parse files');
    }

    const patientIdentities = Object.values(groupedByPatientId);

    if (patientIdentities.length === 0) {
        throw new Error('No patient identity found');
    }

    return new DicomPatientIdentityCheck(patientIdentities);
}
