import { assert } from '@/util';
import { convertLinks } from '@/api/convertLinks';
import type { ApiCatalogComponentBase } from '@/api/catalog/base';
import { isLinerSize } from '@/formus/catalog/liner';
import { isCupSystem } from '@/formus/catalog/cup';
import { bearingInnerDiameterFromString, bearingOuterDiameterFromString, type CatalogBearing } from '@/formus/catalog/bearing';

export type ApiCatalogBearing = ApiCatalogComponentBase & {
    liner_size: string;
    inner_diameter: string;
    outer_diameter: string;
};

const bearingLinkMap = {
    self: 'self',
} as const;

/**
 * Convert a bearing representation from the api into a catalog-bearing
 */
export function catalogBearing(apiBearing: ApiCatalogBearing): CatalogBearing {
    const { name, system, type, size, liner_size, inner_diameter, outer_diameter } = apiBearing;
    assert(isCupSystem(system));
    assert(isLinerSize(liner_size));
    return {
        name,
        system,
        type,
        size,
        linerSize: liner_size,
        innerDiameter: bearingInnerDiameterFromString(inner_diameter),
        outerDiameter: bearingOuterDiameterFromString(outer_diameter),
        ...convertLinks(apiBearing, bearingLinkMap),
    };
}
