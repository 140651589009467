<template>
    <app-card class="app-card-wrapper" data-testid="app-case-card">
        <app-case-card-badge :case-status="status" />
        <div class="app-card-content">
            <app-case-card-content label="Case Name">
                <h4>{{ title }}</h4>
            </app-case-card-content>
            <app-case-card-content label="Case Description" :text="description" v-if="description">
                {{ description }}
            </app-case-card-content>

            <div class="app-card-content-tray">
                <app-case-card-content label="Patient" :text="patient" v-if="patient">
                    {{ patient }}
                </app-case-card-content>
                <app-case-card-content label="Surgeon" :text="surgeon" v-if="surgeon">
                    {{ surgeon }}
                </app-case-card-content>
                <app-case-card-content label="Owner" :text="owner" v-if="owner">
                    {{ owner }}
                </app-case-card-content>
                <app-case-card-content label="Created" :text="createdAt">
                    {{ createdAt }}
                </app-case-card-content>
                <app-case-card-content label="Ref" :text="reference">
                    {{ reference }}
                </app-case-card-content>
            </div>
        </div>
        <div class="app-card-actions">
            <app-menu-item
                icon="mdi-folder-information"
                text="Case Information"
                tooltip
                :to="action(ROUTES.EDIT_CASE)"
                leftmost
            />
            <app-menu-item icon="mdi-camera" text="CT Scans" tooltip :to="action(ROUTES.CT_SCAN)" />
            <app-menu-item icon="mdi-cube" text="3D Planner" tooltip :to="action(ROUTES.PLANNER)" />
            <app-menu-item
                icon="mdi-file-account"
                text="Plans"
                tooltip
                :to="action(ROUTES.PLANS)"
            />
        </div>
    </app-card>
</template>

<script setup lang="ts">
    import AppCaseCardContent from '@/components/case-card/AppCaseCardContent.vue';
    import AppCaseCardBadge from '@/components/case-card/AppCaseCardBadge.vue';
    import AppMenuItem from '@/components/AppMenuItem.vue';
    import AppCard from '@/components/AppCard.vue';
    import { ROUTES } from '@/router';
    import type { CaseStatus } from '@/api/caseSearch';

    import { enabledActionsByStatus } from '@/stores/navigation/enabledActionsByStatus';

    export interface Props {
        title: string;
        description: string;
        reference: string;
        createdAt: string;
        owner: string;
        status: CaseStatus;
        surgeon?: string;
        patient?: string;
        id?: string;
    }

    const props = defineProps<Props>();

    function action(route: ROUTES) {
        if (enabledActionsByStatus(props.status).includes(route)) {
            return {
                name: route,
                params: {
                    id: props.id,
                },
            };
        }
    }
</script>

<style scoped>
    .app-card-wrapper {
        display: flex;
    }

    .app-card-content {
        width: 100%;
    }

    .app-card-content h4 {
        font-size: 21px;
        line-height: 34px;
        color: var(--planner-black);
        font-weight: 400;
    }

    .app-card-content-tray {
        width: 100%;
        display: flex;
    }

    .app-card-content-tray > * {
        margin-right: 40px;
    }

    .app-card-actions {
        display: flex;
        align-items: flex-start;
    }
</style>
