import type { ObjectNode } from '@/planner/3d/object';
import { formatNodeId, type NodeId } from '@/planner/3d/nodeId';
import { Matrix4 } from 'three';

export type NodeMap<NodeType extends ObjectNode> = Map<NodeId, NodeType>;

export function getNode<Node extends ObjectNode>(map: NodeMap<Node>, id: NodeId): Node {
    const result = map.get(id);
    if (result !== undefined) {
        return result;
    } else {
        throw Error(`Could not get node ${formatNodeId(id)}`);
    }
}

export function calculateWorldTransform<Node extends ObjectNode>(map: NodeMap<Node>, id: NodeId): Matrix4 {
    const result = new Matrix4();
    let id_: NodeId | null = id;
    do {
        const node: Node = getNode(map, id_);
        result.premultiply(node.transform);
        id_ = node.parent;
    } while (id_ !== null);
    return result;
}
