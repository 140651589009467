/**
 * Implements software requirements: H1SR-120
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-120/The-software-version-is-displayed-on-user-facing-parts-of-the-software-and-documents
 */

import { type AxiosResponse, HttpStatusCode } from 'axios';
import { isEmpty } from 'lodash';
import { client } from '@/api/http';

export interface Version {
    acid: string;
    acidDeployTime: string;
}

function isValidApiResponse(data: Version) {
    return !isEmpty(data?.acid) && !isEmpty(data?.acidDeployTime);
}

/**
 * displaying the medical device version at all times is a software requirement.
 */
export const getVersion = (): Promise<Version> =>
    client.get<Version>('/version?format=json').then(({ status, data }: AxiosResponse<Version>) => {
        if (status === HttpStatusCode.Ok && isValidApiResponse(data)) {
            return { ...data };
        } else {
            // don't handle common errors because failure to get
            // the medical device version for any reason is a serious problem
            throw Error('Failed to get medical device version');
        }
    });
