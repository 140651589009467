import type { CatalogCup } from '@/formus/catalog/cup';
import type { CatalogLiner, LinerSize, LinerType } from '@/formus/catalog/liner';
import {
    type CatalogHead,
    type HeadOffset,
    type HeadSize,
    headSizeFromString,
    type HeadSizeString,
    headSizeString,
    type HeadSystem,
} from '@/formus/catalog/head';
import type { CatalogStem } from '@/formus/catalog/stem';
import type { Url } from '@/formus/types';
import { filter } from 'ramda';
import type { BearingInnerDiameter, CatalogBearing } from '@/formus/catalog/bearing';

export const DUAL_MOBILITY_HEAD_SIZE = 28;

export type ComponentCatalog = {
    cups: Map<Url, CatalogCup>;
    liners: Map<Url, CatalogLiner>;
    bearings: Map<Url, CatalogBearing>;
    heads: Map<Url, CatalogHead>;
    stems: Map<Url, CatalogStem>;
};

export function findCatalogLiner(
    catalog: ComponentCatalog,
    size: LinerSize,
    headSize: HeadSize | HeadSizeString,
    type: LinerType = 'neutral',
): CatalogLiner {
    if (typeof headSize === 'string') {
        headSize = headSizeFromString(headSize);
    }
    return findComponent(
        catalog.liners,
        `liner with size ${size}, type ${type} and head-size ${headSize}`,
        liner => liner.size === size && liner.headSize === headSize && liner.type === type,
    );
}

export function findBearing(
    catalog: ComponentCatalog,
    linerSize: LinerSize,
    headSize: BearingInnerDiameter,
): CatalogBearing {
    return findComponent(
        catalog.bearings,
        `bearing with liner size ${linerSize} and head size ${headSize}`,
        bearing => bearing.linerSize === linerSize && bearing.innerDiameter === headSize,
    );
}

export function getCatalogComponent<T>(components: Map<Url, T>, url: Url): T {
    const result = components.get(url);
    if (result !== undefined) {
        return result;
    }
    throw Error(`Failed to get component matching uri ${url}`);
}

export function findHead(
    catalog: ComponentCatalog,
    system: HeadSystem,
    size: HeadSize | HeadSizeString,
    offset: HeadOffset,
): CatalogHead {
    if (typeof size === 'number') {
        size = headSizeString(size);
    }
    return findComponent(
        catalog.heads,
        `head with system ${system}, size ${size}, and offset ${offset}`,
        head => head.system === system && head.size === size && head.offset === offset,
    );
}

function findComponent<T>(
    components: Map<Url, T>,
    description: string,
    predicate: (component: T) => boolean,
): T {
    const results = filter(predicate, Array.from(components.values()));
    if (results.length === 1) {
        return results[0];
    }
    if (results.length == 0) {
        throw Error(`Failed to find ${description}`);
    } else {
        throw Error(`Found multiple components matching ${description}`);
    }
}

