import type { CupFormInputs } from '@/stores/preferences';
import type { StateHolder } from '@/stores/shared/dataState';
import type { CaseSettingsOptions } from '@/api/case/types';

export enum OperativeSide {
    Left = 'left',
    Right = 'right',
}

export enum PatientSex {
    Male = 'male',
    Female = 'female',
}

export interface CaseSurgery {
    name: string;
    side: OperativeSide | null;
    description: string;
    procedure: string;
    date: string;
}

export interface CasePatient {
    firstName: string;
    middleNames: string;
    familyName: string;
    sex: PatientSex | null;
    dateOfBirth: string;
}

export interface CaseStudy {
    name: string;
    series: string;
    fileCount: number;
}

export type CaseSpecifications = {
    surgeon: {
        selected: number | null;
        options: CaseSettingsOptions['surgeons'];
        error: boolean;
    };
    stems: {
        preferredSystem: string;
        selected: string[];
        options: CaseSettingsOptions['stems'];
    };
    cup: CupFormInputs;
    targets: {
        legLength: string;
        offset: string;
    };
};

export interface CaseSettingsInputs {
    id: number | null;
    owner_id: number | null;
    study: CaseStudy | null;
    surgery: CaseSurgery;
    patient: CasePatient;
    specifications: CaseSpecifications;
}

export type CaseSettingsState = {
    case: CaseSettingsInputs;
    _lastSavedCase: CaseSettingsInputs;
    displayErrors: boolean;
} & StateHolder;
