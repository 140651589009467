import { assertIs } from '@/util';
import { convertLinks } from '@/api/convertLinks';
import type { ApiCatalogComponentBase } from '@/api/catalog/base';
import { type CatalogLiner, isLinerSize, isLinerType } from '@/formus/catalog/liner';
import { isCupSystem } from '@/formus/catalog/cup';
import { headSizeFromString } from '@/formus/catalog/head';

export type ApiCatalogLiner = ApiCatalogComponentBase & {
    head_size: string;
};

const linerLinkMap = {
    self: 'self',
} as const;

/**
 * Convert a liner representation from the api into a catalog-liner
 */
export function catalogLiner(apiLiner: ApiCatalogLiner): CatalogLiner {
    const { name, system, type, size, head_size } = apiLiner;
    assertIs(isCupSystem, system);
    assertIs(isLinerType, type);
    assertIs(isLinerSize, size);
    return {
        name,
        system,
        type,
        size,
        headSize: headSizeFromString(head_size),
        ...convertLinks(apiLiner, linerLinkMap),
    };
}
