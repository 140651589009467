<template>
    <v-card-title class="app-modal-title">
        <h3>
            <slot></slot>
        </h3>
    </v-card-title>
</template>

<style scoped>
    .app-modal-title {
        padding: 40px 40px 20px 40px;
    }
    h3 {
        font-size: 21px;
        line-height: 34px;
        font-weight: 400;
        color: var(--planner-blue);
    }
</style>
