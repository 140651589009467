/**
 * Implements software requirements: H1SR-114
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-114/Revoke-user-authentication-after-set-period-of-user-inactivity
 */

import { defineStore } from 'pinia';
import { DateTime, Interval } from 'luxon';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import debounce from 'lodash/debounce';

import { ROUTES } from '@/router';
import { startTracking, stopTracking } from '@/lib/browserEvents';
import { persisted } from '@/lib/localStorage';
import { LOGOUT_REASON, useAuth } from '@/stores/auth';
import { useConfig } from '@/stores/config';

const ONE_SECOND = 1000;
const THREE_SECONDS = ONE_SECOND * 3;

export const useIdleTimeout = defineStore('idle-timeout', () => {
    const authStore = useAuth();
    const router = useRouter();
    const config = useConfig();

    let interval: ReturnType<typeof setInterval>;

    const timeout = ref(0);
    const lastInteraction = persisted('lastInteraction');

    const recordTimeoutChange = () => {
        timeout.value = Interval.fromDateTimes(
            DateTime.fromISO(lastInteraction.value),
            DateTime.now(),
        ).length('milliseconds');
    };
    const recordInteraction = () => {
        lastInteraction.value = String(DateTime.now());
    };
    const debouncedRecordInteraction = debounce(recordInteraction, 300, { maxWait: THREE_SECONDS });

    const showWarning = computed(() => timeout.value > config.timeout.warning);
    const hasTimedOut = computed(() => timeout.value > config.timeout.limit);
    const countdown = computed(() =>
        Math.floor((config.timeout.limit - timeout.value) / ONE_SECOND),
    );

    /**
     * Start tracking user interactions through the browser.
     * Periodically check whether the user has timed out yet.
     */
    const startIdleTimeout = () => {
        startTracking({ onUserAction: debouncedRecordInteraction });

        interval = setInterval(() => {
            recordTimeoutChange();

            /**
             * Stop tracking the user interactions when the warning dialog pops up.
             */
            if (showWarning.value) {
                stopTracking({ onUserAction: debouncedRecordInteraction });
            }

            /**
             * The user has actually timed out due to inactivity.
             * Log them out and redirect them to the login page.
             */
            if (hasTimedOut.value) {
                router.push({ name: ROUTES.LOGIN });
                authStore.logout(LOGOUT_REASON.IDLE_TIMEOUT);
            }
        }, ONE_SECOND);
    };

    /**
     * Stop tracking user interactions and stop
     * checking whether user has timed out yet.
     */
    const stopIdleTimeout = () => {
        stopTracking({ onUserAction: debouncedRecordInteraction });
        clearInterval(interval);
    };

    /**
     * When the user takes action on the warning dialog,
     * the idle timeout is reset.
     */
    const resetIdleTimeout = () => {
        recordInteraction();
        startTracking({ onUserAction: debouncedRecordInteraction });
    };

    /**
     * Start the idle timer when the user logs in
     * and stop the idle timer when the user logs out.
     */
    watch(
        () => authStore.isLoggedIn,
        (isLoggedIn: boolean) => {
            if (isLoggedIn) {
                recordInteraction();
                startIdleTimeout();
            } else {
                stopIdleTimeout();
            }
        },
    );

    return { timeout, countdown, showWarning, hasTimedOut, reset: resetIdleTimeout };
});
