import { watch, watchEffect } from 'vue';
import { assert, type LogFunction, stopAll, type StopHandle } from '@/util';
import { useDebounceFn, watchImmediate } from '@vueuse/core';
import type { BodySide } from '@/formus/anatomy/side';
import type { AcetabularAngles } from '@/formus/anatomy/pelvis/acetabularAngles';
import { Matrix4, type Vector3 } from 'three';
import {cross, vector3} from '@/geometry/vector3';
import { isRigidMatrix, positionalPart } from '@/geometry/matrix';
import {
    formatFloat,
    formatMatrixBasis,
    formatRadians,
    formatRadiansAsDegrees,
    formatVector, indent,
    joinIndented,
} from '@/geometry/formatMath';
import type { AnatomicalOffset } from '@/formus/anatomy/pelvis/anatomicalOffset';
import { updateCupCoverage } from '@/planner/cupCoverage/updateCupCoverage';
import type { PlannerStore } from '@/planner/plannerStore';
import { logValidation } from '@/planner/logValidation';
import { cupWorldOffsetFromApi, type FittedCup } from '@/planner/fittedCup';
import { planeTransform } from '@/planner/3d/plane';
import { useDeveloperSettings } from '@/planner/developerSettings';
import { computeCupCollisionSurfaceUrl, computeLinerUrl } from '@/planner/componentUrls';
import { updateCrossSection } from '@/planner/scene/crossSection';

/** Updates to the planner-store that relate to the cup and liner */
export function updateCup(store: PlannerStore): StopHandle {
    const settings = useDeveloperSettings();
    return stopAll(
        watchImmediate(
            () => store.plannerMode !== 'stem',
            (show) => store.nodes.cupGroup.visible = show,
        ),
        watchEffect(() => {
            store.nodes.cup.geometrySource = store.template?.cupUrl ?? null;
        }),
        watchEffect(() => {
            store.nodes.liner.geometrySource = (store.template && store.catalog ? computeLinerUrl(store) : null);
        }),
        watchEffect(() => {
            store.nodes.cupCollisionSurface.geometrySource = computeCupCollisionSurfaceUrl(store);
        }),
        watchImmediate(
            () => store.fittedCup !== null && settings.show3dFeatures === true,
            (show) => {
                [
                    store.nodes.cupNormalAxis,
                    store.nodes.cupApAxis,
                    store.nodes.cupSiAxis,
                ].forEach((node) => node.visible = show);
            },
        ),
        watchEffect(() => _updateCupComponents(store)),
        watchEffect(() => _updateCupTransform(store)),
        _logCupGroupTransforms(store),
        updateCupCoverage(store, store.nodes),
        updateCrossSection(store, store.nodes.cupCoronalCrossSection),
    );
}

function _updateCupComponents(store: PlannerStore): void {
    if (!store.fittedCup) {
        return;
    }
    const cupTransform = store.fittedCup.cupTransform;
    const linerTransform = store.fittedCup.linerTransform;
    const fittedHJC = store.fittedCup.fittedHjc;

    // We use the fitted cup-group transform here in order to calculate the transform of cup liner
    // features relative to the cup-group.
    const cupGroupTransform = cupTransform.clone().setPosition(fittedHJC);

    // The final cup-group transform will be calculated from the cup position and rotation,
    // but we set it here anyway.
    store.nodes.cupGroup.transform.copy(cupGroupTransform);

    // Transformation from world (CT) space to cup-group space
    const worldToCupGroup = cupGroupTransform.clone().invert();

    // Set cup-mesh transforms
    const localCupTransform = worldToCupGroup.clone().multiply(cupTransform);
    store.nodes.cup.transform.copy(localCupTransform);
    store.nodes.cupCoverage.transform.copy(localCupTransform);
    store.nodes.cupCollisionSurface.transform.copy(localCupTransform);

    // Set liner transform
    store.nodes.liner.transform.copy(worldToCupGroup.clone().clone().multiply(linerTransform));

    // Set axis transforms
    store.nodes.cupNormalAxis.direction.copy(store.fittedCup.normal).transformDirection(worldToCupGroup);
    store.nodes.cupApAxis.direction.copy(store.fittedCup.apVector).transformDirection(worldToCupGroup);
    store.nodes.cupSiAxis.direction.copy(store.fittedCup.siVector).transformDirection(worldToCupGroup);

    // Setup coronal cross-section transform
    store.nodes.cupCoronalCrossSection.transform.copy(
        planeTransform(
            positionalPart(store.nodes.cup.transform),
            store.fittedCup.apVector.clone().transformDirection(worldToCupGroup),
        ),
    );
}

function _updateCupTransform(store: PlannerStore): StopHandle {
    const logRotation = useDebounceFn(logValidation, 500, { maxWait: 1000 });
    const logPosition = useDebounceFn(logValidation, 500, { maxWait: 1000 });

    return watchEffect(() => {
        if (!store.fittedCup || !store.template || !store.case) {
            return;
        }
        const rotation = _calculateCupRotation(
            store.case.operationalSide, store.fittedCup, store.template.cupRotation, logRotation);
        const position = _calculateCupPosition(store.fittedCup, store.template.cupOffset, logPosition);

        // Set the position of the cupGroup
        store.nodes.cupGroup.transform.copy(rotation).setPosition(position);
    });
}

function _calculateCupPosition(
    fittedCup: FittedCup,
    offset: AnatomicalOffset,
    log: LogFunction,
): Vector3 {
    const nativeHjc = fittedCup.basis.position.clone();
    const cupPosition = cupWorldOffsetFromApi(fittedCup, offset).add(nativeHjc);
    log([
        'Cup position:',
        `  native hjc: ${formatVector(nativeHjc)}`,
        `  offsets: si: ${formatFloat(offset.si)}  ap: ${formatFloat(offset.ap)}  ml: ${formatFloat(offset.ml)}`,
        '  anatomical basis:',
        `    si: ${formatVector(fittedCup.basis.inferior)}`,
        `    ap: ${formatVector(fittedCup.basis.posterior)}`,
        `    ml: ${formatVector(fittedCup.basis.lateral)}`,
        `  cup-position: ${formatVector(cupPosition)}`,
    ].join('\n'));

    return cupPosition;
}

function _calculateCupRotation(
    side: BodySide,
    fittedCup: FittedCup,
    angles: AcetabularAngles<'anatomic'>,
    log: LogFunction,
): Matrix4 {
    let { anteversion, inclination } = angles;
    if (side === 'right') {
        anteversion = -anteversion;
        inclination = -inclination;
    }

    const { posterior, inferior } = fittedCup.basis;
    const anterior = posterior.clone().negate();

    // In the compute code the transformation is implicit in the calculation of the normal, x and y vectors of
    // the 'cup plane', so those variable names are retained here.
    const normal = inferior.clone()
        .applyAxisAngle(anterior, inclination)
        .applyAxisAngle(inferior, anteversion)
        .normalize()
        .negate();
    const x = cross(anterior, normal).normalize();
    const y = cross(normal, x).normalize();

    // This is the mapping from a 'plane' to a transform matrix that is applied in this python function:
    // acid.lib.atlas.acetabularcup.acetabular_cup.AcetabularCupAtlas.transform_2_plane
    const rotation = new Matrix4().makeBasis(x.negate(), normal, y);

    // make sure the cup holes are aligned correctly if it is a left operative hip. This is needed as we need to
    // maintain a RHS coordinate system on both left and right operative sides
    if (side === 'left') {
        // Hacky fix to rotate left cups 90 degrees to align the holes superiorly and posteriorly
        rotation.multiply(new Matrix4().makeRotationAxis(vector3(0, 1, 0), -1.5708))
    }

    assert(isRigidMatrix(rotation), 'Rotation is not a rigid transform');

    log([
        'Cup rotation:',
        `  anteversion: ${formatRadians(anteversion)} (${formatRadiansAsDegrees(anteversion)})`,
        `  inclination: ${formatRadians(inclination)} (${formatRadiansAsDegrees(inclination)})`,
        '  anatomical basis:',
        `    si: ${formatVector(inferior)}`,
        `    ap: ${formatVector(posterior)}`,
        `  cup-normal: ${formatVector(normal)}`,
        `  cup-x: ${formatVector(x)}`,
        `  cup-y: ${formatVector(y)}`,
    ].join('\n'));

    return rotation;
}

function _logCupGroupTransforms(store: PlannerStore): StopHandle {
    return watch(
        () =>
            store.isLoading
                ? null
                : {
                    cupGroup: store.nodes.cupGroup.transform,
                    cup: store.nodes.cup.transform,
                    liner: store.nodes.liner.transform,
                },
        (transforms) => {
            if (transforms) {
                const { cupGroup, cup, liner } = transforms;
                const cupWorld = cupGroup.clone().multiply(cup);
                const linerWorld = cupGroup.clone().multiply(liner);
                logValidation(joinIndented(2)([
                    `Cup-group transforms:`,
                    'cup-group:',
                    ...indent(2)(formatMatrixBasis(cupGroup)),
                    'cup (world):',
                    ...indent(2)(formatMatrixBasis(cupWorld)),
                    'liner (world):',
                    ...indent(2)(formatMatrixBasis(linerWorld)),
                ]));
            }
        },
        { deep: true },
    );
}

