/**
 * Implements software requirements: H1SR-76, H1SR-78, H1SR-79, H1SR-140
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-76/When-a-case-is-created-it-is-added-to-the-user-dashboard
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-79/Summaries-of-each-cases-data-should-be-displayed-on-the-dashboard
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-78/Cases-on-dashboard-load-only-once-they-are-scrolled-to
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-140/Users-can-search-through-their-cases-on-the-Case-Dashboard
 */

import type { AxiosResponse } from 'axios';
import { HttpStatusCode } from 'axios';
import type { DateTimeFormatOptions } from 'luxon';
import { DateTime } from 'luxon';
import { isEmpty, isNumber } from 'lodash';
import { client, expectedErrorOrRethrow, type UserFriendlyError } from '@/api/http';

import { logger } from '@/util';

const log = logger('caseSearch');

interface ApiResponse {
    count: number;
    next: string | null;
    previous: string | null;
    results: ApiResult[];
}

function isValidApiResponse({ count, results }: ApiResponse): boolean {
    return isNumber(count) && results.every(isValidApiResult);
}

interface ApiResult {
    id: number;
    status: string;
    name: string;
    description?: string;
    created_at: string;
    updated_at?: string;
    patient?: {
        full_name: string;
    };
    surgeon?: {
        full_name: string;
    };
    owner?: {
        full_name: string;
    };
}

function isValidApiResult({ id, status, name, created_at }: ApiResult): boolean {
    return isNumber(id) && !isEmpty(status) && !isEmpty(name) && !isEmpty(created_at);
}

export interface SearchResult {
    count: number;
    pages: {
        current: number;
        previous: number | null;
        next: number | null;
    };
    cases: CaseListing[];
}

export function pageNumberOf(paginatedLink: string | null): number | null {
    if (!paginatedLink) {
        return null;
    }
    const [, queryString] = paginatedLink.split('?');
    const pageParam = new URLSearchParams(queryString).get('page');
    return pageParam ? Number(pageParam) : 1;
}

function currentPageOf(previousPage: number | null, nextPage: number | null): number {
    if (previousPage) {
        return Number(previousPage) + 1;
    } else if (nextPage) {
        return Number(nextPage) - 1;
    } else {
        return 1;
    }
}

function createSearchResult({ previous, next, count, results }: ApiResponse): SearchResult {
    const previousPageNumber = pageNumberOf(previous);
    const nextPageNumber = pageNumberOf(next);
    const currentPageNumber = currentPageOf(previousPageNumber, nextPageNumber);

    return {
        count,
        pages: {
            previous: previousPageNumber,
            current: currentPageNumber,
            next: nextPageNumber,
        },
        cases: results.map(createListing),
    };
}

export interface CaseListing {
    id: string;
    status: CaseStatus;
    name: string;
    createdAt: string;
    description?: string;
    patient?: string;
    surgeon?: string;
    owner?: string;
}

export enum CaseStatusState {
    New = 'new',
    ProcessingCatStack = 'processing-cat-stack',
    Processing = 'processing',
    Complete = 'complete',
    Warning = 'warning',
    Unknown = 'unknown',
}

export type CaseStatus = { state: CaseStatusState; failed: boolean };

function createStatus(status: any): CaseStatus {
    if (
        [
            CaseStatusState.New,
            CaseStatusState.ProcessingCatStack,
            CaseStatusState.Processing,
            CaseStatusState.Complete,
            CaseStatusState.Warning,
            CaseStatusState.Unknown,
        ].includes(status.state)
    ) {
        return status;
    } else {
        throw `unknown case status: ${status}`;
    }
}

function createListing(result: ApiResult): CaseListing {
    const format: DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' };
    const createdDate = DateTime.fromISO(result.created_at).toLocaleString(format);

    return {
        id: String(result.id),
        status: createStatus(result.status),
        name: result.name,
        createdAt: createdDate,
        description: result.description ?? String(result.description),
        patient: result.patient?.full_name,
        surgeon: result.surgeon?.full_name,
        owner: result.owner?.full_name,
    };
}

/**
 * search for all cases visible to the currently logged-in user
 *
 * @param query a fulltext search string, is optional
 * @param page the current page within the search context
 */
export async function search(
    query?: string,
    page?: number,
): Promise<SearchResult | UserFriendlyError> {
    const queryParams = new URLSearchParams();

    if (query) {
        queryParams.append('q', query);
    }
    if (page) {
        queryParams.append('page', String(page));
    }
    const failError = 'Failed to search cases' as const;

    try {
        const { status, data }: AxiosResponse = await client.get(`/cases/?${queryParams}`);
        if (status === HttpStatusCode.Ok && isValidApiResponse(data)) {
            return createSearchResult(data);
        } else {
            return status === HttpStatusCode.BadRequest && data?.detail
                ? `${failError}: ${data?.detail}`
                : failError;
        }
    } catch (error) {
        log.error('Error searching cases %s', error);
        return expectedErrorOrRethrow(error, failError);
    }
}
