import { Matrix3, Matrix4, Vector3 } from 'three';
import { type AnyVector3, vector3 } from '@/geometry/vector3';
import { arraysApproxEqual, DEFAULT_TOLERANCE, matricesApproxEqual } from '@/geometry/approxEquals';

export function identity4(): Matrix4 {
    return new Matrix4();
}

/**
 * Returns a Matrix3 using the upper 3x3 elements of a Matrix4
 */
export function getRotationMatrix(matrix4: Matrix4): Matrix3 {
    return new Matrix3().setFromMatrix4(matrix4);
}

/**
 * Given a matrix4, it takes the upper 3x3 elements of the matrix, and apply it to a vector
 */
export function applyOnlyRotationMatrix(matrix: Matrix4, value: AnyVector3): Vector3 {
    const m3 = getRotationMatrix(matrix);
    return vector3(value).applyMatrix3(m3);
}

/**
 * Given a matrix4, it takes the upper 3x3 elements of the matrix, and apply it to a vector
 */
export function applyMatrix4(matrix: Matrix4, value: AnyVector3): Vector3 {
    return vector3(value).applyMatrix4(matrix);
}

/**
 * Check whether a Matrix3 is a pure rotation matrix (orthogonal and not mirrored).
 */
export function isRotationalMatrix(matrix: Matrix3, tolerance = DEFAULT_TOLERANCE): boolean {
    // M is a rotational matrix if and only if M is orthogonal i.e.:
    // M * transpose(M) == I, and det(M) = 1
    if (Math.abs(matrix.determinant() - 1) > tolerance) {
        return false;
    } else {
        return matricesApproxEqual(new Matrix3(), matrix.clone().transpose().multiply(matrix), tolerance);
    }
}

/**
 * Returns the rotational part of a Matrix4 (the top-left 3x3 elements) as a Matrix3.
 */
export function rotationalPart(matrix4: Matrix4): Matrix3 {
    return new Matrix3().setFromMatrix4(matrix4.clone());
}

/**
 * Return the position part of a Matrix4 (the top-right 3x1 elements) as a Vector3.
 */
export function positionalPart(matrix: Matrix4): Vector3 {
    return new Vector3().setFromMatrixPosition(matrix.clone());
}

/**
 * An identity matrix instance we can use for comparisons.
 *
 * Do not make this public, as we do not want to accidentally mutate it
 */
const _identity4 = new Matrix4();

export function isApproxIdentity(matrix: Matrix4, tolerance = DEFAULT_TOLERANCE): boolean {
    return arraysApproxEqual(matrix.elements, _identity4.elements, tolerance)
}

/**
 * Not all 4x4 matrices correspond to rigid transformations.
 *
 * Return true if the given Matrix4 is one that does.
 */
export function isRigidMatrix(matrix: Matrix4, tolerance = DEFAULT_TOLERANCE): boolean {
    // In a Three Matrix the elements array is in *column-major order*
    // see https://threejs.org/docs/#api/en/math/Matrix4
    // This means the bottom row of the matrix is at indices 3, 7, 11, 15
    const elements = matrix.elements;
    if (
        Math.abs(elements[3]) > tolerance ||
        Math.abs(elements[7]) > tolerance ||
        Math.abs(elements[11]) > tolerance ||
        Math.abs(elements[15] - 1) > tolerance
    ) {
        return false;
    } else {
        return isRotationalMatrix(rotationalPart(matrix), tolerance);
    }
}

/**
 * Calculate the transform matrix for a 'child' relative to a 'parent', given the
 * world-transforms for both.
 */
export function relativeTransform(parentWorld: Matrix4, childWorld: Matrix4): Matrix4 {
    return parentWorld.clone().invert().multiply(childWorld);
}
