<template>
    <div class="app-checkbox-group">
        <slot></slot>
    </div>
</template>

<style scoped>
    :deep(label) {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: normal;
        padding: 0;
        margin-left: 4px;
    }
    :deep(i.mdi-checkbox-marked) {
        color: var(--planner-blue-button);
    }
</style>
<script setup lang="ts"></script>
