import type { PlannerStore } from '@/planner/plannerStore';
import type { PlannerSceneContext } from '@/planner/scene/plannerSceneContext';
import type { StopHandle } from '@/util';
import { logger, stopAll } from '@/util';
import { updateAxes } from '@/planner/3d/axes';
import { updateGrid } from '@/planner/3d/grid';
import { updateGroup } from '@/planner/3d/group';
import { updateAxis } from '@/planner/3d/axis';
import { updatePlannerMesh } from '@/planner/scene/plannerMesh';
import { updateCatstackImage } from '@/planner/scene/catstackImage';
import { updatePlane } from '@/planner/3d/plane';
import { updateCrossSectionClipping } from '@/planner/scene/crossSection';
import type { LoadGeometry } from '@/planner/3d/meshGeometry';
import { makeLoadGeometryInOrder } from '@/planner/3d/meshGeometry';
import { getPlyModel } from '@/planner/api/getPlyModel';

const log = logger('planner-scene');

/** Update */
export function updatePlannerScene(store: PlannerStore, context: PlannerSceneContext): StopHandle {
    const loadGeometry = makeLoadGeometry();
    return stopAll(
        ...Object.values(store.nodes)
            .map((node) => {
                switch (node.type) {
                    case 'axes':
                        return updateAxes(context, node);
                    case 'grid':
                        return updateGrid(context, node);
                    case 'group':
                        return updateGroup(context, node);
                    case 'axis':
                        return updateAxis(context, node);
                    case 'mesh':
                        return updatePlannerMesh(context, store, node, loadGeometry);
                    case 'image-plane':
                        return updateCatstackImage(context, store, node);
                    case 'plane':
                        return updatePlane(context, node);
                    case 'perspective-camera':
                        // Nothing to do for now
                        return;
                    default:
                        log.warning(
                            "Unmapped update for planner-node with type '%s'",
                            (node as { type?: string })?.type ?? 'undefined',
                        );
                }
            })
            .filter((h) => h !== undefined),
        updateCrossSectionClipping(store, context),
    );
}

/**
 * We should be able to load the scene-geometry in parallel, but currently it seems
 * that when we do the data comes back out of order
 * */
const LOAD_GEOMETRY_IN_PARALLEL = false as const;

function makeLoadGeometry(): LoadGeometry {
    return LOAD_GEOMETRY_IN_PARALLEL ? getPlyModel : makeLoadGeometryInOrder();
}
