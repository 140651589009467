import { asyncTimeout, taggedLogger } from '@/util';
import type { AxiosRequestConfig } from 'axios';
import type { ApiPlans } from '@/api/plan/types';
import { fetchPlans } from '@/api/plan/request';

const log = taggedLogger('poll-plans');

const POLL_INTERVAL_MILLISECONDS = 30 * 1000;

/**
 * Repeatedly fetch the plans data until it is cancelled
 */
export async function pollPlans(
    caseId: number,
    onFetch: (data: ApiPlans | null) => void,
    options: {
        signal: AbortSignal;
    },
): Promise<void> {
    const config: AxiosRequestConfig = { signal: options.signal };

    try {
        while (!options.signal.aborted) {
            await asyncTimeout(POLL_INTERVAL_MILLISECONDS, options.signal);

            // Attempt to fetch plans from the API.
            const data = await fetchPlans(caseId, config);
            onFetch(data);
        }
    } catch (e: unknown) {
        if (config.signal?.aborted) {
            log.debug('Polling of plans cancelled');
        } else {
            throw e;
        }
    }
}
