export type Validator<T extends any> = (state: T) => boolean;

export type ValidatorsDefinition<T extends any> = { [key: string]: Validator<T> };

export const isValid = <T extends any>(validators: ValidatorsDefinition<T>, object: T): boolean => {
    const values = Object.entries(validators).map(([key, validator]) => {
        const valid = validator(object);

        if (!valid) {
            console.warn(`${key} is not valid`);
        }

        return valid;
    });

    return values.every((result) => result);
};
