<template>
    <app-error-boundary>
        <!-- A div used to teleport content here and print, using @media print -->
        <div id="print-area" />
        <v-app class="app-wrapper d-print-none" :class="dynamicClass">
            <!-- The top banner has to be displayed the whole time according to the SR-->
            <top-banner v-if="showTopBanner()" />
            <app-idle-warning />
            <app-header />
            <router-view v-slot="{ Component }" class="app-layout-container">
                <suspense timeout="0">
                    <template #fallback>
                        <app-loader>
                            <app-heading>Loading...</app-heading>
                        </app-loader>
                    </template>

                    <template #default>
                        <component :is="Component" />
                    </template>
                </suspense>
            </router-view>
        </v-app>
    </app-error-boundary>
</template>

<script setup lang="ts">
    import { RouterView, useRoute } from 'vue-router';
    import AppErrorBoundary from '@/components/AppErrorBoundary.vue';
    import { showTopBanner } from '@/feature-flags/showTopBanner';
    import TopBanner from '@/components/banners/TopBanner.vue';
    import { computed } from 'vue';
    import { ROUTES } from '@/router';

    const route = useRoute();
    /**
     * In most pages, the top banner is sticky so if the user scrolls it is always visible.
     * In the 3D planner page this is not needed, as the page should not have any scrolling.
     */
    const dynamicClass = computed(() => {
        return {
            'has-banner planner-page': showTopBanner() && route.name === ROUTES.PLANNER,
            'has-banner': showTopBanner() && route.name !== ROUTES.PLANNER,
            'planner-page': !showTopBanner() && route.name === ROUTES.PLANNER,
        };
    });
</script>

<style>
    /*
Vuetify seems to default to adding a scrollbar and we do not want one on the planner page
https://stackoverflow.com/questions/56973002/vuetify-adds-scrollbar-when-its-not-needed
*/
    html {
        overflow-y: auto;
    }
</style>

<style scoped lang="scss">
    @import '../src/assets/variables.scss';

    .app-wrapper {
        background: var(--planner-grey-body);
    }

    /**
        Layouts:
        ========

        1. When there is no top banner, the layout is like this
        +-----------------------+
        |         Header        |   <- Position (Sticky)
        +-----------------------+
        |                       |
        |      Main Section     |   <- Position (Relative) (Padding top to account for the header)
        |                       |
        +-----------------------+

        2. If the top banner is displayed, In most pages (except the 3d planner) it is displayed and the layout is like this:
        +-----------------------+
        |     Top Banner        |   <- Top Banner (Sticky)
        +-----------------------+
        |        Header         |   <- Header (Sticky)
        +-----------------------+
        |                       |
        |     Main Section      |   <- Main Section (Padding top to account for the header + top banner)
        |                       |
        +-----------------------+

        3. If the top banner is displayed, in the 3d planner
        +-----------------------+
        |      Top Banner       |   <- Top Banner (Relative)
        +-----------------------+
        |        Header         |   <- Header (Relative)
        +-----------------------+
        |                       |
        |     Main Section      |   <- Main Section (Relative: without scroll)
        |                       |
        +-----------------------+
        */

    .has-banner .banner-container {
        z-index: 902;
    }

    header {
        z-index: 901;
    }

    :not(.planner-page) {
        .banner-container {
            position: fixed;
            transform: translateY(0%);
        }

        header {
            transform: translateY(0%);
            position: fixed;
        }

        &:not(.has-banner) {
            :deep(.app-layout-container) {
                padding-top: $app-header-height !important;
            }
        }

        &.has-banner {
            header {
                top: $app-top-banner-height;
            }

            :deep(.app-layout-container) {
                padding-top: $app-top-banner-height + $app-header-height !important;
            }
        }
    }

    // On the planner page, it does not matter if the top banner is displayed or not, given the elements
    // position is relative (not sticky) and there is not scrolling.
    .planner-page {
        .banner-container {
            position: relative;
        }

        header {
            top: 0px;
            position: relative;
        }

        :deep(.app-layout-container) {
            top: 0px;
        }
    }
</style>
