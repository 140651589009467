import type { BaseLevels } from 'anylogger';
import { logger, isLogLevel, type LogFunction, type Logger, type LoggerOptions, type LogLevel } from './logger';

/**
 * Create a tagged logger or logging function that prefixes a `[tag]` string to messages.
 *
 * See {@link logger}
 */
export function taggedLogger(tag: string, options?: { name?: string }): Logger
export function taggedLogger(tag: string, options: { name?: string, level: LogLevel }): LogFunction
export function taggedLogger(tag: string, options?: LoggerOptions): Logger | LogFunction {
    const prefix = `[${tag}]`;
    const base = logger({
        ...options,
        name: options?.name ?? tag,
    });

    function prependTag(message?: unknown): string {
        return message ? `${prefix} ${message}` : prefix;
    }

    /**
     * Tagged logging function
     *
     * NOTE: This function is overcomplicated because the 'BaseLogger' interface is overcomplicated.
     * If we wanted to ignore the 'pass the level as the first argument' call overload
     * (i.e. '(level: keyof L, message?: any, ...args: any[]): void')
     * it could be very simple.
     */
    const result = (...args: unknown[]): void => {
        if (args.length == 0) {
            base();
            return;
        }

        const [arg0, ...rest1] = args;

        if (isLogLevel(arg0)) {
            if (rest1.length === 0) {
                base(arg0);
                return;
            }
            const [arg1, ...rest2] = rest1;
            base(arg0, prependTag(arg1), ...rest2);
        } else {
            base(prependTag(arg0), ...rest1)
        }
    };

    return Object.assign(result, {
        error(message?: unknown, ...args: unknown[]): void {
            base.error(prependTag(message), ...args);
        },
        warn(message?: unknown, ...args: unknown[]): void {
            base.warn(prependTag(message), ...args);
        },
        warning(message?: unknown, ...args: unknown[]): void {
            base.warn(prependTag(message), ...args);
        },
        info(message?: unknown, ...args: unknown[]): void {
            base.info(prependTag(message), ...args);
        },
        log(message?: unknown, ...args: unknown[]): void {
            base.log(prependTag(message), ...args);
        },
        debug(message?: unknown, ...args: unknown[]): void {
            base.debug(prependTag(message), ...args);
        },
        trace(message?: unknown, ...args: unknown[]): void {
            base.trace(prependTag(message), ...args);
        },
        enabledFor(level: keyof BaseLevels): boolean {
            return base.enabledFor(level);
        },
    });
}
