import assert from 'assert';
// import Bugsnag from '@bugsnag/js';
import { client } from '@/api/http';
import ContentType from '@/lib/mimetype';
import DownloadUtil from '@/lib/DownloadUtil';
import { HttpStatusCode } from 'axios';
import { logger } from '@/util';

const log = logger('downloadPdf');

/**
 * Download a plan as a pdf to the local computer
 *
 * @see {@link https://stackoverflow.com/a/53775165}
 * {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a}
 */
export const downloadPdf = async (uri: string, filename: string): Promise<void> => {
    try {
        const response = await client.get(uri, {
            responseType: 'blob',
            headers: {
                accept: `${ContentType.Pdf},*/*;q=0.5`,
            },
        });

        if (response.status === HttpStatusCode.Ok) {
            DownloadUtil.httpResponseSaveAs(response, filename);
        } else {
            log.error('Get report failed (%d)', response.status);
        }
    } catch (err: unknown) {
        assert.ok(err instanceof Error);
        log.error('Error fetching report pdf from %s: %s', uri, err.message);
        // Bugsnag.notify(err);
    }
};
