import { splitEvery } from 'ramda';
import { logger } from '@/util/index';

const log = logger('async-iterate');

/** * Options for async iteration */
export type AsyncForEachOptions = {
    /** The number of items to be evaluated on each iteration.
        After N items, the next iteration is scheduled on the next tick.
        Defaults to 1.
     */
    chunkSize?: number;

    /** A signal that can be used to abort the iteration */
    signal?: AbortSignal;

    /** A callback that, if given, will be called when the iteration is completed or aborted */
    metrics?: (metrics: AsyncForEachMetrics) => void;
};

/**
 * Allows performing an iteration asynchronously, only iterating over a 'chunk' of items each tick.
 *
 * Strategy:
 * 1. The list of items is split into chunks of size _N_.
 * 2. The callback function is invoked sequentially for each chunk.
 * 3. After each chunk is executed, a timeout allows other operations to use the CPU
 *
 * @param items the array of items to be iterated.
 * @param callback the callback for each item
 * @param options optional parameters - see {@link AsyncForEachOptions}
 */
export async function asyncForEach<T>(
    items: T[],
    callback: (item: T) => void,
    options?: AsyncForEachOptions,
): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        let totalCycles = 0;
        const chunkSize = options?.chunkSize ?? 1;

        (async () => {
            try {
                const chunks = splitEvery(chunkSize, items);
                const chunkLength = chunks.length;

                // Using simple for/loop instead of array.forEach(), given the intent is to sequentially execute the
                // different promises, and forEach() will not serve that purpose
                // Gotchas:
                // forEach does not work: {@link https://stackoverflow.com/a/37576787}
                // sequentially await promises: {@link https://stackoverflow.com/a/46086037}
                for (const [chunkIndex, chunk] of chunks.entries()) {
                    ++totalCycles;
                    await _runAndDefer(() => {
                        options?.signal?.throwIfAborted();

                        const isLastChunk = chunkIndex === chunkLength - 1;
                        chunk.forEach(callback);

                        if (isLastChunk) {
                            if (options?.metrics) {
                                options.metrics({
                                    aborted: false,
                                    numberOfCycles: totalCycles,
                                    totalLength: items.length,
                                });
                            }
                            resolve();
                        } else {
                            // nothing to do, keep looping.
                        }
                    });
                }
            } catch (err) {
                if (options?.signal?.aborted) {
                    if (options?.metrics) {
                        options.metrics({
                            aborted: true,
                            numberOfCycles: totalCycles,
                            totalLength: items.length,
                        });
                    }
                } else {
                    log.error(
                        'Error while async-iterating. Only %d cycles executed. %s',
                        totalCycles,
                        err,
                    );
                }
                reject(err);
            }
        })();
    });
}

/** * Metrics describing the result of an async iteration */
export type AsyncForEachMetrics = {
    /** True if the iteration was aborted */
    aborted: boolean;
    /** The total number of cycles the that the function run */
    numberOfCycles: number;
    /** The total length of the array to be iterated */
    totalLength: number;
};

/**
 * Run a callback, and delay resolving the promise until the current call stack has cleared.
 *
 * Note: The callback is intentionally run outside the _.defer().
 *       To handle errors, `_.defer` (a simple `setTimeout()` function),
 *       requires a `try-catch` inside the body of the function,
 *       and doing that will add coupling between this utility and the caller.
 * @see https://stackoverflow.com/a/41431714
 */
function _runAndDefer(callback: () => void): Promise<void> {
    return new Promise((resolve) => {
        callback();

        // The callback is intentionally run outside the _.defer().
        setTimeout(resolve, 1);
    });
}
