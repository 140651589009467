/**
 * Implements software requirements: H1SR-11, H1SR-86
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-11/Default-values-for-cases-can-be-defined-in-the-hip-preferences
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-86/Surgeon-users-are-able-to-define-from-a-selection-of-stem-types-which-stems-to-be-used-in-the-fitting-process
 */
import { isFloat, isInRange, isNotEmpty } from '@/lib/validation';
import type { SpinopelvicInputs } from '@/stores/spinopelvic/types';
import type { ValidatorsDefinition } from '@/stores/validation';

const LOWER_LIMIT = -360;
const UPPER_LIMIT = 360;
const INCLUSIVE = true;

export const allFieldsEmpty = (inputs: SpinopelvicInputs): boolean => {
    return (
        !isNotEmpty(inputs.standingPelvicTilt) &&
        !isNotEmpty(inputs.pelvicFemoralAngle) &&
        !isNotEmpty(inputs.lumbarLordosis) &&
        !isNotEmpty(inputs.sacralSlope) &&
        !isNotEmpty(inputs.pelvicTilt)
    );
};

export const isPopulated = (value: string): boolean => {
    return (
        isNotEmpty(value) &&
        isFloat(value, 2) &&
        isInRange(value, LOWER_LIMIT, UPPER_LIMIT, INCLUSIVE)
    );
};

export const validators: ValidatorsDefinition<SpinopelvicInputs> = {
    standingPelvicTilt: (inputs) =>
        isPopulated(inputs.standingPelvicTilt) || allFieldsEmpty(inputs),
    pelvicFemoralAngle: (inputs) =>
        isPopulated(inputs.pelvicFemoralAngle) || allFieldsEmpty(inputs),
    lumbarLordosis: (inputs) => isPopulated(inputs.lumbarLordosis) || allFieldsEmpty(inputs),
    sacralSlope: (inputs) => isPopulated(inputs.sacralSlope) || allFieldsEmpty(inputs),
    pelvicTilt: (inputs) => isPopulated(inputs.pelvicTilt) || allFieldsEmpty(inputs),
};
