import type { PlannerMeshId } from '@/planner/scene/plannerObjectId';
import type { VisibilityToggles } from '@/planner/visibilityToggles';
import { crossSectionIds } from '@/planner/scene/crossSection';
import { none } from 'ramda';

export function isMeshVisible(visibility: VisibilityToggles, plannerId: PlannerMeshId): boolean {
    switch (plannerId) {
        case 'metal':
            return true;
        case 'cup-collision-surface':
            return false;
        case 'bearing':
            return true;
        case 'cup':
        case 'liner':
        case 'stem':
        case 'head':
            return visibility.implant === 'on';
        case 'cup-coverage':
            return visibility.implant === 'on' && visibility.xray === 'on'
                && none(id => visibility[id] === 'on', crossSectionIds);
        case 'operative-femur':
        case 'operative-femur-internal':
        case 'contralateral-femur':
            return visibility.femur === 'on';
        case 'sacrum':
        case 'operative-hemipelvis':
        case 'contralateral-hemipelvis':
            return visibility.pelvis === 'on';
    }
}
