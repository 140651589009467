<template>
    <app-notification
        v-if="store.isError && store.error"
        :title="store.error.title"
        :message="store.error.message"
        variant="error"
        :animate-to="true"
    />
</template>

<script setup lang="ts">
    import { useCaseSettings } from '@/stores/caseSettings/store';
    import { useSavingHooks } from '@/stores/shared/useSavingHooks';
    import { onBeforeUnmount, ref } from 'vue';

    const store = useCaseSettings();

    const { watchErrorOnSaving } = useSavingHooks();

    const show = ref(false);

    const watcher = watchErrorOnSaving(() => {
        show.value = store.isError;
    });

    onBeforeUnmount(() => {
        watcher();
    });
</script>
