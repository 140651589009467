// API representation of matrices
import { Matrix4 } from 'three';

/**
 * Array of 16 numbers, for a 4x4 Matrix
 *
 * This is by convention in [Row-major order](
 *  https://en.wikipedia.org/wiki/Row-_and_column-major_order#Row-major_order
 * )
 */
export type MatrixArray16 = [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
];

export function isMatrixArray16(o: unknown): o is MatrixArray16 {
    return !!o && Array.isArray(o) && o.length === 16;
}

/**
 * Convert an api-structured matrix a Matrix4
 *
 * By convention the API represents matrix in [Row-major order](
 *   https://en.wikipedia.org/wiki/Row-_and_column-major_order#Row-major_order)
 * Three js represents matrix4 internally in column-major order, so it needs to be transposed before calling 'toArray()'
 *
 * @see https://threejs.org/docs/#api/en/math/Matrix4
 */
export function matrixToApi(matrix: Matrix4): MatrixArray16 {
    return matrix.clone().transpose().toArray();
}

/**
 * Convert an API-style matrix (array of 16 numbers) into a Matrix4
 *
 * The array representing the matrix will usually be the transformation matrix that comes from master,
 * found in the case-data
 *
 * This is by convention in [Row-major order](https://en.wikipedia.org/wiki/Row-_and_column-major_order#Row-major_order)
 */
export function matrixFromApi(matrixArray: MatrixArray16): Matrix4 {
    const matrix4 = new Matrix4();
    matrix4.set(
        matrixArray[0],
        matrixArray[1],
        matrixArray[2],
        matrixArray[3],
        matrixArray[4],
        matrixArray[5],
        matrixArray[6],
        matrixArray[7],
        matrixArray[8],
        matrixArray[9],
        matrixArray[10],
        matrixArray[11],
        matrixArray[12],
        matrixArray[13],
        matrixArray[14],
        matrixArray[15],
    );

    return matrix4;
}
