import numeral from 'numeral';
import { isFiniteNumber } from '@/util';

/**
 * Format a number as an angle
 *
 * E.g:
 * 38.33333 => formatLength(38.33333, 1) = 38.3 mm
 * 38.33333 => formatLength(38.33333, 2) = 38.33 mm
 */
export function formatLength(value: number | undefined | null, precision: number = 0): string {
    if (isFiniteNumber(value)) {
        return numeral(value).format('0.' + '0'.repeat(precision)) + ' mm';
    } else {
        return '--';
    }
}
