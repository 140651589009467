<template>
    <div class="error-screen" v-if="appError.hasError">
        <app-heading>
            <v-icon icon="mdi-alert-circle"></v-icon>
            {{ appError.message ?? 'unexpected application error'}}
        </app-heading>

        <a href="https://support.formuslabs.com/hc/en-us" target="_blank">
            Please contact support for assistance
        </a>
    </div>
    <slot v-else></slot>
</template>

<script setup lang="ts">
    import { useAppErrorStore } from '@/stores/appErrorStore';

    const appError = useAppErrorStore();
</script>

<style scoped>
    .error-screen {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;
        padding: 40px;
        text-align: center;
    }
</style>
