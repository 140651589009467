import { formatNode, type ObjectNode } from '@/planner/3d/object';
import type { Object3D } from 'three';
import { type StopHandle, taggedLogger } from '@/util';
import { watchImmediate } from '@vueuse/core';

const log = taggedLogger('3D');

/** The default render-order */
const DEFAULT_RENDER_ORDER = 0 as const;
export type RenderOrderNode = {
    renderOrder: number;
};

export function renderOrderNode(properties?: Partial<RenderOrderNode>): RenderOrderNode {
    return {
        renderOrder: properties?.renderOrder ?? DEFAULT_RENDER_ORDER,
    };
}

export function updateRenderOrder(node: RenderOrderNode & ObjectNode, object: Object3D): StopHandle {
    return watchImmediate(
        () => node.renderOrder,
        (order) => {
            log.debug('Setting render order for %s to %d', formatNode(node), order);
            object.renderOrder = order;
        },
    );
}
