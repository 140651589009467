import type { SyncTemplate, TemplateId } from '@/formus/template/template';
import type { Url } from '@/formus/types';
import { type AxiosRequestConfig, HttpStatusCode } from 'axios';
import { client } from '@/api/http';
import { errorDetail } from '@/planner/api/errorDetail';
import { templateUrl } from '@/api/template/templateUrl';
import type { ApiTemplateProperties } from '@/api/template/templateProperties';
import type { ApiLinks } from '@/api/links';
import { isApproxIdentity } from '@/geometry/matrix';
import { transformMatrixToApi } from '@/geometry/apiTransform';

export async function putTemplate(
    templateId: TemplateId | Url,
    template: SyncTemplate,
    config?: AxiosRequestConfig,
): Promise<void> {
    const url = templateUrl(templateId);

    /**
     * The Vue 2 app is not expecting an identity transform, and raises a conflict if you set
     * one. This is a workaround, temporarily reproducing the existing behavior, which to
     * set a null transform instead of an identity transform.
     *
     * TODO: When we no longer need to support the Vue 2 app we can remove this and just send
     *   an identity transform
     * */
    const stemTransform = isApproxIdentity(template.stemTransform)
        ? null
        : transformMatrixToApi(template.stemTransform);

    const templateData: ApiTemplateUpdate = {
        cup_offset: template.cupOffset,
        cup_rotation: template.cupRotation,
        target_offset_change: template.targetOffsetChange,
        target_leg_length_change: template.targetLegLengthChange,
        stem_transform: stemTransform,
        dual_mobility: template.dualMobility,
        links: [
            {
                rel: 'self',
                href: url,
                title: null,
            },
            {
                rel: 'cup',
                href: template.cup,
                title: 'current',
            },
            {
                rel: 'liner',
                href: template.liner,
                title: 'current',
            },
            {
                rel: 'stem',
                href: template.stem,
                title: 'current',
            },
            {
                rel: 'head',
                href: template.head,
                title: 'current',
            },
        ],
    };

    const { status, data } = await client.put(url, templateData, config);

    if (status !== HttpStatusCode.NoContent) {
        throw Error('Failed to put surgical-template' + errorDetail(data));
    }
}

export type ApiTemplateUpdate = ApiTemplateProperties & ApiLinks;
