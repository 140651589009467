import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import colors from '@/planner/components/colors';
import { createVuetify } from 'vuetify';

export function registerVuetify() {
    return createVuetify({
        components,
        directives,
        theme: {
            themes: {
                light: {
                    dark: false,
                    colors: { ...colors },
                },
            },
        },
    });
}
