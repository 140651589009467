import type { Adjustment } from '@/planner/adjustments';

export type AdjustmentKey =
    | 'stem-atv'
    | 'leg-length'
    | 'leg-offset'
    | 'cup-atv'
    | 'cup-inc'
    | 'cup-coverage';

export type AdjustmentInfo = Adjustment & {
    title: string;
    format: (value: number | null) => string;
};

export function createAdjustment(
    title: string,
    format: (value: number | null) => string,
    data: Partial<Adjustment> | number | null = {},
): AdjustmentInfo {
    if (typeof data === 'number') {
        // If `data` is a number, treat it as the `value`
        return {
            title,
            format,
            value: data,
            calculating: false, // Default to false since no calculating state
            warning: false, // Default to no warning
        };
    }

    return {
        title,
        format,
        value: data?.value ?? null,
        calculating: data?.calculating ?? false,
        warning: data?.warning ?? false,
    };
}
