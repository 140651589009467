<script async setup lang="ts">
    import { ref } from 'vue';
    import { ROUTES } from '@/router';
    import { search, type SearchResult } from '@/api/caseSearch';
    import { isUserFriendlyErrorMessage } from '@/api/http';
    import { debounce } from 'lodash';
    import { assertNonNull } from '@/util/assert';

    const isLoading = ref(true);
    const error = ref('');
    const searchText = ref('');
    const searchFilter = ref('');
    const result = ref<SearchResult>({
        count: 0,
        pages: {
            current: 0,
            previous: null,
            next: null,
        },
        cases: [],
    });

    async function nextPage() {
        await load(assertNonNull(result.value.pages.next, 'pages.next'));
        window.scrollTo(0, 0);
    }

    async function previousPage() {
        await load(assertNonNull(result.value.pages.previous, 'pages.previous'));
        window.scrollTo(0, 0);
    }

    async function load(nextPage = 1) {
        isLoading.value = true;
        error.value = '';

        try {
            const response = await search(searchText.value, nextPage);
            if (isUserFriendlyErrorMessage(response)) {
                error.value = response;
            } else {
                searchFilter.value = searchText.value;
                result.value = response;
            }
        } finally {
            isLoading.value = false;
        }
    }

    /**
     * Time in milliseconds where the search is debounced.
     *
     * This number has to be low enough to produce a good UX,
     * but not that that low that we hit the API multiple unnecessary times. Ideally the whole interaction
     * should be below 250ms.
     *
     * Note: Based on how this request performs on real environments we might need to adjust this value.
     * As this article says { @see https://stackoverflow.com/a/44755058 } this number has:
     * > In general the reason for making use of a debounce operation can be summed up
     * > as having one of two purposes:
     * > Reducing the cost of providing dynamic interactive elements (where cost in our case is more request to the API).
     * > Reducing visual "noise" to avoid distracting the user with page updates while they are busy.
     */
    const DEBOUNCE_SEARCH_IN_MILLISECONDS = 150;

    const debounceLoad = debounce(load, DEBOUNCE_SEARCH_IN_MILLISECONDS);

    // initial page load without search query or pagination
    await load();
</script>

<template>
    <v-layout>
        <app-sidebar>
            <router-link :to="{ name: ROUTES.NEW_CASE }">
                <app-button colour="green" prepend-icon="mdi-plus">Create new Case</app-button>
            </router-link>
        </app-sidebar>

        <app-main-view>
            <div class="case-list-header">
                <h2>
                    Your Formus Cases
                    <span class="app-case-count" v-if="result.count">({{ result.count }})</span>
                </h2>

                <div class="case-list-search">
                    <app-text-input
                        prepend-icon="mdi-magnify"
                        placeholder="Search Cases"
                        bg-color="white"
                        clearable
                        v-model="searchText"
                        @update:model-value="debounceLoad()"
                    />
                </div>
            </div>

            <div class="case-list" v-if="result.count > 0">
                <app-case-card
                    v-for="item in result.cases"
                    :key="item.id"
                    :id="item.id"
                    :title="item.name"
                    :description="item.description"
                    :reference="item.id"
                    :patient="item.patient"
                    :surgeon="item.surgeon"
                    :owner="item.owner"
                    :createdAt="item.createdAt"
                    :status="item.status ?? { state: 'unknown', failed: false }"
                />
                <div class="case-pagination">
                    <a v-if="result.pages.previous" @click="previousPage">previous</a>
                    <span>{{ result.pages.current }}</span>
                    <a v-if="result.pages.next" @click="nextPage">next</a>
                </div>
            </div>
            <div v-else-if="result.count === 0">
                We're sorry, we couldn't find any Cases including "{{ searchFilter }}". Try changing
                your search terms, or clear the search to return to your Cases.
            </div>
        </app-main-view>
    </v-layout>
</template>

<style scoped>
    .case-list-header {
        display: flex;
        justify-content: space-between;
        max-width: 1020px;
    }

    .case-list-search {
        flex-basis: 240px;
    }

    .case-list-header h2 {
        color: var(--planner-black);
        font-size: 1.75rem;
        line-height: 2.875rem;
        font-weight: 400;
        margin: 0 0 2.5rem;
        letter-spacing: 0.0125em;
    }

    .app-case-count {
        color: var(--planner-grey) !important;
    }

    .case-pagination {
        display: flex;
        justify-content: center;
        max-width: 1020px;
        margin: 20px;
    }

    .case-pagination a {
        font-size: 12px;
        margin-left: 10px;
        cursor: pointer;
        user-select: none;
    }

    .case-pagination span {
        font-size: 12px;
        margin-left: 10px;
    }
</style>
