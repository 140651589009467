/**
 * Implements software requirements: H1SR-11, H1SR-86
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-11/Default-values-for-cases-can-be-defined-in-the-hip-preferences
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-86/Surgeon-users-are-able-to-define-from-a-selection-of-stem-types-which-stems-to-be-used-in-the-fitting-process
 */
import { isEmpty } from 'lodash';
import { isInRange, isInteger, isNotEmpty } from '@/lib/validation';
import type { PreferencesFormState } from '@/stores/preferences';

export enum AnteversionMode {
    Auto = 'auto',
    Manual = 'manual',
}

/**
 * functions should return true when the input is valid
 * and false when it is not valid.
 */
export const validators: { [key: string]: (p: PreferencesFormState) => boolean } = {
    stems: ({ stems, preferredSystem }) =>
        !isEmpty([...stems].filter((stem) => stem.includes(preferredSystem))),
    preferredSystem: ({ preferredSystem }) => isNotEmpty(preferredSystem),
    cupInclinationAngle: ({ cup: { inclinationAngle } }) => {
        return isInteger(inclinationAngle) && isInRange(inclinationAngle, 0, 60, true);
    },
    cupFittingMode: ({ cup: { fitMethod } }) => isNotEmpty(fitMethod),
    cupAnteversionMode: ({ cup: { anteversion } }) =>
        anteversion !== undefined && anteversion !== null && anteversion.length > 0,
    cupAnteversionAngle: ({ cup: { anteversion, anteversionAngle } }) => {
        return (
            anteversion !== undefined &&
            anteversion !== null &&
            (anteversion === AnteversionMode.Auto ||
                (anteversion === AnteversionMode.Manual &&
                    isInteger(anteversionAngle) &&
                    isInRange(anteversionAngle, -5, 45, true)))
        );
    },
    alignmentMode: ({ cup: { alignment } }) =>
        alignment !== undefined && alignment !== null && alignment.length > 0,
};

export const isValid = (preferences: PreferencesFormState): boolean =>
    Object.values(validators)
        .map((validator) => validator(preferences))
        .every((result) => result);
