// filter length floats to n-th decimal place
// TODO this should be a global filter DRY this Vue.filter
import numeral from 'numeral';
import { isFiniteNumber } from '@/util';

/**
 * Filter percentage floats to 1 decimal place
 *
 * E.g:
 * 0.38 => formatPercentageNumber(38.33333, 1) = 38%
 * -0.38 => formatPercentageNumber(-38.33333, 2) = -38%
 */
export function formatPercentageNumber(value: number): string {
    if (isFiniteNumber(value)) {
        return numeral(value).format('0%');
    } else {
        return '--';
    }
}
