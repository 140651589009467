<script setup lang="ts">
    import { toRefs } from 'vue';

    export interface Props {
        icon?: string;
        bold?: boolean;
        color?: string;
        alone?: boolean;
    }
    const props = withDefaults(defineProps<Props>(), {
        icon: '',
        color: 'black',
        bold: false,
        alone: false,
    });
    const { icon, bold, color, alone } = toRefs(props);

    const fontWeight = bold.value ? 600 : 400;
    const fontColour = `var(--planner-${color.value})`;
    const fontColourHover = `var(--planner-${color.value}-rich)`;
    const padding = alone.value ? '0' : '0 20px';
</script>

<template>
    <button>
        <span v-if="icon" class="app-link-icon">
            <v-icon :icon="icon" size="large" />
        </span>
        <span class="app-link-content">
            <slot></slot>
        </span>
    </button>
</template>

<style scoped>
    button {
        color: v-bind('fontColour');
        font-size: 14px;
        font-weight: v-bind('fontWeight');
        height: 40px;
        line-height: 1;
        text-align: center;
        padding: v-bind('padding');
    }
    .app-link-icon {
        margin-right: 8px;
    }
    .app-link-icon:hover {
        color: v-bind('fontColourHover');
    }
</style>
